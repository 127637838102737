import React, { Component } from "react";
import { Trans } from "@lingui/macro";
import { toast } from "react-toastify";
import PageHeader from "../components/PageHeader";
import PageBody from "../components/PageBody";
import { ApiContext } from "../contexts/ApiContext";
import { post } from "../api";
import parseErrorMessage from "helpers-js/parseErrorMessage";
import { Button } from "@iforwms/react-components";

class FeedbackCreate extends Component {
  static contextType = ApiContext;
  state = {
    title: "",
    user_id: "",
    severity: "",
    os: "",
    status: "",
    description: "",
    url: "",
    images: [],
    type: "",
    isLoading: false,
    isSubmitting: false,
    isUploadingImage: false,
    time_estimate: "",
    error: null
  };

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  safeSetState = (...args) => {
    this._isMounted && this.setState(...args);
  };

  downscaleImage(dataUrl, newWidth, imageType, imageArguments) {
    let image, oldWidth, oldHeight, newHeight, canvas, ctx, newDataUrl;

    // Provide default values
    imageType = imageType || "image/jpeg";
    imageArguments = imageArguments || 0.7;

    // Create a temporary image so that we can compute the height of the downscaled image.
    image = new Image();
    image.src = dataUrl;
    oldWidth = image.width;
    oldHeight = image.height;
    newHeight = Math.floor((oldHeight / oldWidth) * newWidth);

    // Create a temporary canvas to draw the downscaled image on.
    canvas = document.createElement("canvas");
    canvas.width = newWidth;
    canvas.height = newHeight;

    // Draw the downscaled image on the canvas and return the new data URL.
    ctx = canvas.getContext("2d");
    ctx.drawImage(image, 0, 0, newWidth, newHeight);
    newDataUrl = canvas.toDataURL(imageType, imageArguments);

    return newDataUrl;
  }

  handleSubmit = e => {
    e.preventDefault();

    if (this.state.isUploadingImage) {
      return toast.warn(<Trans>Image upload in progress, please wait.</Trans>);
    }
    let {
      user_id,
      title,
      description,
      severity,
      type,
      os,
      images,
      time_estimate,
      status
    } = this.state;

    this.safeSetState({ isSubmitting: true, error: null });

    const imageNames = images.map(image => image.name);

    let data = {
      assignee_id: user_id,
      title: `${title}`,
      description,
      priority: severity,
      type,
      status,
      time_estimate,
      images: imageNames,
      platform: os
    };

    this.context
      .callApi(() => post(`feedback/general`, data))
      .then(() => {
        this.safeSetState({
          title: "",
          severity: "",
          type: "",
          status: "",
          os: "",
          description: "",
          user_id: "",
          url: "",
          images: [],
          isSubmitting: false
        });

        if (this.imageRef) {
          this.imageRef.value = "";
        }

        toast.success(<Trans>Thanks for getting in touch!</Trans>);
      })
      .catch(e => {
        this.safeSetState({ isSubmitting: false, error: e });
        toast.error(parseErrorMessage(e, <Trans>Failed to create issue, oh the irony...</Trans>));
      });
  };

  handleImageChange = e => {
    e.preventDefault();

    let reader = new FileReader();
    let file = e.target.files[0];
    const formData = new FormData();
    formData.append("file", file, file.name);
    this.safeSetState({ isUploadingImage: true });
    this.context
      .callApi(() => post(`media`, formData))
      .then(res => {
        reader.onloadend = () => {
          this.safeSetState({
            isUploadingImage: false,
            images: this.state.images.concat({
              name: res.data.data.name,
              preview: reader.result
            })
          });

          if (this.imageRef) {
            this.imageRef.value = "";
          }
        };
        reader.readAsDataURL(file);
      })
      .catch(e => {
        this.safeSetState({ isUploadingImage: false });
        toast.error(parseErrorMessage(e, <Trans>Failed to upload image.</Trans>));
      });
  };

  removeImage = imageToRemove => {
    this.setState(prevState => ({
      images: prevState.images.filter(image => image.name !== imageToRemove)
    }));
  };

  render() {
    let {
      title,
      severity,
      os,
      time_estimate,
      url,
      images,
      type,
      user_id,
      error,
      status,
      isSubmitting,
      isUploadingImage,
      description
    } = this.state;

    let imagePreview = null;
    if (images.length) {
      imagePreview = (
        <div>
          {images.map(image => (
            <div
              key={image.name}
              onClick={() => this.removeImage(image.name)}
              className={`cursor-pointer mb-4 overflow-hidden rounded`}>
              <img src={image.preview} alt="" />
            </div>
          ))}
        </div>
      );
    } else {
      imagePreview = (
        <div className="previewText">
          <Trans>Please select an Image for Preview</Trans>
        </div>
      );
    }

    const { search } = this.props.location;
    return (
      <div>
        <PageHeader
          backLink={`/feedback${search}`}
          title={<Trans>Report an Feedback</Trans>}
          body={<Trans>Here you can create an feedback or feature request.</Trans>}
        />
        <PageBody>
          <div className="p-4 md:p-8 container-narrow">
            <form action="#" onSubmit={this.handleSubmit} style={{ maxWidth: "500px" }}>
              <div className="mb-4">
                <label className="form-label text-red">Title</label>
                <input
                  type="text"
                  className="form-input"
                  value={title}
                  onChange={e => this.safeSetState({ title: e.target.value })}
                />
                <span className="text-xs text-gray">
                  E.g. Lesson Mastery - show correct answers if answered incorrectly.
                </span>
              </div>

              <div className="mb-4">
                <label className="form-label">Description</label>
                <textarea
                  rows="5"
                  className="form-input"
                  onChange={e =>
                    this.safeSetState({
                      description: e.target.value
                    })
                  }
                  value={description}
                />
                <span className="block text-xs leading-normal text-gray">
                  E.g. I want to be able to view how many lessons I have completed.
                </span>
              </div>

              <div className="mb-4">
                <label className="form-label">Assigned User</label>
                <select
                  value={user_id}
                  className="form-input"
                  onChange={e => this.safeSetState({ user_id: e.target.value })}>
                  <option value="">Not Set</option>
                  <option value="5413">Ifor</option>
                  <option value="11816">Charles</option>
                  <option value="2463">Felix</option>
                  <option value="16813">Chris</option>
                  <option value="18315">Gavin</option>
                </select>
              </div>

              <div className="pb-4 mb-4 border-b">
                <Button
                  colour={`teal`}
                  disabled={isSubmitting}
                  text={isSubmitting ? <Trans>Submitting</Trans> : <Trans>Quick Submit</Trans>}
                />
              </div>

              <div className="mb-4">
                <label className="form-label">Type</label>
                <select
                  value={type}
                  className="form-input"
                  onChange={e => this.safeSetState({ type: e.target.value })}>
                  <option value="">Not Set</option>
                  <option value="feature">Feature</option>
                  <option value="enhancement">Enhancement</option>
                  <option value="subscription">Subscription</option>
                  <option value="bug">Bug</option>
                  <option value="video_bug">Video Bug</option>
                  <option value="marketing">Marketing</option>
                  <option value="research">Research</option>
                </select>
              </div>

              <div className="mb-4">
                <label className="form-label">Url</label>
                <input
                  type="text"
                  value={url}
                  className="form-input"
                  onChange={e => this.safeSetState({ url: e.target.value })}
                />
                <span className="text-xs text-gray">The URL you are on when the error appears</span>
              </div>

              <div className="mb-4">
                <label className="form-label">Time Estimate</label>
                <input
                  type="text"
                  value={time_estimate}
                  className="form-input"
                  onChange={e => this.safeSetState({ time_estimate: e.target.value })}
                />
                <span className="text-xs text-gray">
                  Time estimate for completing issue (1 point = 1 day).
                </span>
              </div>

              <div className="mb-4">
                <label className="form-label">Severity</label>
                <select
                  value={severity}
                  className="form-input"
                  onChange={e => this.safeSetState({ severity: e.target.value })}>
                  <option value="">Not Set</option>
                  <option value="low">Low</option>
                  <option value="medium">Medium</option>
                  <option value="high">High</option>
                  <option value="critical">Critical</option>
                </select>
                <span className="text-xs text-gray">
                  Low: typos, etc <br />
                  Medium: Affects the user experience, but not urgent
                  <br />
                  High: Affects user experience, needs fixing as soon as possible.
                  <br />
                  Critical: Major issue, to be fixed ASAP.
                </span>
              </div>

              <div className="mb-4">
                <label className="form-label">Status</label>
                <select
                  value={status}
                  className="form-input"
                  onChange={e => this.safeSetState({ status: e.target.value })}>
                  <option value="">Not Set</option>
                  <option value="backlog">Backlog</option>
                  <option value="next_sprint">Next Sprint</option>
                  <option value="current_sprint">Current Sprint</option>
                  <option value="in_progress">In Progress</option>
                </select>
              </div>

              <div className="mb-4">
                <label className="form-label">Platform</label>
                <select
                  value={os}
                  className="form-input"
                  onChange={e => this.safeSetState({ os: e.target.value })}>
                  <option value="">Not Set</option>
                  <option value="server">Server</option>
                  <option value="android">Android</option>
                  <option value="ios">iOS</option>
                  <option value="study">Study</option>
                  <option value="admin">Admin</option>
                  <option value="forum">Forum</option>
                  <option value="wp">Wordpress</option>
                  <option value="content">Content</option>
                  <option value="third_party">Third Party</option>
                </select>
              </div>

              <div className="mb-4">
                <label className="form-label">Screenshot</label>
                <input
                  className="text-sm"
                  type="file"
                  accept="image/*"
                  ref={el => (this.imageRef = el)}
                  onChange={this.handleImageChange}
                />
                <span className="block text-xs leading-normal text-gray">Images only.</span>
              </div>

              <div className="p-4 my-8 text-sm border rounded shadow">
                <p className="mb-1">
                  <strong>Title: </strong>
                  {title}
                </p>
                <p className="mb-1">
                  <strong>Type: </strong>
                  {type}
                </p>
                <p className="mb-1">
                  <strong>URL: </strong>
                  {url}
                </p>
                <p className="mb-1">
                  <strong>Severity: </strong>
                  {severity}
                </p>
                <p className="mb-1">
                  <strong>Operating System: </strong>
                  {os}
                </p>
                <p className="mb-1">
                  <strong>Email: </strong>
                  {this.context.user.email}
                </p>
                <p className="mb-1">
                  <strong>Assignee ID: </strong>
                  {user_id}
                </p>
                <p className="mb-1">
                  <strong>Description: </strong>
                  {description}
                </p>
                <div className="mb-1">
                  <strong className="block">Screenshot:</strong>
                  <div className="mt-2">{imagePreview}</div>
                </div>
              </div>

              <Button
                colour={`teal`}
                disabled={isSubmitting}
                text={isSubmitting ? <Trans>Submitting</Trans> : <Trans>Submit</Trans>}
              />
            </form>
          </div>
        </PageBody>
      </div>
    );
  }
}

export default FeedbackCreate;
