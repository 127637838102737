import React from "react";
import PropTypes from "prop-types";
import LoadingWrapper from "../external/LoadingWrapper";
import MediaUpload from "../external/forms/MediaUpload";
import Panel from "../external/forms/Panel";
import PageHeader from "../PageHeader";
import PageBody from "../PageBody";
import { Trans } from "@lingui/macro";
import { get, patch, post } from "../../api";
import parseErrorMessage from "helpers-js/parseErrorMessage";
import { toast } from "react-toastify";
import history from "../history";

const WordCharacter = ({ isSubmitting, character, removeCharacter, updateCharacter }) => (
  <div className="flex mb-2">
    <input
      disabled={isSubmitting}
      className="block p-2 text-gray-700 border-t border-b border-l rounded-l text-md"
      type="text"
      placeholder="Character"
      onChange={e => updateCharacter(e.target.value, "text", character.id)}
      value={character.text}
    />
    <input
      disabled={isSubmitting}
      className="block p-2 text-gray-700 border-t border-b border-l text-md"
      placeholder="Pinyin"
      type="text"
      onChange={e => updateCharacter(e.target.value, "pinyin", character.id)}
      value={character.pinyin}
    />
    <select
      disabled={isSubmitting}
      className="block p-2 text-gray-700 border rounded-r text-md"
      onChange={e => updateCharacter(e.target.value, "tone", character.id)}
      value={character.tone}>
      <option disabled value="">
        Select Tone
      </option>
      <option value="0">Neutral</option>
      <option value="1">1st</option>
      <option value="2">2nd</option>
      <option value="3">3rd</option>
      <option value="4">4th</option>
    </select>
    <button
      disabled={isSubmitting}
      onClick={() => removeCharacter(character.id)}
      className="p-2 ml-4 text-red-500 border border-red-500 rounded hover:text-red-700 hover:border-red-700">
      X
    </button>
  </div>
);

class DictionaryShow extends React.Component {
  state = {
    characters: [],
    isLoading: false,
    error: null,
    fetchError: null,
    isSubmitting: false
  };

  componentDidMount() {
    this._isMounted = true;

    this.fetchItem();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps) {
    if (this.props.id && this.props.id !== prevProps.id) {
      this.fetchItem();
    }
  }

  safeSetState = (...args) => {
    this._isMounted && this.setState(...args);
  };

  setParentState = (valueKey, value) => {
    this.setState({ [valueKey]: value });
  };

  updateCharacter = (value, type, characterId) => {
    this.setState(prevState => ({
      characters: prevState.characters.map(char => {
        if (char.id !== characterId) {
          return char;
        }

        return { ...char, [type]: value };
      })
    }));
  };

  removeCharacter = id => {
    this.setState(prevState => ({
      characters: prevState.characters.filter(char => char.id != id)
    }));
  };

  fetchItem = () => {
    this.safeSetState({ isLoading: true, fetchError: null });

    get(`/dictionary/${this.props.match.params.id}?edit`)
      .then(({ data }) => {
        this.safeSetState({ ...data.data, isLoading: false });
      })
      .catch(error => this.safeSetState({ fetchError: error, isLoading: false }));
  };

  handleSubmit = e => {
    e.preventDefault();

    this.safeSetState({ submitError: null, isSubmitting: true });

    patch(`/dictionary/${this.props.match.params.id}`, this.state)
      .then(({ data }) => {
        this.safeSetState({ ...data.data, isSubmitting: false });
        toast.success("Word updated!");
      })
      .catch(error => {
        this.safeSetState({ submitError: error, isSubmitting: false });
        toast.error(parseErrorMessage(error, "Failed to update error message"));
      });
  };

  render() {
    const {
      isLoading,
      error,
      characters,
      text,
      language_level,
      audio_src,
      definitions,
      isSubmitting,
      id
    } = this.state;

    return (
      <div>
        <PageHeader isAdmin={true} title={<Trans>Word Edit</Trans>} />
        <PageBody>
          <div className="max-w-3xl p-4 mx-auto">
            <form method={`PATCH`} onSubmit={e => this.handleSubmit(e)}>
              <LoadingWrapper isLoading={isLoading} error={error} retry={this.fetchItem}>
                <div>
                  <Panel colour="blue" title={`Edit Word`}>
                    <div className="">
                      <label className="block text-xs text-gray-500 uppercase undefined">
                        Text
                      </label>
                      <div className="block w-full p-2 text-gray-700 bg-gray-100 border rounded cursor-not-allowed text-md">
                        {characters.map(char => char.text).join("")}
                      </div>
                    </div>

                    <div className="mt-4">
                      <label className="block text-xs text-gray-500 uppercase undefined">
                        Characters
                      </label>
                      <div className="p-2 border border-gray-300 rounded">
                        {characters.map((character, index) => (
                          <WordCharacter
                            isSubmitting={isSubmitting}
                            key={`${character.id}_${index}`}
                            updateCharacter={this.updateCharacter}
                            removeCharacter={this.removeCharacter}
                            character={character}
                          />
                        ))}
                        <div className="block mt-1 text-xs text-orange-500">
                            Single Chinese character <span className="text-gray-500">|</span> Pinyin for character (no tone marks or numbers)
                            <span className="text-gray-500">|</span> Character tone
                        </div>
                        <button
                          type="button"
                          disabled={isSubmitting}
                          className="px-1 py-1 text-sm text-green-500 border border-green-500 rounded hover:border-green-700 hover:text-green-700 mt-3"
                          onClick={() =>
                            this.setState(prevState => ({
                              characters: prevState.characters.concat({
                                id: `NEW_${new Date().toUTCString()}`,
                                text: "",
                                tone: "",
                                pinyin: ""
                              })
                            }))
                          }>
                          Add New
                        </button>
                      </div>
                    </div>

                    <div className="mt-4">
                      <label className="block text-xs text-gray-500 uppercase undefined">
                        Definitions
                      </label>
                      <input
                        value={definitions}
                        disabled={isSubmitting}
                        onChange={e => this.setState({ definitions: e.target.value })}
                        type="text"
                        className="block w-full p-2 text-gray-700 border rounded text-md"
                      />
                      <div className="block mt-1 text-xs text-orange-500">
                        Comma-separated list of definitions.
                      </div>
                    </div>

                    <div className="mt-4">
                      <label className="block text-xs text-gray-500 uppercase undefined">
                        Language Level (HSK)
                      </label>
                      <input
                        value={language_level ?? ""}
                        onChange={e => this.setState({ language_level: e.target.value })}
                        disabled={isSubmitting}
                        type="text"
                        className="block w-full p-2 text-gray-700 border rounded text-md"
                      />
                    </div>
                    <div className="mt-4">
                      <button
                        disabled={isSubmitting}
                        onClick={this.handleSubmit}
                        className="px-2 py-1 border border-teal-500 text-teal-500 rounded w-full hover:text-teal-700 hover:border-teal-700">
                        {isSubmitting ? "Updating..." : "Update"}
                      </button>
                    </div>

                    <div className="pt-4 border-t mt-4">
                      <label className="block text-xs text-gray-500 uppercase undefined">
                        Audio Src
                      </label>
                      {audio_src ? <audio src={audio_src} controls className="mb-2" /> : null}
                      <MediaUpload
                        valueKey="audio_src"
                        endpoint={`/dictionary/${id}/audio`}
                        accept={`audio/*`}
                        post={post}
                        onError={error =>
                          toast.error(
                            parseErrorMessage(error, <Trans>Failed to upload audio file.</Trans>)
                          )
                        }
                        onSuccess={(file, data) => {
                          this.safeSetState({ ...data.data });
                          toast.success("Audio updated!");
                        }}
                      />
                    </div>
                  </Panel>
                </div>
              </LoadingWrapper>
            </form>
          </div>
        </PageBody>
      </div>
    );
  }
}
export default DictionaryShow;
