import React, { Component } from "react";
import PropTypes from "prop-types";
import { Trans } from "@lingui/macro";
import { ErrorMessage, Field, FieldArray, Form, Formik, getIn } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import { AutoComplete, Button, MediaUpload } from "@iforwms/react-components";
import Error from "../Error";
import { parseErrorMessage } from "@iforwms/helpers-js";
import { transformMyRestApiErrorsToAnObject } from "../../utils";
import { ApiConsumer } from "../../contexts/ApiContext";
import { get, post, updateResource } from "../../api";

const ArrayErrorMessage = ({ name }) => (
  <Field
    name={name}
    render={({ form }) => {
      const error = getIn(form.errors, name);
      const touch = getIn(form.touched, name);
      return touch && error ? <div className="mb-2 form-input-error">{error}</div> : null;
    }}
  />
);

const attachmentSchema = Yup.object().shape({
  // attachments: Yup.array()
  //     .of(Yup.integer.required(<Trans>Please enter a attachment.</Trans>))
  //     .required(<Trans>Please enter at least one attachment.</Trans>),
  // dictionary_items: Yup.array()
  //     .of(Yup.integer.required(<Trans>Please enter a dictionary item.</Trans>))
  //     .required(<Trans>Please enter at least one dictionary item</Trans>)
});

class AttachmentFormContainer extends Component {
  static propTypes = {
    initialValues: PropTypes.object.isRequired,
    onSuccess: PropTypes.func.isRequired,
    endpoint: PropTypes.string.isRequired
  };

  state = { attachments: this.props.initialValues.attachments };

  _isMounted = true;

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleSuccess = (file, item) => {
    this.setState({
      attachments: [...this.state.attachments, item.data]
    });

    this.autoComplete.resetState();
  };

  render() {
    const { onSuccess, endpoint, initialValues } = this.props;
    const { attachments } = this.state;

    return (
      <div className="form-container">
        <div>
          <h2 className="form-header">
            <Trans>Update Attachments</Trans>
          </h2>
          <p className="mb-4 text-sm leading-tight text-gray-600">
            <Trans>
              Select a file to upload, and once it's uploaded submit the update attachments form to
              attach the item to the resource. Or click "add an attachment" to select an attachment
              from the document library.
            </Trans>
          </p>
        </div>
        <div className="pb-4 mb-4 border-b">
          <label className="form-label">Upload a new file</label>
          <MediaUpload post={post} onSuccess={this.handleSuccess} />
        </div>
        <div className="pb-4 mb-4 border-b">
          <label className="form-label">Select an existing document</label>
          <AutoComplete
            get={get}
            ref={autoComplete => {
              this.autoComplete = autoComplete;
            }}
            readOnly={true}
            updateState={this.handleSuccess}
            handleSelect={this.handleSuccess}
            itemKey="name"
            url="media"
          />
        </div>

        <AttachmentForm
          endpoint={endpoint}
          initialValues={{ attachments }}
          id={initialValues.id}
          onSuccess={onSuccess}
        />
      </div>
    );
  }
}

export const AttachmentForm = ({ initialValues, id, onSuccess, endpoint }) => (
  <ApiConsumer>
    {({ callApi }) => (
      <Formik
        initialValues={{
          attachments: [],
          ...initialValues
        }}
        enableReinitialize
        validationSchema={attachmentSchema}
        onSubmit={(values, { setSubmitting, setErrors, setStatus, resetForm }) => {
          const url = "media/attach";

          // const attachments = [];

          // //           values.attachments.forEach(attachment => {
          // //             attachments.push(typeof attachment === "object" ? attachment.name : attachment);
          //           });
          const payload = { model: endpoint, id, attachments: values.attachments };
          callApi(() => updateResource(url, payload))
            .then(res => {
              setSubmitting(false);
              onSuccess(res.data);
              toast.success(<Trans>Attachments updated!</Trans>);
            })
            .catch(error => {
              setSubmitting(false);
              setErrors(transformMyRestApiErrorsToAnObject(error));
              toast.error(parseErrorMessage(error, <Trans>Failed to update a Attachments.</Trans>));
            });
        }}>
        {({ isSubmitting, errors, status, values, setFieldValue }) => (
          <div className="">
            <Form>
              <div className="form-input-group">
                <label className="form-label">
                  <Trans>Attachments</Trans>
                </label>
                {!values.attachments.length && (
                  <div className="text-sm text-gray-600">
                    <Trans>No attachments yet.</Trans>
                  </div>
                )}
                <FieldArray
                  name="attachments"
                  render={arrayHelpers => (
                    <div>
                      {values.attachments &&
                        values.attachments.length > 0 &&
                        values.attachments.map((item, index) => (
                          <div key={`${index}`}>
                            <div className="flex mb-2">
                              <div className="flex-1" />
                              <Field
                                className="mr-2 form-input"
                                name={`attachments.${index}.id`}
                                value={item.name}
                                type="text"
                                autoComplete="off"
                                disabled={isSubmitting}
                              />
                              <Button
                                colour={`red`}
                                className="ml-2"
                                type="button"
                                disabled={isSubmitting}
                                onClick={() => arrayHelpers.remove(index)}>
                                -
                              </Button>
                            </div>
                            <ArrayErrorMessage name={`attachments.${index}`} />
                          </div>
                        ))}
                    </div>
                  )}
                />

                <ErrorMessage name="attachments">
                  {errorMessage => <div className="form-input-error">{errorMessage}</div>}{" "}
                </ErrorMessage>
              </div>

              <div className="btn-group">
                <Button colour={`blue`} disabled={isSubmitting}>
                  {isSubmitting ? (
                    <Trans>Updating Attachments</Trans>
                  ) : (
                    <Trans>Update Attachments</Trans>
                  )}
                </Button>

                {status && <Error message={status} />}
              </div>
            </Form>
          </div>
        )}
      </Formik>
    )}
  </ApiConsumer>
);

AttachmentForm.propTypes = {
  initialValues: PropTypes.object.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onSuccess: PropTypes.func.isRequired,
  endpoint: PropTypes.string.isRequired
};

export default AttachmentFormContainer;
