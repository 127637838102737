import React from "react";
import PropTypes from "prop-types";
import { Trans } from "@lingui/macro";
import { ApiContext } from "../contexts/ApiContext";
import { patch, post } from "../api";
import { toast } from "react-toastify";
import parseErrorMessage from "helpers-js/parseErrorMessage";
import DeleteResourceButton from "./forms/DeleteResourceButton";
import { Button } from "@iforwms/react-components";

class MenuLink extends React.Component {
  static contextType = ApiContext;

  state = {
    url: this.props.link.url,
    guest_visible: this.props.link.guest_visible,
    user_visible: this.props.link.user_visible,
    title: this.props.link.title,
    menu_id: this.props.link.menu_id,
    showForm: false
  };

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  safeSetState = (...args) => {
    this._isMounted && this.setState(...args);
  };

  handleClick = () => {
    if (!this.props.link.id) {
      return this.createLink();
    }

    this.updateLink();
  };

  createLink = () => {
    this.context
      .callApi(() => post("/links", this.state))
      .then(({ data }) => {
        toast.success(<Trans>Link created successfully.</Trans>);
        this.safeSetState({ showForm: false });
        this.props.refresh();
      })
      .catch(e => {
        console.error(e);
        toast.error(parseErrorMessage(e, <Trans>Failed to create link.</Trans>));
      });
  };

  updateLink = () => {
    this.context
      .callApi(() => patch(`/links/${this.props.link.id}`, this.state))
      .then(({ data }) => {
        toast.success(<Trans>Link updated successfully.</Trans>);
        this.safeSetState({ showForm: false });
      })
      .catch(e => {
        console.error(e);
        toast.error(parseErrorMessage(e, <Trans>Failed to update link.</Trans>));
      });
  };

  render() {
    const { link, pages } = this.props;
    const { url, guest_visible, user_visible, title, showForm } = this.state;

    if (!showForm) {
      return (
        <div className={`flex items-center justify-between p-2 text-sm border-b`}>
          <span className={`truncate mr-4`}>
            {title ? title : <Trans>Add a new link</Trans>}{" "}
            {link.id ? <span className={`text-gray-500`}>({url})</span> : null}
          </span>

          <div className={`whitespace-no-wrap flex`}>
            <Button
              colour={`indigo`}
              size={`sm`}
              onClick={() => this.setState({ showForm: true })}
              text={link.id ? <Trans>Edit</Trans> : <Trans>Create</Trans>}
            />

            {link.id ? (
              <DeleteResourceButton
                classes={`ml-2`}
                size={`sm`}
                onDelete={() => {}}
                url={`/links/${link.id}`}
              />
            ) : null}
          </div>
        </div>
      );
    }

    return (
      <div className={`border border-orange-500 rounded p-4 mb-4`}>
        {!link.id && (
          <h3 className={`font-bold mb-2`}>
            <Trans>Create New Link</Trans>
          </h3>
        )}

        <div className="mb-2">
          <label className={`block text-pink-500 text-xs cursor-pointer`}>
            <Trans>Title</Trans>
          </label>

          <input
            onChange={e => {
              this.setState({ title: e.target.value });
            }}
            value={title}
            className={`form-input`}
            type="text"
          />
        </div>

        <div className="mb-2">
          <label className={`block text-pink-500 text-xs cursor-pointer`}>
            <Trans>URL</Trans>
          </label>

          <select
            onChange={e => {
              this.setState({ url: e.target.value });
            }}
            value={url}
            className={`form-input mb-2`}>
            <option value="">-- Select a Page --</option>
            {pages.map(page => (
              <option key={page.id} value={`/pages/${page.slug}`}>
                {page.title}
                {page.login_required && " (Login Required)"}
              </option>
            ))}
          </select>
          <input
            onChange={e => {
              this.setState({ url: e.target.value });
            }}
            value={url}
            className={`form-input`}
            type="text"
          />
        </div>

        <div className="mb-2">
          <label className={`cursor-pointer`}>
            <Trans>Visible to guests?</Trans>
            <input
              onChange={e => {
                this.setState({ guest_visible: e.target.checked });
              }}
              checked={guest_visible}
              className={`ml-2`}
              type="checkbox"
            />
          </label>
        </div>

        <div className="mb-2">
          <label className={`cursor-pointer`}>
            <Trans>Visible to logged in users?</Trans>
            <input
              onChange={e => {
                this.setState({ user_visible: e.target.checked });
              }}
              checked={user_visible}
              className={`ml-2`}
              type="checkbox"
            />
          </label>
        </div>

        <div className={`flex justify-between items-center`}>
          <Button
            fullWidth={false}
            size={`sm`}
            onClick={() => this.setState({ showForm: false })}
            text={<Trans>Cancel</Trans>}
          />

          <Button
            colour={`blue`}
            onClick={this.handleClick}
            fullWidth={false}
            size={`sm`}
            text={link.id ? <Trans>Update</Trans> : <Trans>Create</Trans>}
          />
        </div>
      </div>
    );
  }
}

MenuLink.defaultProps = {
  link: {
    id: null,
    ordering: 1,
    title: "",
    url: "",
    guest_visible: false,
    user_visible: false
  }
};

MenuLink.propTypes = {
  link: PropTypes.shape({
    id: PropTypes.number,
    ordering: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    url: PropTypes.string.isRequired,
    guest_visible: PropTypes.bool.isRequired,
    user_visible: PropTypes.bool.isRequired
  }),
  pages: PropTypes.array.isRequired
};

export default MenuLink;
