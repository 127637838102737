import React from "react";
import { Trans } from "@lingui/macro";
import moment from "moment";
import { Table } from "@iforwms/react-components";
import PageHeader from "../components/PageHeader";
import PageBody from "../components/PageBody";
import { Link } from "react-router-dom";
import { destroy, get } from "../api";
import history from "../components/history";

const Users = ({ location }) => (
  <div>
    <PageHeader title={<Trans>User Index</Trans>} />

    <PageBody>
      <div className="max-w-6xl p-4">
        <div className="flex items-center">
          <a
            target="_blank"
            rel="noopener noreferrer"
            className="px-2 py-1 text-sm text-blue-500 bg-white border border-blue-500 rounded"
            href={`${
              process.env.REACT_APP_API_BASE_URL
            }/emails?subscribed&token=${localStorage.getItem("DOMINO_JWT")}`}>
            Subscribed Users
          </a>
          <a
            target="_blank"
            rel="noopener noreferrer"
            className="bg=white border-blue-500 text-blue-500 px-2 py-1 ml-2 text-sm border rounded"
            href={`${process.env.REACT_APP_API_BASE_URL}/emails?token=${localStorage.getItem(
              "DOMINO_JWT"
            )}`}>
            Unsubscribed Users
          </a>
        </div>

        <Table
          filters={[{ label: "Per Page", valueKey: "limit", options: [20, 50, 100] }]}
          title={<Trans>User Index</Trans>}
          destroy={destroy}
          history={history}
          search={location.search}
          get={get}
          headers={[
            { label: "Email", valueKey: "email", url: "mailto:", urlKey: "email" },
            { label: "Name", valueKey: "name" },
            { label: "Last Seen", valueKey: "last_login", fromNow: true }
          ]}
          endpoint={`/users`}
          moment={moment}
          RouterLink={Link}
          actions={["VIEW"]}
        />
      </div>
    </PageBody>
  </div>
);

export default Users;
