import React from "react";
import PropTypes from "prop-types";
import Icon from "../Icon";
import LoadingWrapper from "../LoadingWrapper";
import { RateSelector } from "./RateSelector";

class AudioPlayer extends React.Component {
  state = {
    isPlaying: false,
    ready: false,
    currentTime: 0,
    duration: 0,
    playbackRate: 1,
    showRateSelector: false
  };

  componentDidMount() {
    this.player.oncanplay = () => {
      if (!this.player) return;

      this.setState(
        { ready: true, duration: this.player.duration },
        this.props.autoPlay ? this.playPauseAudio : null
      );
    };

    this.player.ontimeupdate = () => {
      if (!this.player) return;

      this.setState({ currentTime: this.player.currentTime });
    };

    this.player.onended = () => {
      this.setState({ isPlaying: false, duration: 0 });

      if (!this.player) return;

      this.player.currentTime = 0;
    };
  }

  componentWillUnmount() {
    this.player.pause();
  }

  stopAudio = () => {
    this.player.currentTime = 0;
    this.player.pause();
    this.setState({ isPlaying: false, currentTime: 0 });
  };

  playPauseAudio = () => {
    if (this.player.paused) {
      this.player.play();
      this.setState({ isPlaying: true });
    } else {
      this.player.pause();
      this.setState({ isPlaying: false });
    }
  };

  parseTime = (currentTime, duration) => {
    const currentSeconds =
      (Math.floor(currentTime % 60) < 10 ? "0" : "") +
      Math.floor(currentTime % 60);
    const currentMinutes = Math.floor(currentTime / 60);
    const totalSeconds =
      (Math.floor(duration % 60) < 10 ? "0" : "") + Math.floor(duration % 60);
    const totalMinutes = Math.floor(duration / 60);
    return `${currentMinutes}:${currentSeconds} / ${totalMinutes}:${totalSeconds}`;
  };

  setPlaybackRate = playbackRate => {
    this.player.playbackRate = playbackRate;
    this.setState({ playbackRate });
  };

  setTrackPosition = e => {
    e.persist();

    const songSliderWidth = e.nativeEvent.target.offsetWidth;
    const clickLocation =
      e.nativeEvent.layerX - e.nativeEvent.target.offsetLeft;
    const percentage = clickLocation / songSliderWidth;

    this.setLocation(percentage);
  };

  setLocation = percentage => {
    const currentTime = this.state.duration * percentage;
    this.setState({ currentTime });
    this.player.currentTime = currentTime;
  };

  render() {
    const { autoPlay, src, mini, colour } = this.props;
    const {
      isPlaying,
      ready,
      currentTime,
      duration,
      playbackRate,
      showRateSelector
    } = this.state;

    const player = (
      <audio
        className={"hidden"}
        ref={player => {
          this.player = player;
        }}>
        <source src={src} type="audio/mpeg" />
        Your browser does not support the audio element.
      </audio>
    );

    return (
      <div className={mini ? "" : `w-full`}>
        {player}

        <LoadingWrapper isLoading={!ready} padding={mini ? 0 : null}>
          <div className={`flex items-center bg-gray-200 rounded`}>
            <div
              onClick={this.playPauseAudio}
              className={`audio-player cursor-pointer p-2 ${
                mini ? "rounded-r" : "rounded-l"
              } bg-gray-500 text-${colour}-500 hover:text-${colour}-700`}>
              <Icon icon={isPlaying ? "pause" : "play"} />
            </div>

            {!mini ? (
              <>
                <div
                  onClick={this.stopAudio}
                  className={`cursor-pointer rounded-r p-2 bg-gray-700 text-${colour}-500 hover:text-${colour}-700`}>
                  <Icon icon={`stop`} />
                </div>

                <div className={`flex-1 flex items-center`}>
                  <div
                    style={{
                      background: `linear-gradient(to right, #ed64a6 0%, #ed64a6 ${
                        (currentTime / duration) * 100
                      }%, #a0aec0 ${
                        (currentTime / duration) * 100
                      }%, #a0aec0 100%)`
                    }}
                    className="ml-2 h-4 flex-1 rounded-full"
                    onClick={e => this.setTrackPosition(e)}
                  />

                  <div className="ml-2 relative">
                    <div
                      onClick={() => this.setState({ showRateSelector: true })}
                      className={`w-10 text-right cursor-pointer text-sm text-${colour}-500 hover:text-${colour}-700`}>
                      x{playbackRate}
                    </div>

                    <div
                      style={{ marginTop: 7 }}
                      className={` ${
                        showRateSelector ? "block" : "hidden"
                      } z-20 absolute bg-gray-500 p-2 rounded-b`}>
                      {[0.5, 0.75, 1.0, 1.25, 1.5].map(rate => (
                        <RateSelector
                          key={rate}
                          hideSelector={() =>
                            this.setState({ showRateSelector: false })
                          }
                          colour={colour}
                          playbackRate={playbackRate}
                          setPlaybackRate={this.setPlaybackRate}
                          rate={rate}
                        />
                      ))}
                    </div>

                    <div
                      onClick={() => this.setState({ showRateSelector: false })}
                      className={`bg-transparent inset-0 z-10 ${
                        showRateSelector ? "fixed" : "hidden"
                      }`}
                    />
                  </div>

                  <div className={`mx-2 text-sm text-gray-500`}>
                    {this.parseTime(currentTime, duration)}
                  </div>
                </div>
              </>
            ) : null}
          </div>
        </LoadingWrapper>
      </div>
    );
  }
}

AudioPlayer.defaultProps = {
  mini: false,
  colour: "pink"
};

AudioPlayer.propTypes = {
  src: PropTypes.string.isRequired,
  colour: PropTypes.string,
  mini: PropTypes.bool.isRequired
};

export default AudioPlayer;
