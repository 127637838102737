import React, { Component } from "react";
import { Trans } from "@lingui/macro";
import PageHeader from "../components/PageHeader";
import PageBody from "../components/PageBody";
import { Button, Input, PaginationLinks, toneColors } from "@iforwms/react-components";
import { post, get } from "../api";
import history from "../components/history";
import { toast } from "react-toastify";
import parseErrorMessage from "helpers-js/parseErrorMessage";
import { Link } from "react-router-dom";

class Dictionary extends Component {
  state = {
    isLoading: false,
    isSubmitting: false,
    searchTerm: "",
    queryType: "text",
    newText: "",
    newDefinitions: "",
    words: []
  };

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  safeSetState = (...args) => {
    this._isMounted && this.setState(...args);
  };

  setParentState = (valueKey, value) => {
    this.setState({ [valueKey]: value });
  };

  goToItem = id => {
    this.props.history.push(`/dictionary/${id}`);
  };

  handleSelect = item => {
    this.goToItem(item.id);
  };

  handleUpdate = item => {
    this.props.history.push(`/dictionary/${item.id}`);
  };

  handleDelete = () => {
    this.props.history.push(`/dictionary`);
  };

  handleSearchChange = e => {
    e.preventDefault();
    this.fetchWords();
  };

  createNewWord = () => {
    this.setState({ error: null, isSubmitting: true });

    post("/dictionary", { text: this.state.newText, definitions: this.state.newDefinitions })
      .then(({ data }) => {
        console.log(data);
        history.push(`/dictionary/${data.data.id}`);
        toast.success("Word created");
      })
      .catch(error => {
        this.setState({ error, isSubmitting: true });
        toast.error(parseErrorMessage(error, "Failed to create word"));
      });
  };

  fetchWords = () => {
    const { queryType, page, searchTerm } = this.state;
    this.safeSetState({ isLoading: true });

    if (!searchTerm) return;

    const url = `dictionary?query=${searchTerm}&type=${queryType}&page=${page}`;

    get(url)
      .then(({ data }) => {
        this.safeSetState({
          words: data.data,
          links: data.links,
          meta: data.meta,
          showResults: true,
          isLoading: false
        });
      })
      .catch(e => {
        this.safeSetState({ isLoading: false, error: e });
        toast.error(parseErrorMessage(e, <Trans>Failed to fetch word.</Trans>));
      });
  };

  render() {
    const {
      isSubmitting,
      newText,
      newDefinitions,
      queryType,
      isLoading,
      searchTerm,
      showResults,
      links,
      meta,
      words
    } = this.state;

    return (
      <div>
        <PageHeader title={<Trans>Dictionary</Trans>} />

        <PageBody>
          <div className="max-w-2xl p-4">
            <div>
              <div className="flex">
                <Button
                  isOutline={false}
                  size="sm"
                  onClick={() => this.setState({ queryType: "text" })}
                  colour={queryType === "text" ? "blue" : "gray"}>
                  <Trans>Chinese</Trans>
                </Button>

                <Button
                  isOutline={false}
                  size="sm"
                  classes={`mx-2`}
                  onClick={() => this.setState({ queryType: "pinyin" })}
                  colour={queryType === "pinyin" ? "blue" : "gray"}>
                  <Trans>Pinyin</Trans>
                </Button>

                <Button
                  isOutline={false}
                  size="sm"
                  onClick={() => this.setState({ queryType: "definition" })}
                  colour={queryType === "definition" ? "blue" : "gray"}>
                  <Trans>English</Trans>
                </Button>

                <Button
                  size="sm"
                  classes="ml-2 text-center whitespace-no-wrap"
                  url={`${
                    process.env.REACT_APP_API_BASE_URL
                  }/domino-vocab?token=${localStorage.getItem("DOMINO_JWT")}`}
                  colour="indigo"
                  text="Vocab"
                />
              </div>

              <div className="mt-4">
                <form method={`post`} onSubmit={e => this.handleSearchChange(e)}>
                  <div className={`flex`}>
                    <Input
                      containerClasses={`flex-1`}
                      value={searchTerm}
                      valueKey={`searchTerm`}
                      autoFocus
                      disabled={isLoading}
                      placeholder="Search term"
                      setState={this.setParentState}
                      size={`2xl`}
                      colour={`blue`}
                    />

                    <Button
                      fullWidth={false}
                      disabled={isLoading}
                      colour={`blue`}
                      classes={`ml-2`}
                      icon={`search`}
                    />
                  </div>
                </form>

                {queryType === "pinyin" ? (
                  <div className={`text-red-500 leading-tight text-xs mt-2`}>
                    <Trans>Split each pinyin character by a space, and omit tone marks.</Trans>
                  </div>
                ) : null}
              </div>
            </div>

            <div className="mt-4 flex pt-4 border-t">
              <input
                type="text"
                value={newText}
                disabled={isSubmitting}
                placeholder="Text"
                onChange={e => this.setState({ newText: e.target.value })}
                className="block border rounded p-2 text-gray-700 w-full text-md"
              />
              <input
                type="text"
                value={newDefinitions}
                disabled={isSubmitting}
                onChange={e => this.setState({ newDefinitions: e.target.value })}
                placeholder="Definitions"
                className="ml-2 block border rounded p-2 text-gray-700 w-full text-md"
              />
              <button
                disabled={isSubmitting}
                onClick={this.createNewWord}
                className={`hover:text-teal-700 text-teal-500 ml-2 text-md inline-block rounded p-2 disabled:cursor-not-allowed disabled:opacity-50 bg-white border border-teal-500 hover:border-teal-700`}>
                <Trans>Create</Trans>
              </button>
            </div>

            {!isLoading && showResults && (
              <div className="p-4 overflow-x-auto bg-white rounded shadow">
                <div className="">
                  {meta ? (
                    <PaginationLinks
                      fetchData={this.fetchWords}
                      isLoading={isLoading}
                      meta={meta}
                      links={links}
                    />
                  ) : null}

                  <table className="w-full text-sm">
                    <thead>
                      <tr className="border-b">
                        <th>&nbsp;</th>
                        <th className="p-1 text-left">
                          <Trans>Word</Trans>
                        </th>
                        {process.env.REACT_APP_SHOW_PHONETIC === "true" ? (
                          <th className="p-1 text-left">
                            <Trans>Phonetic</Trans>
                          </th>
                        ) : null}
                        <th className="p-1 text-left">
                          <Trans>Translation</Trans>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {isLoading && (
                        <tr>
                          <td className="px-1 py-2" colSpan={5}>
                            <Trans>Loading...</Trans>
                          </td>
                        </tr>
                      )}

                      {!isLoading && !words.length && (
                        <tr>
                          <td className="px-1 py-2" colSpan={5}>
                            <Trans>No results.</Trans>
                          </td>
                        </tr>
                      )}

                      {!isLoading &&
                        words.map((word, index) => (
                          <tr key={index}>
                            <td className="p-1 text-left whitespace-no-wrap">
                              <Button
                                colour={`blue`}
                                RouterLink={Link}
                                size={`sm`}
                                fullWidth={false}
                                url={`/dictionary/${word.id}`}
                                text={<Trans>View</Trans>}
                              />
                            </td>
                            <td className="p-1 whitespace-no-wrap">
                              <span
                                onClick={() => {
                                  this.setState({ searchTerm: word.text });
                                  this.fetchWords();
                                }}
                                className="cursor-pointer text-primary-500 hover:text-primary-600">
                                {process.env.REACT_APP_SHOW_PHONETIC === "true" ? (
                                  <>
                                    {word.characters &&
                                      word.characters.map((char, index) => (
                                        <span
                                          key={index}
                                          className={`text-${toneColors[char.tone]}-500`}>
                                          {char.text}
                                        </span>
                                      ))}
                                  </>
                                ) : (
                                  <span>{word.text}</span>
                                )}
                              </span>
                            </td>
                            {process.env.REACT_APP_SHOW_PHONETIC === "true" ? (
                              <td className="p-1 whitespace-no-wrap">
                                {word.characters &&
                                  word.characters.map((char, index) => (
                                    <span
                                      key={index}
                                      className={`text-${toneColors[char.tone]}-500 mr-1`}>
                                      {char.pinyin_unicode}
                                    </span>
                                  ))}
                              </td>
                            ) : null}
                            <td className="p-1 whitespace-no-wrap">
                              {word.definitions && word.definitions.length
                                ? word.definitions.join(", ")
                                : "-"}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>{" "}
              </div>
            )}
          </div>
        </PageBody>
      </div>
    );
  }
}

export default Dictionary;
