import React from "react";
import PropTypes from "prop-types";
import { get, patch } from "../../../api";
import { Button, Input, LoadingWrapper } from "@iforwms/react-components";
import { QuizContext } from "../../../contexts/QuizContext";
import QuizQuestion from "./QuizQuestion";
import { Trans } from "@lingui/macro";
import { toast } from "react-toastify";
import parseErrorMessage from "helpers-js/parseErrorMessage";

class VideoQuizForm extends React.Component {
  static contextType = QuizContext;

  state = {
    isLoading: false,
    isSubmitting: false,
    error: null,
    full_name: ""
  };

  componentDidMount() {
    this._isMounted = true;
    this.fetchItem();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  safeSetState = (...args) => {
    this._isMounted && this.setState(...args);
  };

  setParentState = (valueKey, value) => {
    this.setState({ [valueKey]: value });
  };

  handleSubmit = e => {
    e.preventDefault();

    this.safeSetState({ isSubmitting: true, error: null });

    const { id } = this.props;

    patch(`/videos/${id}/quiz`, this.context.questions)
      .then(({ data }) => {
        this.context.setQuizState("questions", data.data.questions);
        this.safeSetState({ full_name: data.data.full_name, isSubmitting: false });
        toast.success(<Trans>Quiz updated!</Trans>);
      })
      .catch(error => {
        this.safeSetState({ fetchError: error, isSubmitting: false });
        toast.error(parseErrorMessage(error, <Trans>Failed to update quiz.</Trans>))
      });
  };

  fetchItem = () => {
    const { id } = this.props;

    this.safeSetState({ isLoading: true, fetchError: null });

    get(`/videos/${id}/quiz`)
      .then(({ data }) => {
        this.context.setQuizState("questions", data.data.questions);
        this.safeSetState({ full_name: data.data.full_name, isLoading: false });
      })
      .catch(error => this.safeSetState({ fetchError: error, isLoading: false }));
  };

  addNewQuestion = () => {
    const { questions, setQuizState } = this.context;

    setQuizState(
      "questions",
      questions.concat({ id: new Date().toJSON(), text: "", type: "", answers: [] })
    );
  };

  render() {
    const { isLoading, isSubmitting, error, full_name } = this.state;
    const { questions } = this.context;

    return (
      <form method={`POST`} onSubmit={e => this.handleSubmit(e)}>
        <LoadingWrapper isLoading={isLoading} error={error} retry={this.fetchItem}>
          <div>
            <div className="mt-4">
              <Input disabled={true} valueKey={`full_name`} value={full_name} label={`Full Name`} />
            </div>

            <div className={``}>
              {!questions.length ? <div className={`mt-4`}>No questions at the moment.</div> : null}

              {questions.map(question => (
                <div className={`mt-4`} key={question.id}>
                  <QuizQuestion isSubmitting={isSubmitting} question={question} />
                </div>
              ))}
            </div>

            <div className="mt-4 flex">
              <Button
                onClick={this.addNewQuestion}
                disabled={isSubmitting || isLoading}
                type={`button`}
                colour={`teal`}
                size={`sm`}
                text={`Add New Question`}
              />

              <Button
                classes={`ml-2`}
                colour={`blue`}
                size={`sm`}
                text={isSubmitting ? `Updating Quiz...` : `Update Quiz`}
                disabled={isSubmitting || isLoading}
              />
            </div>
          </div>
        </LoadingWrapper>
      </form>
    );
  }
}

VideoQuizForm.propTypes = {
  id: PropTypes.string
};

export default VideoQuizForm;
