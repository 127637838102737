import { Button, Panel } from "@iforwms/react-components";
import { Trans } from "@lingui/macro";
import PropTypes from "prop-types";
import React from "react";
import { ApiContext } from "../contexts/ApiContext";
import parseErrorMessage from "helpers-js/parseErrorMessage";
import { toast } from "react-toastify";
import { get, post } from "../api";
import { LoadingWrapper } from "@iforwms/react-components";

class ManualSubscriptionForm extends React.Component {
  static contextType = ApiContext;

  state = {
    plans: [],
    selectedPlanId: "",
    created_at: "",
    ends_at: "",
    subscriptionId: "",
    isLoading: true,
    isSubmitting: false,
    error: null
  };

  componentDidMount() {
    this._isMounted = true;
    this.fetchPaymentGateways();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  safeSetState = (...args) => {
    this._isMounted && this.setState(...args);
  };

  fetchPaymentGateways = () => {
    this.safeSetState({ isLoading: true, error: null });

    this.context
      .callApi(() => get("/payment-gateway-plans"))
      .then(({ data }) => {
        this.safeSetState({ plans: data.data, isLoading: false });
      })
      .catch(error => {
        this.safeSetState({ isLoading: false, error });
        toast.error(parseErrorMessage(error, <Trans>Failed to fetch payment gateways.</Trans>));
      });
  };

  handleSubmit = e => {
    e.preventDefault();

    this.safeSetState({ isSubmitting: true });

    const { ends_at, created_at, selectedPlanId, subscriptionId } = this.state;

    const payload = {
      user_id: this.props.userId,
      payment_gateway_plan_id: selectedPlanId,
      ends_at,
      created_at,
      subscription_id: subscriptionId
    };

    this.context
      .callApi(() => post("/subscriptions/manual", payload))
      .then(res => {
        this.safeSetState({ isSubmitting: false });
        toast.success(<Trans>Subscription created!</Trans>);
      })
      .catch(error => {
        this.safeSetState({ isSubmitting: false, error });
        toast.error(parseErrorMessage(error, <Trans>Failed to create subscription.</Trans>));
      });
  };

  render() {
    const {
      plans,
      isLoading,
      selectedPlanId,
      ends_at,
      created_at,
      subscriptionId,
      isSubmitting
    } = this.state;
    return (
      <Panel colour={`blue`} title={<Trans>Create Subscription</Trans>}>
        <LoadingWrapper isLoading={isLoading}>
          <form method={`post`} onSubmit={e => this.handleSubmit(e)}>
            <div>
              <label className={`text-sm text-gray-500 block`}>
                <Trans>Subscription ID</Trans>
              </label>
              <input
                className={`form-input mt-1`}
                type="text"
                disabled={isSubmitting}
                onChange={e =>
                  this.setState({
                    subscriptionId: e.target.value
                  })
                }
                value={subscriptionId}
              />
            </div>

            <div>
              <label className={`text-sm text-gray-500 block mt-3`}>
                <Trans>Payment Gateway Plan</Trans>
              </label>
              <select
                disabled={isSubmitting}
                value={selectedPlanId}
                onChange={e => this.setState({ selectedPlanId: e.target.value })}
                className={`mt-1 form-input`}>
                <option value="">Please Select</option>
                {plans &&
                  plans.map(plan => (
                    <option key={plan.id} value={plan.id}>
                      {plan.sku}
                    </option>
                  ))}
              </select>
            </div>

            <div>
              <label className={`text-sm text-gray-500 block mt-3`}>
                <Trans>Created At (Optional)</Trans>
              </label>
              <input
                disabled={isSubmitting}
                className={`form-input mt-1`}
                type="datetime-local"
                onChange={e =>
                  this.setState({
                    created_at: e.target.value.replace(" ", "-")
                  })
                }
                value={created_at}
              />
            </div>

            <div>
              <label className={`text-sm text-gray-500 block mt-3`}>
                <Trans>Ends At</Trans>
              </label>
              <input
                disabled={isSubmitting}
                className={`form-input mt-1`}
                type="datetime-local"
                onChange={e =>
                  this.setState({
                    ends_at: e.target.value.replace(" ", "-")
                  })
                }
                value={ends_at}
              />
            </div>

            <Button
              colour={`blue`}
              disabled={isSubmitting}
              classes="mt-4"
              text={isSubmitting ? <Trans>Submitting...</Trans> : <Trans>Submit</Trans>}
            />
          </form>
        </LoadingWrapper>
      </Panel>
    );
  }
}

ManualSubscriptionForm.propTypes = {
  userId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
};

export default ManualSubscriptionForm;
