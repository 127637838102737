import { Trans } from "@lingui/macro";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";
import Impersonate from "./Impersonate";
import { Panel, Button } from "@iforwms/react-components";
import { Link } from "react-router-dom";

const CustomerDetails = ({
  user: {
    weekly_completed_video_count,
    weekly_completed_video_duration,
    weekly_completed_flashcard_count,
    overdue_flashcard_count,
    completed_dialog_count,
    avatar_thumb,
    id,
    progress,
    email,
    joined_at,
    last_login,
    email_verified_at,
    completed_lesson_count,
    full_name,
    completed_video_count
  },
  verifyEmail
}) => (
  <>
    <Panel colour={`blue`} title={full_name}>
      <table className="w-full text-sm table-striped table-no-wrap table-cell-spaced">
        <tbody>
          <tr>
            <td colSpan={2} className="text-center">
              <img className="mx-auto rounded-full" src={avatar_thumb} alt="Pic" />

              <div className={`my-2 flex items-center justify-center`}>
                <Link
                  className="inline-block p-1 text-sm text-green-500 bg-white border border-green-500 rounded hover:text-green-700 disabled:cursor-not-allowed disabled:opacity-50 hover:border-green-700"
                  to={`/users/${id}`}>
                  Full Profile
                </Link>

                {email_verified_at ? null : (
                  <div className="ml-2">
                    <Button
                      colour={`blue`}
                      size={`sm`}
                      fullWidth={false}
                      onClick={() => verifyEmail(email)}>
                      <Trans>Verify Email</Trans>
                    </Button>
                  </div>
                )}

                <div className="ml-2">
                  <Impersonate email={email} />
                </div>
              </div>
            </td>
          </tr>
          <tr>
            <td>
              <Trans>ID</Trans>
            </td>
            <td>{id}</td>
          </tr>
          <tr>
            <td>
              <Trans>Email</Trans>
            </td>
            <td>
              <a className="underline text-teal hover:text-teal-600" href={`mailto:${email}`}>
                {email}
              </a>
            </td>
          </tr>
          <tr>
            <td>
              <Trans>Joined At</Trans>
            </td>
            <td>
              {joined_at
                ? `${moment(joined_at).utc().format("DD MMM YYYY, HH:mm")} (${moment(joined_at)
                    .utc()
                    .fromNow()})`
                : "-"}
            </td>
          </tr>
          <tr>
            <td>
              <Trans>Email Verified At</Trans>
            </td>
            <td>
              {email_verified_at
                ? `${moment(email_verified_at).utc().format("DD MMM YYYY, HH:mm")} (${moment(
                    email_verified_at
                  )
                    .utc()
                    .fromNow()})`
                : "-"}
            </td>
          </tr>
          <tr>
            <td>
              <Trans>Last Login</Trans>
            </td>
            <td>
              {last_login
                ? `${moment(last_login).utc().format("DD MMM YYYY, HH:mm")} (${moment(last_login)
                    .utc()
                    .fromNow()})`
                : "-"}
            </td>
          </tr>
        </tbody>
      </table>
    </Panel>

    <div className="mt-8">
      <Panel colour={`teal`} title={<Trans>Customer Progress</Trans>}>
        <table className="w-full text-sm table-striped table-no-wrap table-cell-spaced">
          <tbody>
            <tr>
              <td>Course</td>
              <td>{progress.course_name}</td>
            </tr>
            <tr>
              <td>Level</td>
              <td>{progress.level_name}</td>
            </tr>
            <tr>
              <td>Chapter</td>
              <td>{progress.chapter_name}</td>
            </tr>
            <tr>
              <td>Lesson</td>
              <td>{progress.lesson_name}</td>
            </tr>
            <tr>
              <td>Video</td>
              <td>{progress.video_name}</td>
            </tr>
            <tr>
              <td>Videos Completed This Week</td>
              <td>
                {weekly_completed_video_count} (
                {weekly_completed_video_duration
                  ? Math.floor(weekly_completed_video_duration / 60)
                  : 0}{" "}
                mins)
              </td>
            </tr>
            <tr>
              <td>Flashcards Completed This Week</td>
              <td>{weekly_completed_flashcard_count}</td>
            </tr>
            <tr>
              <td>Overdue Flashcards</td>
              <td>{overdue_flashcard_count}</td>
            </tr>
            <tr>
              <td>Videos Completed</td>
              <td>{completed_video_count}</td>
            </tr>
            <tr>
              <td>Lessons Completed</td>
              <td>{completed_lesson_count}</td>
            </tr>
            <tr>
              <td>Dialogs Completed</td>
              <td>{completed_dialog_count}</td>
            </tr>
          </tbody>
        </table>
      </Panel>
    </div>
  </>
);

CustomerDetails.propTypes = {
  // avatar: PropTypes.string.isRequired,
  // name: PropTypes.string.isRequired,
  // id: PropTypes.number.isRequired,
  // email: PropTypes.string.isRequired,
  // last_login: PropTypes.string.isRequired,
  // completed_lessons: PropTypes.number.isRequired,
  // completed_videos: PropTypes.number.isRequired
};

export default CustomerDetails;
