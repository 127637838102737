import React from "react";
import PropTypes from "prop-types";
import Button from "./forms/Button";
import QS from "query-string";
import config from "../config";
import LoadingWrapper from "./LoadingWrapper";
import PaginationLinks from "./forms/PaginationLinks";
import SelectInput from "./forms/SelectInput";
import { trimChar } from "@iforwms/helpers-js";
import Icon from "./Icon";

class Table extends React.Component {
  state = {
    error: null,
    isLoading: true,
    items: [],
    links: null,
    meta: null,
    next: null,
    prev: null,
    limit: 20,
    page: 1,
    isSubmitting: false,
    isUpdating: false
  };

  componentDidMount() {
    this._isMounted = true;
    this.updateUrl({});
    this.fetchItems();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  safeSetState = (...args) => {
    this._isMounted && this.setState(...args);
  };

  // setParentState = (valueKey, value) => {
  //   this.setState({ [valueKey]: value });
  // };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.search !== this.props.search || prevProps.rawData !== this.props.rawData) {
      this.fetchItems();
    }
  }

  deleteItem = (url, itemId) => {
    const { destroy } = this.props;

    if (!destroy) return;

    const confirm = window.confirm("Are you sure?");
    if (!confirm) return;

    destroy(`${url}/${itemId}`)
      .then(({ data }) => {
        this.safeSetState({
          items: this.state.items.filter(i => {
            const id = i.slug ? i.slug : i.id;
            return id !== itemId;
          }),
          error: null
        });
      })
      .catch(error => {
        this.safeSetState({ error });
      });
  };

  fetchItems = () => {
    const { get, handleSuccess, handleError, search, rawData } = this.props;

    if (rawData) {
      return this.safeSetState({ items: rawData });
    }

    this.safeSetState({
      isLoading: true,
      isUpdating: true,
      error: null
    });

    get(`${this.props.endpoint}/${search}`)
      .then(({ data }) => {
        this.safeSetState({
          items: data.data,
          isLoading: false,
          isUpdating: false,
          error: undefined,
          meta: data.meta,
          links: data.links
        });

        if (handleSuccess) {
          handleSuccess(data);
        }
      })
      .catch(error => {
        this.safeSetState({
          isLoading: false,
          isUpdating: false,
          error
        });

        if (handleError) {
          handleError(error);
        }
      });
  };

  updateUrlFromUrl = url => {
    const parsedUrl = QS.parseUrl(url).query;
    this.updateUrl(parsedUrl);
  };

  updateUrlFromKeyValuePair = (valueKey, value) => {
    this.updateUrl({ [valueKey]: value });
  };

  updateUrl = (queryObject, pushHistory = true, force = false) => {
    const { history, search, endpoint } = this.props;
    let merged = null;

    if (force) {
      merged = queryObject;
    } else {
      const query = QS.parseUrl(search).query;
      merged = { ...query, ...queryObject };
    }

    let queryString = ``;
    let keys = Object.keys(merged);
    keys.forEach(key => {
      // if (key === "NOT_SET") {
      //   return;
      // }

      this.safeSetState({ [key]: merged[key] });
      queryString += `&${key}=${merged[key]}`;
    });

    // const stringFilters = ["platform", "priority", "type", "status", "search", "assignee_id"];
    // const booleanFilters = ["fixed", "due", "kanban"];

    if (pushHistory) {
      this.safeSetState({ items: [] });
    }

    // stringFilters.forEach(filter => {
    //   if (!keys.includes(filter)) {
    //     this.safeSetState({ [filter]: "" });
    //   }
    // });
    //
    // booleanFilters.forEach(filter => {
    //   if (!keys.includes(filter)) {
    //     this.safeSetState({ [filter]: false });
    //   } else {
    //     this.safeSetState({ [filter]: merged[filter] === "true" || merged[filter] === true });
    //   }
    // });

    if (pushHistory) {
      let compiledUrl = `${endpoint}?${trimChar(queryString, "&")}`;
      history.push(compiledUrl);
    }
  };

  render() {
    const {
      headers,
      hasHeaders,
      actions,
      endpoint,
      errorChildren,
      striped,
      size,
      header,
      history,
      moment,
      paginated,
      filters,
      showReload,
      highlight,
      markComplete,
      title
    } = this.props;
    const { error, isLoading, meta, links, items } = this.state;

    return (
      <LoadingWrapper
        errorChildren={errorChildren ? React.cloneElement(errorChildren, { error }) : null}
        error={error}
        isLoading={isLoading}>
        {actions.includes("CREATE") ||
        actions.includes("BULK_CREATE") ||
        title ||
        showReload ||
        filters.length ? (
          <div className="flex flex-col justify-between mb-4 md:flex-row md:items-end">
            <div className={``}>
              {title ? <h3 className="text-2xl font-light">{title}</h3> : null}
            </div>

            {header ? header : null}

            <div className={`flex items-end justify-between`}>
              {meta &&
                filters.map(filter => (
                  <div key={filter.label} className="mr-2">
                    <SelectInput
                      label={filter.label}
                      setState={this.updateUrlFromKeyValuePair}
                      value={this.state[filter.valueKey] ? this.state[filter.valueKey] : ""}
                      valueKey={filter.valueKey}
                      key={filter.valueKey}
                      options={
                        typeof filter.options === "string"
                          ? [{ value: "", title: "All" }, ...meta["topics"]]
                          : filter.options
                      }
                    />
                  </div>
                ))}

              {actions.includes("CREATE") || actions.includes("BULK_CREATE") || showReload ? (
                <div className={`flex items-end`}>
                  {actions.includes("BULK_CREATE") ? (
                    <Button
                      fullWidth={false}
                      colour={`purple`}
                      classes={`ml-2 flex items-center`}
                      onClick={() => history.push(`${endpoint}/bulk`)}
                      text={"Bulk Create"}
                    />
                  ) : null}

                  {actions.includes("CREATE") ? (
                    <Button
                      fullWidth={false}
                      colour={`pink`}
                      classes={`ml-2 flex items-center`}
                      onClick={() => history.push(`${endpoint}/create`)}
                      text={"Create"}
                    />
                  ) : null}

                  {showReload ? (
                    <Button
                      fullWidth={false}
                      colour={`blue`}
                      size={`xl`}
                      classes={`ml-2`}
                      onClick={this.fetchItems}
                      icon={`refresh`}
                    />
                  ) : null}
                </div>
              ) : null}
            </div>
          </div>
        ) : null}

        {paginated && meta && meta.total && (links.next || links.prev) ? (
          <div className="p-4">
            <PaginationLinks
              fetchData={this.updateUrlFromUrl}
              isLoading={isLoading}
              links={links}
              meta={meta}
            />
          </div>
        ) : null}

        <div className="overflow-x-auto bg-white rounded shadow">
          <table className={`w-full text-${size}`}>
            {hasHeaders ? (
              <thead>
                <tr>
                  {actions && actions.filter(a => !["CREATE", "BULK_CREATE"].includes(a)).length ? (
                    <th className={`${config.styles.tableHeaderClasses} text-left`}>Actions</th>
                  ) : null}

                  {headers.map(header => (
                    <th
                      key={header.valueKey}
                      className={`${config.styles.tableHeaderClasses} text-${
                        header.textAlign ? header.textAlign : "left"
                      }`}>
                      {header.label}
                    </th>
                  ))}
                </tr>
              </thead>
            ) : null}

            <tbody>
              {!items || !items.length ? (
                <tr>
                  <td className={`p-2`} colSpan={headers.length + (actions.length ? 1 : 0)}>
                    No results.
                  </td>
                </tr>
              ) : (
                items &&
                items.length &&
                items.map((row, index) => (
                  <tr
                    key={row.id}
                    className={`transition ${
                      highlight && highlight.length === 3 && row[highlight[0]] === highlight[1]
                        ? `bg-${highlight[2]}-200 hover:bg-${highlight[2]}-300`
                        : striped
                        ? `hover:bg-gray-400 bg-gray-${index % 2 === 0 ? "300" : "100"}`
                        : "hover:bg-gray-200"
                    }`}>
                    {actions.length ? (
                      <td className={`flex ${config.styles.tableBodyClasses} text-left`}>
                        {markComplete && actions.includes("MARK_COMPLETE") ? (
                          <div className={`inline-block`}>
                            <div
                              className={`flex items-center text-${
                                row.is_complete ? "orange" : "gray"
                              }-500`}>
                              <Icon icon={`star`} size={14} />

                              <Button
                                size={`xs`}
                                fullWidth={false}
                                classes={`ml-2`}
                                colour={`orange`}
                                disabled={row.is_complete}
                                onClick={() => markComplete(row)}>
                                {row.is_complete ? "COMPLETED!" : "MARK COMPLETE"}
                              </Button>
                            </div>
                          </div>
                        ) : null}

                        {actions.includes("PREVIEW") ? (
                          <Button
                            size={`xs`}
                            fullWidth={false}
                            colour={`blue`}
                            classes={`${actions.includes("MARK_COMPLETE") ? "ml-2" : ""}`}
                            onClick={() =>
                              history.push(`${endpoint}/${row.slug ? row.slug : row.id}/preview`)
                            }>
                            PREVIEW
                          </Button>
                        ) : null}

                        {actions.includes("VIEW_USER") ? (
                          <Button
                            size={`xs`}
                            fullWidth={false}
                            colour={`blue`}
                            classes={`${
                              actions.includes("MARK_COMPLETE") || actions.includes("PREVIEW")
                                ? "ml-2"
                                : ""
                            }`}
                            onClick={() =>
                              history.push(`users/${row.user_id}`)
                            }>
                            VIEW USER
                          </Button>
                        ) : null}


                        {actions.includes("VIEW") ? (
                          <Button
                            size={`xs`}
                            fullWidth={false}
                            colour={`blue`}
                            classes={`${
                              actions.includes("MARK_COMPLETE") || actions.includes("PREVIEW")
                                ? "ml-2"
                                : ""
                            }`}
                            onClick={() =>
                              history.push(`${endpoint}/${row.slug ? row.slug : row.id}`)
                            }>
                            VIEW
                          </Button>
                        ) : null}

                        {row.gateway !== "BRAINTREE" && actions.includes("PARSE_RECEIPT") ? (
                          <Button
                            size={`xs`}
                            fullWidth={false}
                            colour={`orange`}
                            classes={`ml-2`}
                            onClick={() =>
                              history.push(
                                `receipt-parser?gateway=${row.gateway}&receipt=${btoa(
                                  row.subscription_id
                                )}&sku=${row.sku}`
                              )
                            }>
                            RECEIPT
                          </Button>
                        ) : null}

                        {actions.includes("EDIT") ? (
                          <Button
                            size={`xs`}
                            fullWidth={false}
                            colour={`green`}
                            classes={`${
                              actions.includes("MARK_COMPLETE") ||
                              actions.includes("VIEW") ||
                              actions.includes("PREVIEW")
                                ? "ml-2"
                                : ""
                            }`}
                            onClick={() =>
                              history.push(`${endpoint}/${row.slug ? row.slug : row.id}`)
                            }>
                            EDIT
                          </Button>
                        ) : null}

                        {this.props.destroy && actions.includes("DELETE") ? (
                          <Button
                            size={`xs`}
                            fullWidth={false}
                            colour={`red`}
                            classes={`${
                              actions.includes("MARK_COMPLETE") ||
                              actions.includes("VIEW") ||
                              actions.includes("PREVIEW") ||
                              actions.includes("EDIT")
                                ? "ml-2"
                                : ""
                            }`}
                            onClick={() => this.deleteItem(endpoint, row.slug ? row.slug : row.id)}>
                            DELETE
                          </Button>
                        ) : null}
                      </td>
                    ) : null}

                    {headers.map(header => (
                      <td
                        key={`${header.valueKey}_${row.id}`}
                        className={`${config.styles.tableBodyClasses} text-${
                          header.textAlign ? header.textAlign : "left"
                        } ${header.truncate ? "truncate w-100 block" : ""}`}>
                        {header.url ? (
                          typeof row[header.valueKey] === "string" ? (
                            <Button
                              isLink={true}
                              classes={`underline`}
                              size={size}
                              fullWidth={false}
                              type="text"
                              url={`${header.url}${
                                row[header.urlKey ? header.urlKey : header.valueKey]
                              }`}
                              text={row[header.valueKey]}
                            />
                          ) : (
                            row[header.valueKey].map(item => (
                              <Button
                                key={`${header.url}${item}`}
                                isLink={true}
                                classes={`underline ml-2`}
                                size={size}
                                type="text"
                                fullWidth={false}
                                url={`${header.url}${item}`}
                                text={item}
                              />
                            ))
                          )
                        ) : moment && header.fromNow ? (
                          row[header.valueKey] ? (
                            moment(row[header.valueKey]).fromNow()
                          ) : (
                            "N/A"
                          )
                        ) : (
                          row[header.valueKey]
                        )}
                      </td>
                    ))}
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
        {paginated && meta && meta.total && (links.next || links.prev) ? (
          <div className="p-4">
            <PaginationLinks
              fetchData={this.updateUrlFromUrl}
              isLoading={isLoading}
              links={links}
              meta={meta}
            />
          </div>
        ) : null}
      </LoadingWrapper>
    );
  }
}

Table.propTypes = {
  history: PropTypes.object.isRequired,
  search: PropTypes.string.isRequired,
  endpoint: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  size: PropTypes.string.isRequired,
  errorChildren: PropTypes.any,
  actions: PropTypes.array.isRequired,
  filters: PropTypes.array.isRequired,
  hasHeaders: PropTypes.bool.isRequired,
  showReload: PropTypes.bool.isRequired,
  striped: PropTypes.bool.isRequired,
  paginated: PropTypes.bool.isRequired,
  header: PropTypes.any,
  moment: PropTypes.func,
  get: PropTypes.func.isRequired,
  destroy: PropTypes.func,
  rawData: PropTypes.array,
  markComplete: PropTypes.func,
  headers: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      valueKey: PropTypes.string.isRequired,
      textAlign: PropTypes.string,
      url: PropTypes.string,
      urlKey: PropTypes.string,
      fromNow: PropTypes.bool
    })
  )
};

Table.defaultProps = {
  header: null,
  errorChildren: null,
  hasHeaders: true,
  showReload: true,
  filters: [],
  paginated: true,
  size: "md",
  striped: true,
  actions: []
};

export default Table;
