import _slicedToArray from "/Users/ifor/code/domino/domino-admin/node_modules/babel-preset-react-app/node_modules/@babel/runtime/helpers/esm/slicedToArray";

var downscaleImage = function downscaleImage(dataUrl, newWidth, imageType, imageArguments) {
  var image, oldWidth, oldHeight, newHeight, canvas, ctx, newDataUrl; // Provide default values

  imageType = imageType || "image/jpeg";
  imageArguments = imageArguments || 0.7; // Create a temporary image so that we can compute the height of the downscaled image.

  image = new Image();
  image.src = dataUrl;
  oldWidth = image.width;
  oldHeight = image.height;
  newHeight = Math.floor(oldHeight / oldWidth * newWidth); // Create a temporary canvas to draw the downscaled image on.

  canvas = document.createElement("canvas");
  canvas.width = newWidth;
  canvas.height = newHeight; // Draw the downscaled image on the canvas and return the new data URL.

  ctx = canvas.getContext("2d");
  ctx.drawImage(image, 0, 0, newWidth, newHeight);
  newDataUrl = canvas.toDataURL(imageType, imageArguments);
  return newDataUrl;
};

var parseDuration = function parseDuration(seconds) {
  var pad = function pad(string) {
    return ("0" + string).slice(-2);
  };

  var date = new Date(seconds * 1000);
  var hh = date.getUTCHours();
  var mm = date.getUTCMinutes();
  var ss = pad(date.getUTCSeconds());

  if (hh) {
    return "".concat(hh, ":").concat(pad(mm), ":").concat(ss);
  }

  return "".concat(mm, ":").concat(ss);
};

var parseError = function parseError(valueKey, error) {
  if (error && error.response && error.response.data && error.response.data.errors && error.response.data.errors[valueKey]) {
    return error.response.data.errors[valueKey][0];
  }

  if (error && error.errors && error.errors[valueKey]) {
    return error.errors[valueKey][0];
  }

  return null;
};

var parseErrorMessage = function parseErrorMessage(error, defaultMessage) {
  // console.error("error", error);
  if (error && error.response) {
    // console.log("error not defined, setting error to error.response");
    error = error.response;
  }

  if (error && error.data) {
    // console.log("error not defined, setting error to error.data");
    error = error.data;
  }

  if (error && error.errors) {
    // console.log("error.errors exists, checking if there's also message");
    var message = "";

    if (error.message && typeof error.message === "string") {
      message += error.message + " ";
    }

    if (typeof error.errors === "object") {
      // console.log("error.errors is an object, implode by key");
      Object.keys(error.errors).map(function (key) {
        if (typeof error.errors[key][0] === "string") {
          message += error.errors[key][0] + " ";
        }
      });
    }

    return message.trim();
  }

  if (error && error.message && typeof error.message === "string") {
    // console.log("error.message exists, return message");
    return error.message.trim();
  }

  if (error && error.response && error.response.data && error.response.data.error && typeof error.response.data.error === "string") {
    var _message = error.response.data.error;

    if (error.response.statusText) {
      _message = "".concat(error.response.statusText, ": ").concat(_message);
    }

    return _message;
  }

  if (error && error.response && error.response.data && error.response.data.message && typeof error.response.data.message === "string") {
    return error.response.data.message;
  }

  return defaultMessage;
};

var parseStatusCode = function parseStatusCode(error) {
  var code = 500; // console.error("error", error);

  if (error && error.response && error.response.status) {
    // console.log(error.response, "setting error code from status");
    code = error.response.status;
  }

  return code;
};

var parseDate = function parseDate(year, month, day, hour, minute) {
  if (!year || !month | !day || !hour || !minute) {
    return null;
  }

  if (month < 10 && month.length < 2) {
    month = "0".concat(month);
  }

  if (day < 10 && day.length < 2) {
    day = "0".concat(day);
  }

  if (hour < 10 && hour.length < 2) {
    hour = "0".concat(hour);
  }

  if (minute < 10 && minute.length < 2) {
    minute = "0".concat(minute);
  }

  return "".concat(year, "-").concat(month, "-").concat(day, "T").concat(hour, ":").concat(minute, ":00.000000Z");
};

var shuffleArray = function shuffleArray(array) {
  var currentIndex = array.length;
  var temporaryValue, randomIndex;

  while (0 !== currentIndex) {
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex -= 1;
    temporaryValue = array[currentIndex];
    array[currentIndex] = array[randomIndex];
    array[randomIndex] = temporaryValue;
  }

  return array;
};

var reRegExpChar = /[\\^$.*+?()[\]{}|]/g;
var reHasRegExpChar = RegExp(reRegExpChar.source);

function escapeRegExp(string) {
  return string && reHasRegExpChar.test(string) ? string.replace(reRegExpChar, "\\$&") : string;
}

var trimChar = function trimChar(origString, charToTrim) {
  charToTrim = escapeRegExp(charToTrim);
  var regEx = new RegExp("^[" + charToTrim + "]+|[" + charToTrim + "]+$", "g");
  return origString.replace(regEx, "");
};

var pluralise = function pluralise(string) {
  if (typeof string !== "string") return null;

  if (string.substr(-1) === "s") {
    return string + "es";
  }

  if (string.substr(-1) === "y") {
    return string.slice(0, string.length - 1) + "ies";
  }

  return string + "s";
};

var reorderArray = function reorderArray(list, startIndex, endIndex) {
  var result = Array.from(list);

  var _result$splice = result.splice(startIndex, 1),
      _result$splice2 = _slicedToArray(_result$splice, 1),
      removed = _result$splice2[0];

  result.splice(endIndex, 0, removed);
  return result;
};

export { downscaleImage, parseDate, parseDuration, parseError, parseErrorMessage, parseStatusCode, pluralise, reorderArray, shuffleArray, trimChar };