import React from "react";
import PropTypes from "prop-types";
import InputError from "./InputError";
import Label from "./Label";

const RadioInput = ({
  label,
  setState,
  valueKey,
  value,
  disabled,
  error,
  classes,
  containerClasses,
  size,
  description,
  options
}) => (
  <div className={`${containerClasses}`}>
    <Label label={label} />

    <div className={`flex flex-wrap mt-1`}>
      {options.map(option => (
        <Label key={option} classes={`cursor-pointer mr-2`} label={option}>
          <input
            value={option}
            onChange={setState ? e => setState(valueKey, e.target.value) : null}
            type="radio"
            checked={value === option}
            name={valueKey}
            disabled={disabled}
            className={`${classes} ml-2 text-${size}`}
          />
        </Label>
      ))}
    </div>

    {description && <span className="block text-xs mt-1 text-gray-500">{description}</span>}

    <InputError error={error} valueKey={valueKey} />
  </div>
);

RadioInput.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  classes: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  setState: PropTypes.func,
  error: PropTypes.object,
  valueKey: PropTypes.string,
  options: PropTypes.PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
        title: PropTypes.string.isRequired
      })
    ])
  ).isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  containerClasses: PropTypes.string.isRequired
};

RadioInput.defaultProps = {
  classes: "",
  containerClasses: "",
  size: "md",
  disabled: false
};

export default RadioInput;
