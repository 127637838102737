import { Trans } from "@lingui/macro";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";

const SubscriptionDetail = ({ subscription }) => (
  <table className="w-full table-striped table-no-wrap text-sm table-cell-spaced">
    <tbody>
      <tr>
        <td>
          <Trans>ID</Trans>
        </td>
        <td>{subscription.id}</td>
      </tr>
      <tr>
        <td>
          <Trans>Plan</Trans>
        </td>
        <td>{subscription.plan}</td>
      </tr>
      <tr>
        <td>
          <Trans>Gateway</Trans>
        </td>
        <td>
          {subscription.gateway} [{subscription.gateway_subscription_id}]
        </td>
      </tr>
      <tr>
        <td>
          <Trans>Created</Trans>
        </td>
        <td>
          {moment(subscription.created_at).format("YYYY-MM-DD HH:mm")} (
          {moment(subscription.created_at).fromNow()})
        </td>
      </tr>
      <tr>
        <td>
          <Trans>Cancelled</Trans>
        </td>
        <td>
          {subscription.cancelled_at
            ? `${moment(subscription.cancelled_at).format("YYYY-MM-DD HH:mm")} (${moment(
                subscription.cancelled_at
              ).fromNow()})`
            : "-"}
        </td>
      </tr>
      <tr>
        <td>
          <Trans>Ends At</Trans>
        </td>
        <td>
          {subscription.ends_at
            ? `${moment(subscription.ends_at).format("YYYY-MM-DD HH:mm")} (${moment(
                subscription.ends_at
              ).fromNow()})`
            : "-"}
        </td>
      </tr>
    </tbody>
  </table>
);

SubscriptionDetail.propTypes = {
  subscription: PropTypes.object.isRequired
};

export default SubscriptionDetail;
