import React from "react";

const Error = response => {
    let errorMessage = JSON.stringify(response, 2, null);

    if (response && response.data) {
        errorMessage = response.data;
    }

    if (response && response.error && response.error.response && response.error.response.data) {
        errorMessage =
            response.error.response.data[Object.keys(response.error.response.data)[0]][0];
    }

    if (
        response &&
        response.error &&
        response.error.message &&
        response.error.response &&
        (response.error.response.status === 404 || response.error.response.status >= 500)
    ) {
        errorMessage = response.error.message;
    }

    return (
        <div
            style={{ marginLeft: 240 }}
            className="bottom-0 absolute left-0 right-0 bg-red text-sm text-white p-2">
            {errorMessage}
        </div>
    );
};

export default Error;
