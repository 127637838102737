import axios from "axios";
import history from "./components/history";

const http = (timeout = 60000) => {
  const instance = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    timeout,
    withCredentials: true
  });

  instance.interceptors.response.use(
    response => {
      return response;
    },
    error => {
      if (error.response && error.response.status && error.response.status === 403) {
        history.push("/unauthorized");
      }

      if (error.response && error.response.status && error.response.status === 401) {
          return window.location.href = process.env.REACT_APP_AUTH_URL + "?redirectUrl=" + window.location.href;
        // history.push("/login");
      }

      return Promise.reject(error);
    }
  );
  return instance;
};

export const post = (url, payload, onUploadProgress = null) =>
  http(3000000).post(url, payload, { onUploadProgress });
export const destroy = (url, payload) => http().delete(url, payload);
export const patch = (url, payload) => http().patch(url, payload);
export const get = url => http().get(url);
export const getLongTimeout = url => http(3000000).get(url);

export const updateOrdering = payload => http().post("/reorder", payload);

export const createResource = (url, payload) => http().post("/" + url, payload);
export const getResource = url => http().get("/" + url);
export const updateResource = (url, payload) => http().patch("/" + url, payload);

export const updateQuiz = (id, payload) => http().patch(`/quiz/${id}`, payload);

export const createVideoHost = payload => http().post("/video-hosts", payload);
export const getVideoHost = id => http().get("/video-hosts/" + id);
export const updateVideoHost = (id, payload) => http().patch("/video-hosts/" + id, payload);

export const createPaymentGateway = payload => http().post("/payment-gateways", payload);
export const getPaymentGateway = id => http().get("/payment-gateways/" + id);
export const updatePaymentGateway = (id, payload) =>
  http().patch("/payment-gateways/" + id, payload);

export const createPaymentGatewayPlan = payload => http().post("/payment-gateway-plans", payload);
export const updatePaymentGatewayPlan = (id, payload) =>
  http().patch("/payment-gateway-plans/" + id, payload);

export const createPaymentPlan = payload => http().post("/plans", payload);
export const getPaymentPlan = id => http().get("/plans/" + id);
export const updatePaymentPlan = (id, payload) => http().patch("/plans/" + id, payload);

export const uploadCertificate = payload => http().post(`/certificate`, payload);

export const getDictionaryItem = id => http().get(`/dictionary/${id}`);
export const updateDictionaryItem = (id, payload) => http().patch("/dictionary/" + id, payload);

export const getGrammarPoint = id => http().get("/grammars/" + id);
export const updateGrammarPoint = (id, payload) => http().patch("/grammars/" + id, payload);

export const upload = (url, payload) => http().post(url, payload);

export const activateVideos = payload => http().patch(`/activate-videos`, payload);
