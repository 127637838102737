import React from "react";
import PropTypes from "prop-types";
import Icon from "../Icon";
import config from "../../config";

export const iconSizes = {
  xxs: 12,
  xs: 14,
  sm: 16,
  md: 18,
  lg: 20,
  xl: 24,
  "2xl": 32,
  "3xl": 48
};

const Button = ({
  text,
  size,
  colour,
  onClick,
  disabled,
  classes,
  fullWidth,
  isOutline,
  forceAnchor,
  RouterLink,
  icon,
  type,
  muted,
  id,
  border,
  url,
  isLink,
  download,
  children
}) => {
  let colourClasses;

  switch (colour) {
    case "white":
      colourClasses = `text-${colour} hover:text-${colour}`;
      break;
    case "gray":
      colourClasses = isOutline
        ? `text-${colour}-700 hover:text-${colour}-900`
        : `bg-${colour}-700 hover:bg-${colour}-900 text-white`;
      break;
    default:
      colourClasses = isOutline
        ? `hover:text-${colour}-700 text-${colour}-500`
        : `bg-${colour}-500 hover:bg-${colour}-700 text-white`;
  }

  const genericClasses = `${colourClasses} ${classes} text-${size} inline-block`;
  const linkClasses = `${config.styles.linkClasses}`;

  const buttonClasses = `${config.styles.buttonClasses} ${
    ["md", "xl", "2xl"].includes(size) ? "p-2" : "p-1"
  } ${
    fullWidth ? "w-full" : ""
  } disabled:cursor-not-allowed disabled:opacity-50 ${
    muted ? "border-none bg-transparent" : `bg-white`
  } ${
    border
      ? `border border-${colour}-${
          colour === "gray" ? 700 : 500
        } hover:border-${colour}-${colour === "gray" ? 900 : 700}`
      : ""
  }`;

  const body = (
    <>
      {icon ? (
        <span className={`flex items-center justify-center`}>
          <Icon icon={icon} size={iconSizes[size]} />
          {text ? <span className={`ml-2`}>{text}</span> : null}
        </span>
      ) : (
        text
      )}
      {children ? children : null}
    </>
  );

  if (url) {
    const isLocal = ["/", "#"].includes(url.substring(0, 1));
    const isHash = ["#"].includes(url.substring(0, 1));

    if (!isLocal || forceAnchor) {
      return (
        <a
          id={id}
          download={download ? download : false}
          onClick={onClick ? onClick : null}
          className={`${genericClasses} ${
            isLink ? linkClasses : buttonClasses
          }`}
          target="_blank"
          rel="noopener noreferrer"
          href={url}>
          {body}
        </a>
      );
    }

    if (isHash || !RouterLink) {
      return (
        <a
          id={id}
          href={url}
          onClick={onClick ? onClick : null}
          className={`${genericClasses} ${
            isLink ? linkClasses : buttonClasses
          }`}>
          {body}
        </a>
      );
    }

    return (
      <RouterLink
        id={id}
        onClick={onClick ? onClick : null}
        className={`${genericClasses} ${isLink ? linkClasses : buttonClasses}`}
        to={url}>
        {body}
      </RouterLink>
    );
  }

  return (
    <button
      id={id}
      type={type}
      onClick={onClick ? onClick : null}
      disabled={disabled}
      className={`${genericClasses} ${isLink ? linkClasses : buttonClasses}`}>
      {body}
    </button>
  );
};

Button.propTypes = {
  disabled: PropTypes.bool.isRequired,
  fullWidth: PropTypes.bool.isRequired,
  border: PropTypes.bool.isRequired,
  muted: PropTypes.bool.isRequired,
  isLink: PropTypes.bool.isRequired,
  forceAnchor: PropTypes.bool.isRequired,
  url: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  download: PropTypes.string,
  RouterLink: PropTypes.object,
  isOutline: PropTypes.bool.isRequired,
  size: PropTypes.string.isRequired,
  icon: PropTypes.string,
  colour: PropTypes.string.isRequired,
  classes: PropTypes.string.isRequired,
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
    PropTypes.number
  ]),
  type: PropTypes.string.isRequired,
  onClick: PropTypes.func
};

Button.defaultProps = {
  type: "submit",
  disabled: false,
  forceAnchor: false,
  muted: false,
  isLink: false,
  isOutline: true,
  border: true,
  fullWidth: true,
  colour: "gray",
  classes: "",
  size: "md"
};

export default Button;
