import React from "react";
import PropTypes from "prop-types";
import CardList from "./KanbanCardList";

const KanbanView = ({
  feedback,
  updateFeedbackData,
  updatingFeedback,
  handleUpdateFilter,
  showModal,
  filters,
  updateFeedback,
  showMenu,
  toggleMenu
}) => (
  <div className={`p-4 bg-gray-500 overflow-x-auto overflow-y-hidden h-100`}>
    <div className={`flex -mx-1`}>
      {feedback &&
        feedback.length &&
        feedback.map((list, index) =>
          list && list.data && list.data.length ? (
            <CardList
              toggleMenu={toggleMenu}
              updatingFeedback={updatingFeedback}
              filters={filters}
              showModal={showModal}
              updateFeedback={updateFeedback}
              handleUpdateFilter={handleUpdateFilter}
              updateFeedbackData={updateFeedbackData}
              showMenu={showMenu}
              key={index}
              list={list}
            />
          ) : null
        )}
    </div>
  </div>
);

KanbanView.propTypes = {
  feedback: PropTypes.array.isRequired,
  updateFeedback: PropTypes.func.isRequired,
  updateFeedbackData: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  updatingFeedback: PropTypes.bool.isRequired,
  handleUpdateFilter: PropTypes.func.isRequired,
  filters: PropTypes.array.isRequired,
  showMenu: PropTypes.string,
  toggleMenu: PropTypes.func.isRequired
};

export default KanbanView;
