import React from "react";
import { Trans } from "@lingui/macro";
import { toast } from "react-toastify";
import moment from "moment";
import QS from "query-string";
import PageHeader from "../components/PageHeader";
import PageBody from "../components/PageBody";
import { ApiContext } from "../contexts/ApiContext";
import { get, patch } from "../api";
import FeedbackRow from "../components/feedback/FeedbackRow";
import { Button, LoadingWrapper, PaginationLinks } from "@iforwms/react-components";
import { parseErrorMessage, trimChar, pluralise } from "@iforwms/helpers-js";
import history from "../components/history";
import { Link } from "react-router-dom";
import KanbanView from "../components/feedback/KanbanView";
import FeedbackModal from "../components/feedback/FeedbackModal";

class FeedbackIndex extends React.Component {
  static contextType = ApiContext;
  state = {
    error: null,
    isLoading: true,
    updatingFeedback: false,
    showAdvancedFilter: false,
    feedback: [],
    kanban: false,
    isSubmitting: false,
    isUpdating: false,
    limit: 20,
    meta: null,
    links: null,
    sort_by: "created_at",
    sort_direction: "desc",
    platform: "",
    priority: "",
    type: "",
    status: "",
    id: "",
    closed: false,
    fixed: null,
    due: null,
    overdue: false,
    search: "",
    showDropdown: null,
    showFeedback: null
  };

  componentDidMount() {
    this._isMounted = true;
    localStorage.setItem("DOMINO_LAST_ISSUE_FETCH", moment().toJSON());
    this.updateUrl({});
    this.fetchFeedback();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  safeSetState = (...args) => {
    this._isMounted && this.setState(...args);
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.location.search !== prevProps.location.search) {
      this.fetchFeedback();
    }
  }

  fetchFeedback = () => {
    this.setState({
      isLoading: true,
      isUpdating: true,
      feedback: [],
      error: null
    });

    this.context
      .callApi(() => get(`/feedback/general${this.props.location.search}`))
      .then(({ data }) => {
        this.safeSetState({
          feedback: data.data,
          meta: data.meta,
          links: data.links,
          isLoading: false,
          isUpdating: false,
          error: null
        });

        const { id, feedback } = this.state;
        if (id) {
          const filtered = feedback.filter(f => f.id === id);

          if (filtered.length) {
            this.safeSetState({ showFeedback: filtered[0] });
          }
        }
      })
      .catch(error => {
        this.safeSetState({
          isLoading: false,
          isUpdating: false,
          error
        });
        toast.error(parseErrorMessage(error, <Trans>Failed to fetch feedback.</Trans>));
      });
  };

  updateUrlFromUrl = url => {
    const parsedUrl = QS.parseUrl(url).query;
    this.updateUrl(parsedUrl);
  };

  updateUrl = (queryObject, pushHistory = true, force = false) => {
    let merged = null;

    if (force) {
      merged = queryObject;
    } else {
      const query = QS.parseUrl(this.props.location.search).query;
      merged = { ...query, ...queryObject };
    }

    let queryString = ``;
    let keys = Object.keys(merged);
    keys.forEach(key => {
      if (key === "NOT_SET") {
        return;
      }

      this.safeSetState({ [key]: merged[key] });
      queryString += `&${key}=${merged[key]}`;
    });

    const stringFilters = ["platform", "priority", "type", "status", "search", "assignee_id"];
    const booleanFilters = ["fixed", "due", "kanban"];

    if (pushHistory) {
      this.safeSetState({ feedback: [] });
    }

    stringFilters.forEach(filter => {
      if (!keys.includes(filter)) {
        this.safeSetState({ [filter]: "" });
      }
    });

    booleanFilters.forEach(filter => {
      if (!keys.includes(filter)) {
        this.safeSetState({ [filter]: false });
      } else {
        this.safeSetState({ [filter]: merged[filter] === "true" || merged[filter] === true });
      }
    });

    if (pushHistory) {
      history.push(`/feedback?${trimChar(queryString, "&")}`);
    }
  };

  handleUpdate = feedback => {
    this.context
      .callApi(() => patch(`/feedback/general/${feedback.id}`, feedback))
      .then(({ data }) => {
        let updatedFeedback = this.state.feedback;

        if (this.state.kanban) {
          updatedFeedback = updatedFeedback.map(status => {
            if (status.status === data.status) {
              return {
                status: status.status,
                data: status.data.filter(item => item.id !== data.id).concat(data)
              };
            }

            return {
              status: status.status,
              data: status.data.filter(item => item.id !== feedback.id)
            };
          });
        } else {
          updatedFeedback = updatedFeedback.map(f => {
            if (f.id === feedback.id) {
              return data;
            }
            return f;
          });
        }

        this.safeSetState({
          feedback: updatedFeedback,
          showFeedback: this.state.showFeedback ? data : null
        });
        toast.success(<Trans>Feedback updated!</Trans>);
      })
      .catch(error => {
        toast.error(parseErrorMessage(error, <Trans>Failed to update feedback.</Trans>));
      });
  };

  updateFeedbackStatus = (id, action) => {
    const confirm = window.confirm(`Are you sure you want to ${action} this feedback?`);

    if (!confirm) {
      return;
    }

    this.safeSetState({ updatingFeedback: true });

    this.context
      .callApi(() => patch(`/feedback/general/${id}/${action}`))
      .then(({ data }) => {
        this.safeSetState({
          feedback: this.state.feedback.map(feedback => {
            if (feedback.id !== id) {
              return feedback;
            }
            return data;
          }),
          updatingFeedback: false,
          showFeedback: this.state.showFeedback ? data : null
        });
        toast.success(`Feedback closed!`);
      })
      .catch(error => {
        this.safeSetState({ error, updatingFeedback: false });
        toast.error(parseErrorMessage(error, <Trans>Failed to close feedback.</Trans>));
      });
  };

  toggleMenu = (id, type, isModal) => {
    this.setState(prevState => ({
      showDropdown: prevState.showDropdown ? null : `${id}_${type}_${isModal}`
    }));
  };

  showModal = feedback => {
    // this.updateUrl({ id: feedback ? feedback.id : "" });
    this.setState({ showFeedback: feedback });
  };

  render() {
    const {
      error,
      isLoading,
      updatingFeedback,
      showAdvancedFilter,
      feedback,
      kanban,
      isSubmitting,
      isUpdating,
      limit,
      sort_by,
      sort_direction,
      platform,
      priority,
      type,
      status,
      closed,
      fixed,
      due,
      overdue,
      meta,
      links,
      search,
      showDropdown,
      showFeedback
    } = this.state;

    return (
      <div>
        <PageHeader title={<Trans>Feedback</Trans>} />
        <PageBody>
          <div className="relative p-4 md:p-8">
            <div className={``}>
              <div
                className={`p-2 flex flex-col items-center justify-between bg-gray-200 rounded-t`}>
                <div className={`w-full pb-2 flex overflow-y-auto custom-scroll`}>
                  <div className={`flex mr-2`}>
                    <Button
                      size={`sm`}
                      colour={`blue`}
                      disabled={isUpdating || isLoading}
                      onClick={this.fetchFeedback}
                      icon={`refresh`}
                      text={
                        !isLoading && meta ? <span className={`ml-2`}>{meta.total}</span> : null
                      }
                    />

                    <Button
                      size={`sm`}
                      colour={`indigo`}
                      disabled={isUpdating || isLoading}
                      classes={`ml-2`}
                      onClick={() => this.updateUrl({ kanban: !kanban }, true, false)}
                      text={kanban ? <Trans>List</Trans> : <Trans>Kanban</Trans>}
                    />

                    <Button
                      size={`sm`}
                      disabled={isUpdating || isLoading}
                      classes={`ml-2 whitespace-no-wrap`}
                      onClick={() => this.updateUrl({ closed: false, kanban }, true, true)}
                      text={<Trans>Clear Filters</Trans>}
                    />

                    <Button
                      size={`sm`}
                      colour={`pink`}
                      disabled={isUpdating || isLoading}
                      classes={`ml-2`}
                      onClick={() => this.updateUrl({ assignee_id: this.context.user.id })}
                      text={<Trans>Mine</Trans>}
                    />

                    {kanban ? null : (
                      <select
                        style={{ width: 90 }}
                        className={`form-input text-sm ml-2`}
                        disabled={isLoading || isUpdating}
                        value={sort_by}
                        onChange={e => this.updateUrl({ sort_by: e.target.value })}>
                        <option value="" disabled>
                          Sort By
                        </option>
                        <option value="ordering">Ordering</option>
                        <option value="created_at">Created</option>
                        <option value="due_at">Due</option>
                        <option value="closed_at">Closed</option>
                        <option value="fixed_at">Fixed</option>
                      </select>
                    )}

                    {kanban ? null : (
                      <Button
                        size={`sm`}
                        disabled={isUpdating || isLoading}
                        classes={`ml-2`}
                        onClick={() =>
                          this.updateUrl({
                            sort_direction: sort_direction === "asc" ? "desc" : "asc"
                          })
                        }
                        text={sort_direction === "asc" ? <Trans>Asc</Trans> : <Trans>Desc</Trans>}
                      />
                    )}
                  </div>

                  <div className={`flex ml-2 md:ml-auto`}>
                    <form
                      className={`flex`}
                      method={`POST`}
                      onSubmit={e => {
                        e.preventDefault();
                        this.updateUrl({ search });
                      }}>
                      <input
                        style={{ minWidth: 120 }}
                        type="text"
                        placeholder={`Search`}
                        value={search}
                        onChange={e => this.setState({ search: e.target.value })}
                        className="focus:outline-none form-input"
                      />
                      <Button
                        fullWidth={false}
                        size={`sm`}
                        classes="ml-2 flex w-10 items-center justify-center"
                        icon={`search`}
                      />
                    </form>

                    <Button
                      colour={`blue`}
                      fullWidth={false}
                      size={`sm`}
                      RouterLink={Link}
                      url={`/feedback/create${this.props.location.search}`}
                      classes={`ml-2 flex w-10 items-center justify-center`}
                      icon={`add`}
                    />
                  </div>
                </div>

                <div
                  className={`pb-2 w-full mt-2 flex justify-between items-center overflow-y-auto custom-scroll`}>
                  {kanban ? null : (
                    <select
                      className={`form-input text-sm`}
                      disabled={isLoading || isUpdating}
                      style={{ width: 60 }}
                      value={closed}
                      onChange={e => this.updateUrl({ closed: e.target.value })}>
                      <option value="" disabled>
                        Choose Visibility
                      </option>
                      <option value="false">Open</option>
                      <option value="true">Closed</option>
                    </select>
                  )}

                  {kanban ? null : (
                    <select
                      className={`form-input ml-2 text-sm`}
                      style={{ width: 60 }}
                      disabled={isLoading || isUpdating}
                      value={limit}
                      onChange={e => this.updateUrl({ limit: e.target.value })}>
                      <option value="" disabled>
                        Select Limit
                      </option>
                      <option value="20">20</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                  )}

                  {meta &&
                    meta.filters &&
                    meta.filters.map(filter =>
                      kanban && filter.column_name === "status" ? null : (
                        <select
                          value={this.state[filter.column_name]}
                          key={filter.column_name}
                          style={{ minWidth: 128 }}
                          className={`form-input ml-2 text-sm`}
                          onChange={e => this.updateUrl({ [filter.column_name]: e.target.value })}
                          disabled={isLoading || isUpdating}>
                          <option value="">All {pluralise(filter.column_name)}</option>
                          {filter.options
                            .filter(f => f.value)
                            .map(option => (
                              <option key={option.name} value={option.value}>
                                {option.name}
                              </option>
                            ))}
                        </select>
                      )
                    )}
                </div>
              </div>

              {/*<FeedbackFilter*/}
              {/*  fetchFeedback={this.fetchFeedback}*/}
              {/*  sortedFeedback={sortedFeedback}*/}
              {/*  sortBy={sortBy}*/}
              {/*  isUpdating={isUpdating}*/}
              {/*  kanban={kanban}*/}
              {/*  isLoading={isLoading}*/}
              {/*  changeSortBy={this.changeSortBy}*/}
              {/*  filter={filter}*/}
              {/*  handleAdvancedHandleFilter={this.handleAdvancedHandleFilter}*/}
              {/*  handleUpdateFilter={this.handleUpdateFilter}*/}
              {/*  setState={this.setState}*/}
              {/*  showAdvancedFilter={showAdvancedFilter}*/}
              {/*  status={status}*/}
              {/*/>*/}

              <div className="">
                <LoadingWrapper
                  isLoading={isLoading || isUpdating}
                  error={error}
                  message={<Trans>Fetching feedback...</Trans>}>
                  {feedback && feedback.length && kanban ? (
                    <KanbanView
                      feedback={feedback}
                      showModal={this.showModal}
                      showMenu={showDropdown}
                      toggleMenu={this.toggleMenu}
                      filters={meta.filters}
                      updatingFeedback={isUpdating}
                      handleUpdateFilter={this.updateUrl}
                      queryString={this.props.location.search}
                      updateFeedbackData={this.handleUpdate}
                      updateFeedback={this.updateFeedbackStatus}
                      userId={this.context.user.id}
                    />
                  ) : feedback.length ? (
                    feedback.map((feedback, index) => (
                      <FeedbackRow
                        showModal={this.showModal}
                        showMenu={showDropdown}
                        toggleMenu={this.toggleMenu}
                        filters={meta.filters}
                        updatingFeedback={isUpdating}
                        handleUpdateFilter={this.updateUrl}
                        queryString={this.props.location.search}
                        updateFeedbackData={this.handleUpdate}
                        updateFeedback={this.updateFeedbackStatus}
                        key={feedback.id}
                        feedback={feedback}
                        userId={this.context.user.id}
                      />
                    ))
                  ) : (
                    <div className={`p-4 text-center`}>No feedback matched your query.</div>
                  )}
                </LoadingWrapper>
              </div>

              {!kanban && meta && feedback.length ? (
                <div className="p-4 bg-gray-200 rounded-b">
                  <PaginationLinks
                    meta={meta}
                    fetchData={this.updateUrlFromUrl}
                    isLoading={isLoading}
                    links={links}
                  />
                </div>
              ) : null}
            </div>

            {showFeedback ? (
              <FeedbackModal
                feedback={showFeedback}
                handleUpdate={this.handleUpdate}
                toggleMenu={this.toggleMenu}
                updateFeedbackStatus={this.updateFeedbackStatus}
                updateUrl={this.updateUrl}
                filters={meta.filters}
                showDropdown={showDropdown}
                updatingFeedback={updatingFeedback}
                showModal={this.showModal}
              />
            ) : null}
          </div>
        </PageBody>
      </div>
    );
  }
}

export default FeedbackIndex;
