import React, { Component } from "react";
import PropTypes from "prop-types";
import { Trans } from "@lingui/macro";
import { toast } from "react-toastify";
import List from "../components/List";
import PrivateRoute from "../components/PrivateRoute";
import PageHeader from "../components/PageHeader";
import PageBody from "../components/PageBody";
import PaymentGatewayForm from "../components/forms/PaymentGatewayForm";
import { ApiContext } from "../contexts/ApiContext";
import { createPaymentGatewayPlan, getPaymentGateway, updatePaymentGatewayPlan } from "../api";
import parseErrorMessage from "helpers-js/parseErrorMessage";
import { Button, LoadingWrapper } from "@iforwms/react-components";
import { Link } from "react-router-dom";

class PaymentGateways extends Component {
  static contextType = ApiContext;
  state = { loading: false, error: undefined, item: undefined };

  componentDidMount() {
    this._isMounted = true;
    const {
      match: {
        params: { gateway_id }
      }
    } = this.props;
    if (gateway_id) {
      return this.fetchItem();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      match: {
        params: { gateway_id }
      }
    } = this.props;

    if (gateway_id && gateway_id !== prevProps.match.params.gateway_id) {
      this.fetchItem();
    }
  }

  safeSetState = (...args) => {
    this._isMounted && this.setState(...args);
  };

  fetchItem = () => {
    this.safeSetState({ loading: true });

    this.context
      .callApi(() => getPaymentGateway(this.props.match.params.gateway_id))
      .then(res => {
        this.safeSetState({
          item: res.data,
          loading: false
        });
      })
      .catch(error => {
        this.safeSetState({ error, loading: false });
        toast.error(parseErrorMessage(error, <Trans>Failed to fetch details.</Trans>));
      });
  };

  render() {
    const { item, loading, error } = this.state;

    return (
      <div className="static">
        <PageHeader
          backLink="/payment-gateways"
          title={<Trans>Payment Gateways</Trans>}
          body={<Trans>Update, add or delete third-party payment gateways here.</Trans>}
        />

        <PageBody>
          <div className="flex px-4 container-narrow">
            <LoadingWrapper
              isLoading={loading}
              error={error}
              message={<Trans>Fetching details...</Trans>}>
              <PrivateRoute
                exact
                path={`/payment-gateways`}
                render={props => (
                  <List
                    title={<Trans>Payment Gateway Index</Trans>}
                    endpoint="payment-gateways"
                    disableOrdering
                    maxWidth={600}
                    disableLink
                    form={
                      <PaymentGatewayForm
                        onSuccess={() => this.props.history.push("/payment-gateways")}
                      />
                    }
                  />
                )}
              />

              {item && (
                <PrivateRoute
                  path={`/payment-gateways/:gateway_id/edit`}
                  render={props => (
                    <div className="flex flex-col w-full">
                      <PaymentGatewayForm
                        onDelete={() => this.props.history.push("/payment-gateways")}
                        initialValues={item}
                      />
                      <div className="form-container -mt-4 border-t">
                        <h2 className="form-header">Plans</h2>
                        {item.plans.map((plan, index) => (
                          <PaymentGatewayPlanForm
                            plan={plan}
                            key={index}
                            payment_gateway_id={item.id}
                          />
                        ))}
                      </div>
                    </div>
                  )}
                />
              )}
            </LoadingWrapper>
          </div>
        </PageBody>
      </div>
    );
  }
}

class PaymentGatewayPlanForm extends Component {
  static contextType = ApiContext;
  static propTypes = {
    plan: PropTypes.object.isRequired,
    payment_gateway_id: PropTypes.number.isRequired
  };

  state = {
    product_id: this.props.plan.payment_gateway_plan.product_id || "",
    id: this.props.plan.payment_gateway_plan.id || ""
  };

  handleSubmit = e => {
    e.preventDefault();
    const { callApi } = this.context;
    const { plan, payment_gateway_id } = this.props;
    const { product_id, id } = this.state;
    const payload = {
      payment_gateway_id,
      plan_id: plan.id,
      product_id
    };
    callApi(() =>
      id ? updatePaymentGatewayPlan(id, { product_id }) : createPaymentGatewayPlan(payload)
    )
      .then(res => {
        toast.success(<Trans>Saved.</Trans>);
      })
      .catch(e => {
        toast.error(parseErrorMessage(e, <Trans>Save failed.</Trans>));
      });
  };

  render() {
    const { plan } = this.props;
    const { product_id } = this.state;

    return (
      <div className="form-input-group" key={plan.id}>
        <label className="form-label">{plan.name}</label>
        <form method="POST" onSubmit={this.handleSubmit}>
          <div className="flex">
            <input
              type="text"
              className="form-input mr-2"
              onChange={e => this.setState({ product_id: e.target.value })}
              value={product_id}
            />
            <Button RouterLink={Link} colour={`blue`} text={<Trans>Save</Trans>} />
          </div>
        </form>
      </div>
    );
  }
}

export default PaymentGateways;
