import React from "react";
import PropTypes from "prop-types";
import Icon from "../Icon";
import config from "../../config";

const Panel = ({
  title,
  colour,
  background,
  icon,
  children,
  overflow,
  isAlert,
  size
}) => (
  <div
    className={`flex ${
      overflow ? "overflow-y-auto" : ""
    } items-start bg-${background} bg-${background}-100 border-${colour} border-${colour}-500 ${
      config.styles.panelClasses
    } ${isAlert ? "p-2" : "p-4"}`}>
    {icon ? (
      <div
        className={`p-2 rounded-full mr-4 bg-${colour}-200 text-${colour}-500`}>
        <Icon icon={icon} />
      </div>
    ) : null}

    <div className={`flex-1`}>
      {title ? (
        <h3
          className={`${
            children ? "mb-2" : ""
          } text-${size} text-${colour}-500`}>
          {title}
        </h3>
      ) : null}
      {children ? children : null}
    </div>
  </div>
);

Panel.defaultProps = {
  size: "lg",
  colour: "green",
  background: "white",
  overflow: true,
  isAlert: false
};

Panel.propTypes = {
  size: PropTypes.string.isRequired,
  isAlert: PropTypes.bool.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  colour: PropTypes.string.isRequired,
  overflow: PropTypes.bool.isRequired,
  background: PropTypes.string.isRequired,
  icon: PropTypes.string,
  children: PropTypes.any
};

export default Panel;
