import PropTypes from "prop-types";
import React from "react";
import { calculateLevelColor } from "./DialogWord";
import AudioPlayer from "../audioPlayer/AudioPlayer";

const DialogMoreInfo = ({ info }) => (
  <div>
    {info ? (
      typeof info === "object" ? (
        <>
          <div className={`flex`}>
            <div className="text-blue-500 mr-2">{info.text}</div>
            <div className="text-red-500">
              {info.characters.map(char => char.phonetic)}
            </div>
            <div className="ml-auto flex items-start">
              {info.audio_src ? (
                <div className="hover:text-primary-500 cursor-pointer">
                  <AudioPlayer
                    key={info.audio_src}
                    mini={true}
                    src={info.audio_src}
                  />
                </div>
              ) : null}
              {info.language_level ? (
                <div
                  className={`text-xs ml-2 text-${calculateLevelColor(
                    info.language_level,
                    true
                  )} border-b-2 border-${calculateLevelColor(
                    info.language_level,
                    true
                  )}`}>
                  HSK {info.language_level}
                </div>
              ) : null}
            </div>
          </div>
          {info.definitions.length ? (
            <div className={`text-sm`}>{info.definitions.join("; ")}</div>
          ) : null}
        </>
      ) : (
        <div>{info}</div>
      )
    ) : (
      <div className="text-sm">
        Click on an actor name for the translation or on an individual word for
        more details.
      </div>
    )}
  </div>
);

DialogMoreInfo.propTypes = {
  info: PropTypes.shape({
    text: PropTypes.string.isRequired,
    characters: PropTypes.arrayOf(
      PropTypes.shape({
        word_id: PropTypes.number.isRequired,
        text: PropTypes.string.isRequired,
        tone: PropTypes.number.isRequired,
        pinyin_ascii: PropTypes.string,
        pinyin_unicode: PropTypes.string
      })
    ).isRequired,
    definitions: PropTypes.array.isRequired,
    language_level: PropTypes.number,
    audio_src: PropTypes.string
  })
};

export default DialogMoreInfo;
