import React, { Component } from "react";
import PropTypes from "prop-types";
import { Trans } from "@lingui/macro";
import { Link } from "react-router-dom";
import moment from "moment";
import PageBody from "../components/PageBody";
import PageHeader from "../components/PageHeader";
import ReloadButton from "../components/ReloadButton";
import DeleteResourceButton from "../components/forms/DeleteResourceButton";
import { ApiContext } from "../contexts/ApiContext";
import { get } from "../api";
import QS from "query-string";
import history from "../components/history";
import parseErrorMessage from "helpers-js/parseErrorMessage";
import { toast } from "react-toastify";
import { Button, PaginationLinks, Panel } from "@iforwms/react-components";
import { LoadingWrapper } from "@iforwms/react-components";

class ResourceIndex extends Component {
  static contextType = ApiContext;
  state = {
    error: undefined,
    isLoading: true,
    items: [],
    links: null,
    meta: null,
    next: null,
    prev: null,
    limit: 20,
    page: 1,
    isSubmitting: false,
    isUpdating: false
  };

  handleDelete = deleted => {
    this.fetchItems();
  };

  componentDidMount() {
    this._isMounted = true;
    const query = QS.parseUrl(this.props.search).query;
    const limit = query.limit ? query.limit : 20;
    const page = query.page ? query.page : 1;

    if (page === 1 && limit === 20) {
      this.fetchItems();
    } else {
      this.safeSetState({ limit, page });
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps, prevState) {
    const { limit, page } = this.state;
    if (prevState.limit !== limit || prevState.page !== page) {
      history.push(`${this.props.endpoint}?page=${page}&limit=${limit}`);
      return this.fetchItems();
    }

    if (prevProps.search !== this.props.search) {
      this.fetchItems();
    }
  }

  safeSetState = (...args) => {
    this._isMounted && this.setState(...args);
  };

  fetchItems = url => {
    const { callApi } = this.context;
    const { page, limit } = this.state;

    this.safeSetState({
      isLoading: true,
      isUpdating: true
    });

    let fetchUrl = url ? url : `${this.props.endpoint}?page=${page}&limit=${limit}`;

    callApi(() => get(fetchUrl))
      .then(({ data }) => {
        this.safeSetState({
          items: data.data,
          isLoading: false,
          isUpdating: false,
          error: undefined,
          meta: data.meta,
          links: data.links
        });
      })
      .catch(error => {
        toast.error(parseErrorMessage(error, <Trans>Failed to fetch items.</Trans>));
        console.error(error);
        this.safeSetState({
          isLoading: false,
          isUpdating: false,
          error
        });
      });
  };

  changeLimit = e => {
    this.safeSetState({ limit: e.target.value, page: 1 });
  };

  changePage = url => {
    if (url) {
      const query = QS.parseUrl(url).query;
      this.safeSetState({ page: query.page });
    }
  };

  render() {
    const { error, isLoading, items, meta, links, isSubmitting, limit, isUpdating } = this.state;

    const { title, body, endpoint, actions, headers, data } = this.props;

    return (
      <div>
        <PageHeader title={title} body={body} />
        <PageBody>
          <div className="mb-4 p-4 container">
            <Panel colour={`green`} title={title}>
              <div>
                {actions.includes("CREATE") && (
                  <div className="text-right mb-4">
                    <Button
                      RouterLink={Link}
                      fullWidth={false}
                      size={`sm`}
                      colour={`blue`}
                      url={`/${endpoint}/create`}
                      text={<Trans>Create New</Trans>}
                    />
                  </div>
                )}
                <div>
                  <div className="flex justify-between mb-4">
                    <div className="flex items-center">
                      <select
                        className="text-sm p-1 border bg-white"
                        value={limit}
                        onChange={this.changeLimit}>
                        <option value="20">20</option>
                        <option value="50">50</option>
                        <option value="100">100</option>
                        <option value="200">200</option>
                      </select>
                      <span className="ml-2 text-sm">
                        <Trans>records per page</Trans>
                      </span>
                    </div>
                  </div>

                  {meta && meta.total && links && links.first ? (
                    <div className="py-4">
                      <PaginationLinks
                        links={links}
                        fetchData={this.fetchItems}
                        meta={meta}
                        isLoading={isLoading}
                      />
                    </div>
                  ) : null}

                  <div className="mb-2">
                    <LoadingWrapper
                      message={<Trans>Updating results...</Trans>}
                      error={error}
                      retry={this.fetchItems}
                      isLoading={isUpdating}>
                      <div className="overflow-x-auto">
                        <table className="w-full">
                          <thead>
                            <tr>
                              {headers.map((columnName, index) => (
                                <th key={index} className="pb-1 border-b text-sm text-left">
                                  {columnName}
                                </th>
                              ))}
                              {actions.length && (
                                <th className="pb-1 border-b text-sm text-center"><Trans>Actions</Trans></th>
                              )}
                            </tr>
                          </thead>
                          <tbody>
                            {items.map((item, index) => (
                              <tr key={index}>
                                {data.map((columnName, index) => (
                                  <td
                                    key={index}
                                    className="max-w-md truncate whitespace-no-wrap py-2 border-b text-sm pr-4">
                                    {[
                                      "published_at",
                                      "expires_at",
                                      "last_login",
                                      "created_at",
                                      "ends_at",
                                      "cancelled_at"
                                    ].includes(columnName) ? (
                                      item[columnName] ? (
                                        moment.utc(item[columnName]).format("YYYY/MM/DD HH:mm")
                                      ) : (
                                        "-"
                                      )
                                    ) : columnName === "email" ? (
                                      <Button
                                        isLink={true}
                                        colour={`teal`}
                                        classes={`underline`}
                                        url={`mailto:${item.email}`}
                                        text={item.email}
                                      />
                                    ) : (
                                      item[columnName]
                                    )}
                                  </td>
                                ))}
                                {actions.length && (
                                  <td className="py-2 border-b text-sm text-right whitespace-no-wrap flex">
                                    {actions.includes("VIEW") && (
                                      <Button
                                        size={`sm`}
                                        colour={`blue`}
                                        classes={`text-center`}
                                        RouterLink={Link}
                                        url={`/${endpoint}/${item.slug ? item.slug : item.id}`}
                                        text={<Trans>View</Trans>}
                                      />
                                    )}
                                    {actions.includes("VIEW_USER") && (
                                      <Button
                                        size={`sm`}
                                        colour={`green`}
                                        RouterLink={Link}
                                        classes={`ml-2 text-center`}
                                        url={`/users/${item.user_id}`}
                                        text={<Trans>View User</Trans>}
                                      />
                                    )}
                                    {actions.includes("DELETE") && (
                                      <DeleteResourceButton
                                        classes={`ml-2`}
                                        onDelete={() => this.handleDelete(item)}
                                        isSubmitting={isSubmitting}
                                        url={`${endpoint}/${item.slug ? item.slug : item.id}`}
                                      />
                                    )}
                                  </td>
                                )}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </LoadingWrapper>
                  </div>

                  {meta && meta.total && links && links.first ? (
                    <div className="py-4">
                      <PaginationLinks
                        links={links}
                        fetchData={this.fetchItems}
                        meta={meta}
                        isLoading={isLoading}
                      />
                    </div>
                  ) : null}
                </div>
              </div>
            </Panel>
          </div>
        </PageBody>
      </div>
    );
  }
}

ResourceIndex.propTypes = {
  search: PropTypes.string.isRequired,
  title: PropTypes.object.isRequired,
  body: PropTypes.object.isRequired,
  endpoint: PropTypes.string.isRequired,
  actions: PropTypes.array.isRequired,
  headers: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired
};

export default ResourceIndex;
