import React, { Component } from "react";
import { Trans } from "@lingui/macro";
import { toast } from "react-toastify";
import PageBody from "../components/PageBody";
import PageHeader from "../components/PageHeader";
import { ApiContext } from "../contexts/ApiContext";
import UserPermissions from "../components/UserPermissions";
import { post, get, patch } from "../api";
import parseErrorMessage from "helpers-js/parseErrorMessage";
import SubscriptionDetail from "../components/SubscriptionDetail";
import CustomerDetails from "../components/CustomerDetails";
import PasswordResetForm from "../components/PasswordResetForm";
import PaymentDetails from "../components/PaymentDetails";
import ManualSubscriptionForm from "../components/ManualSubscriptionForm";
import { LoadingWrapper, Panel } from "@iforwms/react-components";
import * as Permissions from "../permissions.js";

class Users extends Component {
  static contextType = ApiContext;
  state = {
    password: "",
    error: undefined,
    avatar: null,
    weekly_completed_video_count: 0,
    weekly_completed_video_duration: 0,
    userDetail: null,
    weekly_completed_flashcard_count: 0,
    completed_dialogs: 0,
    completed_lessons: 0,
    completed_videos: 0,
    overdue_flashcard_count: 0,
    progress: null,
    email: "",
    last_login: "",
    email_verified_at: "",
    joined_at: "",
    name: "",
    id: "",
    payments: [],
    subscription: null,
    subscriptions: [],
    isLoading: true,
    isSubmitting: false,
    isUpdating: false
  };

  componentDidMount() {
    this._isMounted = true;
    this.fetchItems();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.fetchItems();
    }
  }

  safeSetState = (...args) => {
    this._isMounted && this.setState(...args);
  };

  changePassword = e => {
    e.preventDefault();

    const { password } = this.state;

    this.safeSetState({ isSubmitting: true });

    this.context
      .callApi(() => patch(`/auth/password/${this.state.id}`, { password }))
      .then(res => {
        toast.success(<Trans>Password changed.</Trans>);
        this.safeSetState({ isSubmitting: false });

        this.setPassword("");
      })
      .catch(error => {
        toast.error(parseErrorMessage(error, <Trans>Failed to change password.</Trans>));
        this.safeSetState({ isSubmitting: false, error });
      });
  };

  verifyEmail = () => {
    this.safeSetState({ isSubmitting: true });

    this.context
      .callApi(() => post(`/auth/manual-verification/${this.state.id}`))
      .then(({ data: { data } }) => {
        toast.success(<Trans>Email verified!</Trans>);
        this.safeSetState({
          email_verified_at: data.email_verified_at,
          isSubmitting: false,
          userDetail: data
        });
      })
      .catch(error => {
        toast.error(parseErrorMessage(error, <Trans>Failed to verify email.</Trans>));
        this.safeSetState({ isSubmitting: false, error });
      });
  };

  setPassword = password => {
    this.safeSetState({ password });
  };

  fetchItems = (url = null) => {
    const {
      match: {
        params: { id }
      }
    } = this.props;

    this.setState({ isLoading: true });

    this.context
      .callApi(() => get("/users/" + id))
      .then(({ data: { data } }) => {
        this.safeSetState({
          isLoading: false,
          userDetail: data,
          avatar: data.avatar_thumb,
          completed_lessons: data.completed_lesson_count,
          completed_dialogs: data.completed_dialog_count,
          completed_videos: data.completed_video_count,
          weekly_completed_video_count: data.weekly_completed_video_count,
          weekly_completed_video_duration: data.weekly_completed_video_duration,
          weekly_completed_flashcard_count: data.weekly_completed_flashcard_count,
          progress: data.progress,
          overdue_flashcard_count: data.overdue_flashcard_count,
          email: data.email,
          id: data.id,
          last_login: data.last_login,
          email_verified_at: data.email_verified_at,
          joined_at: data.joined_at,
          name: data.first_name && data.last_name ? data.first_name + " " + data.last_name : "-",
          payments: data.payments,
          subscription: data.subscription,
          subscriptions: data.subscriptions,
          error: undefined
        });
      })
      .catch(error => {
        this.safeSetState({ isLoading: false, error });
      });
  };

  render() {
    const {
      isLoading,
      avatar,
      completed_dialogs,
      completed_lessons,
      joined_at,
      completed_videos,
      email,
      overdue_flashcard_count,
      last_login,
      email_verified_at,
      name,
      isSubmitting,
      id,
      payments,
      weekly_completed_flashcard_count,
      weekly_completed_video_duration,
      weekly_completed_video_count,
      userDetail,
      subscription,
      progress,
      subscriptions,
      password
    } = this.state;
    const { user } = this.context;

    return (
      <div>
        <PageHeader title={<Trans>User Detail</Trans>} backLink="/users" />
        <PageBody>
          <div className="p-4 md:p-8">
            <LoadingWrapper message={<Trans>Fetching details...</Trans>} isLoading={isLoading}>
              <div className="">
                {id && <CustomerDetails verifyEmail={this.verifyEmail} user={userDetail} />}
              </div>

              <div>
                {user && user.permissions.includes(Permissions.VIEW_PAYMENTS) && subscription && (
                  <div className="mt-8">
                    <Panel colour={`orange`} title={<Trans>Current Subscription</Trans>}>
                      <SubscriptionDetail subscription={subscription} />
                    </Panel>
                  </div>
                )}

                {user && user.permissions.includes(Permissions.VIEW_PAYMENTS) && (
                  <>
                    <div className="mt-8">
                      <Panel colour={`orange`} title={<Trans>Previous Subscriptions</Trans>}>
                        {!subscriptions.length && (
                          <div>
                            <Trans>No previous subscriptions.</Trans>
                          </div>
                        )}
                        {subscriptions.map((subscription, index) => (
                          <div key={index} className="pb-2 mb-2 border-b">
                            <SubscriptionDetail key={subscription.id} subscription={subscription} />
                          </div>
                        ))}
                      </Panel>
                    </div>

                    {id ? (
                      <div className="mt-8">
                        <ManualSubscriptionForm userId={id} />
                      </div>
                    ) : null}

                    <div className="mt-8">
                      <PaymentDetails payments={payments} />
                    </div>

                    <div className="mt-8">
                      <PasswordResetForm
                        isSubmitting={isSubmitting}
                        changePassword={this.changePassword}
                        password={password}
                        setPassword={this.setPassword}
                      />
                    </div>
                  </>
                )}

                {user && userDetail && user.permissions.includes(Permissions.EDIT_PERMISSIONS) && (
                  <UserPermissions
                    userId={id}
                    userPermanentRoles={userDetail.permanent_roles}
                    userPermanentPermissions={userDetail.permanent_permissions}
                    userRoles={userDetail.roles}
                    userPermissions={userDetail.permissions}
                  />
                )}
              </div>
            </LoadingWrapper>
            {/* <UserHistory match={this.props.match} /> */}
          </div>
        </PageBody>
      </div>
    );
  }
}

export default Users;
