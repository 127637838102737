import PropTypes from "prop-types";
import React from "react";
import FeedbackTag from "../FeedbackTag";
import { Icon } from "@iforwms/react-components";

const CardList = ({
  list,
  updateFeedbackData,
  updatingFeedback,
  handleUpdateFilter,
  showModal,
  filters,
  updateFeedback,
  showMenu,
  toggleMenu
}) => (
  <div
    className={`overflow-y-auto p-2 bg-gray-200 rounded shadow mx-1`}
    style={{ width: 240, minWidth: 240 }}>
    <h2 className={`text-sm font-medium text-gray-700`}>
      {list.status} ({list.data.length}) [
      {list.data
        .map(item => item.time_estimate)
        .reduce((time, total) => time + total, 0)
        .toFixed(2)}
      ]
    </h2>

    <div className={`overflow-y-auto h-80`}>
      {list.data.map(feedback => (
        <div key={feedback.id} className={`p-2 mt-2 bg-white shadow rounded`}>
          <div
            onClick={() => showModal(feedback)}
            className={`cursor-pointer flex hover:text-pink-500 text-sm`}>
            {feedback.time_estimate ? <>[{feedback.time_estimate.toFixed(2)}] </> : null}
            {feedback.title}

            {feedback.images && feedback.images.length ? (
              <span className="flex items-center ml-2 text-indigo-500">
                <Icon icon="attachment" size={16} />
              </span>
            ) : null}
          </div>

          <div className="flex flex-wrap items-center -ml-1 text-xs">
            {filters
              .filter(f => f.column_name !== "status")
              .filter(f => feedback[f.column_name] !== "NOT_SET")
              .map(filter => (
                <FeedbackTag
                  toggleMenu={null}
                  showMenu={showMenu}
                  key={filter.column_name}
                  filter={filter}
                  updateFilter={handleUpdateFilter}
                  updateFeedback={updateFeedbackData}
                  feedback={feedback}
                />
              ))}
          </div>
        </div>
      ))}
    </div>
  </div>
);

CardList.propTypes = {
  list: PropTypes.object.isRequired,
  updateFeedback: PropTypes.func.isRequired,
  updateFeedbackData: PropTypes.func.isRequired,
  showModal: PropTypes.func.isRequired,
  updatingFeedback: PropTypes.bool.isRequired,
  handleUpdateFilter: PropTypes.func.isRequired,
  filters: PropTypes.array.isRequired,
  showMenu: PropTypes.string,
  toggleMenu: PropTypes.func.isRequired
};

export default CardList;
