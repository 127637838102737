import React from "react";
import { Trans } from "@lingui/macro";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import PageBody from "../components/PageBody";
import { get, patch, post } from "../api";
import parseErrorMessage from "helpers-js/parseErrorMessage";
import PageHeader from "../components/external/PageHeader";
import Dialog from "../components/external/dialogs/Dialog";
import history from "../components/history";

const DialogShow = ({
  match: {
    params: { id }
  }
}) => (
  <div>
    <PageHeader isAdmin={true} title={<Trans>Dialogs</Trans>} />

    <PageBody>
      <Dialog
        onSuccess={() => {
          history.push("/dialogs");
          toast.success(<Trans>Dialog is currently being processed.</Trans>);
        }}
        handleAudioUploadSuccess={() => {}}
        onError={error =>
          toast.error(parseErrorMessage(error, <Trans>Failed to update dialog.</Trans>))
        }
        RouterLink={Link}
        isAdmin={true}
        id={id}
        post={post}
        patch={patch}
        get={get}
      />
    </PageBody>
  </div>
);

export default DialogShow;
