import React from "react";
import { Trans } from "@lingui/macro";
import moment from "moment";
import { Table } from "@iforwms/react-components";
import PageHeader from "../components/PageHeader";
import PageBody from "../components/PageBody";
import { Link } from "react-router-dom";
import { destroy, get } from "../api";
import history from "../components/history";

const Dialog = ({ location }) => (
  <div>
    <PageHeader isAdmin={true} title={<Trans>Dialog</Trans>} />

    <PageBody>
      <div className="max-w-6xl p-4">
        <Table
          filters={[{ label: "Per Page", valueKey: "limit", options: [20, 50, 100] }]}
          title={<Trans>Dialog Index</Trans>}
          destroy={destroy}
          get={get}
          history={history}
          search={location.search}
          headers={[{ label: "Title", valueKey: "title" }]}
          endpoint={`/dialogs`}
          moment={moment}
          RouterLink={Link}
          actions={["VIEW", "DELETE", "CREATE", "BULK_CREATE"]}
        />
      </div>
    </PageBody>
  </div>
);

export default Dialog;
