export const transformMyRestApiErrorsToAnObject = error => {
  if (error && error.response && error.response.data) {
    return error.response.data;
  }
  return {};
};

export const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: "none",
  background: isDragging ? "#EFF8FF" : "white",
  ...draggableStyle
});

export const getListStyle = isDraggingOver => ({
  background: isDraggingOver ? "#EFF8FF" : "white",
  width: "100%"
});

export const generateLink = (item, endpoint, match, edit = false) => {
  if (endpoint === "payment-gateways") return `/payment-gateways/${item.id}${edit ? "/edit" : ""}`;
  if (endpoint === "video-hosts") return `/video-hosts/${item.id}${edit ? "/edit" : ""}`;
  if (endpoint === "plans") return `/plans/${item.id}${edit ? "/edit" : ""}`;

  if (!match) {
    return `/courses/${item.id}${edit ? "/edit" : ""}`;
  }

  if (match && match.params.lesson_id) {
    if (edit) {
      return `/videos/${item.id}`;
    }
    return `/courses/${match.params.course_id}/levels/${match.params.level_id}/chapters/${match.params.chapter_id}/lessons/${match.params.lesson_id}/videos/${item.id}`;
  }

  if (match && match.params.chapter_id) {
    return `/courses/${match.params.course_id}/levels/${match.params.level_id}/chapters/${
      match.params.chapter_id
    }/lessons/${item.id}${edit ? "/edit" : ""}`;
  }

  if (match && match.params.level_id) {
    return `/courses/${match.params.course_id}/levels/${match.params.level_id}/chapters/${item.id}${
      edit ? "/edit" : ""
    }`;
  }

  return `/courses/${match.params.course_id}/levels/${item.id}${edit ? "/edit" : ""}`;
};

export const parseFilter = (filter, value, key) => {
  const filtered = filter.options.filter(f => f.value === value);

  if (!filtered.length) {
    if (key === "name") {
      return "NOT SET";
    }

    return "gray";
  }

  return filtered[0][key];
};

export const calculateLevelColor = (language_level, showLevel) => {
  if (!showLevel) {
    return "transparent";
  }

  switch (language_level) {
    case 1:
      return "red-500";
    case 2:
      return "green-500";
    case 3:
      return "orange-500";
    case 4:
      return "blue-500";
    case 5:
      return "indigo-500";
    case 6:
      return "brown-500";
    default:
      return "transparent";
  }
};