import React, { Component } from "react";
import PropTypes from "prop-types";
import { Trans } from "@lingui/macro";
import { toast } from "react-toastify";
import { ApiContext } from "../contexts/ApiContext";
import { get, patch } from "../api";
import parseErrorMessage from "helpers-js/parseErrorMessage";
import { Panel } from "@iforwms/react-components";
import { LoadingWrapper } from "@iforwms/react-components";

class UserPermissions extends Component {
  static contextType = ApiContext;
  state = {
    isLoadingRoles: true,
    isLoadingPermissions: true,
    isUpdating: false,
    error: null,
    permanentPermissions: [],
    permanentRoles: [],
    permissions: [],
    roles: [],
    userPermanentPermissions: this.props.userPermanentPermissions,
    userPermanentRoles: this.props.userPermanentRoles,
    userPermissions: this.props.userPermissions,
    userRoles: this.props.userRoles
  };

  static propTypes = {
    userId: PropTypes.number.isRequired,
    userPermanentRoles: PropTypes.array.isRequired,
    userPermanentPermissions: PropTypes.array.isRequired,
    userRoles: PropTypes.array.isRequired,
    userPermissions: PropTypes.array.isRequired
  };

  componentDidMount() {
    this._isMounted = true;
    this.fetchRoles();
    this.fetchPermissions();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  safeSetState = (...args) => {
    this._isMounted && this.setState(...args);
  };

  fetchPermissions = () => {
    this.safeSetState({ isLoadingPermissions: true, error: null });
    this.context
      .callApi(() => get(`/permissions`))
      .then(({ data }) => {
        this.safeSetState({ isLoadingPermissions: false, permissions: data.data });
      })
      .catch(error => {
        toast.error(parseErrorMessage(error, <Trans>Failed to fetch data.</Trans>));
        this.safeSetState({ isLoadingPermissions: false, error });
      });
  };

  fetchRoles = () => {
    this.safeSetState({ isLoadingRoles: true, error: null });
    this.context
      .callApi(() => get(`/roles`))
      .then(({ data }) => {
        this.safeSetState({ isLoadingRoles: false, roles: data.data });
      })
      .catch(error => {
        toast.error(parseErrorMessage(error, <Trans>Failed to fetch data.</Trans>));
        this.safeSetState({ isLoadingRoles: false, error });
      });
  };

  handlePermanentRoleClick = (e, role) => {
    e.persist();

    const preRequestPermanentRoles = [...this.state.userPermanentRoles];
    const updatedPermanentRoles = e.target.checked
      ? [...this.state.userPermanentRoles, role]
      : this.state.userPermanentRoles.filter(perm => perm.id !== role.id);

    if (e.target.checked) {
      this.setState(prevState => ({
        userPermanentRoles: prevState.userPermanentRoles.concat(role)
      }));
    } else {
      this.setState(prevState => ({
        userPermanentRoles: prevState.userPermanentRoles.filter(perm => perm.id !== role.id)
      }));
    }

    this.safeSetState({ isUpdating: true });

    this.context
      .callApi(() =>
        patch(`/users/${this.props.userId}/permanent-permissions`, {
          permanent_type: "ROLE",
          permanent_ids: updatedPermanentRoles.map(r => r.id)
        })
      )
      .then(
        ({
          data: {
            data: { permanent_roles, permanent_permissions }
          }
        }) => {
          this.safeSetState({
            isUpdating: false,
            userPermanentRoles: permanent_roles,
            userPermanentPermissions: permanent_permissions
          });
        }
      )
      .catch(error => {
        toast.error(
          parseErrorMessage(error, <Trans>Failed to update permanent permissions.</Trans>)
        );
        this.safeSetState({
          isUpdating: false,
          error,
          userPermanentRoles: preRequestPermanentRoles
        });
      });
  };

  handlePermanentPermissionClick = (e, permission) => {
    e.persist();

    const preRequestPermanentPermissions = [...this.state.userPermanentPermissions];
    const updatedPermanentPermissions = e.target.checked
      ? [...this.state.userPermanentPermissions, permission]
      : this.state.userPermanentPermissions.filter(perm => perm.id !== permission.id);

    if (e.target.checked) {
      this.setState(prevState => ({
        userPermanentPermissions: prevState.userPermanentPermissions.concat(permission)
      }));
    } else {
      this.setState(prevState => ({
        userPermanentPermissions: prevState.userPermanentPermissions.filter(
          perm => perm.id !== permission.id
        )
      }));
    }

    this.safeSetState({ isUpdating: true });

    this.context
      .callApi(() =>
        patch(`/users/${this.props.userId}/permanent-permissions`, {
          permanent_type: "PERMISSION",
          permanent_ids: updatedPermanentPermissions.map(r => r.id)
        })
      )
      .then(
        ({
          data: {
            data: { permanent_roles, permanent_permissions }
          }
        }) => {
          this.safeSetState({
            isUpdating: false,
            userPermanentRoles: permanent_roles,
            userPermanentPermissions: permanent_permissions
          });
        }
      )
      .catch(error => {
        toast.error(
          parseErrorMessage(error, <Trans>Failed to update permanent permissions.</Trans>)
        );
        this.safeSetState({
          isUpdating: false,
          error,
          userPermanentPermissions: preRequestPermanentPermissions
        });
      });
  };

  handlePermissionClick = (e, permission) => {
    e.persist();

    const preRequestPermissions = [...this.state.userPermissions];
    const updatedPermissions = e.target.checked
      ? [...this.state.userPermissions, permission]
      : this.state.userPermissions.filter(perm => perm.id !== permission.id);

    if (e.target.checked) {
      this.setState(prevState => ({
        userPermissions: prevState.userPermissions.concat(permission)
      }));
    } else {
      this.setState(prevState => ({
        userPermissions: prevState.userPermissions.filter(perm => perm.id !== permission.id)
      }));
    }

    this.safeSetState({ isUpdating: true });

    this.context
      .callApi(() =>
        patch(`/users/${this.props.userId}/permissions`, {
          permission_ids: updatedPermissions.map(r => r.id)
        })
      )
      .then(
        ({
          data: {
            data: { roles, permissions }
          }
        }) => {
          this.safeSetState({
            isUpdating: false,
            userRoles: roles,
            userPermissions: permissions
          });
        }
      )
      .catch(error => {
        toast.error(parseErrorMessage(error, <Trans>Failed to update permissions.</Trans>));
        this.safeSetState({
          isUpdating: false,
          error,
          userPermissions: preRequestPermissions
        });
      });
  };

  handleRoleClick = (e, role) => {
    e.persist();

    const preRequestRoles = [...this.state.userRoles];
    const updatedRoles = e.target.checked
      ? [...this.state.userRoles, role]
      : this.state.userRoles.filter(r => r.id !== role.id);

    if (e.target.checked) {
      this.setState(prevState => ({
        userRoles: prevState.userRoles.concat(role)
      }));
    } else {
      this.setState(prevState => ({
        userRoles: prevState.userRoles.filter(r => r.id !== role.id)
      }));
    }

    this.safeSetState({ isUpdating: true });

    this.context
      .callApi(() =>
        patch(`/users/${this.props.userId}/roles`, {
          role_ids: updatedRoles.map(r => r.id)
        })
      )
      .then(
        ({
          data: {
            data: { roles, permissions }
          }
        }) => {
          this.safeSetState({
            isUpdating: false,
            userPermissions: permissions,
            userRoles: roles
          });
        }
      )
      .catch(error => {
        toast.error(parseErrorMessage(error, <Trans>Failed to update roles.</Trans>));
        this.safeSetState({
          isUpdating: false,
          error,
          userRoles: preRequestRoles
        });
      });
  };

  render() {
    const {
      permissions,
      roles,
      isLoadingRoles,
      isLoadingPermissions,
      isUpdating,
      userPermissions,
      userPermanentRoles,
      userPermanentPermissions,
      userRoles
    } = this.state;
    return (
      <LoadingWrapper isLoading={isLoadingRoles || isLoadingPermissions}>
        <div className="mt-8">
          <Panel colour={`pink`} title={<Trans>Roles & Permissions</Trans>}>
            <p className="mb-4 text-sm">
              Either assign a direct permission to a user by checking the assigned permission list,
              or give the user (potentially) multiple permissions at once by assigning them a role.
            </p>

            <div className="flex flex-row">
              <div className="mb-4">
                <h4 className="text-sm text-pink-500">Assigned Roles</h4>
                <div className="">
                  {roles.map(role => (
                    <div key={role.id} className="mr-4 whitespace-no-wrap">
                      <input
                        disabled={isUpdating}
                        className="p-1 mr-2"
                        type="checkbox"
                        onChange={e => this.handleRoleClick(e, role)}
                        checked={userRoles.map(r => r.id).includes(role.id)}
                      />
                      <input
                        disabled={isUpdating}
                        style={{ outline: "2px solid #ed64a6" }}
                        className="mr-2"
                        type="checkbox"
                        onChange={e => this.handlePermanentRoleClick(e, role)}
                        checked={userPermanentRoles.map(r => r.id).includes(role.id)}
                      />
                      <label className={`text-sm truncate`}>{role.name}</label>
                    </div>
                  ))}
                </div>
              </div>
              <div className="mb-4">
                <h4 className="text-sm text-pink-500">Assigned Permissions</h4>

                <div className="">
                  {permissions.map(permission => (
                    <div key={permission.id} className="mr-4 whitespace-no-wrap">
                      <input
                        disabled={isUpdating}
                        className="mr-2"
                        type="checkbox"
                        onChange={e => this.handlePermissionClick(e, permission)}
                        checked={userPermissions.map(p => p.id).includes(permission.id)}
                      />
                      <input
                        disabled={isUpdating}
                        style={{ outline: "2px solid #ed64a6" }}
                        className="mr-2"
                        type="checkbox"
                        onChange={e => this.handlePermanentPermissionClick(e, permission)}
                        checked={userPermanentPermissions.map(p => p.id).includes(permission.id)}
                      />
                      <label className={`text-sm truncate`}>{permission.name}</label>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </Panel>
        </div>
      </LoadingWrapper>
    );
  }
}

export default UserPermissions;
