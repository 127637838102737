import React from "react";
import { Link } from "react-router-dom";
import { AutoComplete, Button } from "@iforwms/react-components";
import history from "../components/history";
import { ApiConsumer } from "../contexts/ApiContext";
import { get } from "../api";

const PageHeader = ({ title, body, backLink }) => (
  <ApiConsumer>
    {({ user }) => (
      <header id="page-header" className="page-header">
        <div className="flex items-center">
          <div className="flex truncate">
            {backLink && (
              <div className="mr-2">
                <Button RouterLink={Link} size={`sm`} url={backLink} icon={`left`} />
              </div>
            )}
            <h1 className="text-xl text-blue-500 truncate">{title}</h1>
          </div>

          {user.permissions.includes("VIEW_USERS") && (
            <div className="ml-auto">
              <AutoComplete
                get={get}
                url="/users"
                readOnly={true}
                itemKey="email"
                autoFocus={false}
                placeholder="Search by email"
                handleSelect={item => history.push(`/users/${item.id}`)}
              />
            </div>
          )}
        </div>

        {body ? (
          <div className="mt-2" style={{ maxWidth: 600 }}>
            <p className="text-sm leading-normal text-gray-600 mb-2">{body}</p>
          </div>
        ) : null}
      </header>
    )}
  </ApiConsumer>
);

export default PageHeader;
