import React from "react";
import PropTypes from "prop-types";
import { Trans } from "@lingui/macro";
import { toast } from "react-toastify";
import parseErrorMessage from "helpers-js/parseErrorMessage";
import { ApiContext } from "../../../contexts/ApiContext";
import { get, patch, post } from "../../../api";
import { Button, Form, LoadingWrapper, MediaUpload, Panel } from "@iforwms/react-components";
import history from "../../history";

const VideoLearningForm = ({ id }) => (
  <Form
    title={<Trans>Video Source</Trans>}
    inputs={[
      { type: "text", value_key: "full_name", label: "Full Name", disabled: true },
      {
        type: "text",
        value_key: "grammars",
        label: "Grammar Points",
        isArray: true,
        array_value_key: "text",
        search_url: "/grammars",
        edit: item => history.push(`/grammar/${item.id}`)
      },
      {
        type: "text",
        value_key: "words",
        label: "Words",
        isArray: true,
        readOnly: true,
        array_value_key: "text",
        search_url: "/words",
        edit: item => history.push(`/dictionary/${item.id}`)
      }
    ]}
    get={get}
    post={post}
    patch={patch}
    method={"patch"}
    panelWrap={false}
    handleError={error =>
      toast.error(parseErrorMessage(error, <Trans>Failed to create/update video.</Trans>))
    }
    handleSuccess={() =>
      toast.success(id ? <Trans>Video updated!</Trans> : <Trans>Video created!</Trans>)
    }
    fetchEndpoint={`/videos/${id}/learning`}
    submitEndpoint={`/videos/${id}/learning`}
    viewEndpoint={`${process.env.REACT_APP_FRONTEND_BASE_URL}/video/${id}`}
    submitText={"Update Video"}
  />
);

VideoLearningForm.propTypes = {
  id: PropTypes.string
};

export default VideoLearningForm;
