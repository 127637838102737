import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import Modal from "react-modal";
import ReloadButton from "../components/ReloadButton";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Link } from "react-router-dom";
import { Trans } from "@lingui/macro";
import { ApiContext } from "../contexts/ApiContext";
import { get, updateOrdering } from "../api";
import { parseErrorMessage, reorderArray } from "@iforwms/helpers-js";
import { generateLink, getItemStyle, getListStyle } from "../utils";
import { Button, LoadingWrapper } from "@iforwms/react-components";

class List extends Component {
  static contextType = ApiContext;
  static propTypes = {
    form: PropTypes.object.isRequired,
    endpoint: PropTypes.string.isRequired,
    maxWidth: PropTypes.number
  };

  state = {
    items: [],
    error: undefined,
    loading: true,
    formVisible: false,
    isSubmitting: false
  };

  componentDidMount() {
    this._isMounted = true;
    this.fetchItems();
  }

  componentDidUpdate(prevProps) {
    const { match, endpoint } = this.props;

    if (prevProps.match !== match) {
      if (
        (endpoint === "courses" && match.params.course_id === prevProps.match.params.course_id) ||
        (endpoint === "levels" && match.params.course_id === prevProps.match.params.course_id) ||
        (endpoint === "chapters" && match.params.level_id === prevProps.match.params.level_id) ||
        (endpoint === "lessons" && match.params.chapter_id === prevProps.match.params.chapter_id) ||
        (endpoint === "videos" && match.params.lesson_id === prevProps.match.params.lesson_id)
      ) {
        return;
      }
      this.fetchItems();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  safeSetState = (...args) => {
    this._isMounted && this.setState(...args);
  };

  fetchItems = () => {
    const { endpoint, match } = this.props;
    let filter = "";

    let url = endpoint;

    if (match && match.params) {
      if (match.params.course_id) {
        filter = `?parent_id=${match.params.course_id}`;
      }
      if (match.params.level_id) {
        filter = `?parent_id=${match.params.level_id}`;
      }
      if (match.params.chapter_id) {
        filter = `?parent_id=${match.params.chapter_id}`;
      }
      if (match.params.lesson_id) {
        filter = `?parent_id=${match.params.lesson_id}&parent_type=lesson`;
      }
    }
    url += filter;
    this.safeSetState({ loading: true });
    this.context
      .callApi(() => get(url))
      .then(res => {
        this.safeSetState({
          items: res.data.data,
          loading: false,
          error: null
        });
      })
      .catch(error => {
        this.safeSetState({ loading: false, error });
        toast.error(parseErrorMessage(error, `Failed to fetch ${endpoint}.`));
      });
  };

  showForm = _ => {
    this.safeSetState({ formVisible: true });
  };

  hideForm = () => {
    this.safeSetState({ formVisible: false });
  };

  addToItems = item => {
    this.safeSetState({
      items: [...this.state.items, item]
    });
  };

  onSuccess = item => {
    if (!this.state.currentItem) {
      this.addToItems(item);
    }

    this.hideForm();
  };

  onDragEnd = result => {
    const preDragItems = [...this.state.items];
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    const items = reorderArray(this.state.items, result.source.index, result.destination.index);
    const payload = items.map(item => item.id);
    this.safeSetState({ isSubmitting: true, items });
    this.context
      .callApi(() => updateOrdering({ ordering: payload, table: this.props.endpoint }))
      .then(res => {
        this.safeSetState({
          isSubmitting: false
        });
        toast.success(<Trans>Ordering updated!</Trans>);
      })
      .catch(error => {
        this.safeSetState({ isSubmitting: false, error, items: preDragItems });
        toast.error(parseErrorMessage(error, <Trans>Failed to reorder items.</Trans>));
      });
  };

  render() {
    const { loading, items, formVisible, isSubmitting, error } = this.state;
    const { form, title, endpoint, match, disableOrdering, maxWidth, disableLink } = this.props;

    return (
      <div className="list-container" style={{ maxWidth: maxWidth || 250 }}>
        <div className="flex items-center justify-between p-2 border-b">
          <h2 className="list-header">{title}</h2>
        </div>

        {error ? (
          <ReloadButton action={this.fetchItems} />
        ) : (
          <LoadingWrapper isLoading={loading} message={<Trans>Fetching items...</Trans>}>
            {!items.length ? (
              <div className="list-item">
                <Trans>No items yet, create one!</Trans>
              </div>
            ) : disableOrdering ? (
              !items.length ? (
                <div className="list-item">
                  <Trans>No items yet, create one!</Trans>
                </div>
              ) : (
                items.map((item, index) => (
                  <div key={index} className={`list-item ${!item.is_active && "opacity-50"}`}>
                    <ListItem
                      disableLink={disableLink}
                      disableOrdering
                      showForm={this.showForm}
                      item={item}
                      endpoint={endpoint}
                      match={match}
                    />
                  </div>
                ))
              )
            ) : (
              <DragDropContext onDragEnd={this.onDragEnd}>
                <Droppable isDropDisabled={isSubmitting} droppableId="droppable">
                  {(provided, snapshot) => (
                    <div ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                      {!items.length ? (
                        <div className="list-item">
                          <Trans>No items yet, create one!</Trans>
                        </div>
                      ) : (
                        items.map((item, index) => (
                          <Draggable key={item.id} draggableId={item.id} index={index}>
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={getItemStyle(
                                  snapshot.isDragging,
                                  provided.draggableProps.style
                                )}
                                className={`list-item ${!item.is_active && "opacity-50"}`}>
                                <ListItem
                                  disableLink={endpoint === "videos"}
                                  showForm={this.showForm}
                                  item={item}
                                  endpoint={endpoint}
                                  match={match}
                                />
                              </div>
                            )}
                          </Draggable>
                        ))
                      )}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            )}

            <div className="mt-2">
              <Button size={`sm`} colour={`blue`} onClick={() => this.showForm()} text={`+`} />
            </div>

            <Modal
              ariaHideApp={false}
              isOpen={formVisible}
              style={{
                overlay: { zIndex: 20 },
                content: {
                  top: "50%",
                  left: "50%",
                  right: "auto",
                  bottom: "auto",
                  marginRight: "-50%",
                  transform: "translate(-50%, -50%)",
                  maxWidth: 600,
                  width: "100%"
                }
              }}>
              <div>
                <div className="text-right">
                  <Button fullWidth={false} border={false} icon={`close`} onClick={this.hideForm} />
                </div>

                {React.cloneElement(form, {
                  onSuccess: this.onSuccess,
                  endpoint,
                  model: endpoint.substring(0, endpoint.length - 1)
                })}
              </div>
            </Modal>
          </LoadingWrapper>
        )}
      </div>
    );
  }
}

const ListItem = ({ item, endpoint, match, disableOrdering, disableLink }) => (
  <Fragment>
    <div className="mr-2 truncate flex flex-1">
      {!disableOrdering && <span className="inline-block mr-2">=</span>}
      {!disableLink ? (
        <Button
          classes={`truncate`}
          isLink={true}
          RouterLink={Link}
          url={generateLink(item, endpoint, match)}>
          {endpoint === "lessons" && item.subscription_required && (
            <span className="inline-block mr-1" role="img" aria-label="padlock">
              🔓
            </span>
          )}
          {item.name}
        </Button>
      ) : (
        <span className="list-link">{item.name}</span>
      )}
    </div>
    <div>
      <Button
        size={`sm`}
        colour={`blue`}
        RouterLink={Link}
        url={generateLink(item, endpoint, match, true)}>
        <Trans>Edit</Trans>
      </Button>
    </div>
  </Fragment>
);

export default List;
