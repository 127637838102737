import React, { Component } from "react";
import * as Sentry from "@sentry/browser";
import { Trans } from "@lingui/macro";
import { ApiContext } from "../contexts/ApiContext";
import { Button, Panel } from "@iforwms/react-components";
import { dominoSvg } from "../helpers";

class SentryErrorBoundary extends Component {
  static contextType = ApiContext;

  state = { error: null };

  componentDidCatch(error, errorInfo) {
    this.setState({ error });

    if (process.env.NODE_ENV !== "development") {
      if (this.context.user && this.context.user.email) {
        Sentry.configureScope(scope => {
          scope.setUser({ email: this.context.user.email });
        });
      }

      Sentry.withScope(scope => {
        Object.keys(errorInfo).forEach(key => {
          scope.setExtra(key, errorInfo[key]);
        });
        Sentry.captureException(error);
      });
    }
  }

  render() {
    if (this.state.error) {
      return (
        <div
          style={{ backgroundImage: `url("${dominoSvg}")` }}
          className="min-h-screen bg-blue-900 text-white text-xl flex flex-col items-center justify-center">
          <div className="mx-auto p-4">
            <Panel title={<Trans>Oops!</Trans>} colour={`red`} icon={`alert`}>
              <div className="mb-4">
                <p className="text-sm text-gray-600 leading-normal">
                  <Trans>We're really sorry, it looks like the app crashed.</Trans>
                </p>

                {process.env.NODE_ENV !== "development" && (
                  <p className="text-sm text-gray-600 leading-normal">
                    <Trans>
                      If you have a moment, please{" "}
                      <span
                        className="underline text-blue hover:text-blue-600 cursor-pointer"
                        onClick={() => Sentry.showReportDialog()}>
                        send us a message
                      </span>{" "}
                      letting us know what went wrong.
                    </Trans>
                  </p>
                )}
              </div>

              <div className="text-center">
                <Button
                  size={`sm`}
                  type="button"
                  colour={`red`}
                  onClick={() => window.location.reload(true)}
                  text={<Trans>Reload</Trans>}
                />
              </div>
            </Panel>
          </div>
        </div>
      );
    } else {
      return this.props.children;
    }
  }
}

export default SentryErrorBoundary;
