import PropTypes from "prop-types";
import React from "react";

export const RateSelector = ({
  playbackRate,
  colour,
  setPlaybackRate,
  rate,
  hideSelector
}) => (
  <div
    className={`cursor-pointer text-right text-sm ${
      playbackRate === rate ? "text-" + colour + "-500" : "text-white"
    } hover:text-${colour}-700`}
    onClick={() => {
      setPlaybackRate(rate);
      hideSelector();
    }}>
    x{rate}
  </div>
);

RateSelector.propTypes = {
  playbackRate: PropTypes.number.isRequired,
  colour: PropTypes.string.isRequired,
  setPlaybackRate: PropTypes.func.isRequired,
  hideSelector: PropTypes.func.isRequired,
  rate: PropTypes.number.isRequired
};
