import React from "react";
import PropTypes from "prop-types";
import Button from "./Button";
import Input from "./Input";
import MediaUpload from "./MediaUpload";
import Label from "./Label";
import SelectInput from "./SelectInput";
import Panel from "./Panel";
import LoadingWrapper from "../LoadingWrapper";
import RadioInput from "./RadioInput";
import CmsImage from "../cms/CmsImage";
import CmsVideo from "../cms/CmsVideo";
import AutoComplete from "../AutoComplete";

const ignoredKeys = [
  "error",
  "submitError",
  "fetchError",
  "fetchInputError",
  "isLoading",
  "isSubmitting",
  "isUploadingImage",
  "images",
  "videos",
  "inputs"
];

class Form extends React.Component {
  state = {
    inputs: [],
    images: [],
    videos: [],
    isLoading: false,
    error: null,
    fetchError: null,
    fetchInputError: null,
    isSubmitting: false,
    isUploadingImage: false
  };

  componentDidMount() {
    this._isMounted = true;

    this.fetchInputs();
    this.fetchItem();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps) {
    if (this.props.fetchEndpoint && this.props.fetchEndpoint !== prevProps.fetchEndpoint) {
      this.fetchItem();
    }
  }

  safeSetState = (...args) => {
    this._isMounted && this.setState(...args);
  };

  setParentState = (valueKey, value) => {
    this.setState({ [valueKey]: value });
  };

  fetchItem = () => {
    const { get, fetchEndpoint } = this.props;

    if (!get || !fetchEndpoint) {
      return;
    }

    this.safeSetState({ isLoading: true, fetchError: null });

    get(fetchEndpoint)
      .then(({ data }) => {
        this.safeSetState({ ...data.data, isLoading: false });
      })
      .catch(error => this.safeSetState({ fetchError: error, isLoading: false }));
  };

  handleDelete = () => {
    const { destroy, deleteEndpoint, handleDeleteSuccess } = this.props;

    if (!destroy) return;

    const confirm = window.confirm("Are you sure you want to delete?");

    if (!confirm) return;

    destroy(deleteEndpoint)
      .then(res => {
        console.log("[FORM] Delete success:", deleteEndpoint);

        if (handleDeleteSuccess) {
          handleDeleteSuccess(res);
        }
      })
      .catch(error => this.safeSetState({ error, isLoading: false }));
  };

  fetchInputs = () => {
    const { get, inputEndpoint, inputs } = this.props;

    if (!get || !inputEndpoint) {
      return this.safeSetState({ inputs });
    }

    this.safeSetState({ isLoading: true, fetchInputError: null });

    get(inputEndpoint)
      .then(({ data }) => {
        this.safeSetState({ isLoading: false, inputs: data.data });
      })
      .catch(error => {
        this.safeSetState({ fetchInputError: error, isLoading: false });
      });
  };

  handleSubmit = e => {
    e.preventDefault();

    this.safeSetState({ submitError: null, isSubmitting: true });

    const { initialState, submitEndpoint, method, handleSuccess, handleError } = this.props;

    let payload = {};
    Object.keys(this.state)
      .filter(key => {
        return !ignoredKeys.includes(key);
      })
      .forEach(key => {
        payload[key] = this.state[key];
      });
    payload.images = this.state.images.map(i => i.name);
    payload.videos = this.state.videos;
    if (initialState) {
      payload = { ...payload, ...initialState };
    }

    this.props[method.toLowerCase()](submitEndpoint, payload)
      .then(({ data }) => {
        Object.keys(payload)
          .filter(key => key !== "images" && key !== "videos")
          .forEach(key => (payload[key] = ""));
        payload.images = [];
        payload.video = [];
        payload.isSubmitting = false;

        this.safeSetState({ ...payload, ...data.data });

        if (handleSuccess) {
          handleSuccess(data);
        }
      })
      .catch(error => {
        this.safeSetState({ submitError: error, isSubmitting: false });
        if (handleError) {
          handleError(error);
        }
      });
  };

  handleImageChange = (file, data) => {
    console.debug("[FORM] Handling image upload success.", file, data);

    let reader = new FileReader();
    reader.onloadend = () => {
      this.safeSetState({
        isUploadingImage: false,
        images: this.state.images.concat({
          name: data.data.name,
          preview: reader.result
        })
      });
    };
    reader.readAsDataURL(file);
  };

  handleVideoChange = (file, data) => {
    console.debug("[FORM] Handling video upload success.", file, data);

    this.safeSetState({
      isUploadingVideo: false,
      videos: this.state.images.concat({
        name: data.data.name,
        original_name: data.data.original_name
      })
    });
  };

  removeImage = imageToRemove => {
    const confirm = window.confirm("Are you sure you want to remove this image?");

    if (!confirm) return;

    this.setState(prevState => ({
      images: prevState.images.filter(image => image.name !== imageToRemove)
    }));
  };

  generateArray = array => {
    if (!this.state[array.value_key]) return null;

    const { get, post } = this.props;

    return (
      <div>
        <Label label={array.label} />

        {!this.state[array.value_key].length ? (
          <div className={`mt-2`}>No current items</div>
        ) : null}

        {this.state[array.value_key].map(item => (
          <div key={item.id} className={`flex mt-2`}>
            <Input
              containerClasses={`w-full`}
              disabled={true}
              valueKey={array.array_value_key}
              value={item[array.array_value_key]}
            />

            {array.edit ? (
              <Button
                fullWidth={false}
                classes={`ml-2`}
                icon={`pencil`}
                colour={`blue`}
                onClick={() => array.edit(item)}
              />
            ) : null}

            <Button
              fullWidth={false}
              classes={`ml-2`}
              icon={`delete`}
              colour={`red`}
              onClick={() =>
                this.setState(prevState => ({
                  [array.value_key]: prevState[array.value_key].filter(i => i.id !== item.id)
                }))
              }
            />
          </div>
        ))}

        <div className="mt-4">
          <AutoComplete
            readOnly={array.readOnly}
            placeholder={`Type to begin searching ${
              array.readOnly ? "for an item." : "or creating a new item."
            }`}
            itemKey={`text`}
            handleSelect={item =>
              this.safeSetState(prevState => ({
                [array.value_key]: prevState[array.value_key].concat(item)
              }))
            }
            post={post}
            get={get}
            url={array.search_url}
          />
        </div>
      </div>
    );
  };

  render() {
    const {
      isSubmitting,
      isLoading,
      error,
      submitError,
      isUploadingImage,
      isUploadingVideo,
      inputs,
      images
    } = this.state;
    const {
      hasPreview,
      children,
      post,
      buttons,
      title,
      destroy,
      deleteEndpoint,
      viewEndpoint,
      method,
      dummyData,
      colour,
      icon,
      panelWrap,
      submitText
    } = this.props;

    let imagePreview;
    if (images && images.length) {
      imagePreview = (
        <div>
          {images.map(image => (
            <div
              key={image.name}
              onClick={() => {
                this.removeImage(image.name);
              }}
              className={`cursor-pointer overflow-hidden rounded`}>
              <img src={image.preview} alt="" />
            </div>
          ))}
        </div>
      );
    } else {
      imagePreview = <div className="previewText">Please select an Image for Preview</div>;
    }

    const mainForm = (
      <>
        {inputs &&
          inputs.length &&
          inputs.map(input =>
            input.type === "hidden" ? (
              <input type="hidden" value={input.value} name={input.value_key} />
            ) : (
              <div key={input.label} className={`mt-4`}>
                {input.isArray ? (
                  this.generateArray(input)
                ) : input.type === "select" ? (
                  <SelectInput
                    description={input.description}
                    label={input.label}
                    placeholder={input.placeholder}
                    error={submitError}
                    disabled={
                      input.disabled ||
                      isSubmitting ||
                      isLoading ||
                      isUploadingImage ||
                      isUploadingVideo
                    }
                    setState={this.setParentState}
                    valueKey={input.value_key}
                    value={this.state[input.value_key] ?? ""}
                    options={input.options}
                  />
                ) : input.type === "image" ? (
                  <>
                    <Label label={`Image`} />
                    {input.showPreview ? (
                      <div className={`mb-2`}>
                        <CmsImage
                          isEditing={false}
                          data={{ src: this.state[input.value_key] ?? "" }}
                        />
                      </div>
                    ) : null}
                    <MediaUpload
                      endpoint={input.endpoint}
                      accept="image/*"
                      post={post}
                      onError={() => {}}
                      onSuccess={this.handleImageChange}
                    />
                  </>
                ) : input.type === "thumb" ? (
                  <>
                    <Label label={`Thumbnail`} />
                    <CmsImage isEditing={false} data={{ src: this.state[input.value_key] ?? "" }} />
                  </>
                ) : input.type === "file" ? (
                  <>
                    <Label label={input.label} />
                    {input.showPreview ? (
                      <audio
                        controls
                        className={`mb-2`}
                        src={this.state[input.value_key]}
                      />
                    ) : null}
                    <MediaUpload
                      endpoint={input.endpoint}
                      accept={input.accept}
                      post={post}
                      onError={input.handleError ? input.handleError : null}
                      onSuccess={input.handleSuccess ? input.handleSuccess : null}
                    />
                  </>
                ) : input.type === "video" ? (
                  <>
                    <Label label={input.label} />
                    {input.showPreview ? (
                      <CmsVideo
                        classes={`mb-2`}
                        isEditing={false}
                        data={{ src: this.state[input.value_key] ?? "" }}
                      />
                    ) : null}
                    <MediaUpload
                      endpoint={input.endpoint}
                      accept={input.accept}
                      post={post}
                      onError={input.handleError ? input.handleError : null}
                      onSuccess={this.handleVideoChange}
                    />
                  </>
                ) : input.type === "radio" ? (
                  <RadioInput
                    description={input.description}
                    setState={this.setParentState}
                    label={input.label}
                    error={submitError}
                    disabled={
                      input.disabled ||
                      isSubmitting ||
                      isLoading ||
                      isUploadingImage ||
                      isUploadingVideo
                    }
                    options={input.options}
                    value={this.state[input.value_key] ?? ""}
                    valueKey={input.value_key}
                  />
                ) : (
                  <Input
                    description={input.description}
                    setState={this.setParentState}
                    type={input.type}
                    label={input.label}
                    disabled={
                      input.disabled ||
                      isSubmitting ||
                      isLoading ||
                      isUploadingImage ||
                      isUploadingVideo
                    }
                    error={submitError}
                    value={this.state[input.value_key] ?? ""}
                    valueKey={input.value_key}
                  />
                )}
              </div>
            )
          )}
      </>
    );

    const formButtons = (
      <div className="flex items-center mt-4">
        {process.env.NODE_ENV === "development" && dummyData ? (
          <Button type={`button`} onClick={() => this.setState(dummyData)} text={`Generate Data`} />
        ) : null}

        {deleteEndpoint && destroy ? (
          <Button
            onClick={() => this.handleDelete(deleteEndpoint)}
            disabled={isSubmitting || isLoading || isUploadingImage || isUploadingVideo}
            colour={`red`}
            type={`button`}
            classes={process.env.NODE_ENV === "development" && dummyData ? "ml-2" : ""}
            text={`Delete`}
          />
        ) : null}

        {viewEndpoint ? (
          <Button
            type={`button`}
            url={viewEndpoint}
            forceAnchor={true}
            disabled={isSubmitting || isLoading || isUploadingImage || isUploadingVideo}
            colour={`teal`}
            classes={`text-center
              ${
                (process.env.NODE_ENV === "development" && dummyData) || deleteEndpoint
                  ? "ml-2"
                  : ""
              }`}
            text={`View`}
          />
        ) : null}

        {buttons ? buttons : null}

        <Button
          disabled={isSubmitting || isLoading || isUploadingImage || isUploadingVideo}
          colour={colour}
          classes={
            (process.env.NODE_ENV === "development" && dummyData) ||
            (deleteEndpoint && destroy) ||
            viewEndpoint
              ? "ml-2"
              : ""
          }
          text={`${isSubmitting ? "Submitting..." : submitText}`}
        />
      </div>
    );

    return (
      <form method={method} onSubmit={e => this.handleSubmit(e)}>
        <LoadingWrapper isLoading={isLoading} error={error} retry={this.fetchItem}>
          <div>
            {panelWrap ? (
              <Panel icon={icon} colour={colour} title={title}>
                {mainForm}
                {formButtons}
                {children ? children : null}
              </Panel>
            ) : (
              <>
                {mainForm}
                {formButtons}
                {children ? children : null}
              </>
            )}
          </div>

          {hasPreview && inputs && inputs.length ? (
            <div className="mt-4">
              <Panel colour={`blue`} title={`Preview`}>
                {Object.keys(this.state)
                  .filter(key => {
                    return !ignoredKeys.includes(key);
                  })
                  .map(key => (
                    <div key={key}>
                      <strong>{key}</strong>: {this.state[key]}
                    </div>
                  ))}

                {images.length ? (
                  <div className="mt-4">
                    <strong className="block">Image: </strong>
                    <div className="mt-2">{imagePreview}</div>
                  </div>
                ) : null}

                {formButtons}

                {children ? children : null}
              </Panel>
            </div>
          ) : null}
        </LoadingWrapper>
      </form>
    );
  }
}

Form.propTypes = {
  hasPreview: PropTypes.bool.isRequired,
  get: PropTypes.func,
  buttons: PropTypes.any,
  destroy: PropTypes.func,
  post: PropTypes.func,
  patch: PropTypes.func,
  children: PropTypes.any,
  method: PropTypes.string.isRequired,
  inputs: PropTypes.array.isRequired,
  icon: PropTypes.string,
  viewEndpoint: PropTypes.string,
  deleteEndpoint: PropTypes.string,
  fetchEndpoint: PropTypes.string,
  colour: PropTypes.string.isRequired,
  submitText: PropTypes.string.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  submitEndpoint: PropTypes.string.isRequired,
  inputEndpoint: PropTypes.string,
  dummyData: PropTypes.object,
  imageEndpoint: PropTypes.string,
  handleSuccess: PropTypes.func,
  handleDeleteSuccess: PropTypes.func,
  handleError: PropTypes.func
};

Form.defaultProps = {
  inputs: [],
  hasPreview: false,
  panelWrap: true,
  colour: "blue",
  submitText: "Submit",
  method: "post"
};

export default Form;
