import React, { Component } from "react";
import { Trans } from "@lingui/macro";
import { toast } from "react-toastify";
import List from "../components/List";
import PrivateRoute from "../components/PrivateRoute";
import PageHeader from "../components/PageHeader";
import PageBody from "../components/PageBody";
import PaymentPlanForm from "../components/forms/PaymentPlanForm";
import { ApiContext } from "../contexts/ApiContext";
import { getPaymentPlan } from "../api";
import parseErrorMessage from "helpers-js/parseErrorMessage";
import { LoadingWrapper } from "@iforwms/react-components";

class PaymentPlans extends Component {
  static contextType = ApiContext;
  state = { loading: false, error: undefined, item: undefined };

  componentDidMount() {
    this._isMounted = true;
    const {
      match: {
        params: { plan_id }
      }
    } = this.props;
    if (plan_id) {
      return this.fetchItem();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      match: {
        params: { plan_id }
      }
    } = this.props;

    if (plan_id && plan_id !== prevProps.match.params.plan_id) {
      this.fetchItem();
    }
  }

  safeSetState = (...args) => {
    this._isMounted && this.setState(...args);
  };

  fetchItem = () => {
    this.safeSetState({ loading: true });

    this.context
      .callApi(() => getPaymentPlan(this.props.match.params.plan_id))
      .then(res => {
        this.safeSetState({
          item: res.data,
          loading: false
        });
      })
      .catch(error => {
        this.safeSetState({ error, loading: false });
        toast.error(parseErrorMessage(error, <Trans>Failed to fetch details.</Trans>));
      });
  };

  render() {
    const { item, loading, error } = this.state;

    return (
      <div className="static">
        <PageHeader
          backLink="/plans"
          title={<Trans>Payment Plans</Trans>}
          body={<Trans>Update, add or delete payment plans here.</Trans>}
        />

        <PageBody>
          <div className="flex px-4 container-narrow">
            <LoadingWrapper
              isLoading={loading}
              error={error}
              message={<Trans>Fetching details...</Trans>}>
              <PrivateRoute
                exact
                path={`/plans`}
                render={props => (
                  <List
                    title={<Trans>Payment Plans Index</Trans>}
                    endpoint="plans"
                    disableOrdering
                    maxWidth={600}
                    disableLink
                    form={<PaymentPlanForm onSuccess={() => this.props.history.push("/plans")} />}
                  />
                )}
              />

              {item && (
                <PrivateRoute
                  path={`/plans/:plan_id/edit`}
                  render={props => (
                    <PaymentPlanForm
                      onDelete={() => this.props.history.push("/plans")}
                      initialValues={item}
                    />
                  )}
                />
              )}
            </LoadingWrapper>
          </div>
        </PageBody>
      </div>
    );
  }
}

export default PaymentPlans;
