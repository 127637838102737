import { Button, Panel } from "@iforwms/react-components";
import { Trans } from "@lingui/macro";
import PropTypes from "prop-types";
import React from "react";

const PasswordResetForm = ({ changePassword, setPassword, isSubmitting, password }) => (
  <Panel title={<Trans>Password Reset</Trans>} icon={`lock`} colour={`green`}>
    <p>
      <Trans>The user will receive an email confirming the new temporary password.</Trans>
    </p>

    <form method="POST" onSubmit={e => changePassword(e)} className={`flex items-center mt-4`}>
      <input
        disabled={isSubmitting}
        type="text"
        onChange={e => setPassword(e.target.value)}
        value={password}
        className={`form-input`}
      />
      <Button
        fullWidth={false}
        classes={`whitespace-no-wrap ml-2`}
        colour={`green`}
        disabled={isSubmitting}>
        <Trans>Update Password</Trans>
      </Button>
    </form>
  </Panel>
);

PasswordResetForm.propTypes = {
  changePassword: PropTypes.func.isRequired,
  setPassword: PropTypes.func.isRequired,
  isSubmitting: PropTypes.bool.isRequired,
  password: PropTypes.string.isRequired
};

export default PasswordResetForm;
