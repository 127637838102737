import React, { Component } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Trans } from "@lingui/macro";
import Modal from "react-modal";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import parseErrorMessage from "helpers-js/parseErrorMessage";
import { transformMyRestApiErrorsToAnObject } from "../../utils";
import DeleteResourceButton from "./DeleteResourceButton";
import BackButton from "../BackButton";
import Checkbox from "./Checkbox";
import { ApiConsumer, ApiContext } from "../../contexts/ApiContext";
import { updateResource, activateVideos } from "../../api";
import { Button } from "@iforwms/react-components";

const CourseSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, <Trans>Please enter a longer name (min: 2 characters)</Trans>)
    .max(50, <Trans>Please enter a shorter name (max: 50 characters)</Trans>)
    .required(<Trans>Please enter a name.</Trans>)
});

const CourseItemWithForm = ({
  initialValues,
  onSuccess,
  onDelete,
  formVisible,
  hideForm,
  showForm,
  endpoint,
  model,
  form,
  match
}) => (
  <ApiConsumer>
    {({ callApi }) => (
      <Formik
        initialValues={{
          id: undefined,
          name: "",
          is_active: false,
          subscription_required: false,
          has_quiz: false,
          description: "",
          extra_description: "",
          notes: "",
          attachments: [],
          ...initialValues
        }}
        enableReinitialize
        validationSchema={CourseSchema}
        onSubmit={(values, { setSubmitting, setErrors, setStatus, resetForm }) => {
          const url = `${endpoint}/${values.id}`;

          callApi(() => updateResource(url, values))
            .then(res => {
              setSubmitting(false);

              onSuccess(res.data.data);

              toast.success(`${model} Updated!`);
            })
            .catch(error => {
              setSubmitting(false);
              setErrors(transformMyRestApiErrorsToAnObject(error));
              toast.error(parseErrorMessage(error, `Failed to update ${model}.`));
            });
        }}>
        {({ isSubmitting, errors, status, values }) => (
          <div className="form-container">
            <div className="flex items-center mb-4">
              <BackButton className="mr-2" url={match.url.replace("/edit", "")} />
              <h2 className="ml-2 text-lg text-blue-500">Update {model}</h2>
            </div>
            <Form>
              <div className="form-input-group">
                <label className="form-label">
                  <Trans>Name</Trans>
                </label>
                <Field
                  className="form-input"
                  type="text"
                  autoFocus
                  autoComplete="off"
                  name="name"
                  disabled={isSubmitting}
                />
                <ErrorMessage name="name">
                  {errorMessage => <div className="form-input-error">{errorMessage}</div>}
                </ErrorMessage>
              </div>
              <div className="form-input-group">
                <label className="form-label">
                  <Trans>Description</Trans>
                </label>
                <Field
                  className="form-input"
                  component="textarea"
                  rows={5}
                  name="description"
                  disabled={isSubmitting}
                />
              </div>
              <div className="form-input-group">
                <Field
                  component={Checkbox}
                  isSubmitting={isSubmitting}
                  name="is_active"
                  id="is_active"
                  label={`Do you want ${model} this visible to users?`}
                />
              </div>
              {endpoint === "levels" && (
                <>
                  <div className="form-input-group">
                    <label className="form-label">
                      <Trans>Additional Description</Trans>
                    </label>
                    <Field
                      className="form-input"
                      component="textarea"
                      rows={5}
                      name="extra_description"
                      disabled={isSubmitting}
                    />
                  </div>
                  <div className="form-input-group">
                    <label className="form-label">
                      <Trans>Notes</Trans>
                    </label>
                    <Field
                      className="form-input"
                      component="textarea"
                      rows={5}
                      name="notes"
                      disabled={isSubmitting}
                    />
                  </div>
                </>
              )}
              {endpoint === "courses" && (
                <>
                  <div className="form-input-group">
                    <Field
                      component={Checkbox}
                      isSubmitting={isSubmitting}
                      name="has_quiz"
                      id="has_quiz"
                      label={<Trans>Does this course have quiz questions?</Trans>}
                    />
                  </div>
                  {/*<div className="form-input-group">*/}
                  {/*    <Field*/}
                  {/*        component={Checkbox}*/}
                  {/*        isSubmitting={isSubmitting}*/}
                  {/*        name="is_premium"*/}
                  {/*        id="is_premium"*/}
                  {/*        label={<Trans>Is this a premium course?</Trans>}*/}
                  {/*    />*/}
                  {/*</div>*/}
                </>
              )}
              {/*{endpoint === "lessons" && (*/}
              {/*    <div className="form-input-group">*/}
              {/*        <Field*/}
              {/*            component={Checkbox}*/}
              {/*            isSubmitting={isSubmitting}*/}
              {/*            name="subscription_required"*/}
              {/*            id="subscription_required"*/}
              {/*            label={<Trans>Is this lesson subscription only?</Trans>}*/}
              {/*        />*/}
              {/*    </div>*/}
              {/*)}*/}

              <div className="mb-8">
                  <div className="mb-4">
                      <label className="form-label">Current Attachments</label>
                      {!values.attachments.length && (
                          <div>
                              <Trans>No attachments yet, create one!</Trans>
                          </div>
                      )}
                      {values.attachments.map(a => (
                          <div className="mb-1 text-sm text-gray-600" key={a.id}>
                              {a.name}
                          </div>
                      ))}
                  </div>

                  <button
                      onClick={showForm}
                      type="button"
                      className="btn btn-outline">
                      <Trans>Edit Attachments</Trans>
                  </button>
              </div>

              <div className="btn-group">
                <DeleteResourceButton
                  isSubmitting={isSubmitting}
                  onDelete={onDelete}
                  url={`${endpoint}/${values.id}/`}
                />

                {/*{endpoint === "lessons" ? (*/}
                {/*    <ActivateVideoButton type="lesson" id={values.id} />*/}
                {/*) : null}*/}

                <Button disabled={isSubmitting} colour={`blue`}>
                  {isSubmitting ? `Updating ${model}` : `Update ${model}`}
                </Button>
              </div>
            </Form>

            <Modal
              ariaHideApp={false}
              isOpen={formVisible}
              style={{
                overlay: { zIndex: 20 },
                content: {
                  top: "50%",
                  left: "50%",
                  right: "auto",
                  bottom: "auto",
                  marginRight: "-50%",
                  transform: "translate(-50%, -50%)",
                  maxWidth: 600
                }
              }}>
              <div>
                <Button onClick={hideForm} icon={`close`} />
                {React.cloneElement(form)}
              </div>
            </Modal>
          </div>
        )}
      </Formik>
    )}
  </ApiConsumer>
);

export class ActivateVideoButton extends Component {
  static contextType = ApiContext;
  state = { isSubmitting: false };
  componentDidMount() {
    this._isMounted = true;
  }
  componentWillUnmount() {
    this._isMounted = false;
  }
  safeSetState = (...args) => {
    this._isMounted && this.setState(...args);
  };
  activateVideos = () => {
    const { id, type } = this.props;
    this.safeSetState({ isSubmitting: true });
    this.context
      .callApi(() => activateVideos({ type, id }))
      .then(res => {
        this.safeSetState({ isSubmitting: false });
        toast.success(<Trans>Videos activated!</Trans>);
      })
      .catch(error => {
        this.safeSetState({ isSubmitting: false });
        toast.error(parseErrorMessage(error, <Trans>Failed to activate videos.</Trans>));
      });
  };

  render() {
    const { isSubmitting } = this.state;

    return (
      <Button colour={`orange`} type="button" onClick={this.activateVideos} disabled={isSubmitting}>
        {isSubmitting ? <Trans>Activating Videos</Trans> : <Trans>Activate Videos</Trans>}
      </Button>
    );
  }
}

CourseItemWithForm.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
};

export default CourseItemWithForm;
