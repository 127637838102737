import React from "react";
import { ApiContext } from "../contexts/ApiContext";
import PageHeader from "../components/PageHeader";
import PageBody from "../components/PageBody";
import { Trans } from "@lingui/macro";
import { Panel } from "@iforwms/react-components";
import { post } from "../api";
import { toast } from "react-toastify";
import parseErrorMessage from "helpers-js/parseErrorMessage";
import history from "../components/history";
import { Button } from "@iforwms/react-components";
import { Link } from "react-router-dom";

class DialogCreate extends React.Component {
  static contextType = ApiContext;

  state = {
    isSubmitting: false,
    error: null,
    data: `Title|INSERT_TITLE
Situation|INSERT_SITUATION
Topic|TOPIC_1|TOPIC_2
Difficulty|(Easy/Medium/Hard)
ChapterId|abcd-1234-abcd-1234
A|CHINESE|SPLIT|BY|CHARACTER
A|ENGLISH|SPLIT|BY|WORD
A|SWEDISH|SPLIT|BY|WORD
B|CHINESE|SPLIT|BY|CHARACTER
B|ENGLISH|SPLIT|BY|WORD
B|SWEDISH|SPLIT|BY|WORD`
  };

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  safeSetState = (...args) => {
    this._isMounted && this.setState(...args);
  };

  handleSubmit = e => {
    e.preventDefault();

    const payload = { data: this.state.data };
    this.safeSetState({ isSubmitting: true, error: null });

    this.context
      .callApi(() => post("/dialogs", payload))
      .then(({ data }) => {
        this.safeSetState({ isSubmitting: false });
        history.push("/dialogs");
      })
      .catch(error => {
        this.safeSetState({ error, isSubmitting: false });
        toast.error(parseErrorMessage(error, <Trans>Failed to create dialog.</Trans>));
      });
  };

  render() {
    const { data, isSubmitting } = this.state;

    return (
      <div>
        <PageHeader title={<Trans>Dialog Create</Trans>} />
        <PageBody>
          <div className="p-4">
            <Panel title={<Trans>Create a New Dialog</Trans>} colour={`indigo`}>
              <form method={`POST`} onSubmit={e => this.handleSubmit(e)}>
                <div>
                  <label className={`block text-sm text-gray-500`}>Dialog Text</label>
                  <textarea
                    style={{ lineHeight: 1.5 }}
                    disabled={isSubmitting}
                    onChange={e => this.setState({ data: e.target.value })}
                    className={`form-input mt-2 leading-loose`}
                    rows="10"
                    value={data}
                  />
                  <Button
                    colour={`blue`}
                    RouterLink={Link}
                    disabled={isSubmitting}
                    classes="mt-2"
                    text={<Trans>Create Dialog</Trans>}
                  />
                </div>
              </form>
            </Panel>
          </div>
        </PageBody>
      </div>
    );
  }
}

export default DialogCreate;
