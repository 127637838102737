import React from "react";
import PropTypes from "prop-types";
import { Trans } from "@lingui/macro";
import { toast } from "react-toastify";
import parseErrorMessage from "helpers-js/parseErrorMessage";
import { destroy, get, patch, post } from "../../../api";
import { Button, Form } from "@iforwms/react-components";
import history from "../../history";

const VideoBasicForm = ({ id }) => (
  <Form
    title={<Trans>Basic Info</Trans>}
    inputs={[
      { type: "text", value_key: "full_name", label: "Full Name", disabled: true },
      { type: "text", value_key: "original_filename", label: "Original Filename", disabled: true },
      { type: "text", value_key: "aliyun_uploaded_at", label: "Aliyun Uploaded At", disabled: true },
      { type: "text", value_key: "cloudflare_uploaded_at", label: "Cloudflare Uploaded At", disabled: true },
      { type: "thumb", value_key: "thumb" },
      { type: "text", value_key: "name", label: "Name" },
      {
        type: "select",
        value_key: "access_type",
        label: "Access Type",
        options: ["FREE", "BASIC", "PREMIUM"]
      },
      // {
      //   type: "file",
      //   value_key: "subtitle_src",
      //   label: "Subtitle File",
      //   accept: "text/vtt",
      //   endpoint: `/videos/${id}/subtitles`,
      //   handleError: error =>
      //     toast.error(parseErrorMessage(error, <Trans>Failed to upload subtitle file.</Trans>)),
      //   handleSuccess: ({ data }) => {
      //     console.log("%c[VIDEO_BASIC_FORM] onSuccess", "color: #3490dc; font-weight: bold", data);
      //   }
      // },
      { type: "textarea", value_key: "description", label: "Description" },
      {
        type: "checkbox",
        value_key: "is_active",
        label: "Do you want users to be able to view this video?"
      }
    ]}
    post={post}
    get={get}
    patch={patch}
    destroy={destroy}
    handleDeleteSuccess={() => {
      history.push("/videos");
      toast.success(id ? <Trans>Video updated!</Trans> : <Trans>Video created!</Trans>);
    }}
    method={id ? "patch" : "post"}
    panelWrap={false}
    handleError={error =>
      toast.error(parseErrorMessage(error, <Trans>Failed to create/update video.</Trans>))
    }
    handleSuccess={() =>
      toast.success(id ? <Trans>Video updated!</Trans> : <Trans>Video created!</Trans>)
    }
    fetchEndpoint={`/videos/${id}`}
    deleteEndpoint={`/videos/${id}`}
    viewEndpoint={`${process.env.REACT_APP_FRONTEND_BASE_URL}/video/${id}`}
    submitEndpoint={`/videos/${id}`}
    submitText={id ? "Update Video" : "Create Video"}
  />
);

VideoBasicForm.propTypes = {
  id: PropTypes.string
};

export default VideoBasicForm;
