import React, { Component } from "react";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import { t, Trans } from "@lingui/macro";
import { i18n } from "../../App";
import { destroy } from "../../api";
import { ApiContext } from "../../contexts/ApiContext";
import parseErrorMessage from "helpers-js/parseErrorMessage";
import { Button } from "@iforwms/react-components";

class DeleteResourceButton extends Component {
  static contextType = ApiContext;

  deleteResource = () => {
    const confirm = window.confirm(i18n._(t`Are you sure you want to delete this item?`));

    if (!confirm) return;

    this.context
      .callApi(() => destroy(this.props.url))
      .then(res => {
        toast.success(<Trans>Delete success!</Trans>);
        this.props.onDelete();
      })
      .catch(error => {
        toast.error(parseErrorMessage(error, <Trans>Failed to delete item.</Trans>));
      });
  };

  render() {
    const { classes, size } = this.props;

    return (
      <Button
        type="button"
        onClick={this.deleteResource}
        size={size}
        colour={`red`}
        classes={classes}
        text={<Trans>Delete</Trans>}
      />
    );
  }
}

DeleteResourceButton.defaultProps = {
  size: "sm"
};

DeleteResourceButton.propTypes = {
  url: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
  size: PropTypes.string.isRequired
};

export default DeleteResourceButton;
