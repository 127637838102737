import React, { Component } from "react";
import { Trans } from "@lingui/macro";
import { toast } from "react-toastify";
import PageHeader from "../components/PageHeader";
import PageBody from "../components/PageBody";
import { ApiContext } from "../contexts/ApiContext";
import { destroy, get, post } from "../api";
import parseErrorMessage from "helpers-js/parseErrorMessage";
import moment from "moment";
import { Button, Input, Table } from "@iforwms/react-components";
import { Link } from "react-router-dom";
import history from "../components/history";

class Coupons extends Component {
  static contextType = ApiContext;
  state = {
    items: [],
    description: "",
    code: "",
    error: null,
    createError: null,
    isLoading: false,
    isSubmitting: false
  };

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  safeSetState = (...args) => {
    this._isMounted && this.setState(...args);
  };

  setParentState = (valueKey, value) => {
    this.setState({ [valueKey]: value });
  };

  handleSubmit = e => {
    e.preventDefault();
    const { description, code } = this.state;
    this.safeSetState({ isSubmitting: true, error: null });
    this.context
      .callApi(() => post("/coupons", { description, code }))
      .then(({ data: { data } }) => {
        toast.success(<Trans>Saved!</Trans>);
        this.safeSetState({
          isSubmitting: false,
          description: "",
          code: "",
          items: this.state.items.concat(data)
        });
      })
      .catch(error => {
        toast.error(parseErrorMessage(error, <Trans>Failed to save.</Trans>));
        this.safeSetState({ isSubmitting: false, createError: error });
      });
  };

  handleDelete = id => {
    this.setState(prevState => ({
      items: prevState.items.filter(i => i.id !== id)
    }));
  };

  generateLink = item => {
    const el = document.createElement("textarea");
    el.value = `${process.env.REACT_APP_FRONTEND_BASE_URL}/register?coupon=${item.code}`;
    el.setAttribute("readonly", "");
    el.style.position = "absolute";
    el.style.left = "-9999px";
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    alert("Copied!");
  };

  render() {
    const { items, description, code, isLoading, error, createError, isSubmitting } = this.state;

    return (
      <div>
        <PageHeader
          title={<Trans>Referral Codes</Trans>}
          body={<Trans>Add, update or delete referral codes here.</Trans>}
        />
        <PageBody>
          <div className="p-4 md:p-8">
            <div className="max-w-md">
              <h2 className="form-header">
                <Trans>Create a new Referral Code</Trans>
              </h2>

              <form method="post" onSubmit={this.handleSubmit}>
                <div className="mt-2">
                  <Input
                    setState={this.setParentState}
                    error={createError}
                    disabled={isSubmitting}
                    value={code}
                    valueKey={`code`}
                    label={<Trans>Code</Trans>}
                  />
                </div>

                <div className="mt-4">
                  <Input
                    setState={this.setParentState}
                    error={createError}
                    disabled={isSubmitting}
                    value={description}
                    valueKey={`description`}
                    label={<Trans>Description</Trans>}
                  />
                </div>

                <Button
                  disabled={isSubmitting}
                  colour={`blue`}
                  classes="mt-4"
                  text={<Trans>Generate New Code</Trans>}
                />
              </form>
            </div>

            <div className="mt-6">
              <div className="mt-4 mx-auto">
                <Table
                  filters={[{ label: "Per Page", valueKey: "limit", options: [20, 50, 100] }]}
                  history={history}
                  search={this.props.location.search}
                  get={get}
                  destroy={destroy}
                  title={<Trans>Current Referral Codes</Trans>}
                  size={`sm`}
                  headers={[
                    { label: "Description", valueKey: "description" },
                    { label: "Clicks", valueKey: "clicks" },
                    { label: "Registrations", valueKey: "registrations" },
                    { label: "Subscriptions", valueKey: "subscriptions" },
                    { label: "Expires At", valueKey: "expires_at", fromNow: true },
                    {
                      label: "URL",
                      valueKey: "code",
                      url: `${process.env.REACT_APP_FRONTEND_BASE_URL}/register?coupon=`,
                      urlKey: "code"
                    }
                  ]}
                  actions={["DELETE"]}
                  moment={moment}
                  RouterLink={Link}
                  endpoint={`/coupons`}
                />
              </div>
            </div>
          </div>
        </PageBody>
      </div>
    );
  }
}

export default Coupons;
