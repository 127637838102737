import React from "react";
import PropTypes from "prop-types";
import MediaUpload from "../forms/MediaUpload";
import Input from "../forms/Input";
import ReactPlayer from "react-player";

class CmsVideo extends React.Component {
  handleSuccess = (file, data) => {
    const payload = { ...this.state, id: null, ...data.data };
    this.props.onUpdate(payload);
  };

  handleSubmit = () => {
    this.props.onUpdate(this.state);
  };

  setParentState = (valueKey, value) => {
    this.setState({ [valueKey]: value }, () => this.handleSubmit());
  };

  render() {
    const {
      data: { autoPlay, src, name, thumbnail, containerClasses },
      isEditing,
      post,
      showControls,
      classes
    } = this.props;

    if (isEditing) {
      return (
        <div>
          <div className="mt-4">
            <Input
              label="Thumbnail URL"
              value={thumbnail}
              valueKey="thumbnail"
              setState={this.setParentState}
            />
          </div>

          <div className="mt-4">
            <Input
              type="checkbox"
              label={`Autoplay?`}
              value={autoPlay}
              valueKey={`autoPlay`}
              setState={this.setParentState}
            />
          </div>

          <div className="mt-4">
            <Input
              label={`Container CSS Classes`}
              value={containerClasses}
              valueKey={`containerClasses`}
              setState={this.setParentState}
            />
          </div>

          <div className="mt-4">
            <MediaUpload
              accept="video/*"
              post={post}
              onError={() => {}}
              onSuccess={this.handleSuccess}
            />
          </div>
        </div>
      );
    }

    return (
      <div className={containerClasses}>
        {src ? (
          <ReactPlayer
            ref={player => {
              this.player = player;
            }}
            className={`react-player`}
            width="100%"
            height="100%"
            url={src}
            controls
          />
        ) : (
          <div>Save to view image preview ({name}).</div>
        )}
      </div>
    );
  }
}

CmsVideo.propTypes = {
  data: PropTypes.object.isRequired,
  classes: PropTypes.string,
  isEditing: PropTypes.bool.isRequired,
  showControls: PropTypes.bool.isRequired,
  onUpdate: PropTypes.func
};

CmsVideo.defaultProps = {
  showControls: true,
  isEditing: false
};

export default CmsVideo;
