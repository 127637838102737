import { AutoComplete, Button, Icon, Panel } from "@iforwms/react-components";
import PropTypes from "prop-types";
import moment from "moment";
import { Link } from "react-router-dom";
import { Trans } from "@lingui/macro";
import FeedbackTag from "../FeedbackTag";
import React from "react";
import { parseErrorMessage } from "@iforwms/helpers-js";
import { ApiContext } from "../../contexts/ApiContext";
import { toast } from "react-toastify";
import { get, patch } from "../../api";

class FeedbackModal extends React.Component {
  static contextType = ApiContext;

  state = {
    editBody: false,
    relationship: null,
    relationship_type: ""
  };

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  safeSetState = (...args) => {
    this._isMounted && this.setState(...args);
  };

  addRelationship = () => {
    const { relationship, relationship_type } = this.state;
    const { feedback, handleUpdate } = this.props;
    const payload = feedback.relationships
      .map(item => {
        return {
          id: item.id,
          relationship_type: item.relationship_type
        };
      })
      .concat({ id: relationship.id, relationship_type });

    this.context
      .callApi(() => patch(`/feedback/general/${feedback.id}/relationships`, payload))
      .then(({ data: { data } }) => {
        this.safeSetState({ relationship: null, relationship_type: "" });
        handleUpdate(data);
      })
      .catch(error => {
        toast.error(parseErrorMessage(error, <Trans>Failed to add relationship</Trans>));
      });
  };

  removeRelationship = item => {
    console.log(item);
  };

  render() {
    let {
      showModal,
      feedback,
      filters,
      toggleMenu,
      showDropdown,
      updateUrl,
      handleUpdate,
      updatingFeedback,
      updateFeedbackStatus
    } = this.props;

    const { editBody, relationship_type } = this.state;

    return (
      <div className={`absolute inset-0 bg-white`} key={feedback.id}>
        <div className="p-4">
          <Panel colour={`teal`}>
            <div className={`flex items-center justify-between`}>
              <div className={`flex w-full`}>
                <span className={` text-${feedback.closed_at ? "red" : "teal"}-500`}>
                  <Icon icon={feedback.closed_at ? "closedFeedback" : "openFeedback"} />
                </span>

                <div className={`flex w-full`}>
                  <div className={`w-16`}>
                    <input
                      className={`form-input text-xs`}
                      type="text"
                      placeholder={`Points`}
                      defaultValue={feedback.time_estimate}
                      onBlur={e => handleUpdate({ ...feedback, time_estimate: e.target.value })}
                    />
                  </div>

                  <input
                    type={`text`}
                    defaultValue={feedback.title}
                    onBlur={e => handleUpdate({ ...feedback, title: e.target.value })}
                    className={`w-full px-2 border rounded ml-2 text-lg`}
                  />
                </div>
              </div>

              <div
                className={`p-2 cursor-pointer hover:text-pink-500`}
                onClick={() => showModal(null)}>
                <Icon icon={`close`} />
              </div>
            </div>

            <div className="mt-2 text-sm text-gray-600">
              <Button isLink={true} url={`mailto:${feedback.email}?subject=RE: ${feedback.title}`}>
                {feedback.email}
              </Button>{" "}
              opened this feedback {moment(feedback.created_at).fromNow()}
            </div>

            <div className="flex items-center mt-2 text-sm">
              <Button
                fullWidth={false}
                RouterLink={Link}
                colour={`blue`}
                size={`sm`}
                url={`/users/${feedback.user_id}`}>
                <Trans>View User</Trans>
              </Button>
            </div>

            <div className="flex flex-wrap items-center mt-2 -ml-1 text-xs">
              {filters.map(filter => (
                <FeedbackTag
                  isModal={1}
                  toggleMenu={toggleMenu}
                  showMenu={showDropdown}
                  key={filter.column_name}
                  filter={filter}
                  updateFilter={updateUrl}
                  updateFeedback={handleUpdate}
                  feedback={feedback}
                />
              ))}

              <div className="ml-auto">
                {feedback.closed_at ? (
                  <Button
                    fullWidth={false}
                    disabled={updatingFeedback}
                    onClick={() => updateFeedbackStatus(feedback.id, "open")}
                    classes="ml-1 mt-1">
                    <Trans>Re-open</Trans>
                  </Button>
                ) : (
                  <>
                    <Button
                      fullWidth={false}
                      disabled={updatingFeedback}
                      onClick={() => updateFeedbackStatus(feedback.id, "close")}
                      classes="ml-1 mt-1">
                      <Trans>Close</Trans>
                    </Button>

                    <Button
                      disabled={updatingFeedback}
                      fullWidth={false}
                      onClick={() => updateFeedbackStatus(feedback.id, "fix")}
                      classes="ml-1 mt-1">
                      <Trans>Fix</Trans>
                    </Button>
                  </>
                )}
              </div>
            </div>

            <div className={`mt-4 flex flex-col md:flex-row items-start`}>
              {feedback.relationships && feedback.relationships.length ? (
                <div className={`mr-4 -mx-1 flex flex-col md:flex-row`}>
                  <FeedbackRelationship
                    showModal={showModal}
                    updateUrl={updateUrl}
                    removeRelationship={this.removeRelationship}
                    feedback={feedback.relationships}
                    type={`BLOCKS`}
                  />
                  <FeedbackRelationship
                    showModal={showModal}
                    updateUrl={updateUrl}
                    removeRelationship={this.removeRelationship}
                    feedback={feedback.relationships}
                    type={`BLOCKED_BY`}
                  />
                  <FeedbackRelationship
                    showModal={showModal}
                    updateUrl={updateUrl}
                    removeRelationship={this.removeRelationship}
                    feedback={feedback.relationships}
                    type={`RELATED_TO`}
                  />
                  <FeedbackRelationship
                    showModal={showModal}
                    updateUrl={updateUrl}
                    removeRelationship={this.removeRelationship}
                    feedback={feedback.relationships}
                    type={`DUPLICATE_OF`}
                  />
                </div>
              ) : null}
            </div>

            <div className={`mt-2`}>
              <div className={`flex`}>
                <select
                  className={`text-xs uppercase text-gray-500 py-1 px-2 rounded border`}
                  value={relationship_type}
                  onChange={e => this.setState({ relationship_type: e.target.value })}>
                  <option value="">Add Relationship</option>
                  <option value="BLOCKS">Blocks</option>
                  <option value="BLOCKED_BY">Blocked By</option>
                  <option value="RELATED_TO">Related To</option>
                  <option value="DUPLICATE_OF">Duplicate Of</option>
                </select>

                <div className={`ml-2 text-sm text-gray-500`}>
                  <AutoComplete
                    get={get}
                    url={`/feedback/general?closed=false`}
                    handleSelect={data => this.setState({ relationship: data })}
                    itemKey={`title`}
                    readOnly={true}
                  />
                </div>

                <Button
                  fullWidth={false}
                  size={`xs`}
                  colour={`blue`}
                  classes={`ml-2`}
                  onClick={this.addRelationship}>
                  <Trans>Add</Trans>
                </Button>
              </div>
            </div>

            {feedback.geo_data ? (
              <div className={`mt-4 static-content`}>
                <h3 className="font-bold">
                  <Trans>Location</Trans>:{" "}
                  <span className="font-normal">
                    {feedback.geo_data.country_name}, {feedback.geo_data.region_name},{" "}
                    {feedback.geo_data.city}
                  </span>
                </h3>
              </div>
            ) : null}

            {editBody ? (
              <div className={`mt-4`}>
                <textarea
                  className={`w-full p-2 rounded border`}
                  onBlur={e => {
                    handleUpdate({ ...feedback, description: e.target.value });
                    this.setState({ editBody: false });
                  }}
                  defaultValue={feedback.description}
                  rows="10"
                />
              </div>
            ) : (
              <div
                onClick={() => this.setState({ editBody: true })}
                className={`cursor-pointer static-content mt-4`}
                dangerouslySetInnerHTML={{
                  __html: feedback.description_parsed
                    ? feedback.description_parsed
                    : "No description set."
                }}
              />
            )}

            {feedback.images && feedback.images.length ? (
              <div>
                <h3 className={`font-bold text-lg text-pink-500`}>
                  <Trans>Images</Trans>
                </h3>

                {feedback.images.map((image, index) => (
                  <div key={index} style={{ maxWidth: 1280 }} className="p-2 mt-2 border rounded">
                    <Button url={image.src} isLink={true}>
                      <img src={image.src} alt="" />
                    </Button>
                  </div>
                ))}
              </div>
            ) : null}
          </Panel>
        </div>
      </div>
    );
  }
}

const FeedbackRelationship = ({ updateUrl, feedback, type, showModal, removeRelationship }) => {
  const filteredFeedback = feedback.filter(item => item.relationship_type === type);
  if (!filteredFeedback.length) return null;

  return (
    <div className={`mx-1`}>
      <div className={`text-xs uppercase text-gray-500`}>{type}</div>
      <ul className={``}>
        {filteredFeedback.map(item => (
          <li key={item.id} className={`mt-1 flex`}>
            <span
              className={`cursor-pointer block hover:text-blue-500 hover:border-blue-500 rounded-l text-left w-24 truncate text-xs px-2 py-1 border-l border-t border-b`}
              onClick={() => showModal(item)}>
              {item.title}
            </span>
            <span
              className={`cursor-pointer flex items-center hover:text-blue-500 hover:border-blue-500 rounded-r text-center text-xs px-2 py-1 border`}
              onClick={() => removeRelationship(item)}>
              <Icon icon={`close`} size={12} />
            </span>
          </li>
        ))}
      </ul>
    </div>
  );
};

FeedbackRelationship.propTypes = {
  updateUrl: PropTypes.func.isRequired,
  feedback: PropTypes.array,
  showModal: PropTypes.func.isRequired,
  removeRelationship: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired
};

FeedbackModal.propTypes = {
  showModal: PropTypes.func.isRequired,
  feedback: PropTypes.object.isRequired,
  filters: PropTypes.array.isRequired,
  toggleMenu: PropTypes.func.isRequired,
  showDropdown: PropTypes.string,
  updateUrl: PropTypes.func.isRequired,
  handleUpdate: PropTypes.func.isRequired,
  updatingFeedback: PropTypes.bool.isRequired,
  updateFeedbackStatus: PropTypes.func.isRequired
};

export default FeedbackModal;
