import _ from "lodash";

export const colours = [
  "purple",
  "indigo",
  "pink",
  "green",
  "blue",
  "red",
  "orange",
  "yellow",
  "teal",
  "gray",
  "black",
  "white"
];

export const sizes = ["xs", "sm", "md", "lg", "xl", "2xl"];

let defaultSettings = {
  styles: {
    inputClasses: `block border rounded p-2 text-gray-700`,
    inputLoadingStyles: {
      animation: "glow 0.4s ease-in-out infinite alternate"
    },
    labelClasses: `block text-xs uppercase text-gray-500`,
    panelClasses: `border-l-4 shadow rounded-r`,
    buttonClasses: `rounded`,
    linkClasses: ``,
    tableHeaderClasses: `px-6 py-3 border-b border-gray-200 bg-gray-50 text-xs leading-4 whitespace-no-wrap font-medium text-gray-500 uppercase tracking-wider`,
    tableBodyClasses: `px-6 py-4 whitespace-no-wrap text-sm leading-5 text-gray-700`
  }
};

let userSettings = {};

try {
  userSettings = require(process.env.REACT_APP_CUSTOM_STYLE_FILE).default;
} catch (e) {
  userSettings = {};
}

const mergedSettings = _.merge({}, defaultSettings, userSettings);

export default mergedSettings;
