import React, { Component } from "react";

let QuizContext;
const { Consumer, Provider } = (QuizContext = React.createContext());

class QuizProvider extends Component {
  state = {
    questions: []
  };

  safeSetContextState = (...args) => {
    this.setState(...args);
  };

  setQuizState = (key, value) => {
    console.log("setting quiz context state", key, value);
    this.setState({ [key]: value });
  };

  render() {
    const { questions } = this.state;

    return (
      <Provider
        value={{
          questions,
          safeSetContextState: this.safeSetContextState,
          setQuizState: this.setQuizState
        }}>
        {this.props.children}
      </Provider>
    );
  }
}

export { QuizContext, QuizProvider, Consumer as QuizConsumer };
