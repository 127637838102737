import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Trans } from "@lingui/macro";
import Modal from "react-modal";
import { toast } from "react-toastify";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import parseErrorMessage from "helpers-js/parseErrorMessage";
import { transformMyRestApiErrorsToAnObject } from "../../utils";
import DeleteResourceButton from "./DeleteResourceButton";
import BackButton from "../BackButton";
import Checkbox from "./Checkbox";
import { ApiConsumer } from "../../contexts/ApiContext";
import { updateResource } from "../../api";
import { Button } from "@iforwms/react-components";

const CourseSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, <Trans>Please enter a longer name (min: 2 characters)</Trans>)
    .max(50, <Trans>Please enter a shorter name (max: 50 characters)</Trans>)
    .required(<Trans>Please enter a name.</Trans>)
});

const CourseItemWithVideo = ({
  initialValues,
  onSuccess,
  onDelete,
  formVisible,
  hideForm,
  showForm,
  endpoint,
  model,
  form,
  match
}) => (
  <ApiConsumer>
    {({ callApi }) => (
      <Formik
        initialValues={{
          id: undefined,
          name: "",
          is_active: false,
          has_quiz: false,
          description: "",
          videos: [],
          ...initialValues
        }}
        enableReinitialize
        validationSchema={CourseSchema}
        onSubmit={(values, { setSubmitting, setErrors, setStatus, resetForm }) => {
          const url = `${endpoint}/${values.id}`;

          callApi(() => updateResource(url, values))
            .then(res => {
              setSubmitting(false);
              onSuccess(res.data.data);
              toast.success(`${model} Updated!`);
            })
            .catch(error => {
              setSubmitting(false);
              setErrors(transformMyRestApiErrorsToAnObject(error));
              toast.error(parseErrorMessage(error, `Failed to update ${model}.`));
            });
        }}>
        {({ isSubmitting, errors, status, values }) => (
          <div className="form-container">
            <div className="flex items-center mb-4">
              <BackButton className="mr-2" url={match.url.replace("/edit", "")} />
              <h2 className="text-lg text-blue">Update {model}</h2>
            </div>
            <Form>
              <div className="form-input-group">
                <label className="form-label">
                  <Trans>Name</Trans>
                </label>
                <Field
                  className="form-input"
                  type="text"
                  autoFocus
                  autoComplete="off"
                  name="name"
                  disabled={isSubmitting}
                />
                <ErrorMessage name="name">
                  {errorMessage => <div className="form-input-error">{errorMessage}</div>}
                </ErrorMessage>
              </div>
              <div className="form-input-group">
                <label className="form-label">
                  <Trans>Description</Trans>
                </label>
                <Field
                  className="form-input"
                  component="textarea"
                  rows={5}
                  name="description"
                  disabled={isSubmitting}
                />
              </div>
              <div className="form-input-group">
                <Field
                  component={Checkbox}
                  isSubmitting={isSubmitting}
                  name="is_active"
                  id="is_active"
                  label={`Do you want ${model} this visible to users?`}
                />
              </div>
              {endpoint === "courses" && (
                <div className="form-input-group">
                  <Field
                    component={Checkbox}
                    isSubmitting={isSubmitting}
                    name="has_quiz"
                    id="has_quiz"
                    label={`Does this ${model} have quiz questions?`}
                  />
                </div>
              )}

              <div className="mb-8">
                <div className="mb-4">
                  <label className="form-label">Current Videos</label>
                  {!values.videos.length && (
                    <div>
                      <Trans>No videos yet, create one!</Trans>
                    </div>
                  )}
                  {values.videos.map(v => (
                    <div className="mb-4 text-sm text-gray-600 fle" key={v.id}>
                      <span>{v.name}</span>
                      <Button fullWidth={false} url={`/videos/${v.id}`} size={`sm`} classes="ml-2">
                        <Trans>View / Edit Video</Trans>
                      </Button>
                    </div>
                  ))}
                </div>

                <Button onClick={showForm} type="button">
                  <Trans>Create a Video</Trans>
                </Button>
              </div>

              <div className="btn-group">
                <DeleteResourceButton
                  isSubmitting={isSubmitting}
                  onDelete={onDelete}
                  url={`${endpoint}/${values.id}/`}
                />

                <Button color={`blue`} disabled={isSubmitting}>
                  {isSubmitting ? `Updating ${model}` : `Update ${model}`}
                </Button>
              </div>
            </Form>

            <Modal
              ariaHideApp={false}
              isOpen={formVisible}
              style={{
                overlay: { zIndex: 20 },
                content: {
                  top: "50%",
                  left: "50%",
                  right: "auto",
                  bottom: "auto",
                  marginRight: "-50%",
                  transform: "translate(-50%, -50%)",
                  maxWidth: 600,
                  minWidth: 600
                }
              }}>
              <div>
                <Button icon={`close`} onClick={hideForm} />
                {React.cloneElement(form)}
              </div>
            </Modal>
          </div>
        )}
      </Formik>
    )}
  </ApiConsumer>
);

CourseItemWithVideo.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  initialValues: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired
};

export default CourseItemWithVideo;
