import React from "react";
import PropTypes from "prop-types";
import MediaUpload from "../forms/MediaUpload";
import Input from "../forms/Input";

class CmsImage extends React.Component {
  handleSuccess = (file, data) => {
    const payload = { ...this.state, id: null, ...data.data };
    this.props.onUpdate(payload);
  };

  handleSubmit = () => {
    this.props.onUpdate(this.state);
  };

  setParentState = (valueKey, value) => {
    this.setState({ [valueKey]: value }, () => this.handleSubmit());
  };

  render() {
    const {
      data: { containerClasses, name, src, caption },
      isEditing,
      post,
      classes
    } = this.props;

    if (isEditing) {
      return (
        <div>
          <div className="mt-4">
            <Input
              label={`Container CSS Classes`}
              value={containerClasses}
              valueKey={`containerClasses`}
              setState={this.setParentState}
            />
          </div>

          <div className="mt-4">
            <Input
              type={`textarea`}
              label={`Caption`}
              value={caption}
              valueKey={`caption`}
              setState={this.setParentState}
            />
          </div>

          <div className="mt-4">
            <MediaUpload
              accept="image/*"
              post={post}
              onError={() => {}}
              onSuccess={this.handleSuccess}
            />
          </div>
        </div>
      );
    }

    return (
      <div className={containerClasses}>
        <figure
          style={{ width: "fit-content" }}
          className={`shadow rounded overflow-hidden fit-content mx-auto`}>
          {src ? (
            <img className={`mx-auto ${classes}`} src={src} alt={``} />
          ) : (
            <div>Save to view image preview ({name}).</div>
          )}
          {caption ? (
            <figcaption className={`text-sm bg-white p-4 italic`}>
              {caption}
            </figcaption>
          ) : null}
        </figure>
      </div>
    );
  }
}

CmsImage.propTypes = {
  data: PropTypes.object.isRequired,
  classes: PropTypes.string,
  isEditing: PropTypes.bool.isRequired,
  onUpdate: PropTypes.func
};

CmsImage.defaultProps = {
  isEditing: false
};

export default CmsImage;
