import React from "react";
import { Trans } from "@lingui/macro";
import { toast } from "react-toastify";
import { ApiContext } from "../contexts/ApiContext";
import { get, post } from "../api";
import parseErrorMessage from "helpers-js/parseErrorMessage";
import { Button } from "@iforwms/react-components";

class Impersonate extends React.Component {
  static contextType = ApiContext;

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  safeSetState = (...args) => {
    this._isMounted && this.setState(...args);
  };

  loginAs = email => {
    const { callApi, setApiState } = this.context;
    this.safeSetState({ isLoading: true });
    callApi(() => post("users/impersonate", { email }))
      .then(res => {
        localStorage.setItem("DOMINO_JWT", res.data.token);
        callApi(() => get("me"))
          .then(({ data }) => {
            setApiState("user", data);
          })
          .catch(e => {
            return toast.error(parseErrorMessage(e, <Trans>Failed to fetch user.</Trans>));
          });
      })
      .catch(e => {
        this.safeSetState({ isLoading: false });
        return toast.error(parseErrorMessage(e, <Trans>Failed to impersonate user.</Trans>));
      });
  };

  render() {
    return (
      <Button
        colour={`pink`}
        size={`sm`}
        fullWidth={false}
        onClick={() => this.loginAs(this.props.email)}>
        <Trans>Impersonate</Trans>
      </Button>
    );
  }
}

export default Impersonate;
