import React from "react";
import { Trans } from "@lingui/macro";
import { Button } from "@iforwms/react-components";

const ReloadButton = ({ action }) => (
  <div className="flex justify-between flex-col items-center py-4 text-orange text-sm leading-tight">
    <p className="mb-4">
      <Trans>Failed to fetch items.</Trans>
    </p>

    <Button
      colour={`orange`}
      onClick={action ? action : () => window.location.reload(true)}
      text={<Trans>Refresh</Trans>}
    />
  </div>
);

export default ReloadButton;
