import React from "react";
import PropTypes from "prop-types";
import { parseFilter } from "../utils";
import { Icon } from "@iforwms/react-components";

const FeedbackTag = ({
  filter,
  updateFilter,
  updateFeedback,
  feedback,
  showMenu,
  toggleMenu,
  isModal
}) => {
  const colour = parseFilter(filter, feedback[filter.column_name], "colour");
  return (
    <div>
      {toggleMenu && showMenu === `${feedback.id}_${filter.column_name}_${isModal}` ? (
        <div className={`absolute z-5 inset-0`} onClick={toggleMenu} />
      ) : null}

      <div className={`flex relative ml-1 mt-1 text-white`}>
        <div
          className={`rounded-l cursor-pointer p-1 bg-${colour}-500 hover:bg-${colour}-600 ${
            toggleMenu ? "" : "rounded-r text-xxs"
          }`}
          onClick={() =>
            updateFilter
              ? updateFilter({ [filter.column_name]: feedback[filter.column_name] })
              : null
          }>
          {parseFilter(filter, feedback[filter.column_name], "name")}
        </div>

        {toggleMenu ? (
          <div
            className={`border-l border-white flex items-center justify-center rounded-r p-1 bg-${colour}-500 hover:bg-${colour}-600 cursor-pointer`}
            onClick={() => toggleMenu(feedback.id, filter.column_name, isModal)}>
            <Icon size={12} icon="down" />
          </div>
        ) : null}

        {toggleMenu && showMenu === `${feedback.id}_${filter.column_name}_${isModal}` ? (
          <div
            className={`absolute z-5 rounded overflow-hidden flex flex-col bg-${colour}-500`}
            style={{ top: 27 }}>
            {filter.options.map(option => (
              <div
                key={option.name}
                className={`hover:bg-${option.colour}-600 bg-${option.colour}-500 cursor-pointer p-1`}
                onClick={() => {
                  updateFeedback({ ...feedback, [filter.column_name]: option.value });
                  toggleMenu();
                }}>
                {option.name}
              </div>
            ))}
          </div>
        ) : null}
      </div>
    </div>
  );
};

FeedbackTag.propTypes = {
  filter: PropTypes.object.isRequired,
  updateFilter: PropTypes.func.isRequired,
  updateFeedback: PropTypes.func.isRequired,
  feedback: PropTypes.object.isRequired,
  showMenu: PropTypes.string,
  toggleMenu: PropTypes.func,
  isModal: PropTypes.number.isRequired
};

FeedbackTag.defaultProps = {
  isModal: 0
};

export default FeedbackTag;
