import React from "react";

const Checkbox = ({
    field: { name, value, onChange, onBlur },
    form: { errors, touched, setFieldValue },
    id,
    label,
    className,
    isSubmitting,
    ...props
}) => {
    return (
        <div className="flex items-center">
            <label
                className={`form-label form-checkbox-label ${
                    isSubmitting ? "cursor-not-allowed" : "cursor-pointer"
                }`}>
                {label}
                <input
                    name={name}
                    id={id}
                    type="checkbox"
                    value={value}
                    checked={value}
                    onChange={onChange}
                    onBlur={onBlur}
                    disabled={isSubmitting}
                    className="form-checkbox"
                />
            </label>
        </div>
    );
};

export default Checkbox;
