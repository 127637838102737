import React from "react";
import { Route, Switch } from "react-router-dom";
import PrivateRoute from "../components/PrivateRoute";
import VideoIndex from "../pages/VideoIndex";
import CourseManagement from "../pages/CourseManagement";
import Coupons from "../pages/Coupons";
import DegreeIndex from "../pages/DegreeIndex";
import DegreeShow from "../pages/DegreeShow";
import Feedback from "../pages/FeedbackIndex";
// import FeedbackShow from "../pages/FeedbackShow";
import CreateFeedback from "../pages/FeedbackCreate";
import BlogIndex from "../pages/BlogIndex";
import Menus from "../pages/Menus";
import MenuShow from "../pages/MenuShow";
import Certificate from "../pages/Certificate";
import CourseEdit from "../pages/CourseEdit";
import ChapterEdit from "../pages/ChapterEdit";
import StatsOverview from "../pages/StatsOverview";
import ClassOverview from "../pages/ClassOverview";
import PaymentGateways from "../pages/PaymentGateways";
import Subscriptions from "../pages/Subscriptions";
import SubscriptionShow from "../pages/SubscriptionShow";
import PaymentPlans from "../pages/PaymentPlans";
import StaticPageIndex from "../pages/StaticPages";
import StaticPage from "./cms/Page";
import PagePreview from "./cms/PagePreview";
import Payments from "../pages/Payments";
import UserIndex from "../pages/UserIndex";
import UserDetail from "../pages/UserDetail";
import Grammar from "../pages/Grammar";
import Dictionary from "../pages/Dictionary";
import DialogIndex from "../pages/DialogIndex";
import DialogCreate from "../pages/DialogCreate";
import NotificationIndex from "../pages/NotificationIndex";
import NotificationCreate from "../pages/NotificationCreate";
import DialogShow from "../pages/DialogShow";
import VideoHosts from "../pages/VideoHosts";
import Unauthorized from "../pages/Unauthorized";
import LandingPage from "../pages/LandingPage";
import NoMatch from "../pages/NoMatch";
import ReceiptParser from "../pages/ReceiptParser";
import VideoFormContainer from "./forms/videos/VideoFormContainer";
import RedisIndex from "../pages/RedisIndex";
import MissingInfo from "../pages/MissingInfo";
import DialogBulkCreate from "../pages/DialogBulkCreate";
import AudioIndex from "../pages/AudioIndex";
import DictionaryShow from "./forms/DictionaryShow";

const RoutesContainer = () => (
  <Switch>
    <PrivateRoute exact path="/" component={LandingPage} />
    <PrivateRoute exact path="/overview" component={StatsOverview} />
    <Route path="/unauthorized" component={Unauthorized} />

    <PrivateRoute exact path="/audio" component={AudioIndex}/>
    <PrivateRoute exact path="/dialogs" component={DialogIndex} />
    <PrivateRoute exact path="/notifications" component={NotificationIndex} />
    <PrivateRoute exact path="/notifications/create" component={NotificationCreate} />
    <PrivateRoute exact path="/notifications/:id" component={NotificationCreate} />
    <PrivateRoute exact path="/dialogs/bulk" component={DialogBulkCreate} />
    <PrivateRoute exact path="/dialogs/create" component={DialogCreate} />
    <PrivateRoute exact path="/dialogs/:id" component={DialogShow} />
    <PrivateRoute exact path="/certificate" component={Certificate} />
    <PrivateRoute exact path="/dictionary/create" component={DictionaryShow} />
    <PrivateRoute exact path="/dictionary/:id" component={DictionaryShow} />
    <PrivateRoute exact path="/dictionary" component={Dictionary} />
    <PrivateRoute exact path="/feedback" component={Feedback} />
    {/* <PrivateRoute exact path="/feedback/:id" component={FeedbackShow} /> */}
    <PrivateRoute exact path="/coupons" component={Coupons} />
    <PrivateRoute exact path="/feedback/create" component={CreateFeedback} />
    <PrivateRoute exact path="/payments" component={Payments} />
    <PrivateRoute exact path="/subscriptions" component={Subscriptions} />
    <PrivateRoute exact path="/subscriptions/:id" component={SubscriptionShow} />
    <PrivateRoute exact path="/receipt-parser" component={ReceiptParser} />
    <PrivateRoute exact path="/users" component={UserIndex} />
    <PrivateRoute exact path="/users/:id" component={UserDetail} />
    <PrivateRoute exact path="/payment-gateways" component={PaymentGateways} />
    <PrivateRoute exact path="/payment-gateways/:gateway_id/edit" component={PaymentGateways} />
    <PrivateRoute exact path="/plans" component={PaymentPlans} />
    <PrivateRoute exact path="/plans/:plan_id/edit" component={PaymentPlans} />
    <PrivateRoute exact path="/grammar/:grammar_id?" component={Grammar} />
    <PrivateRoute
      path="/courses/:course_id/levels/:level_id/chapters/:chapter_id/lessons/:lesson_id/edit"
      render={props => <CourseEdit {...props} endpoint="lessons" model="Lesson" />}
    />
    <PrivateRoute
      path="/courses/:course_id/levels/:level_id/chapters/:chapter_id/edit"
      render={props => <ChapterEdit {...props} endpoint="chapters" model="Chapter" />}
    />
    <PrivateRoute
      path="/courses/:course_id/levels/:level_id/edit"
      render={props => <CourseEdit {...props} endpoint="levels" model="Level" />}
    />
    <PrivateRoute
      path="/courses/:course_id/edit"
      render={props => <CourseEdit {...props} endpoint="courses" model="Course" />}
    />
    <PrivateRoute path="/courses" component={CourseManagement} />
    <PrivateRoute path="/video-hosts/:host_id?" component={VideoHosts} />

    <PrivateRoute exact path="/degrees" component={DegreeIndex} />
      <PrivateRoute exact path="/degrees/:id" component={DegreeShow} />

    <PrivateRoute path="/videos/:video_id/:tab?" component={VideoFormContainer} />
    <PrivateRoute path="/videos" component={VideoIndex} />
    <PrivateRoute path="/redis" component={RedisIndex} />
    <PrivateRoute path="/missing-info" component={MissingInfo} />

    <Route component={NoMatch} />
  </Switch>
);

export default RoutesContainer;
