import React from "react";
import { ApiContext } from "../contexts/ApiContext";
import { Trans } from "@lingui/macro";
import { post, get, patch } from "../api";
import { toast } from "react-toastify";
import { Button, Input, Label, Panel } from "@iforwms/react-components";
import history from "../components/history";
import moment from "moment";
import PageHeader from "../components/PageHeader";
import PageBody from "../components/PageBody";
import { Link } from "react-router-dom";
import Markdown from "react-markdown";
import { parseErrorMessage, parseDate } from "@iforwms/helpers-js";
import { LoadingWrapper, CmsDate } from "@iforwms/react-components";

class NotificationCreate extends React.Component {
  static contextType = ApiContext;
  state = {
    isSubmitting: false,
    isLoading: false,
    title: "",
    body: "",
    published_year: "",
    published_month: "",
    published_day: "",
    published_hour: "",
    published_minute: "",
    expires_year: "",
    expires_month: "",
    expires_day: "",
    expires_hour: "",
    expires_minute: "",
    expires_at: "",
    error: null
  };

  componentDidMount() {
    this._isMounted = true;
    if (this.props.match.params.id) {
      return this.fetchNotification();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  safeSetState = (...args) => {
    this._isMounted && this.setState(...args);
  };

  setParentState = (valueKey, value) => {
    this.setState({ [valueKey]: value });
  };

  fetchNotification = () => {
    this.safeSetState({ isLoading: true });

    this.context
      .callApi(() => get(`/notifications/${this.props.match.params.id}`))
      .then(({ data: { data } }) => {
        this.safeSetState({
          title: data.title,
          body: data.markdown,
          published_year: data.published_at ? moment.utc(data.published_at).format("YYYY") : "",
          published_month: data.published_at ? moment.utc(data.published_at).format("M") : "",
          published_day: data.published_at ? moment.utc(data.published_at).format("D") : "",
          published_hour: data.published_at ? moment.utc(data.published_at).format("H") : "",
          published_minute: data.published_at ? moment.utc(data.published_at).format("m") : "",
          expires_year: data.expires_at ? moment.utc(data.expires_at).format("YYYY") : "",
          expires_month: data.expires_at ? moment.utc(data.expires_at).format("M") : "",
          expires_day: data.expires_at ? moment.utc(data.expires_at).format("D") : "",
          expires_hour: data.expires_at ? moment.utc(data.expires_at).format("H") : "",
          expires_minute: data.expires_at ? moment.utc(data.expires_at).format("m") : "",
          isLoading: false
        });
      })
      .catch(error => {
        this.safeSetState({ isLoading: false, error });
        toast.error(parseErrorMessage(error, <Trans>Failed to fetch notification</Trans>));
      });
  };

  handleSubmit = e => {
    e.preventDefault();

    this.safeSetState({ isSubmitting: true });

    if (this.props.match.params.id) {
      return this.handleUpdate();
    }

    const {
      title,
      body,
      published_year,
      published_month,
      published_day,
      published_hour,
      published_minute,
      expires_year,
      expires_month,
      expires_day,
      expires_hour,
      expires_minute
    } = this.state;

    const payload = {
      title,
      body,
      published_at: parseDate(
        published_year,
        published_month,
        published_day,
        published_hour,
        published_minute
      ),
      expires_at: parseDate(expires_year, expires_month, expires_day, expires_hour, expires_minute)
    };

    this.context
      .callApi(() => post("/notifications", payload))
      .then(({ data }) => {
        history.push("/notifications");
        this.safeSetState({ isSubmitting: false });
        toast.success(<Trans>Notification Created!</Trans>);
      })
      .catch(error => {
        this.safeSetState({ isSubmitting: false });
        toast.error(parseErrorMessage(error, <Trans>Failed to create notification</Trans>));
      });
  };

  handleUpdate = () => {
    const {
      title,
      body,
      published_year,
      published_month,
      published_day,
      published_hour,
      published_minute,
      expires_year,
      expires_month,
      expires_day,
      expires_hour,
      expires_minute
    } = this.state;

    const payload = {
      title,
      body,
      published_at: parseDate(
        published_year,
        published_month,
        published_day,
        published_hour,
        published_minute
      ),
      expires_at: parseDate(expires_year, expires_month, expires_day, expires_hour, expires_minute)
    };

    this.context
      .callApi(() => patch(`/notifications/${this.props.match.params.id}`, payload))
      .then(() => {
        toast.success(<Trans>Notification Updated!</Trans>);
        this.safeSetState({ isSubmitting: false });
      })
      .catch(error => {
        this.safeSetState({ isSubmitting: false });
        toast.error(parseErrorMessage(error, <Trans>Failed to create notification</Trans>));
      });
  };

  setOwnState = (key, value) => {
    this.setState({ [key]: value });
  };

  render() {
    const {
      isSubmitting,
      isLoading,
      title,
      body,
      published_year,
      published_month,
      published_day,
      published_hour,
      published_minute,
      expires_year,
      expires_month,
      expires_day,
      expires_hour,
      expires_minute
    } = this.state;
    const { id } = this.props.match.params;

    return (
      <div>
        <PageHeader title={<Trans>Notifications</Trans>} />
        <PageBody>
          <LoadingWrapper isLoading={isLoading}>
            <div className={`p-4`}>
              <Button
                fullWidth={false}
                RouterLink={Link}
                url={`/notifications`}
                text={<Trans>Back to Index</Trans>}
              />

              <div className="mt-4">
                <Panel
                  icon={`advert`}
                  title={
                    id ? <Trans>Update Notification</Trans> : <Trans>Create Notification</Trans>
                  }
                  colour={`green`}>
                  <form method={`POST`} onSubmit={this.handleSubmit}>
                    <div className={``}>
                      <Input
                        setState={this.setParentState}
                        valueKey={`title`}
                        label={<Trans>Title</Trans>}
                        disabled={isSubmitting}
                        value={title}
                      />
                    </div>

                    <div className={`mt-4`}>
                      <Input
                        label={`Body`}
                        disabled={isSubmitting}
                        value={body}
                        valueKey={`body`}
                        type={`textarea`}
                        setState={this.setParentState}
                      />
                    </div>

                    <div className={`mt-4`}>
                      <Label label={`Preview`} />
                      <div className="static-content form-input mt-1">
                        <Markdown source={body} />
                      </div>
                    </div>

                    <div className="mt-4">
                      <CmsDate
                        label={`Published At (yyyy/mm/dd hh:mm) (UTC)`}
                        keyPrefix={`published`}
                        day={published_day}
                        year={published_year}
                        hour={published_hour}
                        setParentState={this.setOwnState}
                        minute={published_minute}
                        month={published_month}
                      />
                    </div>

                    <div className="mt-4">
                      <CmsDate
                        label={`Expires At (yyyy/mm/dd hh:mm) (UTC)`}
                        keyPrefix={`expires`}
                        day={expires_day}
                        year={expires_year}
                        hour={expires_hour}
                        setParentState={this.setOwnState}
                        minute={expires_minute}
                        month={expires_month}
                      />
                    </div>

                    <Button
                      colour={`blue`}
                      classes="mt-4"
                      disabled={isSubmitting}
                      text={id ? <Trans>Update</Trans> : <Trans>Create</Trans>}
                    />
                  </form>
                </Panel>
              </div>
            </div>
          </LoadingWrapper>
        </PageBody>
      </div>
    );
  }
}

export default NotificationCreate;
