import React from "react";
import { Formik, Form, Field, ErrorMessage, FieldArray, getIn } from "formik";
import * as Yup from "yup";
import { Trans } from "@lingui/macro";
import Checkbox from "./Checkbox";
import { toast } from "react-toastify";
import parseErrorMessage from "helpers-js/parseErrorMessage";
import { transformMyRestApiErrorsToAnObject } from "../../utils";
import DeleteResourceButton from "./DeleteResourceButton";
import { ApiConsumer } from "../../contexts/ApiContext";
import { updatePaymentPlan, createPaymentPlan } from "../../api";
import { Button } from "@iforwms/react-components";

const ArrayErrorMessage = ({ name }) => (
  <Field
    name={name}
    render={({ form }) => {
      const error = getIn(form.errors, name);
      const touch = getIn(form.touched, name);
      return touch && error ? <div className="form-input-error mb-2">{error}</div> : null;
    }}
  />
);

const PaymentPlanSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, <Trans>Please enter a longer name (min: 2 characters)</Trans>)
    .max(50, <Trans>Please enter a shorter name (max: 50 characters)</Trans>)
    .required(<Trans>Please enter a name.</Trans>),
  amount: Yup.number().required(<Trans>Please enter an amount.</Trans>),
  description_title: Yup.string().required(<Trans>Please enter a description title.</Trans>),
  description_body: Yup.string().required(<Trans>Please enter a description.</Trans>),
  notes: Yup.string(),
  billing_period: Yup.string()
    .oneOf(["monthly", "yearly"])
    .required(<Trans>Please enter a billing period.</Trans>)
});

const PaymentPlanForm = ({ initialValues, onSuccess, onDelete }) => (
  <ApiConsumer>
    {({ callApi }) => (
      <Formik
        initialValues={{
          id: undefined,
          name: "",
          description_title: "",
          description_body: "",
          notes: "",
          features: [],
          is_active: false,
          recommended: false,
          amount: 0,
          billing_period: "monthly",
          ...initialValues
        }}
        enableReinitialize
        validationSchema={PaymentPlanSchema}
        onSubmit={(values, { setSubmitting, setErrors, setStatus, resetForm }) => {
          values = { ...values, features: JSON.stringify(values.features) };
          callApi(() =>
            values.id ? updatePaymentPlan(values.id, values) : createPaymentPlan(values)
          )
            .then(res => {
              setSubmitting(false);

              if (onSuccess) {
                onSuccess(res.data);
              }

              toast.success(
                values.id ? (
                  <Trans>Payment Plan Updated!</Trans>
                ) : (
                  <Trans>Payment Plan created!</Trans>
                )
              );
            })
            .catch(error => {
              setSubmitting(false);
              setErrors(transformMyRestApiErrorsToAnObject(error));
              toast.error(
                values.id
                  ? parseErrorMessage(error, <Trans>Failed to update Payment Plan.</Trans>)
                  : parseErrorMessage(error, <Trans>Failed to update create Payment Plan.</Trans>)
              );
            });
        }}>
        {({ isSubmitting, errors, status, values }) => (
          <div className="form-container">
            <h2 className="form-header">
              {values.id ? values.name : <Trans>Create a Brand New Payment Plan!</Trans>}
            </h2>
            <Form>
              <div className="form-input-group">
                <label className="form-label">
                  <Trans>Name</Trans>
                </label>
                <Field
                  className="form-input"
                  type="text"
                  autoFocus
                  autoComplete="off"
                  name="name"
                  disabled={isSubmitting}
                />
                <ErrorMessage name="name">
                  {errorMessage => <div className="form-input-error">{errorMessage}</div>}
                </ErrorMessage>
              </div>
              <div className="form-input-group">
                <label className="form-label">
                  <Trans>Amount</Trans>
                </label>
                <Field
                  className="form-input"
                  type="text"
                  autoComplete="off"
                  name="amount"
                  disabled={isSubmitting}
                />
                <ErrorMessage name="amount">
                  {errorMessage => <div className="form-input-error">{errorMessage}</div>}
                </ErrorMessage>
              </div>
              <div className="form-input-group">
                <label className="form-label">
                  <Trans>Billing Period</Trans>
                </label>
                <Field
                  className="form-input"
                  type="text"
                  autoComplete="off"
                  name="billing_period"
                  disabled={isSubmitting}
                />
                <ErrorMessage name="billing_period">
                  {errorMessage => <div className="form-input-error">{errorMessage}</div>}
                </ErrorMessage>
              </div>
              <div className="form-input-group">
                <label className="form-label">
                  <Trans>Description Title</Trans>
                </label>
                <Field
                  className="form-input"
                  type="text"
                  name="description_title"
                  disabled={isSubmitting}
                />
                <ErrorMessage name="description_title">
                  {errorMessage => <div className="form-input-error">{errorMessage}</div>}
                </ErrorMessage>
              </div>
              <div className="form-input-group">
                <label className="form-label">
                  <Trans>Description Body</Trans>
                </label>
                <Field
                  className="form-input"
                  component="textarea"
                  rows={5}
                  name="description_body"
                  disabled={isSubmitting}
                />
                <ErrorMessage name="description_body">
                  {errorMessage => <div className="form-input-error">{errorMessage}</div>}
                </ErrorMessage>
              </div>

              <div className="form-input-group">
                <label className="form-label">
                  <Trans>Notes</Trans>
                </label>
                <Field
                  className="form-input"
                  rows={5}
                  component="textarea"
                  name="notes"
                  disabled={isSubmitting}
                />
              </div>

              <div className="form-input-group">
                <label className="form-label">Features</label>
                <FieldArray
                  name="features"
                  render={arrayHelpers => (
                    <div>
                      {values.features &&
                        values.features.length > 0 &&
                        values.features.map((item, index) => (
                          <div key={index}>
                            <div className="flex items-start mb-4">
                              <div>
                                <Field
                                  className="mb-2 form-input"
                                  name={`features.${index}.name`}
                                  type="text"
                                  value={values.features[index].name || ""}
                                  autoComplete="off"
                                  disabled={isSubmitting}
                                />
                                <Field
                                  className="mr-2 form-input"
                                  name={`features.${index}.description`}
                                  component="textarea"
                                  value={values.features[index].description || ""}
                                  rows={5}
                                  disabled={isSubmitting}
                                />
                              </div>
                              <Button
                                colour={`red`}
                                className="ml-2"
                                type="button"
                                disabled={isSubmitting}
                                onClick={() => arrayHelpers.remove(index)}>
                                -
                              </Button>
                            </div>
                            <ArrayErrorMessage name={`features.${index}.name`} />
                          </div>
                        ))}
                      <Button
                        type="button"
                        disabled={isSubmitting}
                        onClick={() =>
                          arrayHelpers.push({
                            name: "",
                            description: ""
                          })
                        }>
                        Add a feature
                      </Button>
                    </div>
                  )}
                />
              </div>

              <div className="form-input-group">
                <Field
                  component={Checkbox}
                  isSubmitting={isSubmitting}
                  name="recommended"
                  id="recommended"
                  label={<Trans>Is this the recommended payment plan?</Trans>}
                />
              </div>

              <div className="form-input-group">
                <Field
                  component={Checkbox}
                  isSubmitting={isSubmitting}
                  name="is_active"
                  id="is_active"
                  label={<Trans>Do you want plan this visible to users?</Trans>}
                />
              </div>

              <div className="btn-group">
                {values.id && (
                  <DeleteResourceButton
                    isSubmitting={isSubmitting}
                    onDelete={onDelete}
                    url={`/plans/${values.id}/`}
                  />
                )}

                <Button colour={`blue`} disabled={isSubmitting}>
                  {values.id && isSubmitting && <Trans>Updating Payment Plan</Trans>}
                  {values.id && !isSubmitting && <Trans>Update Payment Plan</Trans>}
                  {!values.id && isSubmitting && <Trans>Creating Payment Plan</Trans>}
                  {!values.id && !isSubmitting && <Trans>Create Payment Plan</Trans>}
                </Button>
              </div>
            </Form>
          </div>
        )}
      </Formik>
    )}
  </ApiConsumer>
);

export default PaymentPlanForm;
