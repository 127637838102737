import React from "react";
import { Trans } from "@lingui/macro";
import moment from "moment";
import { Table } from "@iforwms/react-components";
import PageHeader from "../components/PageHeader";
import PageBody from "../components/PageBody";
import { Link } from "react-router-dom";
import { destroy, get } from "../api";
import history from "../components/history";

class SubscriptionShow extends React.Component {
  render() {
    return (
      <div>
        <PageHeader title={<Trans>Subscription Details</Trans>} />

        <PageBody>
          <div className="p-4 md:p-8">Coming soon!</div>
        </PageBody>
      </div>
    );
  }
}

export default SubscriptionShow;
