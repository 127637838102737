import React, { Component } from "react";
import { Trans } from "@lingui/macro";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import PageHeader from "../components/PageHeader";
import PageBody from "../components/PageBody";
import { ApiContext } from "../contexts/ApiContext";
import { get, post } from "../api";
import { parseErrorMessage } from "@iforwms/helpers-js";
import { Button, LoadingWrapper } from "@iforwms/react-components";

class Menus extends Component {
  static contextType = ApiContext;
  state = {
    menus: [],
    newMenuName: "",
    error: null,
    isLoading: false
  };

  componentDidMount() {
    this._isMounted = true;
    this.fetchMenus();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  safeSetState = (...args) => {
    this._isMounted && this.setState(...args);
  };

  fetchMenus = () => {
    this.safeSetState({ isLoading: true, error: null });
    this.context
      .callApi(() => get("/menus"))
      .then(({ data }) => {
        this.safeSetState({
          isLoading: false,
          menus: data.data
        });
      })
      .catch(error => {
        toast.error(parseErrorMessage(error, <Trans>Failed to fetch items.</Trans>));
        this.safeSetState({ isLoading: false, error });
      });
  };

  createMenu = () => {
    const { newMenuName } = this.state;

    if (!newMenuName) {
      return toast.error(<Trans>Name cannot be empty.</Trans>);
    }
    this.safeSetState({ isCreating: true, error: null });

    this.context
      .callApi(() => post("/menus", { name: newMenuName }))
      .then(({ data: { data } }) => {
        this.safeSetState({
          isCreating: false,
          menus: this.state.menus.concat(data),
          newMenuName: ""
        });
        toast.success(<Trans>Menu created!</Trans>);
      })
      .catch(error => {
        toast.error(parseErrorMessage(error, <Trans>Failed to create menu.</Trans>));
        this.safeSetState({ isCreating: false, error });
      });
  };

  render() {
    const { menus, newMenuName, isLoading, isCreating } = this.state;

    return (
      <div>
        <PageHeader title={<Trans>Menus</Trans>} body={<Trans>Manage website menus.</Trans>} />
        <PageBody>
          <div className="p-4 mb-4 md:p-4 md:p-8 lg:flex">
            <LoadingWrapper isLoading={isLoading}>
              <table className="w-full max-w-md table-striped">
                <tbody>
                  {!menus.length && (
                    <tr>
                      <td colSpan={2}>
                        <Trans>No menus yet, create one!</Trans>
                      </td>
                    </tr>
                  )}

                  <tr>
                    <td>
                      <input
                        disabled={isCreating}
                        onChange={e => this.setState({ newMenuName: e.target.value })}
                        className="text-sm form-input"
                        value={newMenuName}
                        type="text"
                        placeholder="Enter a menu name"
                      />
                    </td>
                    <td className="p-1 text-right whitespace-no-wrap">
                      <Button
                        colour={`blue`}
                        size={`sm`}
                        disabled={isCreating}
                        onClick={this.createMenu}
                        text={<Trans>Create</Trans>}
                      />
                    </td>
                  </tr>

                  <tr>
                    <td className="bg-white" colSpan={2}>
                      &nbsp;
                    </td>
                  </tr>

                  {menus.map(item => (
                    <tr key={item.id} className="border-b">
                      <td className="p-1">{item.name}</td>
                      <td className="p-1 text-right whitespace-no-wrap">
                        <Button
                          RouterLink={Link}
                          size={`xs`}
                          classes="mr-2 text-center"
                          url={`/menus/${item.slug}`}
                          text={<Trans>Edit</Trans>}
                        />
                        {/* <DeleteResourceButton */}
                        {/*     onDelete={() => this.handleDelete(item.slug)} */}
                        {/*     classes="btn btn-sm btn-red" */}
                        {/*     url={`menus/${item.slug}`} */}
                        {/* /> */}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </LoadingWrapper>
          </div>
        </PageBody>
      </div>
    );
  }
}

export default Menus;
