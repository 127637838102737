import React, { Component } from "react";
import { Trans } from "@lingui/macro";
import PageBody from "../components/PageBody";
import PageHeader from "../components/PageHeader";
import { get } from "../api";
import { ApiContext } from "../contexts/ApiContext";
import CustomerDetails from "../components/CustomerDetails";

const ids = [19539, 15112, 9832, 4233, 18882, 19665, 16064, 7974, 19100, 8236, 9795, 3032, 6244, 10764];

class ClassOverview extends Component {
  static contextType = ApiContext;
  state = {
    isLoading: false,
    error: null,
    users: []
  };

  componentDidMount() {
    this._isMounted = true;
    this.fetchUsers();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  safeSetState = (...args) => {
    this._isMounted && this.setState(...args);
  };

  fetchUsers = () => {
    this.safeSetState({
      isLoading: true,
      subscribersError: null
    });
    this.context
      .callApi(() => get(`/users/many?ids=${ids.join(",")}`))
      .then(({ data }) => {
        this.safeSetState({
          isLoading: false,
          users: data.data
        });
      })
      .catch(error => {
        this.safeSetState({
          isLoading: false,
          error
        });
      });
  };

  render() {
    const { users } = this.state;

    return (
      <div>
        <PageHeader title={<Trans>Class Overview</Trans>} />
        <PageBody>
          <div className="p-4 md:p-8">
            {users.map(user => (
              <div className="pb-8 mb-8 border-b border-teal-500">
                <CustomerDetails key={user.id} user={user} />
              </div>
            ))}
          </div>
        </PageBody>
      </div>
    );
  }
}

export default ClassOverview;
