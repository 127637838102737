import React from "react";
import { Trans } from "@lingui/macro";
import { version } from "../../package.json";
import { Link, NavLink } from "react-router-dom";
// import HeaderNotice from "./HeaderNotice";
import FeedbackTrackerMenu from "./FeedbackTrackerMenu";
import { ApiConsumer } from "../contexts/ApiContext";
import * as Permissions from "../permissions.js";
import { Button } from "@iforwms/react-components";

const Header = ({ switchLanguage, currentLanguage, toggleMenu }) => {
  return (
    <ApiConsumer>
      {({ user }) => (
        <nav className="main-nav" style={{ backgroundColor: process.env.REACT_APP_HEADER_BG }}>
          <div className="mb-8">
            <div className="flex items-center">
              <div
                onClick={toggleMenu}
                style={{ minWidth: 32, width: 32, height: 32 }}
                className="fixed top-0 left-0 m-2 overflow-hidden rounded cursor-pointer">
                <img
                  width="32"
                  height="32"
                  src="/apple-touch-icon.png"
                  alt="Domino Education Logo"
                />
              </div>
              <div className="flex items-center ml-10">
                <h2 className="text-base font-hairline text-white truncate">
                  {process.env.REACT_APP_SITE_NAME}
                </h2>
              </div>
            </div>
          </div>

          {user && (
            <div className="overflow-y-auto custom-scroll">
              {(user.permissions.includes(Permissions.VIEW_ANALYTICS) ||
                user.permissions.includes(Permissions.VIEW_PAYMENTS) ||
                user.permissions.includes(Permissions.VIEW_SUBSCRIPTIONS) ||
                user.permissions.includes(Permissions.EDIT_COUPON) ||
                user.permissions.includes(Permissions.VIEW_USERS)) && (
                <div className="nav-group">
                  <label className="nav-label">
                    <Trans>Analytics</Trans>
                  </label>
                  {user.permissions.includes(Permissions.EDIT_COUPON) && (
                    <Button
                      colour={`white`}
                      classes={`hover:bg-blue-800`}
                      muted={true}
                      RouterLink={Link}
                      onClick={toggleMenu}
                      url="/coupons"
                      text={<Trans>Coupons</Trans>}
                    />
                  )}
                </div>
              )}

              {user.permissions.includes(Permissions.VIEW_FEEDBACK) && (
                <div className="nav-group">
                  <label className="nav-label">
                    <Trans>Feedback</Trans>
                  </label>
                  <FeedbackTrackerMenu toggleMenu={toggleMenu} />
                </div>
              )}

              {(user.permissions.includes(Permissions.EDIT_WEBSITE) ||
                user.permissions.includes(Permissions.EDIT_BLOG)) && (
                <div className="nav-group">
                  <label className="nav-label">
                    <Trans>Website</Trans>
                  </label>
                  {user.permissions.includes(Permissions.EDIT_NOTIFICATIONS) && (
                    <Button
                      colour={`white`}
                      classes={`hover:bg-blue-800`}
                      muted={true}
                      RouterLink={Link}
                      onClick={toggleMenu}
                      url="/notifications"
                      text={<Trans>Notifications</Trans>}
                    />
                  )}
                  {user.permissions.includes(Permissions.EDIT_WEBSITE) && (
                    <>
                      {/* <Button */}
                      {/*   colour={`white`} */}
                      {/*   classes={`hover:bg-blue-800`} */}
                      {/*   muted={true} */}
                      {/*   RouterLink={Link} */}
                      {/*   onClick={toggleMenu} */}
                      {/*   url="/pages" */}
                      {/*   text={<Trans>Static Pages</Trans>} */}
                      {/* /> */}
                      {/* <Button */}
                      {/*   colour={`white`} */}
                      {/*   classes={`hover:bg-blue-800`} */}
                      {/*   muted={true} */}
                      {/*   RouterLink={Link} */}
                      {/*   onClick={toggleMenu} */}
                      {/*   url="/faq" */}
                      {/*   text={<Trans>FAQ</Trans>} */}
                      {/* /> */}
                      {/* <Button */}
                      {/*   colour={`white`} */}
                      {/*   classes={`hover:bg-blue-800`} */}
                      {/*   muted={true} */}
                      {/*   RouterLink={Link} */}
                      {/*   onClick={toggleMenu} */}
                      {/*   url="/menus" */}
                      {/*   text={<Trans>Site Menus</Trans>} */}
                      {/* /> */}
                    </>
                  )}
                  {/* {user.permissions.includes(Permissions.EDIT_BLOG) && ( */}
                  {/*   <Button */}
                  {/*     colour={`white`} */}
                  {/*     classes={`hover:bg-blue-800`} */}
                  {/*     muted={true} */}
                  {/*     RouterLink={Link} */}
                  {/*     onClick={toggleMenu} */}
                  {/*     url="/posts" */}
                  {/*     text={<Trans>Blog Posts</Trans>} */}
                  {/*   /> */}
                  {/* )} */}
                </div>
              )}

              {(user.permissions.includes(Permissions.EDIT_COURSE) ||
                user.permissions.includes(Permissions.VIEW_DEGREES)) && (
                <div className="nav-group">
                  <label className="nav-label">
                    <Trans>Course Management</Trans>
                  </label>

                  <Button
                    colour={`white`}
                    classes={`hover:bg-blue-800`}
                    muted={true}
                    RouterLink={Link}
                    onClick={toggleMenu}
                    url="https://my.dominochinese.com/degrees"
                    text={<Trans>Degrees</Trans>}
                  />

                  {user.permissions.includes(Permissions.EDIT_COURSE) && (
                    <>
                      <Button
                        colour={`white`}
                        classes={`hover:bg-blue-800`}
                        muted={true}
                        RouterLink={Link}
                        onClick={toggleMenu}
                        url="/courses"
                        text={<Trans>## Course Edit</Trans>}
                      />

                      <Button
                        colour={`white`}
                        classes={`hover:bg-blue-800`}
                        muted={true}
                        RouterLink={Link}
                        onClick={toggleMenu}
                        url="/videos"
                        text={<Trans>Videos</Trans>}
                      />

                      <Button
                        colour={`white`}
                        classes={`hover:bg-blue-800`}
                        muted={true}
                        RouterLink={Link}
                        onClick={toggleMenu}
                        url="/dialogs"
                        text={<Trans>Dialogs</Trans>}
                      />

                      <Button
                        colour={`white`}
                        classes={`hover:bg-blue-800`}
                        muted={true}
                        RouterLink={Link}
                        onClick={toggleMenu}
                        url="/dictionary"
                        text={<Trans>Dictionary</Trans>}
                      />

                      <Button
                        colour={`white`}
                        classes={`hover:bg-blue-800`}
                        muted={true}
                        RouterLink={Link}
                        onClick={toggleMenu}
                        url="/grammar"
                        text={<Trans>Grammar Points</Trans>}
                      />

                      {/*                   <Button */}
                      {/*                     colour={`white`} */}
                      {/*                     classes={`hover:bg-blue-800`} */}
                      {/*                     muted={true} */}
                      {/*                     RouterLink={Link} */}
                      {/*                     onClick={toggleMenu} */}
                      {/*                     url="/documents" */}
                      {/*                     text={<Trans>## Documents</Trans>} */}
                      {/*                   /> */}

                      <Button
                        colour={`white`}
                        classes={`hover:bg-blue-800`}
                        muted={true}
                        RouterLink={Link}
                        onClick={toggleMenu}
                        url="/certificate"
                        text={<Trans>## Certificate</Trans>}
                      />

                      <Button
                        colour={`white`}
                        classes={`hover:bg-blue-800`}
                        muted={true}
                        RouterLink={Link}
                        onClick={toggleMenu}
                        url="/missing-info"
                        text={<Trans>Missing Info</Trans>}
                      />
                    </>
                  )}
                </div>
              )}

              {(user.permissions.includes(Permissions.EDIT_SUBSCRIPTION) ||
                user.permissions.includes(Permissions.EDIT_COUPON)) && (
                <div className="nav-group">
                  <label className="nav-label">
                    <Trans>Subscriptions</Trans>
                  </label>
                  {user.permissions.includes(Permissions.EDIT_SUBSCRIPTION) && (
                    <>
                      <Button
                        colour={`white`}
                        classes={`hover:bg-blue-800`}
                        muted={true}
                        RouterLink={Link}
                        onClick={toggleMenu}
                        url="/payment-gateways"
                        text={<Trans>## Payment Gateways</Trans>}
                      />
                      <Button
                        colour={`white`}
                        classes={`hover:bg-blue-800`}
                        muted={true}
                        RouterLink={Link}
                        onClick={toggleMenu}
                        url="/plans"
                        text={<Trans>## Plans</Trans>}
                      />
                    </>
                  )}
                </div>
              )}

              {user.permissions.includes(Permissions.VIEW_ADMIN_TOOLS) && (
                <div className="nav-group">
                  <label className="nav-label">
                    <Trans>Tools</Trans>
                  </label>
                  <Button
                    colour={`white`}
                    classes={`hover:bg-blue-800`}
                    muted={true}
                    RouterLink={Link}
                    onClick={toggleMenu}
                    url="/receipt-parser"
                    text={<Trans>Receipt Parser</Trans>}
                  />
                  <Button
                    colour={`white`}
                    classes={`hover:bg-blue-800`}
                    muted={true}
                    RouterLink={Link}
                    onClick={toggleMenu}
                    url="/redis"
                    text={<Trans>Redis Queue</Trans>}
                  />
                </div>
              )}
            </div>
          )}

          <div className="pt-4 mt-auto">
            <div className="mb-4">
              {user ? (
                <a
                  className={`text-white hover:text-white hover:bg-blue-800 text-md inline-block rounded p-2 w-full disabled:cursor-not-allowed disabled:opacity-50 border-none bg-transparent border border-white-500 hover:border-white-700 block hover:bg-blue-800 text-left`}
                  href={`${process.env.REACT_APP_AUTH_URL}/logout`}>
                  <Trans>Logout</Trans>
                </a>
              ) : (
                <Button
                  colour={`white`}
                  classes={`hover:bg-blue-800`}
                  muted={true}
                  RouterLink={Link}
                  onClick={toggleMenu}
                  url="/login"
                  text={<Trans>Log In</Trans>}
                />
              )}
            </div>

            <div className="">
              <div className="flex items-center justify-between">
                <Button
                  colour={`white`}
                  classes={`hover:bg-blue-800 ${currentLanguage === "en" ? "bg-blue-900" : ""}`}
                  muted={true}
                  onClick={() => switchLanguage("en")}
                  text={`EN`}
                />
                <Button
                  colour={`white`}
                  classes={`ml-2 hover:bg-blue-800 ${
                    currentLanguage === "zh" ? "bg-blue-900" : ""
                  }`}
                  muted={true}
                  onClick={() => switchLanguage("zh")}
                  text={`中文`}
                />
              </div>

              <div className="mt-4 text-right text-white text-xxs">Version {version}</div>
            </div>
          </div>
        </nav>
      )}
    </ApiConsumer>
  );
};

export default Header;
