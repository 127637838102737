import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Trans } from "@lingui/macro";
import { toast } from "react-toastify";
import parseErrorMessage from "helpers-js/parseErrorMessage";
import { transformMyRestApiErrorsToAnObject } from "../../utils";
import DeleteResourceButton from "./DeleteResourceButton";
import { ApiConsumer } from "../../contexts/ApiContext";
import { updatePaymentGateway, createPaymentGateway } from "../../api";
import { Button } from "@iforwms/react-components";

const PaymentGatewaySchema = Yup.object().shape({
  name: Yup.string()
    .min(2, <Trans>Please enter a longer name (min: 2 characters)</Trans>)
    .max(50, <Trans>Please enter a shorter name (max: 50 characters)</Trans>)
    .required(<Trans>Please enter a name.</Trans>),
  gateway_type: Yup.string()
    .oneOf(["web", "android", "ios", "manual"])
    .required(<Trans>Please enter a gateway type.</Trans>)
});

const PaymentGatewayForm = ({ initialValues, onSuccess, onDelete }) => (
  <ApiConsumer>
    {({ callApi }) => (
      <Formik
        initialValues={{
          id: undefined,
          name: "",
          gateway_type: "",
          ...initialValues
        }}
        enableReinitialize
        validationSchema={PaymentGatewaySchema}
        onSubmit={(values, { setSubmitting, setErrors, setStatus, resetForm }) => {
          callApi(() =>
            values.id ? updatePaymentGateway(values.id, values) : createPaymentGateway(values)
          )
            .then(res => {
              setSubmitting(false);

              if (onSuccess) {
                onSuccess(res.data);
              }

              toast.success(
                values.id ? (
                  <Trans>Payment Gateway Updated!</Trans>
                ) : (
                  <Trans>Payment Gateway created!</Trans>
                )
              );
            })
            .catch(error => {
              setSubmitting(false);
              setErrors(transformMyRestApiErrorsToAnObject(error));
              toast.error(
                values.id
                  ? parseErrorMessage(error, <Trans>Failed to update Payment Gateway.</Trans>)
                  : parseErrorMessage(
                      error,
                      <Trans>Failed to update create Payment Gateway.</Trans>
                    )
              );
            });
        }}>
        {({ isSubmitting, errors, status, values }) => (
          <div className="form-container">
            <h2 className="form-header">
              {values.id ? values.name : <Trans>Create a Brand New Payment Gateway!</Trans>}
            </h2>
            <Form>
              <div className="form-input-group">
                <label className="form-label">
                  <Trans>Name</Trans>
                </label>
                <Field
                  className="form-input"
                  type="text"
                  autoFocus
                  autoComplete="off"
                  name="name"
                  disabled={isSubmitting}
                />
                <ErrorMessage name="name">
                  {errorMessage => <div className="form-input-error">{errorMessage}</div>}
                </ErrorMessage>
              </div>
              <div className="form-input-group">
                <label className="form-label">
                  <Trans>Type</Trans>
                </label>
                <Field
                  className="form-input"
                  type="text"
                  autoComplete="off"
                  name="gateway_type"
                  disabled={isSubmitting}
                />
                <ErrorMessage name="gateway_type">
                  {errorMessage => <div className="form-input-error">{errorMessage}</div>}
                </ErrorMessage>
              </div>
              <div className="btn-group">
                {values.id && (
                  <DeleteResourceButton
                    isSubmitting={isSubmitting}
                    onDelete={onDelete}
                    url={`/payment-gateways/${values.id}/`}
                  />
                )}

                <Button colour={`blue`} disabled={isSubmitting}>
                  {values.id && isSubmitting && <Trans>Updating Payment Gateway</Trans>}
                  {values.id && !isSubmitting && <Trans>Update Payment Gateway</Trans>}
                  {!values.id && isSubmitting && <Trans>Creating Payment Gateway</Trans>}
                  {!values.id && !isSubmitting && <Trans>Create Payment Gateway</Trans>}
                </Button>
              </div>
            </Form>
          </div>
        )}
      </Formik>
    )}
  </ApiConsumer>
);

export default PaymentGatewayForm;
