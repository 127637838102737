import React, { Component, Fragment } from "react";
import { Trans } from "@lingui/macro";
import { toast } from "react-toastify";
import PageBody from "../components/PageBody";
import PageHeader from "../components/PageHeader";
import ReloadButton from "../components/ReloadButton";
import CourseItemWithVideo from "../components/forms/CourseItemWithVideo";
import CreateCourseItemForm from "../components/forms/course/CreateCourseItemForm";
import { ApiContext } from "../contexts/ApiContext";
import { getResource } from "../api";
import parseErrorMessage from "helpers-js/parseErrorMessage";
import { LoadingWrapper } from "@iforwms/react-components";

class ChapterEdit extends Component {
  static contextType = ApiContext;
  state = { loading: true, item: undefined, error: undefined, showAttachmentsForm: false };

  componentDidMount() {
    this._isMounted = true;
    this.fetchItem();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleDelete = _ => {
    this.props.history.push(this.props.endpoint);
  };

  handleSucccess = item => {
    this.setState({
      item,
      showAttachmentsForm: false
    });
  };

  handleVideoCreate = item => {
    this.setState({
      item: {
        ...this.state.item,
        videos: [...this.state.item.videos, item]
      },
      showAttachmentsForm: false
    });
  };

  fetchItem = _ => {
    const {
      match: {
        params: { course_id, level_id, chapter_id, lesson_id, video_id }
      },
      endpoint
    } = this.props;

    let id = course_id;

    if (level_id) {
      id = level_id;
    }

    if (chapter_id) {
      id = chapter_id;
    }
    if (lesson_id) {
      id = lesson_id;
    }
    if (video_id) {
      id = video_id;
    }

    const url = `${endpoint}/${id}`;

    this.context
      .callApi(() => getResource(url))
      .then(res => {
        if (this._isMounted) {
          this.setState({
            item: res.data.data,
            loading: false
          });
        }
      })
      .catch(error => {
        if (error.response && error.response.status && error.response.status === 404) {
          toast.error(parseErrorMessage(error, <Trans>Resource not found!</Trans>));
        }

        if (this._isMounted) {
          this.setState({
            error,
            loading: false
          });
        }
      });
  };

  render() {
    const { endpoint, model, match } = this.props;
    const { loading, item, showAttachmentsForm, error } = this.state;

    return (
      <div>
        <PageHeader
          body={`Edit a ${model} here. To add a video, click "Edit Videos" and choose to
                            either create a new video, or search for an existing video.`}
          title={`${model} Edit`}
        />
        <PageBody>
          <div className="p-4 md:p-8 container-narrow">
            <LoadingWrapper message={`Fetching ${model} details...`} isLoading={loading}>
              {error && <ReloadButton />}
              {item && (
                <Fragment>
                  <CourseItemWithVideo
                    match={match}
                    initialValues={item}
                    endpoint={endpoint}
                    model={model}
                    onDelete={this.handleDelete}
                    onSuccess={this.handleSucccess}
                    formVisible={showAttachmentsForm}
                    form={
                      <CreateCourseItemForm
                        model="Video"
                        endpoint="videos"
                        parent_type="chapter"
                        initialValues={item}
                        onSuccess={this.handleVideoCreate}
                        parent_id={item.id}
                        video_id={1}
                      />
                    }
                    hideForm={() => this.setState({ showAttachmentsForm: false })}
                    showForm={() => this.setState({ showAttachmentsForm: true })}
                  />
                </Fragment>
              )}
            </LoadingWrapper>
          </div>
        </PageBody>
      </div>
    );
  }
}

export default ChapterEdit;
