import PropTypes from "prop-types";
import React from "react";
import Button from "./Button";

const PaginationLinks = ({
  isLoading,
  meta,
  links,
  fetchData,
  showCount,
  colour
}) =>
  !isLoading && links && (links.next || links.prev) ? (
    <div className={`flex justify-between items-center`}>
      <Button
        fullWidth={false}
        colour={colour}
        onClick={() => fetchData(links.first)}
        disabled={meta.current_page === 1}
        icon={`stepBackward`}
      />

      <Button
        fullWidth={false}
        classes={`ml-2`}
        colour={colour}
        onClick={() => fetchData(links.prev)}
        disabled={!links.prev}
        icon={`reversePlay`}
      />

      {!isLoading && meta && showCount ? (
        <div className={`flex-1 text-center text-sm text-${colour}-700`}>
          {meta.from}-{meta.to} of {meta.total}
        </div>
      ) : null}

      <Button
        fullWidth={false}
        colour={colour}
        onClick={() => fetchData(links.next)}
        disabled={!links.next}
        icon={`play`}
      />

      <Button
        fullWidth={false}
        classes={`ml-2`}
        colour={colour}
        onClick={() => fetchData(links.last)}
        disabled={meta.current_page === meta.last_page}
        icon={`stepForward`}
      />
    </div>
  ) : null;

PaginationLinks.defaultProps = {
  showCount: true,
  colour: "gray",
  labels: {
    first: "First",
    prev: "Prev",
    next: "Next",
    last: "Last"
  }
};

PaginationLinks.propTypes = {
  isLoading: PropTypes.bool.isRequired,
  showCount: PropTypes.bool.isRequired,
  colour: PropTypes.string.isRequired,
  fetchData: PropTypes.func.isRequired,
  meta: PropTypes.shape({
    from: PropTypes.number,
    current_page: PropTypes.number,
    last_page: PropTypes.number,
    to: PropTypes.number,
    total: PropTypes.number.isRequired
  }).isRequired,
  links: PropTypes.shape({
    first: PropTypes.string,
    last: PropTypes.string,
    next: PropTypes.string,
    prev: PropTypes.string
  }).isRequired,
  labels: PropTypes.shape({
    first: PropTypes.string,
    last: PropTypes.string,
    next: PropTypes.string,
    prev: PropTypes.string
  }).isRequired
};

export default PaginationLinks;
