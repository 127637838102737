import React from "react";
import { Link } from "react-router-dom";
import { Trans } from "@lingui/macro";
import { Button } from "@iforwms/react-components";

const NoMatch = () => (
  <div className="text-4xl p-2 w-full h-screen flex-col flex items-center justify-center bg-red-400">
    <div className="text-left">
      <h1 className="text-sm mb-2 uppercase text-red-600">Error 404</h1>
      <h2 className="text-2xl font-hairline text-white mb-2">
        <Trans>Oops! The page you're looking for doesn't exist.</Trans>
      </h2>
      <p className="text-white text-sm">
        <Trans>
          Click{" "}
          <Button classes="underline" colour={`white`} isLink={true} url="/" RouterLink={Link}>
            here
          </Button>{" "}
          to return to safety!
        </Trans>
      </p>
    </div>
  </div>
);

export default NoMatch;
