import React, { Component } from "react";
import { Trans } from "@lingui/macro";
import PageBody from "../components/PageBody";
import PageHeader from "../components/PageHeader";
import { ApiContext } from "../contexts/ApiContext";
import { post, uploadCertificate } from "../api";
import { Button, MediaUpload } from "@iforwms/react-components";
import { Link } from "react-router-dom";

class Certificate extends Component {
  static contextType = ApiContext;
  state = {
    error: undefined,
    isLoading: true,
    timestamp: null,
    calendar: null,
    isSubmitting: false,
    isUpdating: false
  };

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  safeSetState = (...args) => {
    this._isMounted && this.setState(...args);
  };

  handleFileUpload = item => {
    this.safeSetState({ calendar: item, timestamp: +new Date() });
  };

  render() {
    const { timestamp } = this.state;

    return (
      <div>
        <PageHeader
          title={<Trans>Certificate Template</Trans>}
          body={<Trans>Here you can upload a custom level completion certificate template.</Trans>}
        />
        <PageBody>
          <div className="p-4 md:p-8">
            <div className="mb-8 pb-4 border-b">
              <div className="flex flex-col md:flex-row justify-between items-center">
                <h2 className="text-left w-full text-base mb-2 md:mb-4">
                  <Trans>Upload a New Certificate</Trans>
                </h2>
                <Button
                  fullWidth={false}
                  RouterLink={Link}
                  size={`sm`}
                  url="/certificate-template.jpg"
                  classes="mb-2 whitespace-no-wrap text-center">
                  <Trans>Download Certificate Outline</Trans>
                </Button>
              </div>
              <div className="">
                <MediaUpload
                  post={post}
                  upload={uploadCertificate}
                  onSuccess={this.handleFileUpload}
                  accept="image/jpeg"
                />
                <p className="text-red-400 text-xs">
                  <Trans>Image must be JPEG with dimensions of 1698x1200 px</Trans>
                </p>
              </div>
            </div>

            <div>
              <h2 className="text-base mb-4">
                <Trans>Current Certificate</Trans>
              </h2>
              <div className="border rounded overflow-hidden">
                <img
                  src={`${process.env.REACT_APP_SERVER_BASE_URL}assets/certificate.jpg?${timestamp}`}
                  alt=""
                />
              </div>
            </div>
          </div>
        </PageBody>
      </div>
    );
  }
}

export default Certificate;
