import React from "react";
import debounce from "lodash/debounce";
import PropTypes from "prop-types";
import Input from "./forms/Input";

class AutoComplete extends React.Component {
  state = {
    isLoading: false,
    isSubmitting: false,
    error: null,
    items: [],
    isOpen: false,
    inputValue: this.props.inputValue || "",
    inputId: this.props.inputId || "",
    data: null
  };

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  safeSetState = (...args) => {
    this._isMounted && this.setState(...args);
  };

  setParentState = (valueKey, value) => {
    this.safeSetState({ [valueKey]: value }, this.fetchResults);
  };

  resetState = () => {
    this.safeSetState({
      isLoading: false,
      isSubmitting: false,
      error: null,
      items: [],
      isOpen: false,
      inputValue: "",
      inputId: null,
      data: null
    });
  };

  createNew = () => {
    const { inputValue } = this.state;
    const {
      url,
      updateState,
      itemKey,
      meta,
      post,
      handleSuccess,
      handleError
    } = this.props;

    if (!post) return;

    this.safeSetState({ isSubmitting: true, isOpen: false });

    post(url, { [itemKey]: inputValue, ...meta })
      .then(({ data }) => {
        this.safeSetState({
          isSubmitting: false,
          inputValue: data[itemKey],
          inputId: data.id
        });

        if (handleSuccess) {
          handleSuccess(data);
        }

        if (updateState) {
          updateState(data);
        }
      })
      .catch(error => {
        this.safeSetState({ isSubmitting: false, error });

        if (handleError) {
          handleError(error);
        }
      });
  };

  fetchResults = debounce(async () => {
    const { url, limit, get } = this.props;
    const { inputValue } = this.state;

    if (!inputValue) return;

    const connector = url.includes("?") ? "&" : "?";

    this.safeSetState({ isLoading: true });

    get(`${url}${connector}q=${inputValue}&limit=${limit}`)
      .then(({ data }) => {
        this.safeSetState({
          isLoading: false,
          items: data.data,
          isOpen: true
        });
      })
      .catch(error => {
        this.safeSetState({ isLoading: false, error });
      });
  }, 350);

  selectItem = item => {
    const { handleSelect } = this.props;

    this.safeSetState(
      {
        isOpen: false,
        inputValue: item[this.props.itemKey],
        inputId: item.id,
        data: item
      },
      () => {
        if (handleSelect) {
          handleSelect(item);
        }
      }
    );
  };

  render() {
    const { isLoading, inputValue, items, isOpen, isSubmitting } = this.state;
    const { label, itemKey, readOnly, autoFocus, placeholder } = this.props;

    return (
      <div className="relative">
        <Input
          isLoading={isLoading}
          label={label}
          value={inputValue}
          valueKey={`inputValue`}
          type={`search`}
          autoFocus={autoFocus}
          placeholder={placeholder}
          disabled={isSubmitting}
          setState={this.setParentState}
        />

        {inputValue === "" ? null : (
          <span
            style={{ top: label ? 20 : -5 }}
            className="text-lg right-0 p-2 mr-1 cursor-pointer block absolute"
            onClick={() => this.setState({ inputValue: "", isOpen: false })}>
            &times;
          </span>
        )}

        {isOpen && (
          <div className="transition absolute w-full z-5 border">
            {items && items.length
              ? items.map((item, index) => (
                  <div
                    key={item.id}
                    onClick={() => this.selectItem(item)}
                    className="hover:text-white hover:bg-blue-200 border-b  bg-white p-3 text-xs flex cursor-pointer truncate text-gray-600">
                    {item[itemKey]}
                  </div>
                ))
              : null}

            {inputValue !== "" && items && items.length === 0 && !isLoading ? (
              <div className="hover:text-white hover:bg-blue-200 border-b  bg-white p-3 text-xs flex text-gray">
                No matches for{" "}
                <span className="text-blue ml-1">{inputValue}</span>
              </div>
            ) : null}

            {inputValue !== "" &&
              !readOnly &&
              items &&
              !items.filter(
                item => item[itemKey].toLowerCase() === inputValue.toLowerCase()
              ).length && (
                <div
                  onClick={this.createNew}
                  className="hover:text-white hover:bg-blue-200 border-b  bg-white p-3 text-xs flex cursor-pointer">
                  Click to create{" "}
                  <span className="text-blue ml-1">{inputValue}</span>
                </div>
              )}
          </div>
        )}
      </div>
    );
  }
}

AutoComplete.propTypes = {
  url: PropTypes.string.isRequired,
  readOnly: PropTypes.bool.isRequired,
  get: PropTypes.func.isRequired,
  post: PropTypes.func,
  handleSelect: PropTypes.func,
  autoFocus: PropTypes.bool.isRequired,
  itemKey: PropTypes.string.isRequired,
  limit: PropTypes.number.isRequired
};

AutoComplete.defaultProps = {
  itemKey: "text",
  limit: 5,
  readOnly: false,
  autoFocus: false,
  placeholder: ""
};

export default AutoComplete;
