import React, { Component } from "react";
import { Trans } from "@lingui/macro";
import { toast } from "react-toastify";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import PageHeader from "../components/PageHeader";
import PageBody from "../components/PageBody";
import { ApiContext } from "../contexts/ApiContext";
import { get } from "../api";
import { parseErrorMessage } from "@iforwms/helpers-js";
import MenuLink from "../components/MenuLink";
import { LoadingWrapper } from "@iforwms/react-components";

class Menus extends Component {
  static contextType = ApiContext;
  state = {
    menu: null,
    pages: [],
    error: null,
    isLoading: true,
    isLoadingPages: true
  };

  componentDidMount() {
    this._isMounted = true;
    this.fetchMenu();
    this.fetchPages();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.match.params.slug !== this.props.match.params.slug) {
      this.fetchMenu();
    }
  }

  safeSetState = (...args) => {
    this._isMounted && this.setState(...args);
  };

  fetchMenu = () => {
    this.safeSetState({ isLoading: true, error: null });
    this.context
      .callApi(() => get(`/menus/${this.props.match.params.slug}`))
      .then(({ data: { data } }) => {
        this.safeSetState({
          isLoading: false,
          menu: data
        });
      })
      .catch(error => {
        toast.error(parseErrorMessage(error, <Trans>Failed to fetch item.</Trans>));
        this.safeSetState({ isLoading: false, error });
      });
  };

  fetchPages = () => {
    this.safeSetState({ isLoadingPages: true, error: null });
    this.context
      .callApi(() => get(`/pages`))
      .then(({ data: { data } }) => {
        this.safeSetState({
          isLoadingPages: false,
          pages: data
        });
      })
      .catch(error => {
        toast.error(parseErrorMessage(error, <Trans>Failed to fetch item.</Trans>));
        this.safeSetState({ isLoadingPages: false, error });
      });
  };

  render() {
    const { isLoading, isLoadingPages, error, menu, pages } = this.state;

    return (
      <div>
        <PageHeader title={<Trans>Menu</Trans>} body={<Trans>Edit menu.</Trans>} />
        <PageBody>
          <div className="p-4">
            {/*<MenuLink pages={pages}/>*/}
            <LoadingWrapper isLoading={isLoading || isLoadingPages} error={error}>
              {menu &&
                menu.submenus &&
                menu.submenus.map(sub => (
                  <div key={sub.id} className={`mb-8`}>
                    <h3 className={`px-2 text-indigo-500`}>{sub.name}</h3>
                    {sub.links.map((link, index) => (
                      <MenuLink key={index} link={link} pages={pages} />
                    ))}
                    <MenuLink
                      refresh={this.fetchMenu}
                      pages={pages}
                      link={{
                        menu_id: sub.id,
                        url: "",
                        guest_visible: false,
                        user_visible: false,
                        title: ""
                      }}
                    />
                  </div>
                ))}
            </LoadingWrapper>
          </div>
        </PageBody>
      </div>
    );
  }
}

export default Menus;
