export const VIEW_ADMIN_TOOLS = "VIEW_ADMIN_TOOLS";
export const DOWNLOAD_VIDEOS = "DOWNLOAD_VIDEOS";
export const DOWNLOAD_CERTIFICATE = "DOWNLOAD_CERTIFICATE";

export const VIEW_BASIC_CONTENT = "VIEW_BASIC_CONTENT";
export const VIEW_FLASHCARDS = "VIEW_FLASHCARDS";
export const VIEW_PREMIUM_CONTENT = "VIEW_PREMIUM_CONTENT";
export const VIEW_COURSE_BOOK = "VIEW_COURSE_BOOK";
export const VIEW_TRANSCRIPTIONS = "VIEW_TRANSCRIPTIONS";
export const VIEW_DICTIONARY = "VIEW_DICTIONARY";
export const VIEW_PAYMENTS = "VIEW_PAYMENTS";
export const VIEW_USERS = "VIEW_USERS";
export const VIEW_SUBSCRIPTIONS = "VIEW_SUBSCRIPTIONS";
export const VIEW_CUSTOM_STUDY = "VIEW_CUSTOM_STUDY";
export const VIEW_ANALYTICS = "VIEW_ANALYTICS";
export const VIEW_ADMIN = "VIEW_ADMIN";
export const VIEW_FEEDBACK = "VIEW_FEEDBACK";
export const VIEW_DEGREES = "VIEW_DEGREES";

export const EDIT_COURSE = "EDIT_COURSE";
export const EDIT_NOTIFICATIONS = "EDIT_NOTIFICATIONS";
export const EDIT_WEBSITE = "EDIT_WEBSITE";
export const EDIT_COUPON = "EDIT_COUPON";
export const EDIT_SUBSCRIPTION = "EDIT_SUBSCRIPTION";
export const EDIT_BLOG = "EDIT_BLOG";
export const EDIT_PERMISSIONS = "EDIT_PERMISSIONS";
