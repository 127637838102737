import React, { Component } from "react";
import { Trans } from "@lingui/macro";
import { toast } from "react-toastify";
import PageHeader from "../components/PageHeader";
import PageBody from "../components/PageBody";
import { ApiContext } from "../contexts/ApiContext";
import { destroy, get, post } from "../api";
import parseErrorMessage from "helpers-js/parseErrorMessage";
import moment from "moment";
import { Button, Input, Table } from "@iforwms/react-components";
import { Link } from "react-router-dom";
import history from "../components/history";
import * as Permissions from "../permissions.js";

class DegreeIndex extends Component {
  static contextType = ApiContext;
  state = {
    items: [],
    lesson_start_time: "",
    teacher_email: "",
    start_date: "",
    end_date: "",
    name: "",
    error: null,
    createError: null,
    isLoading: false,
    showCreateForm: false,
    isSubmitting: false
  };

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  safeSetState = (...args) => {
    this._isMounted && this.setState(...args);
  };

  setParentState = (valueKey, value) => {
    this.setState({ [valueKey]: value });
  };

  handleSubmit = e => {
    e.preventDefault();
    const { lesson_start_time, start_date, end_date, teacher_email, name } = this.state;
    this.safeSetState({ isSubmitting: true, error: null });
    this.context
      .callApi(() =>
        post("/degrees", { teacher_email, end_date, lesson_start_time, start_date, name })
      )
      .then(({ data: { data } }) => {
        toast.success(<Trans>Saved!</Trans>);
        this.safeSetState({
          isSubmitting: false,
          lesson_start_time: "",
          start_date: "",
          teacher_email: "",
          end_date: "",
          name: "",
          items: this.state.items.concat(data)
        });
      })
      .catch(error => {
        toast.error(parseErrorMessage(error, <Trans>Failed to save.</Trans>));
        this.safeSetState({ isSubmitting: false, createError: error });
      });
  };

  handleDelete = id => {
    this.setState(prevState => ({
      items: prevState.items.filter(i => i.id !== id)
    }));
  };

  render() {
    const {
      showCreateForm,
      lesson_start_time,
      items,
      start_date,
      end_date,
      name,
      teacher_email,
      isLoading,
      error,
      createError,
      isSubmitting
    } = this.state;

    return (
      <div>
        <PageHeader title={<Trans>Degree Index</Trans>} />
        <PageBody>
          <div className="p-4 md:p-8">
            {this.context.user.permissions.includes(Permissions.EDIT_COURSE) && (
              <>
                <button
                  className="hover:underline"
                  onClick={() =>
                    this.setState(prevState => ({ showCreateForm: !prevState.showCreateForm }))
                  }>
                  Show Create Form
                </button>

                {showCreateForm ? (
                  <div className="border mt-4 p-2 rounded max-w-md">
                    <h2 className="form-header">
                      <Trans>Create New Degree</Trans>
                    </h2>

                    <form method="post" onSubmit={this.handleSubmit}>
                      <div className="mt-2">
                        <Input
                          setState={this.setParentState}
                          error={createError}
                          disabled={isSubmitting}
                          value={name}
                          valueKey={`name`}
                          label={<Trans>Name</Trans>}
                        />
                      </div>

                      <div className="mt-4">
                        <Input
                          setState={this.setParentState}
                          error={createError}
                          disabled={isSubmitting}
                          value={teacher_email}
                          valueKey={`teacher_email`}
                          label={<Trans>Teacher Email</Trans>}
                        />
                      </div>

                      <div className="mt-4">
                        <Input
                          setState={this.setParentState}
                          error={createError}
                          disabled={isSubmitting}
                          value={lesson_start_time}
                          valueKey={`lesson_start_time`}
                          label={<Trans>Lesson Start Time (HH:MM - UTC)</Trans>}
                        />
                      </div>

                      <div className="mt-4">
                        <Input
                          setState={this.setParentState}
                          error={createError}
                          disabled={isSubmitting}
                          value={start_date}
                          valueKey={`start_date`}
                          label={<Trans>start date (YYYY-MM-DD)</Trans>}
                        />
                      </div>

                      <div className="mt-4">
                        <Input
                          setState={this.setParentState}
                          error={createError}
                          disabled={isSubmitting}
                          value={end_date}
                          valueKey={`end_date`}
                          label={<Trans>end date (YYYY-MM-DD)</Trans>}
                        />
                      </div>

                      <Button
                        disabled={isSubmitting}
                        colour={`blue`}
                        classes="mt-4"
                        text={<Trans>Create</Trans>}
                      />
                    </form>
                  </div>
                ) : null}
              </>
            )}

            <div className="mt-6">
              <div className="mt-4 mx-auto">
                <Table
                  filters={[{ label: "Per Page", valueKey: "limit", options: [20, 50, 100] }]}
                  history={history}
                  search={this.props.location.search}
                  get={get}
                  destroy={destroy}
                  title={<Trans>Degree Index</Trans>}
                  size={`sm`}
                  headers={[
                    { label: "name", valueKey: "name" },
                    { label: "teacher", valueKey: "teacher_name" },
                    { label: "Student Count", valueKey: "student_count" },
                    { label: "lesson start time (HH:MM - UTC)", valueKey: "lesson_start_time" },
                    { label: "start date", valueKey: "start_date" },
                    { label: "end date", valueKey: "end_date" }
                  ]}
                  actions={["VIEW"]}
                  moment={moment}
                  RouterLink={Link}
                  endpoint={`/degrees`}
                />
              </div>
            </div>
          </div>
        </PageBody>
      </div>
    );
  }
}

export default DegreeIndex;
