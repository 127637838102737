import React from "react";
import { Trans } from "@lingui/macro";

const LandingPage = () => (
    <div className="text-4xl p-2 w-full h-screen flex-col flex items-center justify-center bg-blue-500">
        <div className="text-left">
            <h1 className="text-sm mb-2 uppercase text-blue-700">Domino Admin</h1>
            <h2 className="text-2xl font-hairline text-white mb-2">
                <Trans>Make yourself at home!</Trans>
            </h2>
        </div>
    </div>
);

export default LandingPage;
