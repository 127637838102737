import React from "react";
import PropTypes from "prop-types";
import { Trans } from "@lingui/macro";
import { Link } from "react-router-dom";
import { Button } from "@iforwms/react-components";

const BackButton = ({ url }) => (
  <Button fullWidth={false} colour="teal" size="sm" RouterLink={Link} url={url}>
    <Trans>Back</Trans>
  </Button>
);

BackButton.propTypes = {
  url: PropTypes.string.isRequired
};

export default BackButton;
