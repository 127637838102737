import PropTypes from "prop-types";
import React from "react";

export const calculateLevelColor = (language_level, showLevel) => {
  if (!showLevel) {
    return "transparent";
  }

  switch (language_level) {
    case 1:
      return "red-500";
    case 2:
      return "green-500";
    case 3:
      return "orange-500";
    case 4:
      return "blue-500";
    case 5:
      return "indigo-500";
    case 6:
      return "brown-500";
    default:
      return "gray-500";
  }
};

export const toneColors = ["gray", "yellow", "green", "blue", "red"];

const DialogWord = ({ word, setWord, showPhonetic, showLevel }) => (
  <div
    className={`whitespace-no-wrap mr-1 cursor-pointer`}
      onClick={setWord ? () => setWord(word): null}>
    {showPhonetic ? (
      <div style={{ minHeight: 18 }} className={`text-xs mr-1 text-center`}>
        {word.characters.map((char, index) => (
          <span
            key={`${index}-${char.word_id}-${word.id}`}
            className={`text-${toneColors[char.tone ? char.tone : 0]}-500`}>
            {char.pinyin_unicode}
          </span>
        ))}
      </div>
    ) : null}

    {word.characters.map((char, index) => (
      <span
        key={`${index}-${char.word_id}-${word.id}`}
        className={`border-b-2 text-center whitespace-no-wrap border-${calculateLevelColor(
          word.language_level,
          showLevel
        )} text-${toneColors[char.tone ? char.tone : 0]}-500`}>
        {char.text}
      </span>
    ))}

    {/*<div>{word.audio_src}</div>*/}
  </div>
);

DialogWord.propTypes = {
  setWord: PropTypes.func.isRequired,
  showPhonetic: PropTypes.bool.isRequired,
  showLevel: PropTypes.bool.isRequired,
  word: PropTypes.shape({
    id: PropTypes.number.isRequired,
    characters: PropTypes.array,
    // text: PropTypes.string.isRequired,
    // phonetic: PropTypes.string.isRequired,
    language_level: PropTypes.number
  })
};

export default DialogWord;
