import React, { Component } from "react";
import List from "../components/List";
import PrivateRoute from "../components/PrivateRoute";
import { Trans } from "@lingui/macro";
import CreateCourseItemForm from "../components/forms/course/CreateCourseItemForm";
import PageHeader from "../components/PageHeader";
import PageBody from "../components/PageBody";

class CourseManagement extends Component {
  render() {
    return (
      <div className="static">
        <PageHeader title={<Trans>Course Management</Trans>} />
        <PageBody>
          <div className="flex px-4 overflow-x-auto">
            <List
              title={<Trans>Course Index</Trans>}
              endpoint="courses"
              form={<CreateCourseItemForm />}
            />

            <PrivateRoute
              path={`/courses/:course_id`}
              render={props => (
                <List
                  title={<Trans>Level Index</Trans>}
                  endpoint="levels"
                  form={<CreateCourseItemForm parent_id={props.match.params.course_id} />}
                  {...props}
                />
              )}
            />

            <PrivateRoute
              path={`/courses/:course_id/levels/:level_id`}
              render={props => (
                <List
                  title={<Trans>Chapter Index</Trans>}
                  form={<CreateCourseItemForm parent_id={props.match.params.level_id} />}
                  endpoint="chapters"
                  {...props}
                />
              )}
            />

            <PrivateRoute
              path={`/courses/:course_id/levels/:level_id/chapters/:chapter_id`}
              render={props => (
                <List
                  title={<Trans>Lesson Index</Trans>}
                  form={<CreateCourseItemForm parent_id={props.match.params.chapter_id} />}
                  endpoint="lessons"
                  {...props}
                />
              )}
            />

            <PrivateRoute
              path={`/courses/:course_id/levels/:level_id/chapters/:chapter_id/lessons/:lesson_id`}
              render={props => (
                <List
                  title={<Trans>Video Index</Trans>}
                  form={
                    <CreateCourseItemForm
                      parent_id={props.match.params.lesson_id}
                      parent_type="lesson"
                    />
                  }
                  endpoint="videos"
                  {...props}
                />
              )}
            />
          </div>
        </PageBody>
      </div>
    );
  }
}

export default CourseManagement;
