import React, { Component } from "react";
import { Trans } from "@lingui/macro";
import PageHeader from "../components/PageHeader";
import { AutoComplete, Panel } from "@iforwms/react-components";
import PageBody from "../components/PageBody";
import { get } from "../api";

class VideoIndex extends Component {
  state = {
    video_id: this.props.match.params.video_id
  };

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  safeSetState = (...args) => {
    this._isMounted && this.setState(...args);
  };

  goToVideo = id => {
    this.props.history.push(`/videos/${id}`);
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.match.params.video_id !== this.props.match.params.video_id) {
      this.safeSetState({
        video_id: this.props.match.params.video_id
      });
    }
  }

  handleSelect = item => {
    this.goToVideo(item.id);
  };

  render() {
    return (
      <div>
        <PageHeader title={<Trans>Video Index</Trans>} />
        <PageBody>
          <div className="p-4 max-w-2xl">
            <Panel
              icon={`search`}
              colour={`blue`}
              overflow={false}
              title={<Trans>Search Videos</Trans>}>
              <AutoComplete
                get={get}
                itemKey="name"
                handleSelect={this.handleSelect}
                readOnly={true}
                url="videos"
              />
            </Panel>
          </div>
        </PageBody>
      </div>
    );
  }
}

export default VideoIndex;
