import React from "react";
import PropTypes from "prop-types";
import Debug from "./Debug";
import Spinner from "./Spinner";
import Button from "./forms/Button";
import { parseErrorMessage } from "@iforwms/helpers-js";
import Panel from "./forms/Panel";

const LoadingWrapper = ({
  isLoading,
  error,
  errorChildren,
  children,
  minHeight,
  retry,
  spinner,
  spinnerColour,
  padding,
  retryText
}) => {
  if (isLoading) {
    return (
      <div
        className={`flex w-full justify-center items-center p-${padding || 4}`}
        style={minHeight ? { minHeight } : {}}>
        <Spinner name={spinner} color={spinnerColour} />
      </div>
    );
  }

  if (error) {
    return errorChildren ? (
      errorChildren
    ) : (
      <div className={`max-w-xl mx-auto m-4`}>
        <Panel background={`red`} title={`Error`} colour={`red`} icon={`alert`}>
          <h2 className="text-lg font-hairline">{parseErrorMessage(error)}</h2>

          {retry ? (
            <div className="mt-4">
              <Button onClick={retry} colour={`red`} text={retryText} />
            </div>
          ) : null}

          {process.env.NODE_ENV === "development" ? (
            <Debug>{error}</Debug>
          ) : null}
        </Panel>
      </div>
    );
  }

  return children || null;
};

LoadingWrapper.propTypes = {
  padding: PropTypes.number,
  spinner: PropTypes.string.isRequired,
  spinnerColour: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  error: PropTypes.object,
  retryText: PropTypes.string.isRequired,
  retry: PropTypes.func,
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  errorChildren: PropTypes.oneOfType([PropTypes.object, PropTypes.array])
};

LoadingWrapper.defaultProps = {
  spinner: "cube-grid",
  padding: 4,
  spinnerColour: "#fc0b27",
  retryText: "Retry"
};

export default LoadingWrapper;
