import React from "react";
import { Trans } from "@lingui/macro";
import ResourceIndex from "./ResourceIndex";

const Payments = props => (
    <ResourceIndex
        endpoint="payments"
        title={<Trans>Payments</Trans>}
        body={<Trans>Here's where you can manage all payments.</Trans>}
        search={props.location.search}
        actions={["VIEW_USER"]}
        headers={[<Trans>Email</Trans>, <Trans>Amount (HKD)</Trans>, <Trans>Date</Trans>]}
        data={["email", "amount", "created_at"]}
    />
);

export default Payments;
