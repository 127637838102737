import React, { Component } from "react";
import PropTypes from "prop-types";
import Input from "./Input";
import Panel from "./Panel";

class MediaUpload extends Component {
  state = {
    progress: 0,
    file: null,
    error: null,
    isSubmitting: false
  };

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  safeSetState = (...args) => {
    this._isMounted && this.setState(...args);
  };

  handleFileChange = e => {
    e.persist();
    if (!e.target.files) return;

    const { onSuccess, onError, endpoint, post } = this.props;

    console.debug(
      "[MediaUpload] Preparing to upload image.",
      e.target,
      this.props
    );

    const file = e.target.files[0];
    this.safeSetState({ file });

    let data = new FormData();
    data.append("file", file);
    data.append("name", file.name);

    this.safeSetState({ isSubmitting: true });

    post(endpoint, data, event => {
      this.safeSetState({
        progress: Math.round((100 * event.loaded) / event.total)
      });
    })
      .then(res => {
        console.debug("[MediaUpload] Image upload success!");
        this.safeSetState({ isSubmitting: false });
        e.target.value = null;

        if (onSuccess) {
          console.debug("[MediaUpload] Calling onSuccess()", file, res.data);
          onSuccess(file, res.data);
        }
      })
      .catch(error => {
        e.target.value = null;
        this.safeSetState({ isSubmitting: false, error });

        if (onError) {
          onError(error);
        }
      });
  };

  render() {
    const { isSubmitting, progress } = this.state;
    const { accept, label, classes, error, post, description } = this.props;

    if (!post) {
      return (
        <Panel
          icon={`alert`}
          title={`Error: No upload handler found.`}
          colour={`red`}
        />
      );
    }

    return (
      <div>
        {!isSubmitting ? (
          <Input
            label={label}
            error={error}
            description={description}
            setState={e => this.handleFileChange(e)}
            disabled={isSubmitting}
            type="file"
            accept={accept}
            classes={classes}
          />
        ) : (
          <Panel
            isAlert={true}
            size={`base`}
            colour={`orange`}
            title={`Uploading media ${progress}%...`}
          />
        )}

        {progress > 99 ? (
          <Panel
            isAlert={true}
            size={`base`}
            colour={`green`}
            title={`Upload Successful`}
          />
        ) : null}
      </div>
    );
  }
}

MediaUpload.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  error: PropTypes.any,
  classes: PropTypes.string,
  onSuccess: PropTypes.func.isRequired,
  onError: PropTypes.func.isRequired,
  post: PropTypes.func.isRequired,
  accept: PropTypes.string.isRequired,
  endpoint: PropTypes.string.isRequired
};

MediaUpload.defaultProps = {
  endpoint: "/media",
  accept: "*"
};

export default MediaUpload;
