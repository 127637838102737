import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Trans } from "@lingui/macro";
import { toast } from "react-toastify";
import parseErrorMessage from "helpers-js/parseErrorMessage";
import { transformMyRestApiErrorsToAnObject } from "../../../utils";
import { ApiConsumer } from "../../../contexts/ApiContext";
import { createResource, post } from "../../../api";
import { Button } from "@iforwms/react-components";

const CourseItemSchema = Yup.object().shape({
  name: Yup.string()
    .min(2, <Trans>Please enter a longer name (min: 2 characters)</Trans>)
    .max(50, <Trans>Please enter a shorter name (max: 50 characters)</Trans>)
    .required(<Trans>Please enter a name.</Trans>)
});

const CreateCourseItemForm = ({ onSuccess, endpoint, model, parent_id, parent_type }) => (
  <ApiConsumer>
    {({ callApi }) => (
      <Formik
        initialValues={{
          name: ""
        }}
        validationSchema={CourseItemSchema}
        onSubmit={(values, { setSubmitting, setErrors, setStatus, resetForm }) => {
          callApi(() => post("/" + endpoint, { ...values, parent_id, parent_type }))
            .then(res => {
              setSubmitting(false);
              onSuccess(res.data.data);
              toast.success(`${model} created!`);
            })
            .catch(error => {
              setSubmitting(false);
              setErrors(transformMyRestApiErrorsToAnObject(error));
              const errorMsg =
                error.response && error.response.status && error.response.status === 400 ? (
                  <Trans>That name is already taken, please choose another.</Trans>
                ) : (
                  `Failed to create ${model}.`
                );
              toast.error(parseErrorMessage(error, errorMsg));
            });
        }}>
        {({ isSubmitting, errors, status, values }) => (
          <div className="form-container">
            <h2 className="form-header">New {model} form</h2>
            <div className="mb-4">
              <p className="text-gray-600 text-sm leading-tight">
                Create a brand new {model} here. Once you have created the {model} click the "Edit"
                button in the {model} index to add attachments, description and other properties to
                the {model}.
              </p>
            </div>
            <Form>
              <div className="form-input-group">
                <label className="form-label">
                  <Trans>Name</Trans>
                </label>
                <Field
                  className="form-input"
                  type="text"
                  autoFocus
                  autoComplete="off"
                  name="name"
                  disabled={isSubmitting}
                />
                <ErrorMessage name="name">
                  {errorMessage => <div className="form-input-error">{errorMessage}</div>}
                </ErrorMessage>
              </div>
              <div className="btn-group">
                <Button colour={`blue`} disabled={isSubmitting}>
                  {isSubmitting ? `Creating ${model}` : `Create ${model}`}
                </Button>
              </div>
            </Form>
          </div>
        )}
      </Formik>
    )}
  </ApiConsumer>
);

export default CreateCourseItemForm;
