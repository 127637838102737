import React, { Component } from "react";
import { toast } from "react-toastify";
import history from "../components/history";

let ApiContext;
const { Consumer, Provider } = (ApiContext = React.createContext());

class ApiProvider extends Component {
    state = {
        isLoading: false,
        error: null,
        user: null,
        lang: "en"
    };

    setApiState = (key, value) => {
        this.setState({ [key]: value });
    };

    callApi = api => {
        toast.dismiss();
        this.setState({ isLoading: true, error: null });
        return api()
            .then(res => {
                process.env.NODE_ENV === "development" &&
                    console.log(
                        "%cAPI success",
                        "color: #fff; font-weight: bold; background: #F6993F; padding: 2px 4px; border-radius: 2px;",
                        res.config.url,
                        res
                    );
                this.setState({ isLoading: false, error: null });
                return res;
            })
            .catch(error => {
                process.env.NODE_ENV === "development" &&
                    console.warn(
                        "%cAPI failed",
                        "color: #f6993f; font-weight: bold",
                        error.response
                    );
                this.setState({ isLoading: false, error });

                if (error.response && error.response.data) {
                    throw error.response.data;
                }

                throw error;
            });
    };

    render() {
        const { isLoading, error, user, lang } = this.state;

        return (
            <Provider
                value={{
                    isLoading,
                    error,
                    user,
                    lang,
                    callApi: this.callApi,
                    setApiState: this.setApiState,
                    logout: this.logout
                }}>
                {this.props.children}
            </Provider>
        );
    }
}

export { ApiContext, ApiProvider, Consumer as ApiConsumer };
