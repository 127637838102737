import React, { Component } from "react";
// import UserSearchBox from "../components/UserSearchBox";
import moment from "moment";
import { Trans } from "@lingui/macro";
import PageBody from "../components/PageBody";
import StatsOverview from "../components/stats/Overview";
import PageHeader from "../components/PageHeader";
import { get } from "../api";
import { Chart } from "@iforwms/react-components";
import { ApiContext } from "../contexts/ApiContext";

class Stats extends Component {
  static contextType = ApiContext;
  state = {
    loading: false,
    error: null,
    start_date: moment().subtract(30, "days").format("YYYY-MM-DD"),
    end_date: moment().format("YYYY-MM-DD")
  };

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  safeSetState = (...args) => {
    this._isMounted && this.setState(...args);
  };

  fetchSubscribers = () => {
    const { start_date, end_date } = this.state;
    this.safeSetState({
      isLoadingSubscribers: true,
      subscribersError: null
    });
    this.context
      .callApi(() =>
        get(
          "/reports/subscriptions?start_date=" +
            moment(start_date) +
            "&end_date=" +
            moment(end_date)
        )
      )
      .then(({ data }) => {
        this.safeSetState({
          isLoadingSubscribers: false,
          subscribers: data,
          subscriptionDiffData: this.parseSubscriptionDiffData(data),
          subscriptionData: this.parseSubscriptionData(data)
        });
      })
      .catch(error => {
        this.safeSetState({
          isLoadingSubscribers: false,
          subscribersError: error
        });
      });
  };

  fetchPayments = () => {
    const { start_date, end_date } = this.state;
    this.safeSetState({
      isLoadingPayments: true,
      paymentsError: null
    });
    this.context
      .callApi(() => get(moment(start_date).toJSON(), moment(end_date).toJSON()))
      .then(({ data }) => {
        this.safeSetState({
          payments: data,
          paymentData: this.parsePaymentData(data),
          isLoadingPayments: false,
          totalIncome: data[data.length - 1].running_total
        });
      })
      .catch(error => {
        this.safeSetState({
          isLoadingPayments: false,
          paymentsError: error
        });
      });
  };

  fetchPlans = () => {
    this.safeSetState({
      isLoadingPlans: true,
      plansError: null
    });
    this.context
      .callApi(get)
      .then(({ data }) => {
        this.safeSetState({
          planData: this.parsePlanData(data),
          isLoadingPlans: false
        });
      })
      .catch(error => {
        this.safeSetState({
          isLoadingPlans: false,
          plansError: error
        });
      });
  };

  parseSubscriptionData = data => {
    let chartData = {
      labels: [],
      datasets: [
        {
          label: "Total Subscribers",
          data: [],
          fill: true,
          lineTension: 0,
          pointBackgroundColor: "rgb(227, 52, 47, .3)",
          borderColor: "rgb(227, 52, 47, .3)",
          backgroundColor: "rgb(227, 52, 47, .1)"
        }
      ]
    };

    data.forEach(day => {
      chartData.labels.push(day["1-date"]);
      chartData.datasets[0].data.push(day["5-total"]);
    });

    return chartData;
  };

  parseSubscriptionDiffData = data => {
    let chartData = {
      labels: [],
      datasets: [
        {
          label: "New Subscribers",
          data: [],
          fill: true,
          lineTension: 0,
          pointBackgroundColor: "rgb(56, 193, 114, .3)",
          borderColor: "rgb(56, 193, 114, .3)",
          backgroundColor: "rgb(56, 193, 114, .1)"
        },
        {
          label: "Cancelled Subscribers",
          data: [],
          fill: true,
          lineTension: 0,
          pointBackgroundColor: "rgb(227, 52, 47, .3)",
          borderColor: "rgb(227, 52, 47, .3)",
          backgroundColor: "rgb(227, 52, 47, .1)"
        }
      ]
    };
    data.forEach(day => {
      chartData.labels.push(day["1-date"]);
      chartData.datasets[0].data.push(day["2-new"]);
      chartData.datasets[1].data.push(
        day["3-cancel"] > 0 ? "-" + day["3-cancel"] : day["3-cancel"]
      );
    });

    return chartData;
  };

  parsePaymentData = data => {
    let chartData = {
      labels: [],
      datasets: [
        {
          label: "Payments",
          data: [],
          fill: true,
          lineTension: 0,
          pointBackgroundColor: "rgb(56, 193, 114, .3)",
          borderColor: "rgb(56, 193, 114, .3)",
          backgroundColor: "rgb(56, 193, 114, .1)"
        }
      ]
    };

    let subscriptionRunningTotal = 0;

    data.forEach(day => {
      chartData.labels.push(day.date);
      chartData.datasets[0].data.push(day.amount);

      subscriptionRunningTotal += day.amount;
    });

    this.safeSetState({ subscriptionRunningTotal });

    return chartData;
  };

  updateRecent = (period, amount) => {
    this.safeSetState({
      start_date: moment().subtract(amount, period).format("YYYY-MM-DD"),
      end_date: moment().format("YYYY-MM-DD")
    });
  };

  render() {
    const { start_date, end_date } = this.state;

    return (
      <div>
        <PageHeader
          title={<Trans>Overview</Trans>}
          body={
            <Trans>
              Here you can view subscription and payment details. Change the dates to view a
              specific time period.
            </Trans>
          }
        />
        <PageBody>
          <div className="p-4 md:p-8">
            {/* <UserSearchBox /> */}

            <StatsOverview />

            {/*<div className="md:p-4 flex flex-col text-xs">*/}
            {/*  <div className="mb-4 flex">*/}
            {/*    <div className="flex items-center md:mr-6 mb-2 md:mb-0">*/}
            {/*      <span className="mr-2" style={{ minWidth: 40 }}>*/}
            {/*        <Trans>From</Trans>*/}
            {/*      </span>*/}
            {/*      <span className="flex items-center flex-1 md:flex-auto">*/}
            {/*        <input*/}
            {/*          onChange={e => this.setState({ start_date: e.target.value })}*/}
            {/*          value={start_date}*/}
            {/*          type="date"*/}
            {/*          className="form-input"*/}
            {/*        />*/}
            {/*      </span>*/}
            {/*    </div>*/}

            {/*    <div className="flex items-center mb-2 md:mb-0">*/}
            {/*      <span className="mr-2 mb-2 md:mb-0" style={{ minWidth: 40 }}>*/}
            {/*        <Trans>To</Trans>*/}
            {/*      </span>*/}
            {/*      <span className="flex flex-1 md:flex-auto items-center md:mr-2">*/}
            {/*        <input*/}
            {/*          onChange={e => this.setState({ end_date: e.target.value })}*/}
            {/*          value={end_date}*/}
            {/*          type="date"*/}
            {/*          className="form-input"*/}
            {/*        />*/}
            {/*      </span>*/}
            {/*    </div>*/}
            {/*  </div>*/}
            {/*  <div>*/}
            {/*    <button*/}
            {/*      className="btn btn-sm md:mr-2 mb-2 md:mb-0"*/}
            {/*      onClick={() => this.updateRecent("days", 1)}>*/}
            {/*      <Trans>Last 24 hours</Trans>*/}
            {/*    </button>*/}

            {/*    <button*/}
            {/*      className="btn btn-sm md:mr-2 mb-2 md:mb-0"*/}
            {/*      onClick={() => this.updateRecent("days", 7)}>*/}
            {/*      <Trans>Last Week</Trans>*/}
            {/*    </button>*/}

            {/*    <button*/}
            {/*      className="btn btn-sm md:mr-2 mb-2 md:mb-0"*/}
            {/*      onClick={() => this.updateRecent("months", 1)}>*/}
            {/*      <Trans>Last Month</Trans>*/}
            {/*    </button>*/}

            {/*    <button*/}
            {/*      className="btn btn-sm md:mr-2 mb-2 md:mb-0"*/}
            {/*      onClick={() => this.updateRecent("months", 3)}>*/}
            {/*      <Trans>Last Quarter</Trans>*/}
            {/*    </button>*/}
            {/*  </div>*/}
            {/*</div>*/}

            <div className="flex flex-wrap justify-center">
              <Chart
                get={get}
                url="reports/registrations"
                title="New Registrations"
                type="bar"
                start_date={start_date}
                end_date={end_date}
              />

              <Chart
                get={get}
                url="reports/subscriptions"
                title="New Subscriptions"
                type="stackedBar"
                start_date={start_date}
                end_date={end_date}
              />

              <Chart
                get={get}
                url="reports/subscription-totals"
                title="Subscriptions by Platform"
                type="stackedBar"
                start_date={start_date}
                end_date={end_date}
              />

              {/*<StatsChart*/}
              {/*    url="reports/payments"*/}
              {/*    title="Received Payments"*/}
              {/*    type="line"*/}
              {/*    start_date={start_date}*/}
              {/*    end_date={end_date}*/}
              {/*/>*/}
            </div>
          </div>
        </PageBody>
      </div>
    );
  }
}

export default Stats;
