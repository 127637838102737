import React from "react";
import PropTypes from "prop-types";
import { Trans } from "@lingui/macro";
import { toast } from "react-toastify";
import parseErrorMessage from "helpers-js/parseErrorMessage";
import { get, patch, post } from "../../../api";
import Form from "../../external/forms/Form";
import { Button } from "@iforwms/react-components";

const VideoSourceForm = ({ id }) => (
  <Form
    title={<Trans>Video Source</Trans>}
    inputs={[
      { type: "text", value_key: "full_name", label: "Full Name", disabled: true },
      { type: "text", value_key: "original_filename", label: "Original Filename", disabled: true },
      { type: "text", value_key: "aliyun_uploaded_at", label: "Aliyun Uploaded At", disabled: true },
      { type: "text", value_key: "cloudflare_uploaded_at", label: "Cloudflare Uploaded At", disabled: true },
      { type: "image", value_key: "thumb", label: "Video Thumb", showPreview: true },
      { type: "number", value_key: "duration", label: "Duration" },
      { type: "text", value_key: "host_id", label: "Host ID" },
      {
        type: "video",
        value_key: "sq_src",
        label: "Aliyun Video Preview",
        showPreview: true,
        accept: "video/*"
      },
      {
        type: "video",
        value_key: "cloudflare_src",
        label: "Cloudflare Video Preview",
        showPreview: true,
        accept: "video/*"
      }
    ]}
    post={post}
    get={get}
    patch={patch}
    method={id ? "patch" : "post"}
    panelWrap={false}
    handleError={error =>
      toast.error(parseErrorMessage(error, <Trans>Failed to create/update video.</Trans>), {
        autoClose: false
      })
    }
    handleSuccess={() =>
      toast.success(id ? <Trans>Video updated!</Trans> : <Trans>Video created!</Trans>, {
        autoClose: false
      })
    }
    fetchEndpoint={`/videos/${id}/source`}
    submitEndpoint={`/videos/${id}/source`}
    viewEndpoint={`${process.env.REACT_APP_FRONTEND_BASE_URL}/video/${id}`}
    submitText={"Update Video"}>
    <div className="p-2 mt-4 text-white bg-red-500 rounded">
      <h3 className="text-sm">Advanced - Do not press!</h3>

      <div className="flex items-center justify-between mt-2">
        <Button
          type={`button`}
          classes={``}
          size="sm"
          text={`Preload CDN`}
          colour={`gray`}
          onClick={() =>
            post(`videos/${id}/preload-cdn`)
              .then(() => toast.success(`CDN node preloaded!`))
              .catch(error =>
                toast(parseErrorMessage(error, <Trans>Failed to preload CDN.</Trans>))
              )
          }
        />
        <Button
          type="button"
          classes="ml-2 "
          size="sm"
          text="Copy to Cloudflare"
          colour="gray"
          onClick={() =>
            post(`videos/${id}/cloudflare`)
              .then(() => toast.success(`Video copied to cloudflare!`, { autoClose: false }))
              .catch(error =>
                toast.error(parseErrorMessage(error, <Trans>Failed to copy video</Trans>), {
                  autoClose: false
                })
              )
          }
        />
      </div>
    </div>
  </Form>
);

VideoSourceForm.propTypes = {
  id: PropTypes.string
};

export default VideoSourceForm;
