import React, { Component, Fragment } from "react";
import { Trans } from "@lingui/macro";
import { toast } from "react-toastify";
import PageBody from "../components/PageBody";
import ReloadButton from "../components/ReloadButton";
import PageHeader from "../components/PageHeader";
import CourseItemWithForm from "../components/forms/CourseItemWithForm";
import AttachmentFormContainer from "../components/forms/AttachmentFormContainer";
import { ApiContext } from "../contexts/ApiContext";
import { get } from "../api";
import parseErrorMessage from "helpers-js/parseErrorMessage";
import { LoadingWrapper } from "@iforwms/react-components";

class CourseEdit extends Component {
  static contextType = ApiContext;
  state = { isLoading: true, item: undefined, error: undefined };

  componentDidMount() {
    this._isMounted = true;
    this.fetchItem();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleDelete = _ => {
    this.props.history.push(this.props.endpoint);
  };

  safeSetState = (...args) => {
    this._isMounted && this.setState(...args);
  };

  handleSuccess = item => {
    console.log("handle success", item);
    this.safeSetState({ item });
  };

  fetchItem = _ => {
    const {
      match: {
        params: { course_id, level_id, chapter_id, lesson_id, video_id }
      },
      endpoint
    } = this.props;

    let id = course_id;

    if (level_id) {
      id = level_id;
    }

    if (chapter_id) {
      id = chapter_id;
    }

    if (lesson_id) {
      id = lesson_id;
    }

    if (video_id) {
      id = video_id;
    }

    const url = `${endpoint}/${id}`;

    this.context
      .callApi(() => get(url))
      .then(({ data: { data } }) => {
        this.safeSetState({
          item: data,
          isLoading: false
        });
      })
      .catch(error => {
        if (error.response && error.response.status && error.response.status === 404) {
          toast.error(parseErrorMessage(error, <Trans>Resource not found!</Trans>));
        }

        this.safeSetState({
          error,
          isLoading: false
        });
      });
  };

  render() {
    const { endpoint, model, match } = this.props;
    const { isLoading, item, showAttachmentsForm, error } = this.state;

    return (
      <div>
        <PageHeader title={`${model} Edit`} />
        <PageBody>
          <div className="p-4 md:p-8 container-narrow">
            <LoadingWrapper message={`Fetching ${model} details...`} isLoading={isLoading}>
              {error && <ReloadButton />}

              {item && (
                <Fragment>
                  <CourseItemWithForm
                    match={match}
                    initialValues={item}
                    endpoint={endpoint}
                    model={model}
                    onDelete={this.handleDelete}
                    onSuccess={this.handleSuccess}
                    formVisible={showAttachmentsForm}
                    form={
                      <AttachmentFormContainer
                        onSuccess={this.handleSuccess}
                        endpoint={endpoint}
                        initialValues={item}
                      />
                    }
                    hideForm={() => this.setState({ showAttachmentsForm: false })}
                    showForm={() => this.setState({ showAttachmentsForm: true })}
                  />
                </Fragment>
              )}
            </LoadingWrapper>
          </div>
        </PageBody>
      </div>
    );
  }
}

export default CourseEdit;
