import { Panel } from "@iforwms/react-components";
import { Trans } from "@lingui/macro";
import moment from "moment";
import PropTypes from "prop-types";
import React from "react";

const PaymentDetails = ({ payments }) => (
  <Panel colour={`indigo`} title={<Trans>Last Ten Payments</Trans>}>
    <table className="w-full table-striped table-no-wrap table-cell-spaced text-sm">
      <thead>
        <tr>
          <th className="text-left">ID</th>
          <th className="text-left">Gateway</th>
          <th className="text-left">Amount</th>
          <th className="text-left">Created</th>
        </tr>
      </thead>
      <tbody>
        {!payments.length && (
          <tr>
            <td colSpan={3}>
              <Trans>No previous payments.</Trans>
            </td>
          </tr>
        )}
        {payments.map((payment, index) => (
          <tr key={index}>
            <td>{payment.id}</td>
            <td>{payment.gateway}</td>
            <td>{payment.amount} HKD</td>
            <td>
              {moment(payment.created_at).format("YYYY-MM-DD")} (
              {moment(payment.created_at).fromNow()})
            </td>
          </tr>
        ))}
      </tbody>
    </table>
  </Panel>
);

PaymentDetails.propTypes = { payments: PropTypes.array.isRequired };

export default PaymentDetails;
