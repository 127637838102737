import React from "react";
import PropTypes from "prop-types";
import Label from "./Label";
import config, { colours } from "../../config";
import Icon from "../Icon";
import InputError from "./InputError";
import { parseError } from "@iforwms/helpers-js";

class Input extends React.Component {
  state = { showPassword: false };

  render() {
    let {
      type,
      label,
      setState,
      valueKey,
      value,
      disabled,
      error,
      accept,
      elementKey,
      rows,
      autoFocus,
      classes,
      containerClasses,
      size,
      description,
      placeholder,
      readOnly,
      required,
      fullWidth,
      isLoading,
      autoComplete
    } = this.props;
    const { showPassword } = this.state;

    if (type === "password") {
      return (
        <div className={containerClasses}>
          <Label label={label} classes={`flex items-center cursor-pointer`} />
          <div className="relative">
            <input
              key={elementKey}
              style={isLoading ? config.styles.inputLoadingStyles : {}}
              required={required}
              readOnly={readOnly}
              autoFocus={autoFocus}
              type={showPassword ? "text" : "password"}
              className={`${config.styles.inputClasses} ${
                fullWidth ? "w-full" : ""
              } text-${size} ${classes} ${
                parseError(valueKey, error) ? "border-red-500" : ""
              }`}
              defaultValue={value}
              onChange={e => setState(valueKey, e.target.value)}
              autoComplete={autoComplete}
              disabled={disabled}
            />
            <span
              className={`flex justify-center items-center bg-white transition rounded-r border hover:text-primary-500 absolute right-0 inset-y-0 block p-2 cursor-pointer ${
                parseError(valueKey, error) ? "border-red-500" : ""
              }`}
              onClick={() =>
                this.setState(prevState => ({
                  showPassword: !prevState.showPassword
                }))
              }>
              <Icon size={16} icon={showPassword ? "eyeOpen" : "eyeClosed"} />
            </span>
          </div>
          {description && (
            <span className="block mt-1 text-xs text-gray-500">
              {description}
            </span>
          )}
          <InputError error={error} valueKey={valueKey} />
        </div>
      );
    }

    if (type === "checkbox") {
      return (
        <div className={containerClasses}>
          <Label
            label={label}
            classes={`${
              fullWidth ? "w-full" : ""
            } whitespace-no-wrap flex items-center cursor-pointer`}>
            <input
              key={elementKey}
              style={isLoading ? config.styles.inputLoadingStyles : {}}
              className={`cursor-pointer ml-2 p-2 ${classes} ${
                parseError(valueKey, error) ? "border-red-500" : ""
              }`}
              required={required}
              readOnly={readOnly}
              type="checkbox"
              disabled={disabled}
              checked={value}
              onChange={e => setState(valueKey, e.target.checked)}
            />
          </Label>
          {description && (
            <span className="block mt-1 text-xs text-gray-500">
              {description}
            </span>
          )}
          <InputError error={error} valueKey={valueKey} />
        </div>
      );
    }

    let input = null;

    if (type === "textarea") {
      input = (
        <textarea
          key={elementKey}
          className={`${config.styles.inputClasses} ${
            fullWidth ? "w-full" : ""
          } text-${size} ${classes} ${
            parseError(valueKey, error) ? "border-red-500" : ""
          }`}
          placeholder={placeholder}
          required={required}
          readOnly={readOnly}
          rows={rows}
          autoFocus={autoFocus}
          defaultValue={value}
          disabled={disabled}
          onChange={setState ? e => setState(valueKey, e.target.value) : null}
        />
      );
    }

    if (type === "file") {
      input = (
        <input
          key={elementKey}
          style={isLoading ? config.styles.inputLoadingStyles : {}}
          className={`${config.styles.inputClasses} ${
            fullWidth ? "w-full" : ""
          } text-${size} ${classes} ${
            parseError(valueKey, error) ? "border-red-500" : ""
          }`}
          onChange={e => setState(e)}
          disabled={disabled}
          type="file"
          accept={accept}
        />
      );
    }

    if (
      type === "text" ||
      type === "hidden" ||
      type === "number" ||
      type === "email" ||
      type === "search"
    ) {
      input = (
        <input
          style={isLoading ? config.styles.inputLoadingStyles : {}}
          className={`${config.styles.inputClasses} ${
            fullWidth ? "w-full" : ""
          } text-${size} ${classes} ${
            parseError(valueKey, error) ? "border-red-500" : ""
          }`}
          key={elementKey}
          placeholder={placeholder}
          required={required}
          readOnly={readOnly}
          autoFocus={autoFocus}
          type={type}
          value={value}
          disabled={disabled}
          onChange={setState ? e => setState(valueKey, e.target.value) : null}
        />
      );
    }

    if (type === "colour") {
      input = (
        <div className={`-mx-1 flex flex-wrap`}>
          {colours.map(colour => (
            <div
              key={colour}
              onClick={setState ? () => setState(valueKey, colour) : null}
              className={`${
                fullWidth ? "w-full" : ""
              } text-white flex items-center justify-center cursor-pointer m-1 w-8 h-8 rounded bg-${colour} bg-${colour}-500 hover:bg-${colour}-700 hover:bg-${colour}`}>
              {value === colour ? <Icon icon={`tick`} /> : null}
            </div>
          ))}
        </div>
      );
    }

    return (
      <div className={containerClasses}>
        {label ? <Label label={label} /> : null}
        {input}
        {description ? (
          <span className={`block text-xs mt-1 text-gray-500`}>
            {description}
          </span>
        ) : null}
        <InputError error={error} valueKey={valueKey} />
      </div>
    );
  }
}

Input.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  type: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  required: PropTypes.bool.isRequired,
  classes: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
  accept: PropTypes.string,
  rows: PropTypes.number.isRequired,
  disabled: PropTypes.bool.isRequired,
  fullWidth: PropTypes.bool.isRequired,
  readOnly: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  setState: PropTypes.func,
  error: PropTypes.object,
  valueKey: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool
  ]),
  checked: PropTypes.bool,
  containerClasses: PropTypes.string.isRequired,
  autoComplete: PropTypes.string.isRequired
};

Input.defaultProps = {
  type: "text",
  classes: "",
  containerClasses: "",
  size: "md",
  autoComplete: "current-password",
  fullWidth: true,
  disabled: false,
  autoFocus: false,
  isLoading: false,
  readOnly: false,
  required: false,
  rows: 5
};

export default Input;
