import React from "react";
import PropTypes from "prop-types";
import { destroy, post, get, patch } from "../../../api";
import { AudioPlayer, MediaUpload, Button, Input, LoadingWrapper } from "@iforwms/react-components";
import { Trans } from "@lingui/macro";
import { toast } from "react-toastify";
import { parseErrorMessage } from "@iforwms/helpers-js";
import { ApiContext } from "../../../contexts/ApiContext";

class VideoSentencesForm extends React.Component {
  static contextType = ApiContext;

  state = {
    isLoading: false,
    isSubmitting: false,
    error: null,
    newText: "",
    newTranslation: "",
    sentences: []
  };

  componentDidMount() {
    this._isMounted = true;
    this.fetchItem();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  handleAudioUpload = () => {
    console.log("todo");
  };

  safeSetState = (...args) => {
    this._isMounted && this.setState(...args);
  };

  setParentState = (valueKey, value) => {
    this.setState({ [valueKey]: value });
  };

  handleRemove = sentence => {
    this.safeSetState({ isSubmitting: true, error: null });

    const { id } = this.props;

    destroy(`/videos/${id}/sentences/${sentence}`)
      .then(({ data }) => {
        this.safeSetState({
          sentences: data.data,
          isSubmitting: false
        });
        toast.success(<Trans>Sentences updated!</Trans>);
      })
      .catch(error => {
        this.safeSetState({ fetchError: error, isSubmitting: false });
        toast.error(parseErrorMessage(error, <Trans>Failed to update sentences.</Trans>));
      });
  };

  handleSubmit = e => {
    e.preventDefault();

    this.safeSetState({ isSubmitting: true, error: null });

    const { id } = this.props;

    post(`/videos/${id}/sentences`, {
      text: this.state.newText,
      translation: this.state.newTranslation
    })
      .then(({ data }) => {
        this.safeSetState({
          newText: "",
          newTranslation: "",
          sentences: data.data,
          isSubmitting: false
        });
        toast.success(<Trans>Sentences updated!</Trans>);
      })
      .catch(error => {
        this.safeSetState({ fetchError: error, isSubmitting: false });
        toast.error(parseErrorMessage(error, <Trans>Failed to update sentences.</Trans>));
      });
  };

  fetchItem = () => {
    const { id } = this.props;

    this.safeSetState({ isLoading: true, fetchError: null });

    get(`/videos/${id}/sentences`)
      .then(({ data }) => {
        this.safeSetState({ sentences: data.data, isLoading: false });
      })
      .catch(error => this.safeSetState({ fetchError: error, isLoading: false }));
  };

  //   addNewSentence = () => {
  //     this.setState(prevState => ({
  //       sentences: prevState.sentences.concat({ id: "abc", text: "", translation: "" })
  //     }));
  //   };

  render() {
    const { newText, newTranslation, sentences, isLoading, isSubmitting, error } = this.state;

    return (
      <LoadingWrapper isLoading={isLoading} error={error} retry={this.fetchItem}>
        <div>
          <div className={``}>
            <form method={`POST`} onSubmit={e => this.handleSubmit(e)}>
              <div className={``}>
                <div className="">
                  <label className="text-xs text-gray-500 uppercase">Text</label>
                  <input
                    className="w-full p-1 border rounded"
                    type="text"
                    value={newText}
                    onChange={e => this.setState({ newText: e.target.value })}
                  />
                </div>

                <div className="mt-2">
                  <label className="text-xs text-gray-500 uppercase">Translation</label>
                  <input
                    className="w-full p-1 border rounded"
                    type="text"
                    value={newTranslation}
                    onChange={e => this.setState({ newTranslation: e.target.value })}
                  />
                </div>

                <button
                  type="submit"
                  disabled={isSubmitting}
                  className="w-full p-1 mt-2 text-sm text-teal-500 border border-teal-500 rounded">
                  {isSubmitting ? "Creating Sentence..." : "Create Sentece"}
                </button>
              </div>
            </form>

            {!sentences.length ? <div className={`mt-4`}>No sentences at the moment.</div> : null}

            {sentences.map(sentence => (
              <div className={`mt-4`} key={sentence.id}>
                <div className="">
                  <label className="text-xs text-gray-500 uppercase">Text</label>
                  <input
                    className="w-full p-1 border rounded"
                    type="text"
                    disabled
                    defaultValue={sentence.text}
                  />
                </div>

                <div className="mt-2">
                  <label className="text-xs text-gray-500 uppercase">Translation</label>
                  <input
                    disabled
                    className="w-full p-1 border rounded"
                    type="text"
                    defaultValue={sentence.translation}
                  />
                </div>

                {sentence.audio_src ? (
                  <div className="mt-2">
                    <AudioPlayer src={sentence.audio_src} />
                  </div>
                ) : null}

                <div className="mt-2">
                  <MediaUpload
                    post={post}
                    label={<Trans>Audio</Trans>}
                    endpoint={`/videos/${this.props.id}/sentences/${sentence.id}/audio`}
                    onError={error =>
                      toast.error(parseErrorMessage(error, <Trans>Failed to upload audio</Trans>))
                    }
                    accept="audio/mpeg"
                    handleUpload={this.handleAudioUpload}
                    onSuccess={(file, data) => {
                      // console.log("%cdata", "color: #3490dc; font-weight: bold", data);
                      this.safeSetState({ sentences: data.data });
                    }}
                  />
                </div>

                <button
                  disabled={isSubmitting}
                  onClick={() => this.handleRemove(sentence.id)}
                  className="w-full p-1 mt-2 text-sm text-red-500 border border-red-500 rounded">
                  Remove
                </button>
              </div>
            ))}
          </div>

          {/* <div className="flex mt-4"> */}
          {/*   <Button */}
          {/*     onClick={this.addNewSentence} */}
          {/*     disabled={isSubmitting || isLoading} */}
          {/*     type={`button`} */}
          {/*     colour={`teal`} */}
          {/*     size={`sm`} */}
          {/*     text={`Add New Sentence`} */}
          {/*   /> */}

          {/*   <Button */}
          {/*     classes={`ml-2`} */}
          {/*     colour={`blue`} */}
          {/*     size={`sm`} */}
          {/*     text={isSubmitting ? `Updating Sentences...` : `Update Sentences`} */}
          {/*     disabled={isSubmitting || isLoading} */}
          {/*   /> */}
          {/* </div> */}
        </div>
      </LoadingWrapper>
    );
  }
}

VideoSentencesForm.propTypes = {
  id: PropTypes.string
};

export default VideoSentencesForm;
