import React from "react";
import { Trans } from "@lingui/macro";
import { toast } from "react-toastify";
import PageHeader from "../components/PageHeader";
import PageBody from "../components/PageBody";
import { ApiContext } from "../contexts/ApiContext";
import { get } from "../api";
import parseErrorMessage from "helpers-js/parseErrorMessage";
import { Button, Icon, LoadingWrapper, Panel } from "@iforwms/react-components";
import * as PropTypes from "prop-types";

class RedisIndex extends React.Component {
  static contextType = ApiContext;

  state = {
    error: null,
    isLoading: false,
    delayed: [],
    failed: [],
    pending: [],
    reserved: []
  };

  componentDidMount() {
    this._isMounted = true;
    this.fetchQueue();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  safeSetState = (...args) => {
    this._isMounted && this.setState(...args);
  };

  fetchQueue = () => {
    this.setState({
      isLoading: true,
      delayed: [],
      pending: [],
      reserved: [],
      failed: [],
      error: null
    });

    this.context
      .callApi(() => get(`/redis`))
      .then(({ data }) => {
        this.safeSetState({
          delayed: data.data.delayed,
          reserved: data.data.reserved,
          pending: data.data.pending,
          failed: data.data.failed,
          isLoading: false,
          error: null
        });
      })
      .catch(error => {
        this.safeSetState({
          isLoading: false,
          error
        });
        toast.error(parseErrorMessage(error, <Trans>Failed to fetch queue.</Trans>));
      });
  };

  render() {
    const { error, isLoading, pending, failed, delayed, reserved } = this.state;

    return (
      <div>
        <PageHeader title={<Trans>Redis Queue</Trans>} />

        <PageBody>
          <div className="relative p-4 md:p-8">
            <div>
              <Button
                fullWidth={false}
                colour={`blue`}
                disabled={isLoading}
                onClick={this.fetchQueue}
                icon={`refresh`}
              />
            </div>

            <LoadingWrapper error={error} isLoading={isLoading}>
              <div className={`mt-4`}>
                <Panel title={`Failed (${failed.length})`} colour={`red`}>
                  {!failed.length ? (
                    <div>
                      <Trans>No failed jobs.</Trans>
                    </div>
                  ) : null}

                  {failed.map(job => (
                    <Job job={job} />
                  ))}
                </Panel>
              </div>

              <div className={`mt-4`}>
                <Panel title={`Pending (${pending.length})`} colour={`indigo`}>
                  {!pending.length ? (
                    <div>
                      <Trans>No pending jobs.</Trans>
                    </div>
                  ) : null}

                  {pending.map(job => (
                    <Job job={job} />
                  ))}
                </Panel>
              </div>

              <div className={`mt-4`}>
                <Panel title={`Reserved (${reserved.length})`} colour={`blue`}>
                  {!reserved.length ? (
                    <div>
                      <Trans>No reserved jobs.</Trans>
                    </div>
                  ) : null}

                  {reserved.map(job => (
                    <Job job={job} />
                  ))}
                </Panel>
              </div>

              <div className={`mt-4`}>
                <Panel title={`Delayed (${delayed.length})`} colour={`orange`}>
                  {!delayed.length ? (
                    <div>
                      <Trans>No delayed jobs.</Trans>
                    </div>
                  ) : null}

                  {delayed.map(job => (
                    <Job job={job} />
                  ))}
                </Panel>
              </div>
            </LoadingWrapper>
          </div>
        </PageBody>
      </div>
    );
  }
}

class Job extends React.Component {
  state = {
    showCommand: false,
    showException: false
  };

  render() {
    const { job } = this.props;
    const { showCommand, showException } = this.state;

    return (
      <div className={`rounded border mt-4`}>
        <table>
          <tbody>
            <tr>
              <td className={`py-1 px-2 font-bold`}>
                <Trans>ID</Trans>
              </td>
              <td className={`py-1 px-2`}>{job.id}</td>
            </tr>
            <tr>
              <td className={`py-1 px-2 font-bold`}>
                <Trans>Name</Trans>
              </td>
              <td className={`py-1 px-2`}>{job.commandName}</td>
            </tr>
            <tr>
              <td className={`py-1 px-2 font-bold`}>
                <Trans>Attempts</Trans>
              </td>
              <td className={`py-1 px-2`}>{job.attempts}</td>
            </tr>
            <tr>
              <td className={`py-1 px-2 font-bold`}>
                <Trans>Timeout</Trans>
              </td>
              <td className={`py-1 px-2`}>{job.timeout}</td>
            </tr>
            {job.failed_at ? (
              <tr>
                <td className={`py-1 px-2 font-bold`}>
                  <Trans>Failed At</Trans>
                </td>
                <td className={`py-1 px-2`}>{job.failed_at}</td>
              </tr>
            ) : null}
            <tr>
              <td
                onClick={() =>
                  this.setState(prevState => ({ showCommand: !prevState.showCommand }))
                }
                className={`cursor-pointer align-baseline py-1 px-2 font-bold underline`}>
                <Trans>Command</Trans>
              </td>
              <td className={`py-1 px-2`}>{showCommand ? job.command : "Click heading to show"}</td>
            </tr>
            {job.exception ? (
              <tr>
                <td
                  onClick={() =>
                    this.setState(prevState => ({ showException: !prevState.showException }))
                  }
                  className={`cursor-pointer align-baseline py-1 px-2 font-bold underline`}>
                  <Trans>Exception</Trans>
                </td>
                <td className={`py-1 px-2`}>
                  {showException ? job.exception : "Click heading to show"}
                </td>
              </tr>
            ) : null}
          </tbody>
        </table>
      </div>
    );
  }
}

Job.propTypes = { job: PropTypes.any };

export default RedisIndex;
