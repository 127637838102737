import React, { Component } from "react";
import { Trans } from "@lingui/macro";
import VideoBasicForm from "./VideoBasicForm";
import { Panel } from "@iforwms/react-components";
import PageHeader from "../../PageHeader";
import PageBody from "../../PageBody";
import VideoSourceForm from "./VideoSourceForm";
import history from "../../history";
import VideoLearningForm from "./VideoLearningForm";
import VideoQuizForm from "./VideoQuizForm";
import VideoSentencesForm from "./VideoSentencesForm";
import { QuizProvider } from "../../../contexts/QuizContext";


class VideoFormContainer extends Component {
  componentDidMount() {
    this.navigateToTab();
  }

  navigateToTab = () => {
    const { tab, video_id } = this.props.match.params;
    if (!tab) {
      history.push(`/videos/${video_id}/basic`);
    }
  };

  render() {
    const { video_id, tab } = this.props.match.params;

    return (
      <div>
        <PageHeader title={<Trans>Video Edit</Trans>} />
        <PageBody>
          <div className="max-w-2xl p-4">
            <Panel colour={`green`}>
              <ul className="flex border-b">
                <li className="flex">
                  <span
                    onClick={() => history.push(`/videos`)}
                    className={`text-sm border-t border-l border-r hover:text-blue-700 rounded-t p-2 cursor-pointer`}>
                    <Trans>Search</Trans>
                  </span>
                </li>

                <li className="flex ml-1">
                  <span
                    onClick={() => history.push(`/videos/${video_id}/basic`)}
                    className={`${
                      tab === "basic" ? "text-white bg-blue-500 border-blue-500" : ""
                    } text-sm border-t border-l border-r hover:text-blue-700 rounded-t p-2 cursor-pointer`}>
                    <Trans>Basic</Trans>
                  </span>
                </li>

                {video_id ? (
                  <>
                    <li className="flex ml-1">
                      <span
                        onClick={() => history.push(`/videos/${video_id}/source`)}
                        className={`${
                          tab === "source" ? "text-white bg-blue-500 border-blue-500" : ""
                        } text-sm border-t border-l border-r hover:text-blue-700 rounded-t p-2 cursor-pointer`}>
                        <Trans>Source</Trans>
                      </span>
                    </li>
                    <li className="flex ml-1">
                      <span
                        onClick={() => history.push(`/videos/${video_id}/sentences`)}
                        className={`${
                          tab === "sentences" ? "text-white bg-blue-500 border-blue-500" : ""
                        } text-sm border-t border-l border-r hover:text-blue-700 rounded-t p-2 cursor-pointer`}>
                        <Trans>Sentences</Trans>
                      </span>
                    </li>
                    <li className="flex ml-1">
                      <span
                        onClick={() => history.push(`/videos/${video_id}/learning`)}
                        className={`${
                          tab === "learning" ? "text-white bg-blue-500 border-blue-500" : ""
                        } text-sm border-t border-l border-r hover:text-blue-700 rounded-t p-2 cursor-pointer`}>
                        <Trans>Learning Points</Trans>
                      </span>
                    </li>
                    <li className="flex ml-1">
                      <span
                        onClick={() => history.push(`/videos/${video_id}/quiz`)}
                        className={`${
                          tab === "quiz" ? "text-white bg-blue-500 border-blue-500" : ""
                        } text-sm border-t border-l border-r hover:text-blue-700 rounded-t p-2 cursor-pointer`}>
                        <Trans>Quiz</Trans>
                      </span>
                    </li>
                  </>
                ) : null}
              </ul>

              {tab === "basic" && <VideoBasicForm id={video_id} />}
              {tab === "source" && <VideoSourceForm id={video_id} />}
              {tab === "learning" && <VideoLearningForm id={video_id} />}
              {tab === "sentences" && <VideoSentencesForm id={video_id} />}
              {tab === "quiz" && (
                <QuizProvider>
                  <VideoQuizForm id={video_id} />
                </QuizProvider>
              )}
            </Panel>
          </div>
        </PageBody>
      </div>
    );
  }
}

export default VideoFormContainer;
