import React, { Component } from "react";
import { t, Trans } from "@lingui/macro";
import { toast } from "react-toastify";
import { i18n } from "../App";
import { Link } from "react-router-dom";
import moment from "moment";
import PageHeader from "../components/PageHeader";
import PageBody from "../components/PageBody";
import { ApiContext } from "../contexts/ApiContext";
import { destroy, get } from "../api";
import { parseErrorMessage } from "@iforwms/helpers-js";
import { Button, LoadingWrapper } from "@iforwms/react-components";

class AudioIndex extends Component {
  static contextType = ApiContext;

  state = {
    isLoadingFeedback: false,
    deletingFeedback: null,
    feedback: []
  };

  componentDidMount() {
    this._isMounted = true;
    this.fetchFeedback();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  safeSetState = (...args) => {
    this._isMounted && this.setState(...args);
  };

  fetchFeedback = () => {
    this.setState({
      isLoadingFeedback: true,
      error: null
    });

    this.context
      .callApi(() => get(`/audio`))
      .then(({ data: { data } }) => {
        this.safeSetState({ feedback: data, isLoadingFeedback: false });
      })
      .catch(error => {
        this.safeSetState({ isLoadingFeedback: false, error });
      });
  };

  handleDeleteFeedback = item => {
    const confirm = window.confirm(i18n._(t`Are you sure you want to delete this item?`));

    if (!confirm) return;

    this.setState({ deletingFeedback: item.id, error: null });

    this.context
      .callApi(() => destroy(`/audio/${item.id}`))
      .then(() => {
        this.safeSetState({
          feedback: this.state.feedback.filter(a => a.id !== item.id),
          deletingFeedback: null
        });
      })
      .catch(error => {
        toast.error(parseErrorMessage(error, <Trans>Failed to delete item.</Trans>));
        this.safeSetState({ deletingFeedback: null, error });
      });
  };

  render() {
    const { isLoadingFeedback, feedback, deletingFeedback } = this.state;

    return (
      <div>
        <PageHeader title={<Trans>Audio Feedback</Trans>} />
        <PageBody>
          <div className="p-4 md:p-8">
            <div className="">
              <LoadingWrapper
                isLoading={isLoadingFeedback}
                message={<Trans>Fetching feedback...</Trans>}>
                <div>
                  {!feedback.length ? <div>No feedback yet.</div> : null}

                  {feedback.map(f => (
                    <div key={f.user_id} className="p-4 mb-2 border border-indigo-500 rounded">
                      <div className="flex items-center justify-between mb-2">
                        <div>
                          <strong>
                            <Trans>User</Trans>:
                          </strong>
                          <Button
                            isLink={true}
                            colour={`teal`}
                            classes={`ml-2 underline`}
                            url={`mailto:${f.email}?subject=Subscription Cancellation`}
                            text={f.email}
                          />
                        </div>

                        <div>
                          <Button
                            colour={`blue`}
                            RouterLink={Link}
                            size={`sm`}
                            classes={`ml-2`}
                            fullWidth={false}
                            text={<Trans>View User</Trans>}
                            url={`/users/${f.user_id}`}
                          />
                        </div>
                      </div>

                      <div>
                        <strong>
                          <Trans>Audio</Trans>
                        </strong>
                        <div>
                          {f.audios.map(audio => (
                            <div
                              className="flex flex-col mb-2 sm:items-center sm:flex-row"
                              key={audio.id}>
                              <div className="px-2 py-1 text-lg bg-gray-300 rounded">
                                {audio.text}
                              </div>
                              <div className="mt-2 sm:ml-4 sm:mt-0">
                                <audio src={audio.audio_src} controls />
                              </div>
                              <button
                                onClick={() => alert("Coming soon!")}
                                className="px-2 py-1 mt-2 ml-auto text-sm text-red-500 border border-red-500 rounded cursor-pointer sm:mt-0">
                                DELETE
                              </button>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </LoadingWrapper>
            </div>
          </div>
        </PageBody>
      </div>
    );
  }
}

export default AudioIndex;
