import React, { Component } from "react";
import { Trans } from "@lingui/macro";
import { toast } from "react-toastify";
import PageHeader from "../components/PageHeader";
import PageBody from "../components/PageBody";
import { ApiContext } from "../contexts/ApiContext";
import { get, patch } from "../api";
import { parseErrorMessage } from "@iforwms/helpers-js";
import moment from "moment";
import { Button, Input } from "@iforwms/react-components";
import { Link } from "react-router-dom";
import LoadingWrapper from "../components/external/LoadingWrapper";
import * as Permissions from "../permissions.js";

class DegreeShow extends Component {
  static contextType = ApiContext;
  state = {
    students: [],
    start_date: "",
    lesson_start_time: "",
    end_date: "",
    name: "",
    error: null,
    isLoading: false,
    showCreateForm: false,
    isSubmitting: false
  };

  componentDidMount() {
    this._isMounted = true;
    this.fetchDegree();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  safeSetState = (...args) => {
    this._isMounted && this.setState(...args);
  };

  setParentState = (valueKey, value) => {
    this.setState({ [valueKey]: value });
  };

  handleSubmit = e => {
    e.preventDefault();

    this.safeSetState({
      isSubmitting: true,
      error: null
    });
    const {
      name,
      teacher_email,
      // teacher_bio,
      lesson_start_time,
      start_date,
      end_date
    } = this.state;

    const payload = {
      name,
      teacher_email,
      // teacher_bio,
      lesson_start_time,
      start_date,
      end_date
    };

    patch(`degrees/${this.props.match.params.id}`, payload)
      .then(({ data: { data } }) => {
        this.safeSetState({
          isSubmitting: false,
          name: data.name,
          // teacher_bio: data.teacher ? data.teacher.bio : "",
          teacher_email: data.teacher ? data.teacher.email : "",
          teacher_name: data.teacher ? data.teacher.name : "",
          students: data.students,
          lesson_start_time: moment(data.lesson_start_time).utc().format("HH:mm"),
          start_date: moment(data.start_date).utc().format("YYYY-MM-DD"),
          end_date: moment(data.end_date).utc().format("YYYY-MM-DD")
        });

        toast.success("Degree updated!");
      })
      .catch(error => {
        this.safeSetState({
          isSubmitting: false,
          error
        });

        toast.error(parseErrorMessage(error, "Failed to update degree"));
      });
  };

  fetchDegree = () => {
    this.safeSetState({
      isLoading: true,
      error: null
    });

    get(`degrees/${this.props.match.params.id}`)
      .then(({ data: { data } }) => {
        this.safeSetState({
          isLoading: false,
          name: data.name,
          // teacher_bio: data.teacher ? data.teacher.bio : "",
          teacher_email: data.teacher ? data.teacher.email : "",
          teacher_name: data.teacher ? data.teacher.name : "",
          students: data.students,
          lesson_start_time: moment(data.lesson_start_time).utc().format("HH:mm"),
          start_date: moment(data.start_date).utc().format("YYYY-MM-DD"),
          end_date: moment(data.end_date).utc().format("YYYY-MM-DD")
        });
      })
      .catch(error => {
        this.safeSetState({
          isLoading: false,
          error
        });

        toast.error(parseErrorMessage(error, "Failed to fetch degree"));
      });
  };

  render() {
    const {
      lesson_start_time,
      start_date,
      end_date,
      name,
      teacher_email,
      // teacher_bio,
      students,
      isLoading,
      error,
      isSubmitting
    } = this.state;

    return (
      <div>
        <PageHeader title={<Trans>Degree Show</Trans>} />
        <PageBody>
          <LoadingWrapper isLoading={isLoading}>
            <div className="p-4 md:p-8">
              <div className="max-w-2xl">
                <form method="post" onSubmit={this.handleSubmit}>
                  <div className="mt-2">
                    <Input
                      setState={this.setParentState}
                      error={error}
                      disabled={
                        isSubmitting ||
                        !this.context.user.permissions.includes(Permissions.EDIT_COURSE)
                      }
                      value={name}
                      valueKey={`name`}
                      label={<Trans>Name</Trans>}
                    />
                  </div>

                  <div className="mt-2">
                    <Input
                      setState={this.setParentState}
                      error={error}
                      disabled={
                        isSubmitting ||
                        !this.context.user.permissions.includes(Permissions.EDIT_COURSE)
                      }
                      value={teacher_email}
                      valueKey={`teacher_email`}
                      label={<Trans>Teacher Email</Trans>}
                    />
                  </div>

{/*                   <div className="mt-2"> */}
{/*                     <Input */}
{/*                       setState={this.setParentState} */}
{/*                       error={error} */}
{/*                       disabled={ */}
{/*                         isSubmitting || */}
{/*                         !this.context.user.permissions.includes(Permissions.EDIT_COURSE) */}
{/*                       } */}
{/*                       type="textarea" */}
{/*                       value={teacher_bio} */}
{/*                       valueKey={`teacher_bio`} */}
{/*                       label={<Trans>Teacher Bio</Trans>} */}
{/*                     /> */}
{/*                   </div> */}

                  <div className="mt-4">
                    <Input
                      setState={this.setParentState}
                      error={error}
                      disabled={
                        isSubmitting ||
                        !this.context.user.permissions.includes(Permissions.EDIT_COURSE)
                      }
                      value={lesson_start_time}
                      valueKey={`lesson_start_time`}
                      label={<Trans>Lesson Start Time (HH:MM - UTC)</Trans>}
                    />
                  </div>

                  <div className="mt-4">
                    <Input
                      setState={this.setParentState}
                      error={error}
                      disabled={
                        isSubmitting ||
                        !this.context.user.permissions.includes(Permissions.EDIT_COURSE)
                      }
                      value={start_date}
                      valueKey={`start_date`}
                      label={<Trans>start date (YYYY-MM-DD)</Trans>}
                    />
                  </div>

                  <div className="mt-4">
                    <Input
                      setState={this.setParentState}
                      error={error}
                      disabled={
                        isSubmitting ||
                        !this.context.user.permissions.includes(Permissions.EDIT_COURSE)
                      }
                      value={end_date}
                      valueKey={`end_date`}
                      label={<Trans>end date (YYYY-MM-DD)</Trans>}
                    />
                  </div>

                  {this.context.user.permissions.includes(Permissions.EDIT_COURSE) && (
                    <Button
                      disabled={isSubmitting}
                      colour={`blue`}
                      classes="mt-4"
                      text={<Trans>Update</Trans>}
                    />
                  )}
                </form>

                <div className="mt-8">
                  <h2 className="text-2xl">Students ({students.length})</h2>

                  {students.map(student => (
                    <div key={student.id}>
                      {student.full_name} ({student.email}){" "}
                      <Link className="text-teal-500 hover:underline" to={`/users/${student.id}`}>
                        View
                      </Link>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </LoadingWrapper>
        </PageBody>
      </div>
    );
  }
}

export default DegreeShow;
