import React, { Component } from "react";
import { Trans } from "@lingui/macro";
import { get, getLongTimeout } from "../../api";
import { ApiContext } from "../../contexts/ApiContext";
import { Chart, LoadingWrapper } from "@iforwms/react-components";

class StatsOverview extends Component {
  static contextType = ApiContext;

  state = {
    isLoading: true,
    error: null,
    braintree: 0,
    apple: 0,
    google: 0,
    users: 0,
    income: 0,
    subscriptionsTotal: 0,
    chartData: null
  };

  componentDidMount() {
    this._isMounted = true;
    this.fetchData();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  safeSetState = (...args) => {
    this._isMounted && this.setState(...args);
  };

  fetchData = () => {
    this.safeSetState({
      isLoading: true,
      error: null
    });
    this.context
      .callApi(() => getLongTimeout("/reports/overview"))
      .then(({ data }) => {
        this.safeSetState({
          users: data.totals.users,
          braintree: data.totals.braintree,
          apple: data.totals.apple,
          google: data.totals.google,
          subscriptionsTotal: data.totals.total,
          income: data.totals.income,
          chartData: this.parseChartData(data.chart_data),
          isLoading: false
        });
      })
      .catch(error => {
        this.safeSetState({
          isLoading: false,
          error
        });
      });
  };

  parseChartData = data => {
    return { gateways: data.gateways, labels: data.labels };
  };

  render() {
    const {
      isLoading,
      braintree,
      apple,
      google,
      users,
      chartData,
      income,
      subscriptionsTotal
    } = this.state;
    return (
      <div className="flex flex-col mx-auto text-gray-800">
        <div className="p-4 shadow border rounded border-collapse mb-4">
          <LoadingWrapper minHeight={92} isLoading={isLoading}>
            {chartData ? (
              <Chart
                get={get}
                rawData={chartData}
                title="Subscriptions by Gateway (USD / month)"
                type="stackedBar"
              />
            ) : null}

            <table className="w-full">
              <tbody className="text-sm">
                <tr className={`border-b`}>
                  <td className="py-1">
                    <Trans>Registered Users</Trans>
                  </td>
                  <td className="text-right whitespace-no-wrap">{users}</td>
                </tr>
                <tr className={`border-b`}>
                  <td className="py-1">
                    <Trans>Monthly Income (USD / month)</Trans>
                  </td>
                  <td className="text-right whitespace-no-wrap">{income}</td>
                </tr>

                <tr>
                  <td className="py-1">
                    <Trans>Braintree Subscriptions</Trans>
                  </td>
                  <td className="text-right">{braintree}</td>
                </tr>
                <tr>
                  <td className="py-1">
                    <Trans>Apple Subscriptions</Trans>
                  </td>
                  <td className="text-right">{apple}</td>
                </tr>
                <tr className={`border-b`}>
                  <td className="py-1">
                    <Trans>Google Subscriptions</Trans>
                  </td>
                  <td className="text-right">{google}</td>
                </tr>
                <tr>
                  <td className="py-1">
                    <Trans>Total Subscriptions</Trans>
                  </td>
                  <td className="text-right">{subscriptionsTotal}</td>
                </tr>
              </tbody>
            </table>
          </LoadingWrapper>
        </div>
      </div>
    );
  }
}

export default StatsOverview;
