import React from "react";
import { Redirect, Route } from "react-router-dom";
import { ApiContext } from "../contexts/ApiContext";

class PrivateRoute extends React.Component {
  static contextType = ApiContext;

  render() {
    const { component: Component, ...rest } = this.props;
    const { user } = this.context;

    if (!user) {
      return (window.location.href =
        process.env.REACT_APP_AUTH_URL + "?redirectUrl=" + window.location.href);
    }

    if (!user.permissions.includes("VIEW_ADMIN")) {
      return (
        <Route
          {...rest}
          render={props => (
            <Redirect
              to={{
                pathname: "/unauthorized",
                state: { from: props.location }
              }}
            />
          )}
        />
      );
    }

    if (!Component) return <Route {...rest} />;

    return <Route {...rest} render={props => <Component {...props} />} />;
  }
}

export default PrivateRoute;
