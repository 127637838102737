import PropTypes from "prop-types";
import React from "react";
import { parseError } from "@iforwms/helpers-js";

const InputError = ({ valueKey, error }) => {
  if (parseError(valueKey, error)) {
    return <span className="text-red-500 text-xs mt-1">{parseError(valueKey, error)}</span>;
  }

  return null;
};

InputError.propTypes = {
  error: PropTypes.object,
  valueKey: PropTypes.string
};

export default InputError;
