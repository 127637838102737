import React, { Component } from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import moment from "moment";
import { Trans } from "@lingui/macro";
import { ApiContext } from "../contexts/ApiContext";
import { get } from "../api";
import { Button } from "@iforwms/react-components";

class FeedbackTrackerMenu extends Component {
  static contextType = ApiContext;

  state = { audio: 0, user: 0 };

  componentDidMount() {
    this._isMounted = true;
    this.fetchFeedback();
    this.interval = setInterval(this.fetchFeedback, 1000 * 300);
  }

  componentWillUnmount() {
    this._isMounted = false;
    clearInterval(this.interval);
  }

  safeSetState = (...args) => {
    this._isMounted && this.setState(...args);
  };

  fetchFeedback = () => {
    let lastFetched = localStorage.getItem("DOMINO_LAST_ISSUE_FETCH");
    if (!lastFetched) {
      lastFetched = moment().toJSON();
    }
    const { user, audio } = this.state;
    this.context
      .callApi(() => get("/feedback/unread?last_fetched_at=" + lastFetched))
      .then(({ data }) => {
        this.safeSetState({
          user: user + data.user,
          audio: audio + data.audio
        });
        localStorage.setItem("DOMINO_LAST_ISSUE_FETCH", moment().toJSON());

        if (data.user > 0 || data.audio > 0) {
          toast.info(`Received new feedback.`);
        }
      })
      .catch(error => {});
  };

  render() {
    const { audio, user } = this.state;
    const { toggleMenu } = this.props;
    return (
      <>
        <Button
          colour={`white`}
          classes={`hover:bg-blue-800 flex`}
          muted={true}
          RouterLink={Link}
          onClick={() => {
            this.setState({ user: 0 });
            toggleMenu();
          }}
          url="https://my.dominochinese.com/feedback">
          <Trans>Feedback</Trans>{" "}
          {user > 0 && (
            <span
              className="flex items-center justify-center p-1 ml-2 text-xs text-white bg-red-500 rounded"
              style={{ minWidth: 21 }}>
              {user}
            </span>
          )}
        </Button>

        <Button
          colour={`white`}
          classes={`hover:bg-blue-800 flex`}
          muted={true}
          RouterLink={Link}
          onClick={() => {
            this.setState({ audio: 0 });
            toggleMenu();
          }}
          url="/audio">
          <Trans>Audio</Trans>{" "}
          {audio > 0 && (
            <span
              className="flex items-center justify-center p-1 ml-2 text-xs text-white bg-red-500 rounded"
              style={{ minWidth: 21 }}>
              {audio}
            </span>
          )}
        </Button>
      </>
    );
  }
}

export default FeedbackTrackerMenu;
