import React from "react";
import PropTypes from "prop-types";
import LoadingWrapper from "../LoadingWrapper";
import Panel from "../forms/Panel";
import Button from "../forms/Button";
import AudioPlayer from "../audioPlayer/AudioPlayer";
import DialogWord from "./DialogWord";
import DialogMoreInfo from "./DialogMoreInfo";
import MediaUpload from "../forms/MediaUpload";
import Label from "../forms/Label";
import Input from "../forms/Input";

class Dialog extends React.Component {
  state = {
    dialog: null,
    error: null,
    moreInfo: null,
    showPhonetic: false,
    showLevel: false,
    isLoading: false,
    isSubmitting: false
  };

  componentDidMount() {
    this._isMounted = true;
    if (this.props.id) {
      this.fetchDialog();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps) {
    if (this.props.id && prevProps.id !== this.props.id) {
      this.fetchDialog();
    }
  }

  safeSetState = (...args) => {
    this._isMounted && this.setState(...args);
  };

  setParentState = (valueKey, value) => {
    this.setState({ [valueKey]: value });
  };

  fetchDialog = () => {
    const { id, get } = this.props;
    this.safeSetState({
      isLoading: true,
      error: null
    });

    get(`/dialogs/${id}`)
      .then(({ data: { data } }) => {
        this.safeSetState({
          isLoading: false,
          dialog: data,
          rawText: data.raw_text
        });
      })
      .catch(error => {
        this.safeSetState({ isLoading: false, error });
      });
  };

  markComplete = () => {
    const { id, post } = this.props;
    this.safeSetState({ isSubmitting: true, error: null });

    post(`dialogs/${id}/complete`)
      .then(({ data: { data } }) => {
        this.safeSetState({
          isSubmitting: false,
          dialog: data
        });
      })
      .catch(error => {
        this.safeSetState({ isSubmitting: false, error });
      });
  };

  showWordDetails = word => {
    const hasCharacters = word.characters.filter(char => char.pinyin_unicode)
      .length;

    if (!hasCharacters) return;

    this.setState({ moreInfo: word });
  };

  handleUpdate = e => {
    e.preventDefault();

    const { onSuccess, onError, patch } = this.props;

    const payload = { data: this.state.rawText };
    this.safeSetState({ isSubmitting: true, error: null });

    patch(`/dialogs/${this.state.dialog.id}`, payload)
      .then(({ data }) => {
        this.safeSetState({ isSubmitting: false });
        if (onSuccess) {
          onSuccess(data);
        }
      })
      .catch(error => {
        this.safeSetState({ error, isSubmitting: false });
        if (onError) {
          onError(error);
        }
      });
  };

  render() {
    const {
      dialog,
      isLoading,
      error,
      isSubmitting,
      moreInfo,
      showPhonetic,
      showLevel,
      rawText
    } = this.state;
    const { isAdmin, RouterLink, post, handleAudioUploadSuccess } = this.props;

    return (
      <div className="max-w-5xl p-4 mx-auto">
        <LoadingWrapper isLoading={isLoading} error={error} retry={this.fetchDialog}>
          {dialog ? (
            <div>
              <div>
                <Panel colour={`green`}>
                  <div className={`flex flex-col md:flex-row items-start justify-between`}>
                    <div className={`flex items-start`}>
                      <h3 className={`text-lg text-green-500`}>
                        {dialog.title}
                      </h3>

                      {dialog.difficulty ? (
                        <Button
                          fullWidth={false}
                          RouterLink={RouterLink}
                          size={`xs`}
                          url={`/dialogs?difficulty=${dialog.difficulty}`}
                          colour={`blue`}
                          classes="ml-2"
                          text={dialog.difficulty}
                        />
                      ) : null}
                    </div>

                    <div className={`mt-2 md:mt-0`}>
                      <Button
                        size={`sm`}
                        fullWidth={false}
                        RouterLink={RouterLink}
                        url={`/dialogs`}
                        text={`Back to Index`}
                      />

                      <Button
                        size={`sm`}
                        classes={`ml-2`}
                        fullWidth={false}
                        colour={dialog.is_complete ? "green" : "blue"}
                        disabled={dialog.is_complete || isSubmitting}
                        onClick={this.markComplete}
                        text={
                          dialog.is_complete ? `Completed!` : `Mark Complete`
                        }
                      />
                    </div>
                  </div>

                  {dialog.scenario ? (
                    <h5 className="mt-2 text-sm text-gray-500">
                      {dialog.scenario}
                    </h5>
                  ) : null}

                  {dialog.topics.length ? (
                    <h5 className="mt-2 text-sm text-gray-500">
                      {dialog.topics.map(topic => (
                        <Button
                          size={`sm`}
                          RouterLink={RouterLink}
                          fullWidth={false}
                          text={topic.name}
                          url={`/dialogs?topic=${topic.name}`}
                          key={topic.id}
                          colour={`indigo`}
                          classes="mr-2"
                        />
                      ))}
                    </h5>
                  ) : null}

                  {isAdmin ? (
                    <div className={`mt-4`}>
                      <form
                        method={`PATCH`}
                        onSubmit={e => this.handleUpdate(e)}>
                        <div>
                          <Input
                            valueKey={`rawText`}
                            value={rawText}
                            type={`textarea`}
                            rows={10}
                            label={`Dialog Text`}
                            setState={this.setParentState}
                            disabled={isSubmitting}
                          />

                          <div className="mt-4">
                            <Label label={`Audio`} />
                            <div
                              className={`flex flex-col mt-2 md:flex-row items-center`}>
                              {dialog.audio_src ? (
                                <div className={`mb-2 md:mb-0 md:mr-2`}>
                                  <AudioPlayer
                                    classes={`p-2 border rounded`}
                                    src={dialog.audio_src}
                                  />
                                </div>
                              ) : null}

                              <MediaUpload
                                onError={() => {}}
                                post={post}
                                accept="audio/mpeg"
                                endpoint={`/dialogs/${dialog.id}/audio`}
                                onSuccess={handleAudioUploadSuccess}
                              />
                            </div>
                          </div>

                          <Button
                            fullWidth={false}
                            colour={`blue`}
                            disabled={isSubmitting}
                            classes="mt-4">
                            Update Dialog
                          </Button>
                        </div>
                      </form>
                    </div>
                  ) : null}
                </Panel>
              </div>
              <div className={`mt-4`}>
                <Panel colour={`pink`}>
                  <div
                    style={{ height: 80 }}
                    className="p-2 overflow-y-scroll border rounded">
                    <DialogMoreInfo info={moreInfo} />
                  </div>

                  <div
                    className={`flex flex-1 flex-col md:flex-row justify-between mt-4`}>
                    {dialog.audio_src ? (
                      <div className="w-full mr-0 md:mr-2">
                        <AudioPlayer src={dialog.audio_src} />
                      </div>
                    ) : null}

                    <div className={`mt-4 sm:mt-0 flex`}>
                      <Button
                        size={`sm`}
                        onClick={() =>
                          this.setState(prevState => ({
                            showPhonetic: !prevState.showPhonetic
                          }))
                        }
                        classes={`whitespace-no-wrap`}
                        text={showPhonetic ? `Hide Phonetic` : `Show Phonetic`}
                      />

                      <Button
                        classes={`ml-2 whitespace-no-wrap`}
                        onClick={() =>
                          this.setState(prevState => ({
                            showLevel: !prevState.showLevel
                          }))
                        }
                        size={`sm`}
                        text={showLevel ? `Hide Level` : `Show Level`}
                      />
                    </div>
                  </div>

                  <table className="mt-4 table-striped">
                    <tbody>
                      {dialog.sentences.map((s, index) => (
                        <tr key={index}>
                          <td
                            className={`p-2 cursor-pointer hover:text-primary-500`}
                            onClick={() =>
                              this.setState({
                                moreInfo: s.translation
                              })
                            }>
                            {s.actor}:
                          </td>

                          <td className={`p-2 flex flex-wrap`}>
                            {s.words.map((word, j) => (
                              <DialogWord
                                key={index + word.id + j}
                                word={word}
                                setWord={this.showWordDetails}
                                showPhonetic={showPhonetic}
                                showLevel={showLevel}
                              />
                            ))}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </Panel>
              </div>
            </div>
          ) : null}
        </LoadingWrapper>
      </div>
    );
  }
}

Dialog.propTypes = {
  get: PropTypes.func.isRequired,
  RouterLink: PropTypes.object.isRequired,
  isAdmin: PropTypes.bool.isRequired,
  post: PropTypes.func,
  patch: PropTypes.func
};

Dialog.defaultProps = {
  isAdmin: false
};

export default Dialog;
