import React, { Component } from "react";
import { toast } from "react-toastify";
import moment from "moment";
import { ApiContext } from "../contexts/ApiContext";
import { get } from "../api";

class Notification extends Component {
    static contextType = ApiContext;

    componentDidMount() {
        this._isMounted = true;
        this.fetchNotifications();
        this.interval = setInterval(this.fetchNotifications, 1000 * 60 * 60);
    }

    componentWillUnmount() {
        this._isMounted = false;
        clearInterval(this.interval);
    }

    safeSetState = (...args) => {
        this._isMounted && this.setState(...args);
    };

    fetchNotifications = () => {
        let lastFetched = localStorage.getItem("DOMINO_LAST_NOTIFICATION_FETCH");
        if (!lastFetched) {
            lastFetched = moment().toJSON();
            localStorage.setItem("DOMINO_LAST_NOTIFICATION_FETCH", lastFetched);
        }
        this.context
            .callApi(() => get("/notifications/unread?last_fetched_at=" + lastFetched))
            .then(({ data: { data } }) => {
                this.safeSetState({
                    notifications: data
                });

                localStorage.setItem("DOMINO_LAST_NOTIFICATION_FETCH", moment().toJSON());

                if (data.length) {
                    data.map(n =>
                        toast.info(n.title, {
                            autoClose: false,
                            position: toast.POSITION.BOTTOM_RIGHT
                        })
                    );
                }
            })
            .catch(error => {});
    };

    render() {
        return null;
    }
}

export default Notification;
