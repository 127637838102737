import React, { Component } from "react";
import PropTypes from "prop-types";
import { toast } from "react-toastify";
import { Trans } from "@lingui/macro";
import { Link } from "react-router-dom";
import moment from "moment";
import PageBody from "../../components/PageBody";
import {
  Button,
  CmsButton,
  CmsCard,
  CmsDate,
  CmsHtml,
  CmsImage,
  CmsMarkdown,
  CmsPanel,
  CmsSocialLinks,
  CmsVideo,
  colours,
  Input,
  LoadingWrapper,
  MediaUpload,
  PageHeader,
  CmsSignUpForm,
  Panel
} from "@iforwms/react-components";
import history from "../../components/history";
import { ApiContext } from "../../contexts/ApiContext";
import { get, patch, post, upload } from "../../api";
import { parseDate, parseErrorMessage } from "@iforwms/helpers-js";

export const components = {
  social_links: { component: CmsSocialLinks, icon: "fb" },
  sign_up_form: { component: CmsSignUpForm, icon: "login" },
  image: { component: CmsImage, icon: "photo" },
  panel: { component: CmsPanel, icon: "document" },
  card: { component: CmsCard, icon: "tablet" },
  video: { component: CmsVideo, icon: "video" },
  button: { component: CmsButton, icon: "button" },
  markdown: { component: CmsMarkdown, icon: "code" },
  html: { component: CmsHtml, icon: "code" },
  text: { component: CmsHtml, icon: "text" }
};

class Page extends Component {
  static contextType = ApiContext;

  state = {
    title: "",
    subtitle: "",
    author: "",
    isDirty: false,
    id: "",
    slug: "",
    body: [],
    excerpt: "",
    bg_image: "",
    bg_temp_image_name: "",
    published_year: "",
    published_month: "",
    published_day: "",
    published_hour: "",
    published_minute: "",
    login_required: false,
    newSection: "",
    isLoading: false,
    isSubmitting: false,
    error: null
  };

  componentDidMount() {
    this._isMounted = true;
    if (this.props.match.params.slug && !this.props.isCreate) {
      this.fetchItem();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  safeSetState = (...args) => {
    this._isMounted && this.setState(...args);
  };

  fetchItem = () => {
    const {
      isBlogPost,
      match: {
        params: { slug }
      }
    } = this.props;
    this.safeSetState({ isLoading: true, error: null });
    this.context
      .callApi(() => (isBlogPost ? get(`/posts/${slug}`) : get(`/pages/${slug}`)))
      .then(({ data: { data } }) => {
        this.safeSetState({
          title: data.title,
          author: data.author,
          excerpt: data.excerpt,
          bg_image: data.bg_image,
          bg_temp_image_name: null,
          bg_image_id: data.bg_image_id,
          subtitle: data.subtitle,
          slug: data.slug,
          login_required: data.login_required,
          id: data.id,
          body: data.body,
          published_year: data.published_at ? moment.utc(data.published_at).format("YYYY") : "",
          published_month: data.published_at ? moment.utc(data.published_at).format("M") : "",
          published_day: data.published_at ? moment.utc(data.published_at).format("D") : "",
          published_hour: data.published_at ? moment.utc(data.published_at).format("H") : "",
          published_minute: data.published_at ? moment.utc(data.published_at).format("m") : "",
          isLoading: false
        });
      })
      .catch(error => {
        this.safeSetState({ isLoading: false, error });
        toast.error(parseErrorMessage(error, <Trans>Failed to fetch item.</Trans>));
      });
  };

  removeSection = key => {
    this.setState(prevState => ({
      body: prevState.body.filter((section, index) => index !== key)
    }));
  };

  handleSectionUpdate = (data, key) => {
    // console.log("section updated", data, key);

    this.setState(prevState => ({
      body: prevState.body.map((section, index) => {
        if (index === key) {
          // console.log("merged section", section, data, {
          //   ...section,
          //   data: { ...section.data, ...data }
          // });

          if (typeof section.data === "string") {
            return { ...section, data };
          }

          return { ...section, data: { ...section.data, ...data } };
        }
        return section;
      })
    }));
  };

  addSection = section => {
    if (!section) return;

    this.setState(prevState => ({
      body: prevState.body.concat({
        type: section,
        data: section === "button" ? {} : ""
      })
    }));
  };

  handleCreate = () => {
    const {
      slug,
      body,
      title,
      subtitle,
      login_required,
      author,
      excerpt,
      published_year,
      published_month,
      published_day,
      published_hour,
      published_minute,
      bg_temp_image_name
    } = this.state;
    const { isBlogPost } = this.props;
    this.safeSetState({ isSubmitting: true, error: null });
    this.context
      .callApi(() =>
        isBlogPost
          ? post(`/posts`, {
              slug,
              published_at: parseDate(
                published_year,
                published_month,
                published_day,
                published_hour,
                published_minute
              ),
              body: JSON.stringify(body),
              title,
              author,
              bg_image: bg_temp_image_name,
              excerpt,
              subtitle
            })
          : post(`pages`, {
              slug,
              published_at: parseDate(
                published_year,
                published_month,
                published_day,
                published_hour,
                published_minute
              ),
              body: JSON.stringify(body),
              title,
              login_required,
              subtitle
            })
      )
      .then(({ data: { data } }) => {
        this.safeSetState({
          isSubmitting: false
        });
        isBlogPost ? history.push("/posts/" + data.slug) : history.push("/pages/" + data.slug);
        toast.success(<Trans>Created!</Trans>);
      })
      .catch(error => {
        this.safeSetState({ isSubmitting: false, error });

        toast.error(parseErrorMessage(error, <Trans>Failed to create page.</Trans>));
      });
  };

  reorderArray = (newIndex, oldIndex) => {
    const movedItem = this.state.body.find((item, index) => index === oldIndex);
    const remainingItems = this.state.body.filter((item, index) => index !== oldIndex);

    const reorderedItems = [
      ...remainingItems.slice(0, newIndex),
      movedItem,
      ...remainingItems.slice(newIndex)
    ];

    this.setState({ body: reorderedItems });
  };

  handleSubmit = e => {
    e.preventDefault();

    if (!this.props.match.params.slug) {
      return this.handleCreate();
    }

    const { isBlogPost } = this.props;

    const {
      slug,
      body,
      title,
      subtitle,
      login_required,
      author,
      bg_temp_image_name,
      bg_image_id,
      published_year,
      published_month,
      published_day,
      published_hour,
      published_minute,
      excerpt
    } = this.state;
    this.safeSetState({ isSubmitting: true, error: null });
    this.context
      .callApi(() =>
        isBlogPost
          ? patch(`/posts/${this.props.match.params.slug}`, {
              published_at: parseDate(
                published_year,
                published_month,
                published_day,
                published_hour,
                published_minute
              ),
              body: JSON.stringify(body),
              title,
              excerpt,
              bg_image: bg_temp_image_name ? bg_temp_image_name : parseInt(bg_image_id),
              author,
              subtitle
            })
          : patch(`pages/${this.props.match.params.slug}`, {
              slug,
              published_at: parseDate(
                published_year,
                published_month,
                published_day,
                published_hour,
                published_minute
              ),
              body: JSON.stringify(body),
              title,
              login_required,
              subtitle
            })
      )
      .then(({ data: { data } }) => {
        this.safeSetState({
          isSubmitting: false,
          isDirty: false,
          title: data.title,
          author: data.author,
          excerpt: data.excerpt,
          bg_image: data.bg_image,
          bg_image_id: data.bg_image_id,
          bg_temp_image_name: null,
          subtitle: data.subtitle,
          slug: data.slug,
          login_required: data.login_required,
          id: data.id,
          body: data.body,
          published_year: data.published_at ? moment.utc(data.published_at).format("YYYY") : "",
          published_month: data.published_at ? moment.utc(data.published_at).format("M") : "",
          published_day: data.published_at ? moment.utc(data.published_at).format("D") : "",
          published_hour: data.published_at ? moment.utc(data.published_at).format("H") : "",
          published_minute: data.published_at ? moment.utc(data.published_at).format("m") : ""
        });
        isBlogPost ? history.push("/posts/" + data.slug) : history.push("/pages/" + data.slug);
        toast.success(<Trans>Saved!</Trans>);
      })
      .catch(error => {
        this.safeSetState({ isSubmitting: false, error });

        toast.error(parseErrorMessage(error, <Trans>Failed to save page.</Trans>));
      });
  };

  setParentState = (valueKey, value) => {
    this.setState({ [valueKey]: value, isDirty: true });
  };

  render() {
    const {
      isLoading,
      body,
      title,
      subtitle,
      author,
      bg_image,
      bg_temp_image_name,
      excerpt,
      slug,
      published_year,
      published_month,
      published_day,
      published_hour,
      published_minute,
      error,
      isSubmitting,
      isDirty,
      login_required
    } = this.state;
    const { isBlogPost } = this.props;

    return (
      <div>
        <PageHeader
          isAdmin={true}
          Link={Link}
          backLink={isBlogPost ? "/posts" : "/pages"}
          title={isBlogPost ? <Trans>Edit Blog Post</Trans> : <Trans>Edit Static Page</Trans>}>
          <div className="">
            <div className="flex items-center justify-between">
              <div className={`flex-wrap -mx-1`}>
                {Object.keys(components).map(c => (
                  <Button
                    colour={`pink`}
                    fullWidth={false}
                    icon={components[c].icon}
                    size={`xxs`}
                    text={c}
                    classes={`uppercase mx-1 mt-2`}
                    key={c}
                    onClick={() => this.addSection(c)}
                  />
                ))}
              </div>
              <div className={`flex items-center`}>
                <Button
                  RouterLink={Link}
                  fullWidth={false}
                  classes={`whitespace-no-wrap`}
                  size={`xs`}
                  disabled={isDirty}
                  colour={`orange`}
                  onClick={
                    isBlogPost
                      ? () => history.push(`/posts/${slug}/preview`)
                      : () => history.push(`/pages/${slug}/preview`)
                  }>
                  <Trans>Preview</Trans>
                  {isDirty ? " (Unsaved Changes)" : null}
                </Button>

                <Button
                  size={`xs ml-2`}
                  onClick={this.handleSubmit}
                  colour={`blue`}
                  disabled={isSubmitting}>
                  {isSubmitting ? <Trans>Saving...</Trans> : <Trans>Save</Trans>}
                </Button>
              </div>
            </div>
          </div>
        </PageHeader>

        <PageBody>
          <div className="p-4">
            <LoadingWrapper isLoading={isLoading} retry={this.fetchItem}>
              <div className={`mt-4`}>
                <form method="POST" onSubmit={this.handleSubmit}>
                  <div className="mb-4">
                    <Panel icon={`info`} title={<Trans>Metadata</Trans>} colour={`pink`}>
                      <div className="">
                        <Input
                          error={error}
                          setState={this.setParentState}
                          label={`Title`}
                          value={title}
                          valueKey={`title`}
                        />
                      </div>

                      <div className="mt-4">
                        <Input
                          error={error}
                          setState={this.setParentState}
                          label={`Subtitle`}
                          value={subtitle}
                          valueKey={`subtitle`}
                        />
                      </div>

                      <div className="mt-4">
                        <CmsDate
                          label={`Published At (YYYY/MM/DD HH:mm)`}
                          setParentState={this.setParentState}
                          keyPrefix={`published`}
                          year={published_year}
                          month={published_month}
                          day={published_day}
                          hour={published_hour}
                          minute={published_minute}
                        />
                      </div>

                      {isBlogPost ? (
                        <>
                          <div className="mt-4">
                            <Input
                              error={error}
                              setState={this.setParentState}
                              label={`Author`}
                              value={author}
                              valueKey={`author`}
                            />
                          </div>
                          <div className="mt-4">
                            <Input
                              error={error}
                              setState={this.setParentState}
                              label={`Excerpt`}
                              value={excerpt}
                              valueKey={`excerpt`}
                            />
                          </div>

                          <div className="mt-4">
                            <MediaUpload
                              post={post}
                              label={<Trans>Featured Image</Trans>}
                              endpoint={`/media`}
                              onError={() => {}}
                              accept="image/jpeg"
                              handleUpload={upload}
                              onSuccess={(file, data) => {
                                console.log("%cdata", "color: #3490dc; font-weight: bold", data);
                                this.safeSetState({
                                  bg_temp_image_name: data.data.name
                                });
                              }}
                            />

                            {bg_temp_image_name ? (
                              <div className={`mt-4`}>
                                <Panel colour={`orange`} icon={`alert`}>
                                  <div className={`text-lg text-orange-500`}>
                                    <Trans>Save to preview image.</Trans>
                                  </div>
                                </Panel>
                              </div>
                            ) : bg_image ? (
                              <img className="rounded" src={bg_image} alt="" />
                            ) : null}

                            {error && error.errors && error.errors.bg_image ? (
                              <span className="text-xs text-red-500 mt-1">
                                {error.errors.bg_image}
                              </span>
                            ) : null}
                          </div>
                        </>
                      ) : (
                        <div className="mt-4">
                          <Input
                            error={error}
                            type={`checkbox`}
                            setState={this.setParentState}
                            label={`login_required`}
                            value={login_required}
                            valueKey={`login_required`}
                          />
                        </div>
                      )}
                    </Panel>
                  </div>

                  <div className="flex flex-col lg:flex-row">
                    <div className="flex-1 max-w-xl overflow-y-auto max-h-screen ">
                      {body.map((section, index) => {
                        const Section = components[section.type].component;
                        return (
                          <div className={`mb-4`} key={`${index}_${body.length}`}>
                            <Panel
                              colour={colours[index % colours.length]}
                              title={
                                <>
                                  {section.type}
                                  {(section.type === "image" || section.type === "video") &&
                                  section.data &&
                                  section.data.name ? (
                                    <> ({section.data.name})</>
                                  ) : null}
                                </>
                              }>
                              <div className="">
                                <Section
                                  post={post}
                                  onError={() => {}}
                                  onSuccess={() => {}}
                                  onUpdate={data => this.handleSectionUpdate(data, index)}
                                  isEditing={true}
                                  title={title}
                                  data={section.data}
                                />
                              </div>

                              <div className="flex justify-between mt-2">
                                <div className="flex">
                                  {index < body.length - 1 && (
                                    <Button
                                      classes={`mr-2`}
                                      type="button"
                                      onClick={() => this.reorderArray(index + 1, index)}
                                      icon={`down`}
                                    />
                                  )}
                                  {index > 0 && (
                                    <Button
                                      type="button"
                                      onClick={() => this.reorderArray(index - 1, index)}
                                      icon={`up`}
                                    />
                                  )}
                                </div>
                                <Button
                                  fullWidth={false}
                                  colour={`red`}
                                  type="button"
                                  onClick={() => this.removeSection(index)}
                                  icon={`delete`}
                                />
                              </div>
                            </Panel>
                          </div>
                        );
                      })}

                      <div className="mt-4">
                        <Button colour={`blue`} disabled={isSubmitting}>
                          {isSubmitting ? <Trans>Saving...</Trans> : <Trans>Save</Trans>}
                        </Button>
                      </div>
                    </div>

                    <div className="flex-1 static-content overflow-y-auto max-h-screen flex items-start border rounded mt-4 md:mt-0 md:ml-4">
                      <div>
                        <PageHeader title={title} description={subtitle} />
                        <div className={`body`}>
                          {body.map(({ type, data }, index) => {
                            const Section = components[type].component;
                            return (
                              <div key={index} className="">
                                <Section post={post} RouterLink={Link} title={title} data={data} />
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </LoadingWrapper>
          </div>
        </PageBody>
      </div>
    );
  }
}

Page.propTypes = {
  isBlogPost: PropTypes.bool.isRequired,
  isCreate: PropTypes.bool.isRequired
};

Page.defaultProps = {
  isBlogPost: false,
  isCreate: false
};

export default Page;
