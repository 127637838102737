import React, { Component } from "react";
import { Trans } from "@lingui/macro";
import { toast } from "react-toastify";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import PageHeader from "../components/PageHeader";
import PageBody from "../components/PageBody";
import { ApiContext } from "../contexts/ApiContext";
import { get, destroy } from "../api";
import parseErrorMessage from "helpers-js/parseErrorMessage";
import history from "../components/history";
import { PaginationLinks, Panel, LoadingWrapper, Table } from "@iforwms/react-components";
import moment from "moment";
import { Link } from "react-router-dom";

const MissingInfoNew = ({ location }) => (
  <div>
    <PageHeader isAdmin={true} title={<Trans>Missing Info</Trans>} />

    <PageBody>
      <div className="max-w-6xl p-4">
        <Table
          filters={[
            { label: "Model", valueKey: "model", options: ["video", "dialog", "word"] },
            { label: "Per Page", valueKey: "limit", options: [20, 50, 100] }
          ]}
          history={history}
          search={location.search}
          title={<Trans>Missing Info</Trans>}
          destroy={destroy}
          get={get}
          headers={[{ label: "Name", valueKey: "name" }]}
          endpoint={`/missing-info`}
          moment={moment}
          RouterLink={Link}
          actions={["VIEW", "DELETE", "CREATE"]}
        />
      </div>
    </PageBody>
  </div>
);

class MissingInfo extends Component {
  static contextType = ApiContext;
  state = {
    items: [],
    meta: null,
    links: null,
    endpoint: "",
    column: "",
    filters: [],
    isLoading: false,
    isSubmitting: false,
    error: null
  };

  componentDidMount() {
    this._isMounted = true;
    this.fetchItems();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.props.location.search !== prevProps.location.search) {
      this.fetchItems();
    }
  }

  safeSetState = (...args) => {
    this._isMounted && this.setState(...args);
  };

  updateFilter = () => {
    const { endpoint, column } = this.state;

    if (endpoint && column) {
      return history.push(`/missing-info?endpoint=${endpoint}&column=${column}`);
    }

    history.push(`/missing-info`);
  };

  fetchItems = url => {
    this.safeSetState({ isLoading: true, error: null });
    const { search } = window.location;

    let fetchUrl = url ? url : "/missing-info";
    fetchUrl += fetchUrl.includes("?") ? search.replace("?", "&") : search;

    this.context
      .callApi(() => get(fetchUrl))
      .then(({ data }) => {
        this.safeSetState({
          isLoading: false,
          items: data.data,
          meta: data.meta,
          filters: data.meta.filters,
          endpoint: data.meta.endpoint,
          column: data.meta.column,
          links: data.links
        });
      })
      .catch(error => {
        toast.error(parseErrorMessage(error, <Trans>Failed to fetch items.</Trans>));
        this.safeSetState({ isLoading: false, error, items: [], meta: null, links: null });
      });
  };

  genenerateLink = (endpoint, item) => {
    let url = `/${endpoint}/${item.id}`;

    if (endpoint === "words") {
      url = `/dictionary/${item.id}`;
    }

    if (endpoint === "sentences") {
      url = `/videos/${item.id}/sentences`;
    }

    return url;
  };

  render() {
    const { items, isLoading, meta, links, filters, endpoint, column } = this.state;

    const title =
      endpoint && column ? (
        `${endpoint.toUpperCase()} MISSING ${column.toUpperCase()} ${
          meta && meta.total ? "(" + meta.total + ")" : null
        }`
      ) : (
        <Trans>Missing Info</Trans>
      );

    return (
      <div>
        <PageHeader title={<Trans>Missing Info</Trans>} />
        <PageBody>
          <div className="max-w-2xl p-4 m-auto">
            <Panel colour={`blue`} title={title}>
              <LoadingWrapper isLoading={isLoading}>
                <div
                  className={`p-2 text-sm rounded-t bg-gray-200 flex items-center justify-start`}>
                  <div className={``}>
                    <div className={``}>
                      <label className={`block text-xs`}>
                        <Trans>Model</Trans>
                      </label>
                      <select
                        className={`px-2 py-1 mt-1 text-xs rounded bg-white`}
                        value={endpoint}
                        onChange={e => this.setState({ endpoint: e.target.value, column: "" })}>
                        <option value="" disabled>
                          SELECT MODEL
                        </option>
                        {filters.map(f => (
                          <option key={f.endpoint} value={f.endpoint}>
                            {f.endpoint.toUpperCase()}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  {endpoint ? (
                    <div
                      className={`ml-2 text-sm rounded-t bg-gray-200 flex items-center justify-start`}>
                      <div className={``}>
                        <label className={`block text-xs`}>
                          <Trans>COLUMN</Trans>
                        </label>
                        <select
                          className={`px-2 py-1 mt-1 text-xs rounded bg-white`}
                          value={column}
                          onChange={e =>
                            this.setState({ column: e.target.value }, this.updateFilter)
                          }>
                          <option value="" disabled>
                            SELECT COLUMN
                          </option>
                          {filters
                            .filter(f => f.endpoint === endpoint)[0]
                            ["columns"].map(col => (
                              <option key={col} value={col}>
                                {col.toUpperCase()}
                              </option>
                            ))}
                        </select>
                      </div>
                    </div>
                  ) : null}
                </div>

                <table className={`w-full`}>
                  <tbody>
                    {!items.length && (
                      <tr>
                        <td className={`p-2`} colSpan={2}>
                          {endpoint && column ? (
                            <>
                              <Trans>No items matched your search.</Trans>
                              <span
                                className={`underline cursor-pointer inline-block ml-2`}
                                onClick={() =>
                                  this.setState({ endpoint: "", column: "" }, this.updateFilter)
                                }>
                                <Trans>Clear Filters</Trans>
                              </span>
                            </>
                          ) : (
                            <Trans>Please select a model and column.</Trans>
                          )}
                        </td>
                      </tr>
                    )}

                    {items.map((item, index) => (
                      <tr key={index} className={`border-b hover:bg-gray-200 whitespace-no-wrap`}>
                        <td>
                          <Link
                            to={this.genenerateLink(endpoint, item)}
                            className={`block py-1 px-2 cursor-pointer whitespace-no-wrap`}>
                            {item.name}
                          </Link>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>

                {meta && meta.total && links && links.first ? (
                  <div className="max-w-2xl px-4 py-8 mx-auto">
                    <PaginationLinks
                      meta={meta}
                      fetchData={this.fetchItems}
                      isLoading={isLoading}
                      links={links}
                    />
                  </div>
                ) : null}
              </LoadingWrapper>
            </Panel>
          </div>
        </PageBody>
      </div>
    );
  }
}

export default MissingInfo;
