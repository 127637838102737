import React from "react";
import PropTypes from "prop-types";
import { Button, Input, Panel } from "@iforwms/react-components";
import { QuizContext } from "../../../contexts/QuizContext";

class QuizAnswer extends React.Component {
  static contextType = QuizContext;

  removeAnswer = () => {
    const { questions, setQuizState } = this.context;
    const { answer, question } = this.props;

    const confirm = window.confirm("Are you sure?");

    if (!confirm) return;

    setQuizState(
      "questions",
      questions.map(q => {
        if (q.id === question.id) {
          return {
            ...q,
            answers: q.answers.filter(a => a.id !== answer.id)
          };
        }
        return q;
      })
    );
  };

  handleUpdate = (valueKey, value) => {
    const { questions, setQuizState } = this.context;
    const { question, answer } = this.props;

    const updatedQuestions = questions.map(q => {
      if (q.id === question.id) {
        return {
          ...q,
          answers: q.answers.map(a => {
            if (a.id === answer.id) {
              return { ...a, [valueKey]: value };
            }
            return a;
          })
        };
      }
      return q;
    });

    setQuizState("questions", updatedQuestions);
  };

  render() {
    const { question, answer, isSubmitting } = this.props;

    return (
      <Panel colour={`indigo`}>
        <div className="mt-2">
          <Input
            setState={this.handleUpdate}
            value={answer.text}
            valueKey={`text`}
            disabled={isSubmitting}
          />

          {["choice", "text"].includes(question.type) ? (
            <Input
              setState={this.handleUpdate}
              disabled={isSubmitting}
              containerClasses={`mt-2`}
              type={`checkbox`}
              label={`Is correct?`}
              value={answer.is_correct}
              valueKey={`is_correct`}
            />
          ) : null}
        </div>

        {["choice", "text"].includes(question.type) ||
        (question && question.answers && question.answers.length > 1) ? (
          <div className={`mt-4`}>
            <Button
              fullWidth={false}
              classes={`ml-auto`}
              size={`xs`}
              type={`button`}
              colour={`red`}
              icon={`delete`}
              text={`Remove Answer`}
              onClick={this.removeAnswer}
              disabled={isSubmitting}
            />
          </div>
        ) : null}
      </Panel>
    );
  }
}

QuizAnswer.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
  question: PropTypes.object.isRequired,
  answer: PropTypes.object.isRequired
};

export default QuizAnswer;
