import React, { Component } from "react";
import { Trans } from "@lingui/macro";
import PrivateRoute from "../components/PrivateRoute";
import { AutoComplete, Form } from "@iforwms/react-components";
import PageBody from "../components/PageBody";
import PageHeader from "../components/PageHeader";
import { destroy, get, patch, post } from "../api";
import { toast } from "react-toastify";

class Grammar extends Component {
  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  goToItem = id => {
    this.props.history.push(`/grammar/${id}`);
  };

  handleSelect = item => {
    this.goToItem(item.id);
  };

  handleUpdate = item => {
    this.autoComplete.resetState();
    toast.success(<Trans>Grammar updated!</Trans>);
  };

  handleDelete = () => {
    this.props.history.push(`/grammar`);
    this.autoComplete.resetState();
  };

  render() {
    return (
      <div>
        <PageHeader title={<Trans>Grammar Points</Trans>} />

        <PageBody>
          <div className="p-4 md:p-8">
            <div className="mb-8 container-really-narrow">
              <AutoComplete
                get={get}
                post={post}
                label={<Trans>Search Grammar Points</Trans>}
                handleSelect={this.handleSelect}
                updateState={this.handleUpdate}
                ref={autoComplete => {
                  this.autoComplete = autoComplete;
                }}
                url="grammars/"
              />
            </div>

            <PrivateRoute
              path={`/grammar/:id`}
              render={props => (
                <Form
                  title={`Edit Grammar Point`}
                  method={`patch`}
                  destroy={destroy}
                  deleteEndpoint={`grammars/${props.match.params.id}`}
                  patch={patch}
                  get={get}
                  handleSuccess={() => toast.success(<Trans>Grammar updated!</Trans>)}
                  fetchEndpoint={`grammars/${props.match.params.id}`}
                  submitEndpoint={`grammars/${props.match.params.id}`}
                  inputs={[{ type: "textarea", value_key: "text", label: "Text" }]}
                />
              )}
            />
          </div>
        </PageBody>
      </div>
    );
  }
}

export default Grammar;
