import React from "react";
import PropTypes from "prop-types";
import { Button, Input, Panel, SelectInput } from "@iforwms/react-components";
import { QuizContext } from "../../../contexts/QuizContext";
import QuizAnswer from "./QuizAnswer";

class QuizQuestion extends React.Component {
  static contextType = QuizContext;

  handleUpdate = (valueKey, value) => {
    const { questions, setQuizState } = this.context;
    const { question } = this.props;

    const updatedQuestions = questions.map(q => {
      if (q.id === question.id) {
        return { ...q, [valueKey]: value };
      }
      return q;
    });

    setQuizState("questions", updatedQuestions);
  };

  addAnswer = () => {
    const { questions, setQuizState } = this.context;
    const { question } = this.props;

    setQuizState(
      "questions",
      questions.map(q => {
        if (q.id === question.id) {
          return {
            ...q,
            answers: q.answers.concat({ id: new Date().toJSON(), text: "", is_correct: true })
          };
        }
        return q;
      })
    );
  };

  removeQuestion = () => {
    const { questions, setQuizState } = this.context;
    const { question } = this.props;

    const confirm = window.confirm("Are you sure?");

    if (!confirm) return;

    setQuizState(
      "questions",
      questions.filter(q => q.id !== question.id)
    );
  };

  render() {
    const { question, isSubmitting } = this.props;

    return (
      <Panel colour={`teal`}>
        <Input
          setState={this.handleUpdate}
          label={`Text`}
          disabled={isSubmitting}
          value={question.text}
          valueKey={`text`}
        />

        <SelectInput
          setState={this.handleUpdate}
          value={question.type}
          valueKey={`type`}
          containerClasses={`mt-4`}
          options={["choice", "sentence", "text"]}
          label={`Type`}
          disabled={isSubmitting}
          placeholder={`Select Type`}
        />

        {!question.answers.length ? <div className={`mt-4`}>No answers at the moment.</div> : null}

        {question.answers.map(answer => (
          <div className={`mt-4`} key={`${answer.id}`}>
            <QuizAnswer question={question} isSubmitting={isSubmitting} answer={answer} />
          </div>
        ))}

        <div className="flex mt-4">
          <Button
            type={`button`}
            onClick={this.removeQuestion}
            colour={`red`}
            size={`sm`}
            text={`Remove Question`}
            disabled={isSubmitting}
          />

          {["choice", "text"].includes(question.type) || question.answers.length === 0 ? (
            <Button
              type={`button`}
              onClick={this.addAnswer}
              text={`Add Answer`}
              classes={`ml-2`}
              size={`sm`}
              colour={`indigo`}
              disabled={isSubmitting}
            />
          ) : null}
        </div>
      </Panel>
    );
  }
}

QuizQuestion.propTypes = {
  isSubmitting: PropTypes.bool.isRequired,
  question: PropTypes.object.isRequired
};

export default QuizQuestion;
