import React from "react";
import PageHeader from "../components/PageHeader";
import { Trans } from "@lingui/macro";
import {
  AudioPlayer,
  Button,
  Label,
  CmsMarkdown,
  MediaUpload,
  Panel
} from "@iforwms/react-components";
import { post } from "../api";
import PageBody from "../components/PageBody";
import { toast } from "react-toastify";
import parseErrorMessage from "helpers-js/parseErrorMessage";

class DialogBulkCreate extends React.Component {
  state = {
    isSubmitting: false,
    error: null,
    dialogs: [],
    batch_id: null,
    currentDialog: null,
    currentDialogIndex: 0
  };

  componentDidMount() {
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  safeSetState = (...args) => {
    this._isMounted && this.setState(...args);
  };

  setParentState = (valueKey, value) => {
    this.setState({ [valueKey]: value });
  };

  handleSubmit = () => {
    this.safeSetState({ isSubmitting: true, error: null });
    const payload = this.state.dialogs.map(dialog => dialog.name);

    post(`/dialogs/bulk/${this.state.batch_id}`, { filenames: payload })
      .then(({ data }) => {
        toast.success(<Trans>Dialogs processing!</Trans>);
        this.safeSetState({ isSubmitting: false });
      })
      .catch(error => {
        toast.error(parseErrorMessage(error, <Trans>Failed to process dialogs</Trans>));
        this.safeSetState({ isSubmitting: false, error });
      });
  };

  handleError = error => {
    toast.error(parseErrorMessage(error, <Trans>Upload failed.</Trans>));
    this.safeSetState({ isSubmitting: false, error });
  };

  handleSuccess = (file, data) => {
    this.setState({
      dialogs: data.data,
      currentDialog: data.data.length ? data.data[0] : null,
      batch_id: data.id
    });
  };

  removeDialog = () => {
    this.setState(prevState => ({
      dialogs: prevState.dialogs.filter((dialog, index) => index !== this.state.currentDialogIndex)
    }));
  };

  render() {
    const { isSubmitting, dialogs, currentDialog, currentDialogIndex } = this.state;

    return (
      <div>
        <PageHeader title={<Trans>Dialog Bulk Create</Trans>} />

        <PageBody>
          <div className="p-4">
            <Panel title={<Trans>Dialog Bulk Create</Trans>} icon={`upload`}>
              <MediaUpload
                onError={this.handleError}
                onSuccess={this.handleSuccess}
                post={post}
                disabled={isSubmitting}
                endpoint={`/dialogs/bulk`}
                accept={"application/zip"}
                label={<Trans>Zip File</Trans>}
                description={
                  <Trans>
                    Upload a zip file with a collection of text files, and optional mp3 file of the
                    same names.
                  </Trans>
                }
              />
            </Panel>

            {dialogs.length ? (
              <div className="mt-8">
                <Panel title={`Files Waiting to Be Processed`} colour={`indigo`} icon={`flashcard`}>
                  <div className={`-mx-1 flex flex-wrap`}>
                    {dialogs.map((dialog, index) => (
                      <Button
                        key={index}
                        size={`sm`}
                        colour={currentDialogIndex === index ? `indigo` : "gray"}
                        classes={`m-1 w-8 h-8`}
                        fullWidth={false}
                        text={index + 1}
                        onClick={() =>
                          this.setState(prevState => ({
                            currentDialog: prevState.dialogs[index],
                            currentDialogIndex: index
                          }))
                        }
                      />
                    ))}
                  </div>

                  {currentDialog ? (
                    <div className={`mt-4`}>
                      {currentDialog.audio_path ? (
                        <div>
                          <Label label={currentDialog.audio_filename} />
                          <AudioPlayer
                            key={currentDialog.audio_path}
                            src={currentDialog.audio_path}
                          />
                        </div>
                      ) : (
                        <div className={`text-sm p-2 text-white rounded bg-red-500`}>
                          <Trans>Audio file not found.</Trans>
                        </div>
                      )}

                      {currentDialog.text ? (
                        <div className={`mt-4`}>
                          <Label label={currentDialog.text_filename} />
                          <div className={`whitespace-pre p-2 border rounded mt-1`}>
                            <CmsMarkdown data={currentDialog.text} />
                          </div>
                        </div>
                      ) : null}

                      <div className="mt-2">
                        <Button
                          fullWidth={false}
                          text={<Trans>Remove</Trans>}
                          icon={`delete`}
                          colour={`red`}
                          disabled={isSubmitting}
                          onClick={this.removeDialog}
                        />
                      </div>
                    </div>
                  ) : null}

                  <div className="mt-4">
                    <Button
                      text={`Process Dialogs`}
                      onClick={this.handleSubmit}
                      colour={`indigo`}
                      disabled={isSubmitting}
                    />
                  </div>
                </Panel>
              </div>
            ) : null}
          </div>
        </PageBody>
      </div>
    );
  }
}

export default DialogBulkCreate;
