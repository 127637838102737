import React from "react";
import PropTypes from "prop-types";
import Label from "./Label";
import config from "../../config";
import InputError from "./InputError";
import { parseError } from "@iforwms/helpers-js";

const SelectInput = ({
  label,
  setState,
  valueKey,
  value,
  disabled,
  error,
  containerClasses,
  classes,
  description,
  placeholder,
  fullWidth,
  options,
  size,
  required
}) => (
  <div className={containerClasses}>
    {label ? <Label label={label} /> : null}
    <select
      className={`${config.styles.inputClasses} ${
        fullWidth ? "w-full" : ""
      } text-${size} ${classes} ${
        parseError(valueKey, error) ? "border-red-500" : ""
      }`}
      required={required}
      value={value}
      disabled={disabled}
      onChange={setState ? e => setState(valueKey, e.target.value) : null}>
      {placeholder ? (
        <option disabled value="">
          {placeholder}
        </option>
      ) : null}
      {options.map(option => (
        <option
          key={
            typeof option === "string" || typeof option === "number"
              ? option
              : option.value
          }
          value={
            typeof option === "string" || typeof option === "number"
              ? option
              : option.value
          }>
          {typeof option === "string" || typeof option === "number"
            ? option
            : option.title}
        </option>
      ))}
    </select>

    {description && (
      <span className="block text-xs mt-1 text-gray-500">{description}</span>
    )}

    <InputError error={error} valueKey={valueKey} />
  </div>
);

SelectInput.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  placeholder: PropTypes.string,
  required: PropTypes.bool.isRequired,
  fullWidth: PropTypes.bool.isRequired,
  size: PropTypes.string.isRequired,
  classes: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  setState: PropTypes.func,
  error: PropTypes.object,
  containerClasses: PropTypes.string.isRequired,
  options: PropTypes.PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.number,
      PropTypes.string,
      PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
          .isRequired,
        title: PropTypes.string.isRequired
      })
    ])
  ).isRequired,
  valueKey: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

SelectInput.defaultProps = {
  classes: "",
  containerClasses: "",
  disabled: false,
  readOnly: false,
  fullWidth: true,
  size: "md",
  required: false
};

export default SelectInput;
