import React from "react";
import PropTypes from "prop-types";
import config from "../../config";

const Label = ({ label, classes, children }) => (
  <label
    className={`${config.styles.labelClasses} ${classes}`}>
    {label}
    {children ? children : null}
  </label>
);

Label.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  classes: PropTypes.string
};

export default Label;
