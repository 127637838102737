/* eslint-disable import/first */
import React, { Component } from "react";
import { Router } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import { Trans } from "@lingui/macro";
import { toast } from "react-toastify";
import history from "./components/history";
import Header from "./components/Header";
import { ApiContext } from "./contexts/ApiContext";
import RoutesContainer from "./components/RoutesContainer";
import { post, get } from "./api";
import Spinner from "react-spinkit";
import ScrollToTop from "./components/ScrollToTop";

// import "react-toastify/dist/ReactToastify.css";
import "react-toastify/dist/ReactToastify.min.css";
import { I18nProvider } from "@lingui/react";
import { setupI18n } from "@lingui/core";
import catalogZh from "./locales/zh/messages.js";
import catalogEn from "./locales/en/messages.js";

import "./index.css";
import parseErrorMessage from "helpers-js/parseErrorMessage";
import Notification from "./components/Notifications";
import { dominoSvg } from "./helpers";

export const i18n = setupI18n();
const catalogs = { zh: catalogZh, en: catalogEn };

class App extends Component {
  static contextType = ApiContext;

  state = {
    booted: false,
    showMenu: false
  };

  componentDidMount() {
    document.title = process.env.REACT_APP_SITE_NAME;
    // this.checkJwt();
    const { setApiState, callApi } = this.context;
    callApi(() => get("/me"))
      .then(({ data }) => {
        setApiState("user", data);
        this.setState({ booted: true });
      })
      .catch(e => {
        return toast.error(parseErrorMessage(e, <Trans>Failed to fetch your details.</Trans>));
      });
  }

  switchLanguage = lang => {
    const { callApi, setApiState, user } = this.context;
    setApiState("lang", lang);

    if (user) {
      callApi(() => post("/me/lang", { language: lang }));
    }
  };

  toggleMenu = () => {
    this.setState(prevState => ({ showMenu: !prevState.showMenu }));
  };

  render() {
    const { booted, showMenu } = this.state;
    const { lang } = this.context;

    return (
      <I18nProvider i18n={i18n} language={lang} catalogs={catalogs}>
        {booted ? (
          <div>
            <Router history={history}>
              <div className={`${showMenu ? "show-menu" : ""}`}>
                {/*<Notification/>*/}
                <Header
                  toggleMenu={this.toggleMenu}
                  currentLanguage={lang}
                  switchLanguage={this.switchLanguage}
                />
                <div className="main-container">
                  <ScrollToTop>
                    <RoutesContainer />
                  </ScrollToTop>
                </div>
              </div>
            </Router>
          </div>
        ) : (
          <BootingScreen />
        )}
        <ToastContainer />
      </I18nProvider>
    );
  }
}

const BootingScreen = () => (
  <div
    style={{ backgroundImage: `url("${dominoSvg}")` }}
    className="min-h-screen bg-blue-900 text-white text-xl flex flex-col items-center justify-center">
    <Spinner name="cube-grid" color="#fc0b27" />
    <p className="mt-4">
      <Trans>Booting up, please wait...</Trans>
    </p>
  </div>
);

export default App;
