import React from "react";
import { Trans } from "@lingui/macro";
import moment from "moment";
import PageHeader from "../components/PageHeader";
import { Table } from "@iforwms/react-components";
import PageBody from "../components/PageBody";
import { Link } from "react-router-dom";
import { destroy, get } from "../api";
import history from "../components/history";

const Notification = ({ location }) => (
  <div>
    <PageHeader
      isAdmin={true}
      title={<Trans>Notification Index</Trans>}
      description={<Trans>Here's where you create notifications for users.</Trans>}
    />

    <PageBody>
      <div className="max-w-6xl p-4">
        <Table
          filters={[{ label: "Per Page", valueKey: "limit", options: [20, 50, 100] }]}
          history={history}
          search={location.search}
          title={<Trans>Notifications</Trans>}
          destroy={destroy}
          get={get}
          headers={[
            { label: "Title", valueKey: "title" },
            { label: "Published At", valueKey: "published_at", fromNow: true },
            { label: "Expires At", valueKey: "expires_at", fromNow: true }
          ]}
          endpoint={`/notifications`}
          moment={moment}
          RouterLink={Link}
          actions={["VIEW", "DELETE", "CREATE"]}
        />
      </div>
    </PageBody>
  </div>
);

export default Notification;
