import React from "react";
import { ApiContext } from "../contexts/ApiContext";
import QS from "query-string";
import PageHeader from "../components/PageHeader";
import PageBody from "../components/PageBody";
import { Trans } from "@lingui/macro";
import { Button, Panel } from "@iforwms/react-components";
import { post } from "../api";
import parseErrorMessage from "helpers-js/parseErrorMessage";
import { toast } from "react-toastify";
import { LoadingWrapper } from "@iforwms/react-components";

class ReceiptParser extends React.Component {
  static contextType = ApiContext;

  state = {
    receipt: "",
    parsedReceipt: null,
    gateway: "",
    isLoading: false,
    sku: "",
    error: null
  };

  componentDidMount() {
    this._isMounted = true;
    this.updateUrl();
  }

  updateUrl = () => {
    const query = QS.parseUrl(this.props.location.search).query;
    const { gateway, sku, receipt } = query;

    if (sku) {
      this.safeSetState({ sku });
    }

    if (receipt) {
      this.safeSetState({ receipt: atob(receipt) });
    }

    if (gateway) {
      this.safeSetState({ gateway });
    }
  };

  componentWillUnmount() {
    this._isMounted = false;
  }

  safeSetState = (...args) => {
    this._isMounted && this.setState(...args);
  };

  handleSubmit = e => {
    e.preventDefault();

    const { receipt, gateway, sku } = this.state;
    this.safeSetState({ isLoading: true, error: null });

    let payload = { gateway, receipt, sku };
    if (gateway === "APPLE") {
      delete payload.sku;
    }

    this.context
      .callApi(() => post("parse-receipt", payload))
      .then(({ data }) => {
        this.safeSetState({ isLoading: false, parsedReceipt: data.data });
      })
      .catch(error => {
        toast.error(parseErrorMessage(error, <Trans>Failed to parse receipt.</Trans>));
        this.safeSetState({ isLoading: false, error });
      });
  };

  render() {
    const { receipt, isLoading, error, parsedReceipt, gateway, sku } = this.state;

    return (
      <div>
        <PageHeader title={<Trans>Receipt Parser</Trans>} />
        <PageBody>
          <div className="px-4 pt-4">
            <Panel title={<Trans>Subscription Receipt Parser</Trans>} colour={`pink`}>
              <form method="POST" onSubmit={e => this.handleSubmit(e)}>
                <div>
                  <label className="block text-sm text-gray-500">
                    <Trans>Select Gateway</Trans>
                  </label>
                  <select
                    disabled={isLoading}
                    value={gateway}
                    onChange={e => this.setState({ gateway: e.target.value })}
                    className="mt-1 form-input">
                    <option value="">Select Gateway</option>
                    <option value="APPLE">Apple App Connect</option>
                    <option value="GOOGLE">Google Play</option>
                  </select>
                </div>

                <div className={`mt-2`}>
                  <label className="block text-sm text-gray-500">
                    {gateway === "APPLE" ? (
                      <Trans>Receipt String</Trans>
                    ) : (
                      <Trans>Purchase Token</Trans>
                    )}
                  </label>
                  <textarea
                    disabled={isLoading}
                    onChange={e => this.setState({ receipt: e.target.value })}
                    rows="8"
                    className="mt-1 form-input"
                    value={receipt}
                  />
                </div>

                {gateway === "APPLE" ? null : (
                  <div className={`mt-2`}>
                    <label className="block text-sm text-gray-500">
                      <Trans>SKU</Trans>
                    </label>

                    <input
                      type={`text`}
                      disabled={isLoading}
                      onChange={e => this.setState({ sku: e.target.value })}
                      className="mt-1 form-input"
                      value={sku}
                    />
                  </div>
                )}

                <Button
                  colour={`pink`}
                  disabled={isLoading}
                  classes="mt-4"
                  text={isLoading ? <Trans>Parsing...</Trans> : <Trans>Parse Receipt</Trans>}
                />
              </form>
            </Panel>
          </div>

          {parsedReceipt ? (
            <div className="px-4 mt-4">
              <Panel colour={`pink`} title={<Trans>Parsed Receipt</Trans>}>
                <LoadingWrapper error={error} isLoading={isLoading}>
                  <div>
                    <pre>
                      <code>{JSON.stringify(parsedReceipt, null, 2)}</code>
                    </pre>
                  </div>
                </LoadingWrapper>
              </Panel>
            </div>
          ) : null}
        </PageBody>
      </div>
    );
  }
}

export default ReceiptParser;
