import React from "react";
import PropTypes from "prop-types";

const Spinner = ({ colour }) => (
  <div className={`sk-cube-grid text-${colour}-500`}>
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
    <div />
  </div>
);

Spinner.propTypes = {
  colour: PropTypes.string.isRequired
};

Spinner.defaultProps = {
  colour: "red"
};

export default Spinner;
