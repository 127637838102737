import React from "react";
import { Trans } from "@lingui/macro";
import { Button } from "@iforwms/react-components";

const Unauthorized = () => (
  <div className="text-4xl p-2 w-full h-screen flex-col flex items-center justify-center bg-red-400">
    <div className="text-left">
      <h1 className="text-sm mb-2 uppercase text-red-600">Error 403</h1>
      <h2 className="text-2xl font-hairline text-white mb-2">
        <Trans>Oops! It doesn't look like you should be here!</Trans>
      </h2>
      <p className="text-white text-sm">
        <Trans>
          Click{" "}
          <Button isLink={true} classes="underline" url={process.env.REACT_APP_FRONTEND_BASE_URL}>
            here
          </Button>{" "}
          to return to safety!
        </Trans>
      </p>
    </div>
  </div>
);

export default Unauthorized;
