import React from "react";
import PropTypes from "prop-types";
import Button from "./forms/Button";

const PageHeader = ({
  colour,
  title,
  description,
  backLink,
  Link,
  children,
  searchBar,
  isAdmin
}) => {
  if (!isAdmin) {
    return (
      <div id={`page-header`} className={`no-print px-4 text-center py-4 md:py-6 bg-${colour}-500 text-white`}>
        <h1 className={`mt-2 text-xl md:text-4xl ${description ? "mb-2" : null} font-normal`}>
          {title}
        </h1>

        {description && <div className="max-w-2xl mx-auto leading-normal">{description}</div>}
      </div>
    );
  }

  return (
    <header id={`page-header`} style={{ marginLeft: 240 }} className={`p-2 shadow fixed top-0 inset-x-0 bg-white z-5`}>
      <div className="flex items-center">
        <div className="flex truncate">
          {Link && backLink && (
            <div className="mr-2">
              <Button RouterLink={Link} size={`sm`} url={backLink} icon={`left`} />
            </div>
          )}

          <h1 className={`text-xl text-${colour}-500 truncate`}>{title}</h1>
        </div>

        {searchBar ? searchBar : null}
      </div>

      {description ? (
        <div className="mt-2" style={{ maxWidth: 600 }}>
          <p className="text-sm leading-normal text-gray-600 mb-2">{description}</p>
        </div>
      ) : null}

      {children ? children : null}
    </header>
  );
};

PageHeader.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  backLink: PropTypes.string,
  Link: PropTypes.any,
  colour: PropTypes.string.isRequired,
  children: PropTypes.any,
  searchBar: PropTypes.any,
  isAdmin: PropTypes.bool.isRequired
};

PageHeader.defaultProps = {
  isAdmin: false,
  colour: "blue"
};

export default PageHeader;
