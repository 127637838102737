import React, { Component } from "react";

class PageBody extends Component {
    state = { headerHeight: 0 };

    componentDidMount() {
        const header = document.getElementById("page-header");

        if (header) {
            this.setState({
                headerHeight: header.offsetHeight
            });
        }
    }

    render() {
        return (
            <div className="text-gray-600" style={{ marginTop: this.state.headerHeight }}>
                {this.props.children}
            </div>
        );
    }
}

export default PageBody;
