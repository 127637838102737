import React from "react";
import { Trans } from "@lingui/macro";
import moment from "moment";
import Table from "../components/external/Table";
import PageHeader from "../components/PageHeader";
import PageBody from "../components/PageBody";
import { Link } from "react-router-dom";
import { destroy, get } from "../api";
import history from "../components/history";

const Subscriptions = ({ location }) => (
  <div>
    <PageHeader title={<Trans>Subscriptions</Trans>} />

    <PageBody>
      <div className="p-4">
        <div className="mb-2 flex flex-wrap">
          <div className="text-gray-500">Filter By Plan</div>
          <Link className="hover:underline ml-2" to="/subscriptions?plan=classroom">
            Classroom
          </Link>
          <Link className="hover:underline ml-2" to="/subscriptions?plan=ambassador">
            Ambassador
          </Link>
          <Link className="hover:underline ml-2" to="/subscriptions?plan=premium">
            Premium
          </Link>
          <Link className="hover:underline ml-2" to="/subscriptions?plan=basic">
            Basic
          </Link>
          <Link className="hover:underline ml-2" to="/subscriptions?plan=survival">
            Survival
          </Link>
        </div>

        <Table
          title={<Trans>Subscriptions</Trans>}
          destroy={destroy}
          history={history}
          search={location.search}
          get={get}
          filters={[{ label: "Per Page", valueKey: "limit", options: [20, 50, 100] }]}
          headers={[
            { label: "Email", valueKey: "email", url: "mailto:" },
            { label: "Plan", valueKey: "plan" },
            { label: "Gateway", valueKey: "gateway" },
            { label: "Created At", valueKey: "created_at", fromNow: true },
            { label: "Ends At", valueKey: "ends_at", fromNow: true },
            { label: "Cancelled At", valueKey: "cancelled_at", fromNow: true },
            { label: "Gateway ID", valueKey: "subscription_id", truncate: true }
          ]}
          endpoint={`/subscriptions`}
          moment={moment}
          RouterLink={Link}
          actions={["VIEW_USER", "PARSE_RECEIPT"]}
        />
      </div>
    </PageBody>
  </div>
);

export default Subscriptions;
